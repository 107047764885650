import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official"
import { cloneDeep, compact, get, has, isEqual, last, merge, remove, set, sortBy, startCase } from "lodash"
import moment from "moment"
import { useEffect, useMemo, useRef, useState } from "react"
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip } from "reactstrap"

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from "classnames"
import iassign from 'immutable-assign'
import { AttributionChart, AttributionData, AttributionPerformancePeriod, AttributionSeriesDataPoint, ComponentApprovalCode, ComponentExportType, ComponentOverrideSettings, ComponentPeriodType, ComponentType, LayoutSectionType, ListsForOrgFragment, ManagerPerformancePeriodicity, NewReportAttributionDataQuery, PlanAutocomplete, ReportAttributionDataQuery, ReportAttributionDataQueryVariables, ReportAttributionFetchFragment, ReportAttributionTableFragment, ReportAttributionTableRowFragment, ReportsFragment, SearchTypes, useClearReportComponentCacheMutation, useListSimpleQuery, useNewReportAttributionDataQuery, useReportAttributionDataQuery, useUpdateComponentApprovalMutation } from "../../../__generated__/graphql"
import { DATE_DISPLAY_FORMAT } from "../../../helpers/constant"
import { excludePropertyArray } from "../../../helpers/object"
import { convertComponentForMutation } from "../../../helpers/report"
import { StaticPortfolioSearch } from '../../Search/SearchAddStatic'
import { AutocompleteItemTypes } from '../../Search/SearchAutocomplete'
import EditButtons from '../../ui/EditButtons'
import { FormInput } from '../../ui/Forms/FormInput'
import { ApprovalsReportDisplay } from "../Shared/ReportApprovals"
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, ErrorComponent, ErrorComponentData, FootnotableComponent, FootnoteLink, getPortfolioRowLinks, IndividualComponentProps, LoadingComponent, OptionsModal, ReportDisplayType, TemplateComponent, ToolbarProps } from "../Shared/ReportComponent"
import { ReportDataPoint } from '../Shared/ReportDataPoint'
import { AggregatedReportEditProps, ReportEditField, ReportEditHeading, ReportEditInfoFields, ReportEditInstances, SimpleReportEdit } from "../Shared/ReportEdit"

const HIGHLIGHT_ROWS = ['Effective Weight', 'Benchmark Weight', 'Manager Effect', 'Allocation Effect']

// Used to store state that must be shared with export
export type AttributionComponentState = {
  cacheCleared: boolean
  loading?: boolean
  errored?: boolean
}

const Attribution: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportAttributionFetchFragment>
      expectedTypename={"AttributionSettings"}
      reactComponent={AttributionDisplayWrapper}
      {...props}
    />
  )
}

const fetchDisplayData = (data: NewReportAttributionDataQuery | ReportAttributionDataQuery, view: ReportDisplayType, usePreview: boolean): AttributionData => {
  if(has(data, 'componentPreviewData')) {
    const previewData = data as NewReportAttributionDataQuery
    return previewData?.componentPreviewData as AttributionData
  }

  const previewData:ReportAttributionDataQuery = data as ReportAttributionDataQuery

  if ((view === ReportDisplayType.Draft || view === ReportDisplayType.External) && previewData?.components?.[0]?.draftData) {
    return previewData?.components?.[0]?.draftData as AttributionData
  } else if (usePreview && previewData?.components?.[0]?.previewData) {
    return previewData?.components?.[0]?.previewData as AttributionData
  }

  return previewData.components?.[0]?.liveData as AttributionData
}

const AttributionDisplayWrapper: React.FC<IndividualComponentProps<ReportAttributionFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, componentNumber, report, clientId, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, hideSingleExport, overwriteDate, portfolioId, updateSettings: parentUpdateSettings, reportState: inState, setReportState, removeComponentStateValue, instanceType }) => {
  const name = component.name || ""
  const componentId = component.id
  const reportState = inState as AttributionComponentState

  const asOfDate = moment(overwriteDate || settings.date).format(DATE_DISPLAY_FORMAT)

  const [initSettings, setInitSettings] = useState(settings)
  const [hoveredRow, setHoveredRow] = useState<number | null>(null)
  const [optionsModalOpen, setOptionsModalOpen] = useState(false)
  const [optionsSettingUsed, setOptionsSettingUsed] = useState(false)
  const [clearReportComponentCache] = useClearReportComponentCacheMutation()
  const [updateComponentApprovalMutation] = useUpdateComponentApprovalMutation()

  useEffect(()=>{
    if(editMode === false && initSettings !== settings) {
      setInitSettings(settings)
    }
  }, [editMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateSettings = (newSettings: ReportAttributionFetchFragment) => {
    parentUpdateSettings(newSettings)
    setOptionsSettingUsed(true)
    setOptionsModalOpen(false)
  }

  // Only update this when editMode changes, otherwise the options modal will overwrite the initSettings

  const usePreview = (!isEqual(initSettings, settings) || optionsSettingUsed)
  const useNew = component.id < 1
  let usedSettings:{ [name: string]: any; } = {}

  if(usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.Attribution), ["__typename"])

    usedSettings = {
      attribution: {
        ...convertedSettings,
      }
    }
  }

  const lastPublishVersionId = last(sortBy(report?.publishVersions, 'published'))?.version


  const queryVariables: ReportAttributionDataQueryVariables = {
    ids: [component.id],
    liveView: (view === ReportDisplayType.Live) && !usePreview,
    draftView: (view === ReportDisplayType.Draft || view === ReportDisplayType.External) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
    publishVersionId: view !== ReportDisplayType.Live ? -1 : lastPublishVersionId
  }

  const { loading: newLoading, data: newData, error: newError, stopPolling: newStopPolling, startPolling: newStartPolling  } = useNewReportAttributionDataQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: !useNew
  })

  const { loading: baseLoading, data: baseData, error: baseError, stopPolling: baseStopPolling, startPolling: baseStartPolling, refetch  } = useReportAttributionDataQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: useNew
  })

  const stopPolling = () => {
    if(useNew){
      newStopPolling()
    } else {
      baseStopPolling()
    }
  }

  let loading, error, startPolling, data
  if(useNew) {
    loading = newLoading
    error = newError
    startPolling = newStartPolling
    data = newData
  } else {
    loading = baseLoading
    error = baseError
    startPolling = baseStartPolling
    data = baseData
  }

  useEffect(() => {
    if (get(reportState, 'cacheCleared') === true) {
      if (removeComponentStateValue) {
        removeComponentStateValue('cacheCleared')
      }

      refetch().then(() => {
        startPolling(5000)
      })
    }
  }, [reportState])

  useEffect(() => {
    const newState = { ...reportState }

    if (reportState.loading !== loading) {
      newState.loading = loading
    }

    if (data) {
      const displayedData = fetchDisplayData(data, view, usePreview)

      if (!displayedData || (displayedData.status === "Failed" && displayedData.error)) {
        newState.errored = true
      }
    } else if (reportState.errored !== !!error) {
      newState.errored = !!error
    }

    setReportState(newState)
  }, [loading, error, data])

  let baseTooltipProps:ToolbarProps = {
    refreshFunction: () => {
      clearReportComponentCache({
        variables: { ids: [component.id] },
      })
      .then(() => {
        refetch().then(() => {
          startPolling(5000)
        })
      })

      updateComponentApprovalMutation({
        variables: { input: {
          id: componentId,
          patch: {
            approval: ComponentApprovalCode._1,
          }
        }}
      })
    }
  }

  const baseHolderValues = {name, rightText: `As of ${asOfDate}`, componentNumber, editMode, selected, onClick: handleSelect, sectionNumber, editedDraftLayout, setEditedDraftLayout, portfolioId, instanceType, tooltipProps: baseTooltipProps, view}

  if(loading) return <LoadingComponent {...baseHolderValues}/>
  if(error) return <ErrorComponent error={error?.message} {...baseHolderValues}/>


  // Stop polling for the other type
  if(useNew){
    baseStopPolling()
  } else {
    newStopPolling()
  }

  if (data) {
    const displayedData = fetchDisplayData(data, view, usePreview)

    if(displayedData && displayedData.status !== "Pending"){
      stopPolling()
    }

    if(!displayedData || (displayedData.status === "Failed" && displayedData.error)) return (
      <ErrorComponentData
        errors={compact(displayedData.error) || []}
        report={report}
        {...baseHolderValues}
      />
    )

    if(displayedData.status === "Pending") return <LoadingComponent {...baseHolderValues}/>

    let tooltipProps:ToolbarProps = cloneDeep(baseTooltipProps)

    const attributionOverrideExportSettings:{ startDate?: string, period?: ComponentPeriodType | null } = {}

    if(componentNumber === -1){
      tooltipProps['exportOptions'] = {
        name: name,
        slides: [{
          title: name,
          sections: [{
            components: [componentId],
            type: LayoutSectionType.SingleColumnSection
          }]
        }],
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report?.client?.name
        }
      }

      if(view === ReportDisplayType.Live) {
        set(tooltipProps, 'exportOptions.settings.publishVersionId', lastPublishVersionId)
      }

      if (component.exportTypes?.includes(ComponentExportType.XLSX)) {
        tooltipProps['exportExcelOptions'] = {
          name: name,
          componentId: component.id,
          settings: {
            live: view === ReportDisplayType.Live
          }
        }
      }

      if (has(usedSettings,'attribution.reportingPerformancePeriod')) {
        if (usedSettings.attribution.reportingPerformancePeriod === AttributionPerformancePeriod.Trailing) {
          set(attributionOverrideExportSettings, 'period', usedSettings.attribution.attributionPeriod)
        } else {
          set(attributionOverrideExportSettings, 'startDate', usedSettings.attribution.startDate)
        }

        set(tooltipProps, 'exportOptions.settings.componentOverrideSettings', {
          attribution: [{
            componentId: componentId,
            ...attributionOverrideExportSettings
          }]
        })

        if (component.exportTypes?.includes(ComponentExportType.XLSX)) {
          set(tooltipProps, 'exportExcelOptions.settings.componentOverrideSettings', {
            attribution: {
              ...attributionOverrideExportSettings
            }
          })
        }
      }
    }


    tooltipProps['openOptionsModal'] = (view !== ReportDisplayType.External || auth.checkPermissions(["edit:product_analysis"])) ? () => setOptionsModalOpen(true) : undefined

    const tableData = displayedData?.table
    const chartData = displayedData?.chart

    return (
      <TemplateComponent
        componentTypeName='Attribution'
        report={report}
        componentId={componentId}
        setSelectedComponentId={setSelectedComponentId}
        auth={auth}
        hideExport={hideSingleExport}
        {...baseHolderValues}
        tooltipProps={tooltipProps}
      >
        <AttributionOptionsModal
          settings={settings}
          optionsModalOpen={optionsModalOpen}
          setOptionsModalOpen={setOptionsModalOpen}
          updateSettings={updateSettings}
        />
        <Row className="no-gutters">
          {/* <Col sm="12"> // Removed for the time being until new components are created.
            <PerformanceComparisonSupporting
              settings={settings}
              portfolio={portfolio}
              dcPortfolio={dcPortfolio}
            />
          </Col> */}
          { displayedData && chartData && (
            <AttributionGraph
              key={`attribution-graph-${componentId}`}
              editMode={editMode}
              chartData={chartData}
              hoveredRow={hoveredRow}
              rows={tableData?.rows || []}
              errors={compact(displayedData?.error) || []}
            />
          )}
        </Row>
        <Row className="no-gutters">
          <Col sm="12">
            { displayedData && tableData && (
              <AttributionDataTable
                key={`attribution-table-${componentId}`}
                hoveredRow={hoveredRow}
                setHoveredRow={setHoveredRow}
                tableData={tableData}
                view={view}
                componentId={componentId}
                report={report}
                clientId={clientId}
                planId={report?.plans?.[0]?.id || 0}
              />
            )}
          </Col>
        </Row>
      </TemplateComponent>
    )
  }

  return <ErrorComponent error={"No data returned"} {...baseHolderValues}/>

}

interface AttributionGraphProps {
  chartData: AttributionChart
  editMode: boolean
  errors: string[]
  hoveredRow: number | null
  rows: ReportAttributionTableRowFragment[]
}

const AttributionGraph: React.FC<AttributionGraphProps> = ({ editMode, chartData, hoveredRow, errors, rows }) => {
  const graphRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement>; }>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.chart.reflow()
      graphRef.current.chart.reflow()
      graphRef.current.chart.reflow()
    }
  }, [editMode])

  // Convert rows to a data set easier to work with for generating tooltips
  const rowTooltips = rows.map((row) => {
    const series = chartData.additionalSeries?.find((series) => series?.assetMixNum === row.rowId)
    return {
      rowId: row.rowId,
      name: row.name,
      series: series?.series
    }
  }, {})

  const chartOptions = merge(
    JSON.parse(chartData.config || '{}'),
    {
      tooltip: { // Setup tooltips
        useHTML: true,
        format: null,
        formatter: function() {
          const date = moment.utc(this.x).format('MMMM DD, YYYY')

          const allSeries = rowTooltips.map((row) => {
            const tooltipData = row.series?.map((series) => {
              return {
                name: series.name,
                value: series.data.find((p) => `${p.date}` === `${this.x}`)?.value
              }
            })

            return {
              name: row.name,
              data: tooltipData
            }
          })

          const seriesData = allSeries.map((series) => {
            return `
              <div style="border-top: 1px solid #dee2e6; padding-top: 3px; margin-top: 3px;">
                <strong>${series.name}</strong><br />
                ${series.data?.map((d) => `${d.name}: <b>${Number(d.value).toFixed(2)}</b>`).join('<br />')}
              </div>
            `
          })

          return `<span style="font-size: 0.8em">${date}</span><br/>${seriesData.join('')}`
        }
      },
      xAxis: {
        lineColor: '#dee2e6',
        tickColor: '#dee2e6',
        labels: { style: { fontSize: "14px" }}
      }
    }
  )

  if (hoveredRow && chartData.additionalSeries) {
    const newSeries = chartData.additionalSeries.find((series) => series?.assetMixNum === hoveredRow)

    if (newSeries) {
      const strippedSeries = excludePropertyArray(newSeries, ["__typename"])

      // convert series data points from object to 2 element array
      const convertedSeries = strippedSeries.series.map((series:any) => (
        iassign(
          series,
          (s) => s.data,
          (data) => {
            return data.map((point:AttributionSeriesDataPoint) => ([ point.date, point.value ]))
          }
        )
      ))

      chartOptions.series = convertedSeries
    }
  }

  const graph = useMemo(() => {
    return (
      <Col sm="12">
        <div className="form-section-title headline underline small-font-size mb-0 pb-0">
          {chartData.title}
          { errors && errors.length > 0 && (
            <>
              <div className="footnote-icon d-inline" ref={tooltipRef}>
                <FontAwesomeIcon
                  icon={"info-circle" as IconName}
                  size="sm"
                  className="text-danger"
                />
              </div>
              <UncontrolledTooltip placement='top' target={tooltipRef} delay={200} autohide={false}>
                { errors?.join(', ')}
              </UncontrolledTooltip>
            </>
          )}
        </div>
        <HighchartsReact
          ref={graphRef}
          options={chartOptions}
          highcharts={Highcharts}
          containerProps={{ style: { height: "400px"  }}}
        />
      </Col>
    )
  }, [chartOptions, chartData.title])

  return graph
}

interface AttributionTableRowProps {
  row: ReportAttributionTableRowFragment
  columns: string[]
  componentId: number
  hoveredRow: number | null
  setHoveredRow: (row: number | null) => void
  view: ReportDisplayType
  report?: ReportsFragment
  clientId?: number
  planId?: number
}

const AttributionTableRowDisplay: React.FC<AttributionTableRowProps> = (props) => {
  const { row, columns, componentId, setHoveredRow, view, report, clientId, planId } = props
  const { rowId, name, total, columnData } = row

  const draftView = view === ReportDisplayType.Draft

  const links: FootnoteLink[] = getPortfolioRowLinks({componentId: rowId, reportId: report?.id, planId, clientId, view })


  return (
    <tr
      className={classNames({
        "report-tr-title": true,
        "report-tr-total": total,
      })}
      key={`attribution-row-${componentId}-${rowId}`}
      onMouseEnter={() => setHoveredRow(rowId)}
      onMouseLeave={() => setHoveredRow(null)}
    >
      <td className="pl-0 text-left d-flex" >
        <FootnotableComponent
          // links={links}
        >
          <div className='report-table-title'>{ name }</div>
        </FootnotableComponent>
      </td>
      {
        columns && columns?.map((column, cidx) => {
          // const column = columns[idx]
          let dataPoint = undefined
          if(columnData && columnData[cidx]){
            dataPoint = columnData[cidx]
          }
          const classes = HIGHLIGHT_ROWS.includes(column) ? ['report-highlight'] : []

          return(
            <ReportDataPoint
              key={`attribution-cell-${componentId}-${rowId}-${cidx}`}
              dataPoint={dataPoint || undefined}
              draftView={draftView}
              cellClasses={classes}
            />
          )
        })
      }
    </tr>
  )
}

interface AttributionDataTableProps {
  tableData: ReportAttributionTableFragment
  hoveredRow: number | null
  setHoveredRow: (row: number | null) => void
  view: ReportDisplayType
  componentId: number
  report?: ReportsFragment
  clientId?: number
  planId?: number
}

const AttributionDataTable: React.FC<AttributionDataTableProps> = ({tableData, hoveredRow, setHoveredRow, view, componentId, report, clientId, planId}) => {
  const columns = compact(tableData.columns) || []
  const tableRows = compact(tableData.rows) || []

  return (
    <Col sm="12">
      <div className="form-section-title headline underline small-font-size mb-0 pb-0">
        {tableData.title}
      </div>

      <Table hover className="report-table-attribution-table report-table">
        <thead>
          <tr className='row-border-olive-100'>
            <th>Asset Class</th>
          {
            columns.map((column, idx) => (
              <th className={classNames(["pl-0 text-right", { 'report-highlight': HIGHLIGHT_ROWS.includes(column) }]) } key={`attribution-column-header-${idx}`}>
                { column }
              </th>
            ))
          }
          </tr>
        </thead>
        <tbody>
          {
            tableRows.map((row: ReportAttributionTableRowFragment, idx) => (
              <AttributionTableRowDisplay
                key={`attribution-row-${componentId}-${idx}`}
                row={row}
                columns={columns}
                componentId={componentId}
                hoveredRow={hoveredRow}
                setHoveredRow={setHoveredRow}
                view={view}
                report={report}
                clientId={clientId}
                planId={planId}
              />
            )
          )}
        </tbody>
      </Table>
    </Col>

  )

}

const AttributionReportingPeriodFields:ReportEditField[] = [
  {
    property: "attributionReportingPerformancePeriod",
    label: "",
    type: "radio",
    options: [{
      code: "Trailing",
      value: "Trailing",
    },{
      code: "StartingOn",
      value: "Starting On",
    }],
    subClasses: {
      inputWrapperClasses: "col-sm-12 radio-input-with-divider mt-1 mb-2 w-100",
    },
  }
]

const AttributionPerformanceFields = [
  {
    property: "performancePeriodicity",
    label: "Returns used",
    type: "select",
    optionSource: "ManagerPerformancePeriodicity",
    required: true
  },
  {
    property: "attributionPerformanceType",
    label: "Performance type",
    type: "select",
    optionSource: "FeeType",
    required: true,
    optionsMutateLabelFunction: (value: any) => value ? startCase(value.toLowerCase()) : ""
  }
]

const AttributionAdditionalReportingFields:ReportEditField[] = [
  {
    property: "lineChart",
    label: "Line Chart",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const AttributionEditFootnoteFields:ReportEditField[] = [
  {
    property: "showTargetFootnote",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const ComponentPeriodOptionsOrder = ['lastMonth', 'lastQuarter', 'yearToDate', 'fiscalYear', 'last1Year','last2Years', 'last3Years','last5Years','last7Years','last10Years','last15Years','last20Years','sinceInception']

const getColumnsByPeriod = (settings: ReportAttributionFetchFragment, inOptionsModal:boolean = false):ReportEditField[] => {
  switch (settings.attributionReportingPerformancePeriod) {
    case AttributionPerformancePeriod.Trailing:
      let newOptions = Object.values(ComponentPeriodType).map((period) => ({
        code: period,
        value: startCase(period)
      }))
      newOptions = newOptions.sort((a, b) => ComponentPeriodOptionsOrder.indexOf(a.code) - ComponentPeriodOptionsOrder.indexOf(b.code))

      if (settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly) {
        remove(newOptions, (option) => option.code === 'lastMonth')
      }

      return [{
        property: "attributionPeriod",
        label: "Period",
        type: "select",
        options: newOptions,
        sortOptions: false
      }]

    case AttributionPerformancePeriod.StartingOn:
      let subType = 'month'
      if (settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly) {
        subType = 'quarter'
      }

      let subClasses = {}
      if (inOptionsModal) {
        subClasses = {
          inputClasses: "in-modal",
        }
      }

      return [
        {
          property: "startDate",
          label: "Start on and include",
          type: "date",
          subtype: subType,
          required: true,
          subClasses
        }
      ]
    default:
      return []
  }
}

interface AttributionOptionsModalProps {
  settings: ReportAttributionFetchFragment
  optionsModalOpen: boolean
  setOptionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateSettings: (settings: ReportAttributionFetchFragment) => void
}

const AttributionOptionsModal = (props: AttributionOptionsModalProps) => {
  const { settings, optionsModalOpen, setOptionsModalOpen, updateSettings } = props
  const [settingsCopy, setSettingsCopy] = useState<ReportAttributionFetchFragment>(settings)


  let reportingPeriodColumns = [...AttributionReportingPeriodFields, ...getColumnsByPeriod(settingsCopy, true)]

  const handleSubmit = () => {
    if(updateSettings) updateSettings(settingsCopy)
    setOptionsModalOpen(false)
  }

  const handleInputChange = (value:any, property: string) => {
    setSettingsCopy((prevState) => {
      let newState = cloneDeep(prevState)
      set(newState, property, value)
      return newState
    })
  }

  return (
    <OptionsModal show={optionsModalOpen} setShow={setOptionsModalOpen} onSubmit={handleSubmit}>

      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={handleInputChange}
        currentState={settingsCopy}
      />
    </OptionsModal>
  )
}


export const AttributionEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId, report}) => {
  const [portfolioSelectionModalOpen, setPortfolioSelectionModalOpen] = useState(false)
  const settings = component.draftSettings as ReportAttributionFetchFragment
  const { data: listData } = useListSimpleQuery({
    variables: {id: settings.list?.id || 0},
    skip: !settings || !settings.list?.id,
    errorPolicy: "all",
  })

  if(!settings){
    return(
      <></>
    )
  }

  let reportingPeriodColumns = [...AttributionReportingPeriodFields, ...getColumnsByPeriod(settings)]

  const handleReportiongPeriodChange = (value: any, property: string) => {
    handleInputChange(value, property)
    if (property === "attributionReportingPerformancePeriod") {
      handleInputChange(null, "attributionPeriod")
      handleInputChange(null, "startDate")
    }
  }

  const ownedComponent = component.owner?.id === user?.person?.id

  let benchmarkOptions = settings?.clientPortfolio?.performanceTargetMap?.map((targetMap) => (
    {
      code: targetMap?.target?.targetId,
      value: targetMap?.target?.name || '',
    }
  ))
  let infoColumns = [...ReportEditInfoFields(handleInputChange), {
    property: "clientPortfolio",
    label: "Portfolio",
    type: "child",
  },
  {
    property: "target.targetId",
    label: "Benchmark",
    type: "select",
    options: benchmarkOptions,
    required: true
  }]

  const listIndex = infoColumns.findIndex((column) => column.property === "list.id")
  set(infoColumns, `[${listIndex}].filterOptionsMethod`, (list:ListsForOrgFragment) => list?.subCategory?.value === "Attribution")

  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Attribution"}
        componentType={ComponentType.Attribution}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      >
        <FormGroup className='row no-gutters' >
          <Label className="col-form-label col-sm-4">Portfolio</Label>
          <div className="col-sm-8"  >
            <div className='input-group' onClick={() => setPortfolioSelectionModalOpen(true)}>
              <div className='fake-input form-control d-flex justify-content-between'>
                <span className='overflow-ellipsis' >{ settings.clientPortfolio && `${settings.clientPortfolio?.name} (${settings.clientPortfolio?.id})` }</span>
                <FontAwesomeIcon icon="search" className="mr-0" />
              </div>
            </div>
          </div>
        </FormGroup>
        </SimpleReportEdit>
      <SimpleReportEdit
        name={"Performance"}
        retractable={true}
        fields={AttributionPerformanceFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"Additional Reporting"}
        retractable={true}
        fields={AttributionAdditionalReportingFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={handleReportiongPeriodChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={AttributionEditFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <PorfolioSelectionModal
        handleInputChange={handleInputChange}
        settings={settings}
        modalOpen={portfolioSelectionModalOpen}
        setModalOpen={setPortfolioSelectionModalOpen}
        report={report}
      />
    </>
  )
}

export const AttributionExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  // const { component } = props
  // const reportState = props.reportState as AttributionComponentState
  // const tab = reportState?.tab
  // const selectedDrillDown = reportState?.selectedDrillDown

  // return { attribution: [{
  //   componentId: component?.id,
  //   startDate:
  // }]}
  // return {PerformanceComparison: [
  //   {
  //     componentId: component?.id,
  //     selectedDate: tab?.value,
  //     drillDownGroup: parseInt(selectedDrillDown || "") || undefined
  //   }
  // ]}
  return {}
}

interface PortfolioSelectionModalProps {
  handleInputChange: (value: any, property: string) => void
  settings: ReportAttributionFetchFragment
  modalOpen: boolean,
  setModalOpen: (open:boolean) => void
  report: ReportsFragment
}

type PlanAutocompleteSearchType = {
  id: number
  name: string
}

const PorfolioSelectionModal = ({ handleInputChange, settings, modalOpen, setModalOpen, report }:PortfolioSelectionModalProps) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanAutocompleteSearchType | null>(null)
  const [selectedPortfolio, setSelectedPortfolio] = useState<any | null>(null)
  const [previousPortfolio, setPreviousPortfolio] = useState<any | null>(null)
  const [previousPlan, setPreviousPlan] = useState<PlanAutocompleteSearchType | null>(null)

  // Keep hold of the previous portfolio selection in case of modal cancel
  useEffect(() => {
    if (modalOpen) {
      setPreviousPortfolio(settings?.clientPortfolio || null)
      setPreviousPlan({
        id: report.plans?.[0]?.id || 0,
        name: report.plans?.[0]?.name || ""
      })
    } else {
      setSelectedPortfolio(null)
      setSelectedPlan(null)
    }
  }, [modalOpen])

  useEffect(() => {
    setSelectedPortfolio(settings?.clientPortfolio || null)
    setSelectedPlan({
      id: report.plans?.[0]?.id || 0,
      name: report.plans?.[0]?.name || ""
    })
  }, [settings])

  const toggle = () => setModalOpen(!modalOpen)

  const selectPortfolio = (addItem:AutocompleteItemTypes, linkUrl: string) => {
    if(addItem.__typename === "ClientPortfolio") {
      setSelectedPortfolio(addItem)
      handleInputChange(addItem, 'clientPortfolio')
      handleInputChange(get(addItem, ['performanceTargetMap', 0, 'target', 'targetId']), 'target.targetId')
    }
  }

  const onSubmit = () => {
    setModalOpen(false)
  }

  const cancelEdit = () => {
    setModalOpen(false)
    setSelectedPortfolio(previousPortfolio)
    setSelectedPlan(previousPlan)

    handleInputChange(previousPortfolio, 'clientPortfolio')
    handleInputChange(get(previousPortfolio, ['performanceTargetMap', 0, 'target', 'targetId']), 'target.targetId')
  }

  const staticSearchPlan:PlanAutocomplete = {
    __typename: 'PlanAutocomplete',
    id: selectedPlan?.id || 0,
  }

  return (
    <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader toggle={toggle}>Choose Portfolio</ModalHeader>
      <ModalBody>
        <FormGroup className='row no-gutters list-add-connected-inputs'>
          <Label className="col-form-label col-sm-3 justify-content-end pr-1">
            Plan:
          </Label>
          <div className="col-sm-9">
            <FormInput
              property={"plan-search"}
              displayName={""}
              type={"search"}
              subtype={"single"}
              placeholder={`First find Plan by name or ID`}
              idx={1}
              editMode={true}
              propertyVal={selectedPlan}
              updateValue={(value) => { setSelectedPlan(value || null)}}
              searchTypes={[SearchTypes.Plan]}
              subClasses={{wrapperClasses: "no-gutters input-group ml-0 pr-0 list-fix-input-form my-0 mr-1", inputClasses: "search-bar placeholder-gray-50 ml-0"}}
            />
            <FontAwesomeIcon icon="search" className="mr-0 form-input-append-icon" />
          </div>
        </FormGroup>
        <StaticPortfolioSearch
          selectedPlan={staticSearchPlan}
          includedRows={[`ClientPortfolio:${selectedPortfolio?.id}`]}
          onClick={selectPortfolio}
          modalSelection
          forPerformanceComparison
        />

      </ModalBody>
      <ModalFooter>
        <EditButtons
          editMode={true}
          setEditMode={() => true}
          cancelEdit={cancelEdit} saving={false}
          onSubmit={onSubmit} disableOnError={true}
          saveText={"OK"}
        />
      </ModalFooter>
    </Modal>
  )
}

export default Attribution