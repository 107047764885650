import React from "react"
import { get } from "lodash"
import { useHistory } from "react-router-dom"
import { Input, Table } from "reactstrap"
import { ClientPortfolioDefinitionThirdFragment } from "../../../__generated__/graphql";

export interface ClientPortfolioListsProps {
  data: ClientPortfolioDefinitionThirdFragment["lists"][];
}

type portfolioListCol = {
  field: string;
  title: string;
  textLinkParams?: {
    property: string;
    url: string;
  };
}

const MemberOfCompositesInput:portfolioListCol[] = [
  {
    field: "list.name",
    title: "List Name",
    textLinkParams: {
      property: "list.id",
      url: "/lists/",
    },
  },
  {
    field: "list.id",
    title: "List ID",
    textLinkParams: {
      property: "list.id",
      url: "/lists/",
    },
  },
  {
    field: "list.category.value",
    title: "Category",
  },
  {
    field: "list.subCategory.value",
    title: "Subcategory",
  },
  {
    field: "list.type",
    title: "List Type",
  },
]

export const ClientPortfolioLists: React.FC<ClientPortfolioListsProps> = (props) => {
  let data = props.data
  return (
    <Table hover size="sm" className={"ml-negative-3 mb-4"}>
      <thead className="table-border-bottom">
        <tr className="table-header">
          {MemberOfCompositesInput.map((headerDef, idx) => {
            return (
              <th key={`memberof-composites-header-${idx}`} className={"text-uppercase font-weight-normal text-gray-80 font-small"}>
                {headerDef.title}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((row: ClientPortfolioDefinitionThirdFragment["lists"], idx: number) => {
          return (
            <ClientPortfolioRow
              data={row}
              key={`memberof-composite-row-${idx}`}
              row={idx}
              columnDef={MemberOfCompositesInput}
            />
          )
        })}
      </tbody>
    </Table>
  )
}

interface ClientPortfolioRowProps {
  data: ClientPortfolioDefinitionThirdFragment["lists"];
  row: number;
  columnDef: portfolioListCol[];
}

const ClientPortfolioRow: React.FC<ClientPortfolioRowProps> = (props) => {
  const history = useHistory()
  const { data, row, columnDef } = props
  return (
    <tr>
      {columnDef.map((colDef: portfolioListCol, idx: number) => {
        let value = get(data, colDef.field)
        if (colDef.textLinkParams) {
          value = (
            <a
              href={`${colDef.textLinkParams?.url}${get(data, colDef.textLinkParams?.property)}`}
              onClick={(e) => {
                e.preventDefault()
                window.open(`${colDef.textLinkParams?.url}${get(data, colDef.textLinkParams?.property || "")}`,'_blank')
              }}
            >
              {value}
            </a>
          )
        }
        return (
          <td key={`memberof-composite-row-${row}-col-${idx}`} className={"text-gray-80"}>
            {value}
          </td>
        )
      })}
    </tr>
  )
}

export default ClientPortfolioLists
