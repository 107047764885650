import { CellClassParams, CellValueChangedEvent, Column, GridApi, GridReadyEvent, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import iassign from "immutable-assign"
import { cloneDeep, compact, find, findIndex, first, get, groupBy, isEqual, isNumber, keyBy, mergeWith, reduce, some, sortBy, sum, uniq, uniqBy, uniqueId } from "lodash"
import moment from "moment"
import numbro from "numbro"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, ButtonDropdown, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledTooltip } from "reactstrap"
import stringHash from "string-hash"
import { appDate } from "../../Context/CalendarContext"
import { TemporaryAlertContext } from "../../Context/TemporaryAlertContext"
import { AssetClassAbbreviationCode, BaseCurrencyCode, CashFlowMarketValueInput, CashFlowNetAssetValueInput, CashFlowTransactionFragment, CashFlowTransactionTypeInput, CharacteristicsDataChangeSource, CountryCode, DeleteCashFlowTransactionInput, ListDetailFragment, PortfolioCurrencyCode, PortfolioPeriod, SumSheetCashFlowDocument, SumSheetClientPortfolioFragment, SumSheetNonPollingClientPortfolioFragment, SumSheetNonPollingQuery, SumSheetQuery, SumSheetSavedJobFragment, TransactionTypeFragment, UniqueTransactionTypeCode, useDeleteClientPortfolioCashFlowMarketValueMutation, useDeletePerformanceOrderMutation, useDeleteSumSheetCashFlowMutation, useExchangeRatesQuery, usePerformanceOrderIdQuery, useRunSavedJobMutation, useSumSheetCashFlowQuery, useSumSheetHoverFlowLazyQuery, useSumSheetNonPollingQuery, useSumSheetQuery, useUpdateSumSheetCashFlowMutation, VehicleCategoryCode } from "../../__generated__/graphql"
import { CashFlowColumnDef } from "../../helpers/columnDefs"
import { DATE_API_FORMAT, DATE_TEXT_FORMAT, DATE_TIME_DISPLAY_FORMAT, DATE_QUARTER_FORMAT, DATE_DISPLAY_FORMAT } from "../../helpers/constant"
import { currencyFormatter, currencySymbolMapping, headerComponentWithIcon, percentFormatter } from "../../helpers/helpers"
import { expandList, listExpanded } from "../../helpers/list"
import { useDocumentTitle } from "../../helpers/usehook"
import { SUM_SHEET_QUERY } from "../../queries/Report"
import { currencyWithMantissaFormat } from "../Report/Shared/ReportComponent"
import ErrorDisplay from "../Shared/ErrorDisplay"
import SortableTable from "../Shared/SortableTable"
import EditButtons from "../ui/EditButtons"
import LoadingOverlay from "../ui/Loading"
import { benchmarkTooltipCellRenderer, diffWarningRenderer, endingMarketTooltipCellRenderer, noteTickRenderer, paramsSourceTooltipCellRenderer, rightAlignedRenderer, statusRenderer, sumSheetPortfolioLinkRenderer, vehicleReturnTooltipCellRenderer } from "../../helpers/agGridHelpers"
import { match, useHistory } from "react-router-dom"


type DateOption = {
  period: PortfolioPeriod
  date: string
  text: string
}

interface SumSheetProps {
  auth: any
  listId: number
  reportId: number
  planId: number
  match: match<{ view: string }>
}

type viewParams = "default" | "summary" | "assets" | "performance" | "1year" | "fees" | "characteristics"
const viewOptions:viewParams[] = ["default", "summary", "assets", "performance", "1year", "fees", "characteristics"]
type SumSheetView = {
  name: string
  columns: string[]
  showColumns?: {
    year?: boolean
    fees?: boolean
    characteristics?: boolean
  }
}

type SumSheetViews = {
  [key in viewParams]: SumSheetView
}

type CombinedSumSheetType = SumSheetNonPollingClientPortfolioFragment & SumSheetClientPortfolioFragment
type SumSheetTotalValues = {
  totalBeginningAssets: number
  totalCashFlowAssets: number
  totalEndingAssets: number
}

const sumSheetViews:SumSheetViews = {
  "default": {
    name: "Sumsheet",
    columns: ["id", "vehicleCategory", "notes", "beginningAssets", "cashFlowActivity", "endingAssets", "grossPerformance", "netPerformance", "vehicleReturn", "benchmarkReturn", "calculatedReturn", "returnUpdated", "returnSync", "cashFlowSync", "syncStatus"],
  },
  "summary": {
    name: "Summary",
    columns: ["id", "vehicleCategory", "notes", "beginningAssets", "cashFlowActivity", "endingAssets", "grossPerformance", "netPerformance", "assetStatus", "performanceStatus", "yearPerformanceStatus", "feeStatus", "characteristicStatus", "syncStatus", "returnUpdated"],
    showColumns: {
      year: true,
      fees: true,
      characteristics: true,
    },
  },
  "assets": {
    name: "Assets Check",
    columns: ["id", "notes", "assetStatus", "beginningAssets", "cashFlowActivity", "calculatedCashFlow", "diffCashFlow", "endingAssets", "calculatedEndingAssets", "diffEndingAssets", "returnUpdated"],
  },
  "performance": {
    name: "Performance Check",
    columns: ["id", "vehicleCategory", "notes", "performanceStatus", "beginningAssets", "cashFlowActivity", "endingAssets", "grossPerformance", "netPerformance", "vehicleReturn", "diffVehicleReturn", "benchmarkReturn", "diffBenchmarkReturn", "calculatedReturn", "diffCalculatedReturn", "returnUpdated"],
  },
  "1year": {
    name: "1 Year Performance Check",
    columns: ["id", "vehicleCategory", "notes", "yearPerformanceStatus", "beginningAssets", "cashFlowActivity", "endingAssets", "yearGrossPerformance", "yearNetPerformance", "yearVehicleReturn", "diffYearVehicleReturn", "yearBenchmarkReturn", "diffYearBenchmarkReturn", "yearCalculatedReturn", "diffYearCalculatedReturn", "grossPerformance", "netPerformance", "returnUpdated"],
    showColumns: {
      year: true
    }
  },
  "fees": {
    name: "Fees Check",
    columns: ["id", "vehicleCategory", "notes", "feeStatus", "beginningAssets", "cashFlowActivity", "endingAssets", "grossPerformance", "netPerformance", "impliedFee", "vehicleFee", "diffVehicleFee","yearGrossPerformance", "yearNetPerformance", "yearImpliedFee", "yearVehicleFee", "diffYearVehicleFee", "yearCalculatedFee", "diffYearCalculatedFee", "returnUpdated"],
    showColumns: {
      year: true,
      fees: true,
    },
  },
  "characteristics": {
    name: "Characteristics Check",
    columns: ["id", "vehicleCategory", "notes", "characteristicStatus", "beginningAssets", "cashFlowActivity", "endingAssets", "grossPerformance", "netPerformance", "characteristicsCount", "priorCharacteristicsCount", "diffCharacteristicsCount", "diffPercentCharacteristicsCount", "characteristicsUpdated", "syncIssues", "returnUpdated"],
    showColumns: {
      characteristics: true,
    },
  },
}

const warningValues = {
  diffCashFlowWarning: 50,
  diffEndingAssetsWarning: 50,
  performanceMutualFund: 0.05,
  performanceCit: .3,
  performanceVehicle: 1,
  performanceActive: 3,
  performancePassive: .3,
  performanceSimpleDietz: .5,
  feeMutualFund: 0.05,
  feeVehicle: .5,
  feePassive: .1,
  feeLow: 2,
  feeHigh: 6,
  feeMissing: 2,
  characteristicCount: 3,
  characteristicPercent: 10.0,
}


export const SumSheet: React.FC<SumSheetProps> = (props) => {
  const { auth, reportId, planId, listId, match} = props
  const [search, setSearch] = useState<string>("")
  const [viewDropdownOpen, setViewDropdownOpen] = useState(false)
  const [view, setView] = useState<SumSheetView>(sumSheetViews[match.params.view as viewParams] || sumSheetViews["default"])
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [jobDropdownOpen, setJobDropdownOpen] = useState(false)
  const [cashFlowModalOpen, setCashFlowModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<ReturnColumnDataType[]>([])
  const [jobList, setJobList] = useState<SumSheetSavedJobFragment[]>([])
  const [selectedJobs, setSelectedJobs] = useState<SumSheetSavedJobFragment[] | null>(null)
  const [deletePerformanceOrder] = useDeletePerformanceOrderMutation()
  const [finishedLoad, setFinishedLoad] = useState(false)
  const history = useHistory()
  const [refreshing, setRefreshing] = useState(false)

  let dateOptions = useMemo(()=> {
    const usedAppDate = moment(appDate)
    let dateOptions = [{
      period: PortfolioPeriod.Quarterly,
      date: moment(usedAppDate).endOf("month").format(DATE_API_FORMAT),
      text: moment(usedAppDate).format("[Q]Q YYYY"),
    }, {
      period: PortfolioPeriod.Quarterly,
      date: moment(usedAppDate).subtract(1, "Q").endOf("month").format(DATE_API_FORMAT),
      text: moment(usedAppDate).subtract(1, "Q").format("[Q]Q YYYY"),
    }, {
      period: PortfolioPeriod.Quarterly,
      date: moment(usedAppDate).subtract(2, "Q").endOf("month").format(DATE_API_FORMAT),
      text: moment(usedAppDate).subtract(2, "Q").format("[Q]Q YYYY"),
    }]

    let checkDate = moment().subtract(1, "month").endOf("month")
    let endDate = moment(usedAppDate).subtract(2, "Q").startOf("quarter")

    while(checkDate > endDate){
      dateOptions.push({
        period: PortfolioPeriod.Monthly,
        date: checkDate.format(DATE_API_FORMAT),
        text: checkDate.format("MMM YYYY"),
      })
      checkDate = checkDate.subtract(1, "month").endOf("month")
    }

    return dateOptions
  }, [appDate])
  const [selectedDate, setSelectedDate] = useState<DateOption>(dateOptions[0])
  let startDate = moment(selectedDate.date).subtract(1, selectedDate.period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT)
  if(view.showColumns?.year) startDate = moment(selectedDate.date).subtract(1,"year").endOf("month").format(DATE_API_FORMAT)

  let request = JSON.stringify({
    variables: {
      id: listId,
      showMonth: selectedDate.period === PortfolioPeriod.Monthly,
      showQuarter: selectedDate.period === PortfolioPeriod.Quarterly,
      show1YearMonth: (view.showColumns?.year && selectedDate.period === PortfolioPeriod.Monthly) || false,
      show1YearQuarter: (view.showColumns?.year && selectedDate.period === PortfolioPeriod.Quarterly) || false,
      period: selectedDate.period,
      startDate: startDate,
      endDate: selectedDate.date,
    },
    queryHash: stringHash(JSON.stringify(SUM_SHEET_QUERY))
  })

  const {data, refetch} = usePerformanceOrderIdQuery({
    variables: {
      request: request
    },
  })

  useEffect(()=>{
    if(refetch){
      refetch()
    }
  }, [selectedDate, listId])

  useEffect(() => {
    if(match.params.view && view.name !== sumSheetViews[match.params.view as viewParams]?.name){
      setView(sumSheetViews[match.params.view as viewParams] || sumSheetViews["default"])
    }
  }, [match])

  const cashFlowActive = selectedRows.length === 1 && selectedRows[0].id >= 0 && selectedRows[0].dataType?.code === "SUM"
  const openCashflowModal = () => {
    if(cashFlowActive){
      setCashFlowModalOpen(true)
    }
  }

  const exportToCsv = () => {
    if(!gridApi) return
    gridApi.exportDataAsCsv()
  }

  const jobTypeList = useMemo(() => {
    let usedJobList = jobList
    if(selectedRows.length > 0){
      usedJobList = (selectedRows.flatMap((row) => (row.group[0] !== "Excluded Portfolios" && row.savedJobs) || []) || []) as SumSheetSavedJobFragment[]
    }
    return groupBy(uniqBy(usedJobList, (job) => job?.id), (job) => job?.type?.code)
  }, [jobList, selectedRows])

  const refetchId = () => {
    setRefreshing(true)
    deletePerformanceOrder({
      variables: { input: { performanceOrderID: data?.performanceOrderID as number } },
    })
    .then(() => {
      refetch()
        .then(() => {
          setFinishedLoad(() => {
            return false
          })
        })
    })
  }
  const urlWithoutView = match.url.slice(0, -match.params.view.length - 1)
  return (
    <>
      <div className={classNames("pane pane-toolbar sticky-top above-picker", {"pane-toolbar-open": jobDropdownOpen || viewDropdownOpen || dropdownOpen})}>
        <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} className="mr-1 sumsheet-date-picker">
          <DropdownToggle caret className="text-callan-blue border-blue-80">
            {selectedDate?.text || "Select Date"}
          </DropdownToggle>
          <DropdownMenu>
            {dateOptions.map((dateOption, idx) => {
              const selectDate = (dateOption: DateOption) => {
                setSelectedDate(dateOption)
                if(dateOption.period === PortfolioPeriod.Monthly && ["Characteristics Check"].includes(view.name)){
                  setView(sumSheetViews["default"])
                }
              }
              return(
                <React.Fragment key={idx}>
                  {idx === 3 && <DropdownItem divider className='mx-3'/>}
                  <DropdownItem key={`${dateOption.text}`} onClick={() => selectDate(dateOption)} className="d-flex background-white text-text-color position-relative">
                    {selectedDate.text === dateOption.text && <div className="quartile-check"><FontAwesomeIcon icon="check" /></div>}
                    <span className="ml-2 pl-1">{`${dateOption.text}`}</span>
                  </DropdownItem>
                </React.Fragment>
              )
            })}
          </DropdownMenu>
        </ButtonDropdown>
        <ButtonDropdown isOpen={viewDropdownOpen} toggle={() => setViewDropdownOpen(!viewDropdownOpen)} className="mr-1 sumsheet-date-picker sumsheet-view-picker">
          <DropdownToggle caret className="text-callan-blue border-blue-80">
            {view.name}
          </DropdownToggle>
          <DropdownMenu>
            {viewOptions.map((viewName, idx) => {
              const viewOption = sumSheetViews[viewName]
              const selected = view === viewOption
              if(["characteristics"].includes(viewName) && selectedDate.period === PortfolioPeriod.Monthly){
                return(<React.Fragment key={idx}>{idx === 1 && <DropdownItem divider className='mx-3'/>}</React.Fragment>)
              }
              return(
                <React.Fragment key={idx}>
                  {idx === 1 && <DropdownItem divider className='mx-3'/>}
                  <DropdownItem key={`${viewOption.name}`} onClick={() => history.push(urlWithoutView + "/" + viewName)} className="d-flex background-white text-text-color position-relative">
                    {selected && <div className="quartile-check"><FontAwesomeIcon icon="check" /></div>}
                    <span className="ml-2 pl-1">{`${viewOption.name}`}</span>
                  </DropdownItem>
                </React.Fragment>
              )
            })}
          </DropdownMenu>
        </ButtonDropdown>
        <Form className="mr-2 pr-1 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              className="wide-search"
              type="text"
              placeholder="Search by name or ID"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon icon={["fas", "search"]} size="2x" className="fontawesome-icon dark-icon-color text-gray-50" />
            </span>
          </FormGroup>
        </Form>
        <Button color={cashFlowActive ? "secondary" : "light" } id={"cashflowModal"} className=" mr-2 ml-2" onClick={() => openCashflowModal()}>
          <span className={classNames({"text-gray-70": !cashFlowActive, "text-callan-blue": cashFlowActive})}>
            Cash Flows
            <FontAwesomeIcon icon="pencil" className="ml-2" />
          </span>
        </Button>
        {selectedRows.length === 1 &&
          <CashFlowModal
            isOpen={cashFlowModalOpen}
            toggle={() => setCashFlowModalOpen(!cashFlowModalOpen)}
            selectedRow={selectedRows[0]}
            period={selectedDate.period}
            date={selectedDate.date}
          />
        }
        <ButtonDropdown isOpen={jobDropdownOpen} toggle={() => setJobDropdownOpen(!jobDropdownOpen)} className="ml-1 btn-thin">
          <DropdownToggle caret className="mt-0 text-callan-blue border-blue-80">
            Run Job
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(jobTypeList).map((jobType) => {
              let job = jobTypeList[jobType][0]
              return(
                <DropdownItem key={jobType} onClick={() => setSelectedJobs(jobTypeList[jobType])} className="d-flex background-white text-text-color position-relative">
                  <span className="ml-2 pl-1">{`${job?.type?.value}`}</span>
                </DropdownItem>
              )
            })}
            {jobList.length === 0 && <DropdownItem disabled>No Jobs Available</DropdownItem>}
          </DropdownMenu>
        </ButtonDropdown>
        <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
        <Button color="light" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={() => {if(refetchId) refetchId()}}>
          <FontAwesomeIcon
              icon={["fas", "sync"]}
              size="lg"
              className="text-callan-blue cursor-pointer"
            />
        </Button>
      </div>
      <JobModal
        isOpen={!!selectedJobs}
        toggle={() => setSelectedJobs(null)}
        jobs={selectedJobs || undefined}
        planId={planId}
        date={selectedDate.date}
        autoRun={selectedRows.length > 0}
      />
      {data?.performanceOrderID && <SumSheetFetch
        date={selectedDate.date}
        period={selectedDate.period}
        search={search}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        jobList={jobList}
        setJobList={setJobList}
        setGridApi={setGridApi}
        orderId={data.performanceOrderID}
        finishedLoad={finishedLoad}
        setFinishedLoad={setFinishedLoad}
        view={view}
        setRefreshing={setRefreshing}
        refreshing={refreshing}
        {...props}
      />}
      {!data && <LoadingOverlay />}
    </>
  )
}

interface SumSheetFetchProps extends SumSheetProps {
  date: string
  period: PortfolioPeriod
  search: string
  selectedRows: ReturnColumnDataType[]
  setSelectedRows: (rows: ReturnColumnDataType[]) => void
  jobList: SumSheetSavedJobFragment[]
  setJobList: (jobList: SumSheetSavedJobFragment[]) => void
  setGridApi: (api: GridApi) => void
  orderId: number
  finishedLoad: boolean
  setFinishedLoad: React.Dispatch<React.SetStateAction<boolean>>
  view: SumSheetView
  setRefreshing: React.Dispatch<React.SetStateAction<boolean>>
  refreshing: boolean
}

export const SumSheetFetch: React.FC<SumSheetFetchProps> = (props) => {
  const { auth, listId, reportId, search, period, date, selectedRows,  setSelectedRows, jobList, setJobList, setGridApi, orderId, finishedLoad, setFinishedLoad, view, refreshing, setRefreshing } = props

  let startDate = moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT)
  if(view.showColumns?.year) startDate = moment(date).subtract(1,"year").endOf("month").format(DATE_API_FORMAT)
  let usedView = cloneDeep(view)
  if(view.name === "Summary" && period === PortfolioPeriod.Monthly){
    let columns = cloneDeep(sumSheetViews["summary"].columns)
    columns.splice(columns.indexOf("characteristicStatus"), 1)
    usedView.columns = columns
  }

  const { data, loading, error, stopPolling, startPolling, refetch } = useSumSheetQuery({
    variables: {
      id: listId,
      orderId,
      showMonth: period === PortfolioPeriod.Monthly,
      showQuarter: period === PortfolioPeriod.Quarterly,
      show1YearMonth: (view.showColumns?.year && period === PortfolioPeriod.Monthly) || false,
      show1YearQuarter: (view.showColumns?.year && period === PortfolioPeriod.Quarterly) || false,
      // startDate: startDate,
      endDate: date,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    onCompleted: () => {
      setRefreshing(false)
    }
  })

  const { data: nonPollingData, loading: nonPollingLoading, error: nonPollingError, refetch: nonPollingRefetch } = useSumSheetNonPollingQuery({
    variables: {
      id: listId,
      showMonth: period === PortfolioPeriod.Monthly,
      showQuarter: period === PortfolioPeriod.Quarterly,
      show1YearMonth: (view.showColumns?.year && period === PortfolioPeriod.Monthly) || false,
      show1YearQuarter: (view.showColumns?.year && period === PortfolioPeriod.Quarterly) || false,
      showFees: view.showColumns?.fees || false,
      showCharacteristics: view.showColumns?.characteristics || false,
      startDate: startDate,
      endDate: date,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  })

  const allDone = (data: SumSheetQuery) => {
    return !some(data.list?.items, (item) => {
      if(item.item?.__typename === "ClientPortfolio") {
        const portfolio = item.item
        let returned = false
        let usedKey = period === PortfolioPeriod.Monthly ? "monthPerformance" : "quarterPerformance"
        returned = some(get(portfolio, usedKey), (performance) => {
          const done = !performance || performance.status === "Done"
          return !done
        })
        // if(!returned){
        //   returned = some(get(portfolio.relatedVehicle?.vehicle, usedKey), (performance) => {
        //     const done = !performance || performance.status === "Done"
        //     return !done
        //   })
        // }
        if(!returned){
          returned = some(portfolio.performanceTargetMap, (performanceTarget) => {
            return some(get(performanceTarget?.target, usedKey), (performance) => {
              const done = !performance || performance.status === "Done"
              return !done
            })
          })
        }
        return returned
      }
    })
  }

  if((data && allDone(data) && !loading && !refreshing) || error){
    stopPolling()
    if(!finishedLoad){
      setFinishedLoad(() => {
        return true
      })
    }
  }

  useEffect(() => {
    if(!finishedLoad){
      refetch()
      nonPollingRefetch()
      startPolling(5000)
      setRefreshing(true)
    }
  }, [finishedLoad])

  if(data?.list?.items && nonPollingData?.list?.items){
    return (
      <SumSheetDisplay
        auth={auth}
        reportId={reportId}
        data={data.list}
        nonPollingData={nonPollingData?.list}
        search={search}
        period={period}
        date={date}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        jobList={jobList}
        setJobList={setJobList}
        setGridApi={setGridApi}
        refreshing={refreshing}
        view={usedView}
      />
    )
  } else if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  return (
    <LoadingOverlay/>
  )
}

interface SumSheetDisplayProps {
  auth: any
  reportId: number
  data: SumSheetQuery["list"]
  nonPollingData: SumSheetNonPollingQuery["list"]
  search: string
  period: PortfolioPeriod
  date: string
  setSelectedRows?:(rows: ReturnColumnDataType[]) => void
  selectedRows?: ReturnColumnDataType[]
  jobList: SumSheetSavedJobFragment[]
  setJobList: (jobList: SumSheetSavedJobFragment[]) => void
  setGridApi: (api: GridApi) => void
  refreshing: boolean
  view: SumSheetView
}

export type ReturnColumnDataType = SumSheetClientPortfolioFragment & {
  rowId: string
  group: string[]
  beginningAssets?: number | string,
  cashFlowAssets?: number | string,
  endingAssets?: number | string,
  grossReturn?: number | string,
  netReturn?: number | string,
  vehicleReturn?: number | string,
  benchmarkReturn?: number | string,
  calculatedReturn?: number | string,
  yearGrossReturn?: number | string,
  yearNetReturn?: number | string,
  yearVehicleReturn?: number | string,
  yearBenchmarkReturn?: number | string,
  returnSync?: number | string,
  cashFlowSync?: number | string,
}

export const SumSheetDisplay: React.FC<SumSheetDisplayProps> = (props) => {
  const { auth, reportId, data, nonPollingData, search, period, date, selectedRows, setSelectedRows, jobList, setJobList, setGridApi, refreshing, view} = props
  const { pushTitlePart, popTitlePart } = useDocumentTitle()

  const [getHoverValues, { loading:historicCashflowLoading, error:historicCashflowError, data:historicCashflowData }] = useSumSheetHoverFlowLazyQuery({
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    pushTitlePart('Sumsheet')

    return () => {
      popTitlePart()
    }
  }, [])

  const mappedData = useMemo(() => {
    let mappedData:{[key in string]: SumSheetNonPollingClientPortfolioFragment} = {}
    nonPollingData?.items?.forEach((item) => {
      if(item.item?.__typename === "ClientPortfolio" && item.item.id){
        mappedData[`portfolio:${item.item.id}`] = item.item
      }
    })
    nonPollingData?.excludeList?.forEach((excludeList) => {
      excludeList.items?.forEach((item) => {
        if(item.item?.__typename === "ClientPortfolio" && item.item.id){
          mappedData[`portfolio:${item.item.id}`] = item.item
        }
      })
    })
    return mappedData
  }, [nonPollingData])

  const columnData = useMemo(() => {
    // const expandedList = expandReportList(data as ReportsListFragment, {appendUnusedGroups: true, excludeList: first(data?.excludeList)  as ReportsListFragment})
    const expandedList = expandList(data as ListDetailFragment, {appendUnusedGroups: true, excludeList: first(data?.excludeList) as ListDetailFragment})
    const periodMapping = {
      "Monthly": "month",
      "Quarterly": "quarter",
    }

    const iterateList = (list: listExpanded[], hierarchy: string[], passedProps?: {compositeMemberPerformanceChanges: any, characteristicDataChangeSource: CharacteristicsDataChangeSource[]}):{baseItems: any, totalValues: any} => {
      let totalValues = {
        totalBeginningAssets: 0,
        totalCashFlowAssets: 0,
        totalEndingAssets: 0,
      }
      let {compositeMemberPerformanceChanges: inCompositeMemberPerformanceChanges, characteristicDataChangeSource} = passedProps || {}
      const timeDivisor = period === PortfolioPeriod.Monthly ? 12 : 4

      const getVehicleFee = (vehicle: SumSheetNonPollingClientPortfolioFragment["relatedVehicle"]) => {
        if(!vehicle) return undefined
        let datedFees, feeAtAum, fees
        if("mutualFund" in vehicle){
          datedFees = vehicle.mutualFund?.datedFees
        } else if("exchangeTradedFund" in vehicle){
          datedFees = vehicle.exchangeTradedFund?.datedFees
        } else if("variableAnnuity" in vehicle){
          datedFees = vehicle.variableAnnuity?.datedFees
        } else if("collectiveInvestmentFundComposite" in vehicle){
          datedFees = vehicle.collectiveInvestmentFundComposite?.datedFees
        } else if("collectiveInvestmentFund" in vehicle){
          datedFees = vehicle.collectiveInvestmentFund?.datedFees
        } else if("pooledVehicle" in vehicle){
          feeAtAum = vehicle.pooledVehicle?.feeAtAum
        } else if("separateAccount" in vehicle){
          feeAtAum = vehicle.separateAccount?.feeAtAum
        } else if("hedgeFundVehicle" in vehicle){
          feeAtAum = vehicle.hedgeFundVehicle?.feeAtAum
        } else if("stableValueSeparateAccount" in vehicle){
          feeAtAum = vehicle.stableValueSeparateAccount?.feeAtAum
        } else if("stableValueCollectiveInvestmentFund" in vehicle){
          fees = vehicle.stableValueCollectiveInvestmentFund?.fees
        }

        if(datedFees){
          let reducedFee = reduce(datedFees as any, (acc, fee) => {
            if(moment(fee?.date).isAfter(acc?.date)){
              return fee
            }
            return acc
          }, datedFees[0])

          if(reducedFee && "equalWeightedFee" in reducedFee){
            return reducedFee.equalWeightedFee
          } else if(reducedFee && "totalExpense" in reducedFee){
            return reducedFee.totalExpense
          }
        } else if(feeAtAum){
          return feeAtAum.fee
        } else if(fees){
          return reduce(fees, (acc, fee) => {
            if(moment(fee?.date).isAfter(acc?.date)){
              return fee
            }
            return acc
          }, fees[0])?.allInFee
        }
      }

      return {baseItems: list.flatMap((item, idx) => {
        const portfolio:CombinedSumSheetType = {...mappedData[`portfolio:${item.item.id}`], ...item.item}
        const name = portfolio.name || item.item.text || ""
        let baseItems:any[] = []
        let cashFlowAsset, characteristicsCount
        if(isEqual(hierarchy, ["Unordered Portfolios"]) && name === "") return {baseItems, totalValues}

        let baseValues:{[key in string]: string | number | undefined | null | string[]} = {
          beginningAssets: "Loading",
          cashFlowAssets: "Loading",
          endingAssets: "Loading",
          grossReturn: "Loading",
          netReturn: "Loading",
          impliedFee: "Loading",
          vehicleReturn: "Loading",
          benchmarkReturn: "Loading",
          calculatedReturn: "Loading",
          yearVehicleFee: "Loading",
          yearGrossReturn: "Loading",
          yearNetReturn: "Loading",
          yearVehicleReturn: "Loading",
          yearBenchmarkReturn: "Loading",
          returnSync: "Loading",
          cashFlowSync: "Loading",
          characteristicsCount: "Loading",
          priorCharacteristicsCount: "Loading",
          tooltipId: portfolio.id,
        }
        let lineTotalValues = {
          totalBeginningAssets: 0,
          totalCashFlowAssets: 0,
          totalEndingAssets: 0,
        }
        if(!refreshing){
          let assets = portfolio.assets || []
          let beginningDate = moment(date, DATE_API_FORMAT).subtract(1, periodMapping[period] as moment.unitOfTime.DurationConstructor).endOf("month").format(DATE_API_FORMAT)
          let beginningAssets = assets.find((asset:any) => asset.date === beginningDate)
          let endingAssets = assets.find((asset:any) => asset.date === date)

          let cashFlowAssets = portfolio.cashFlowActivity || []
          cashFlowAsset = cashFlowAssets.find((asset:any) => asset.date === date && asset.period === period)
          let totalAsset
          if(!cashFlowAsset && period === PortfolioPeriod.Quarterly){
            totalAsset = cashFlowAssets.reduce((acc:any, asset:any) => {
              if(asset.period === PortfolioPeriod.Monthly &&
                moment(asset.date, DATE_API_FORMAT).isSameOrBefore(moment(date, DATE_API_FORMAT)) &&
                moment(asset.date, DATE_API_FORMAT).isAfter(moment(date, DATE_API_FORMAT).subtract(3, "months").endOf("month"))
              ){
                acc = acc ? acc + asset.netFlow : asset.netFlow
              }
              return acc
            }, undefined)
          }
          let yearCashFlowTotal = cashFlowAssets.reduce((acc:any, asset:any) => {
            if(asset.period === period &&
              moment(asset.date, DATE_API_FORMAT).isSameOrBefore(moment(date, DATE_API_FORMAT)) &&
              moment(asset.date, DATE_API_FORMAT).isAfter(moment(date, DATE_API_FORMAT).subtract(1, "year").endOf("month"))
            ){
              acc = acc ? acc + asset.netFlow : asset.netFlow
            }
            return acc
          }, undefined)

          // Characteristic
          characteristicsCount = find(portfolio.characteristicsCount, ["date", date])
          let priorCharacteristicsCount = find(portfolio.characteristicsCount, ["date", beginningDate])

          // Handle issues with Total not being a group header
          if(hierarchy.length === 0 && idx ===0){
            inCompositeMemberPerformanceChanges = cashFlowAsset?.compositeMemberPerformanceChanges
            if(characteristicsCount?.dataChangeSource) characteristicDataChangeSource = compact(characteristicsCount?.dataChangeSource)
          }

          let grossPerformances = portfolio[`${periodMapping[period] as "month" | "quarter"}PerformanceGross`] || []
          const grossLoading = grossPerformances.find((performance:any) => performance.status !== "Done")
          let correctGrossPerformance = grossPerformances.find((performance:any) => performance.assetClassGroup.code === AssetClassAbbreviationCode.ALL)

          let netPerformances = portfolio[`${periodMapping[period] as "month" | "quarter"}PerformanceNet`] || []
          const netLoading = netPerformances.find((performance:any) => performance.status !== "Done")
          let correctNetPerformance = netPerformances.find((performance:any) => performance.assetClassGroup.code === AssetClassAbbreviationCode.ALLN)

          const capitalizedPeriod = periodMapping[period].charAt(0).toUpperCase() + periodMapping[period].slice(1) as "Month" | "Quarter"
          let yearGrossPerformances = portfolio[`year${capitalizedPeriod}PerformanceGross`] || []
          const yearGrossLoading = yearGrossPerformances.find((performance:any) => performance.status !== "Done")
          let yearCorrectGrossPerformance = yearGrossPerformances.find((performance:any) => performance.assetClassGroup.code === AssetClassAbbreviationCode.ALL)

          let yearNetPerformances = portfolio[`year${capitalizedPeriod}PerformanceNet`] || []
          const yearNetLoading = yearNetPerformances.find((performance:any) => performance.status !== "Done")
          let yearCorrectNetPerformance = yearNetPerformances.find((performance:any) => performance.assetClassGroup.code === AssetClassAbbreviationCode.ALLN)

          let vehicle = portfolio.relatedVehicle?.vehicle
          let vehiclePerformances = vehicle?.[`${periodMapping[period] as "month" | "quarter"}Performance`] || []
          let correctVehiclePerformance = first(vehiclePerformances) as any

          let vehicleFee = getVehicleFee(portfolio.relatedVehicle) as any
          if(vehicleFee && isNumber(vehicleFee)){
            vehicleFee = Math.round(vehicleFee*100000)/1000
          }

          let yearVehicle = portfolio.relatedVehicle?.vehicle
          let yearVehiclePerformances = yearVehicle?.[`year${capitalizedPeriod}Performance`] || []
          let yearCorrectVehiclePerformance = first(yearVehiclePerformances) as any

          let targets = portfolio.performanceTargetMap || []
          let correctTarget = targets.length > 1 ? targets.find((target:any) => target.order === 1) : first(targets)
          let targetPerformances = correctTarget?.target?.[`${periodMapping[period] as "month" | "quarter"}Performance`] || []
          const targetLoading = targetPerformances.find((performance:any) => performance.status !== "Done")
          let correctTargetPerformance = first(targetPerformances) as any

          let yearTargetPerformances = correctTarget?.target?.[`year${capitalizedPeriod}Performance`] || []
          const yearTargetLoading = yearTargetPerformances.find((performance:any) => performance.status !== "Done")
          let yearCorrectTargetPerformance = first(yearTargetPerformances) as any

          let endingAsset = assets.find((asset:any) => asset.date === date)
          let beginningAssetsValue = beginningAssets?.totalMarketValue || 0
          let endingAssetsValue = endingAsset?.totalMarketValue || 0
          let netCashActivityValue = cashFlowAsset?.netFlow || 0
          let validCalculatedReturn = beginningAssetsValue !== 0 || netCashActivityValue !== 0
          let calculatedReturn = (endingAssetsValue - beginningAssetsValue - netCashActivityValue) / (beginningAssetsValue + (0.5 * netCashActivityValue))

          let yearBeginningDate = moment(date, DATE_API_FORMAT).subtract(1, "year").endOf("month").format(DATE_API_FORMAT)
          let yearBeginningAssets = assets.find((asset:any) => asset.date === yearBeginningDate)?.totalMarketValue || 0
          let yearValidCalculatedReturn = yearBeginningAssets !== 0 || yearCashFlowTotal !== 0
          let yearCalculatedReturn = (endingAssetsValue - yearBeginningAssets - yearCashFlowTotal) / (yearBeginningAssets + (0.5 * yearCashFlowTotal))

          cashFlowAsset = cashFlowAsset || {}
          let cashFlowSync = (cashFlowAsset?.cashflowDataChanges?.length || 0) > 0 ? cashFlowAsset?.cashflowDataChanges?.map((date:string) => moment(date).format(DATE_DISPLAY_FORMAT)) : undefined
          let returnSync = undefined
          if (inCompositeMemberPerformanceChanges) {
            let matchingChanges = inCompositeMemberPerformanceChanges.filter((change:any) => change?.member?.id === portfolio.id)
            if(matchingChanges.length > 0) returnSync = uniq(matchingChanges.map((change:any) => moment(change.date).format(DATE_DISPLAY_FORMAT))) as string[]
          }

          let impliedFee = isNumber(correctGrossPerformance?.value?.active) &&  isNumber(correctNetPerformance?.value?.active) ? correctGrossPerformance?.value?.active - correctNetPerformance?.value?.active : undefined
          if(impliedFee === 0 || (impliedFee && Math.abs(impliedFee) < 0.01)) impliedFee = undefined
          let yearImpliedFee = isNumber(yearCorrectGrossPerformance?.value?.active) &&  isNumber(yearCorrectNetPerformance?.value?.active) ? yearCorrectGrossPerformance?.value?.active - yearCorrectNetPerformance?.value?.active : undefined
          if(yearImpliedFee === 0 || (yearImpliedFee && Math.abs(yearImpliedFee) < 0.01)) yearImpliedFee = undefined

          // characteristic sync
          let matchedChange = characteristicDataChangeSource?.find((change:any) => change?.id === portfolio.id && change?.date !== beginningDate)

          baseValues = {
            beginningAssets: beginningAssets?.totalMarketValue,
            cashFlowAssets: totalAsset ? totalAsset : cashFlowAsset?.netFlow,
            endingAssets: endingAssets?.totalMarketValue,
            grossReturn: grossLoading ? "Loading" : correctGrossPerformance?.value?.active,
            netReturn: netLoading ? "Loading" : correctNetPerformance?.value?.active,
            yearGrossReturn: yearGrossLoading ? "Loading" : yearCorrectGrossPerformance?.value?.active,
            yearNetReturn: yearNetLoading ? "Loading" : yearCorrectNetPerformance?.value?.active,
            vehicleReturn: correctVehiclePerformance?.value?.active,
            yearVehicleReturn: yearCorrectVehiclePerformance?.value?.active,
            vehicleFee: isNumber(vehicleFee) ? vehicleFee / timeDivisor : undefined,
            yearVehicleFee: vehicleFee,
            benchmarkReturn: targetLoading ? "Loading" : correctTargetPerformance?.value?.active,
            yearBenchmarkReturn: yearTargetLoading ? "Loading" : yearCorrectTargetPerformance?.value?.active,
            calculatedReturn: validCalculatedReturn ? Math.round(calculatedReturn*100000)/1000 : undefined,
            yearCalculatedReturn: yearValidCalculatedReturn ? Math.round(yearCalculatedReturn*100000)/1000 : undefined,
            cashFlowSync,
            returnSync,
            impliedFee,
            yearImpliedFee,
            yearCalculatedFee: isNumber(impliedFee) ? impliedFee * timeDivisor : undefined,
            characteristicsCount: characteristicsCount?.count,
            priorCharacteristicsCount: priorCharacteristicsCount?.count,
            characteristicsUpdated: characteristicsCount?.updated,
            syncIssues: matchedChange ? "Cautionary" : undefined,
            syncIssuesText: matchedChange ? moment(matchedChange?.date).format(DATE_DISPLAY_FORMAT) : undefined,
            tooltipId: portfolio.id,
          }

          lineTotalValues.totalBeginningAssets += beginningAssetsValue
          lineTotalValues.totalCashFlowAssets += totalAsset ? totalAsset : netCashActivityValue
          lineTotalValues.totalEndingAssets += endingAssetsValue

          totalValues.totalBeginningAssets += beginningAssetsValue
          totalValues.totalCashFlowAssets += totalAsset ? totalAsset : netCashActivityValue
          totalValues.totalEndingAssets += endingAssetsValue
        }

        // SUBGROUP
        if(item.subGroup){
          let {baseItems: subItems, totalValues: subTotalValues} = iterateList(item.subGroup, [...hierarchy, name + "|" + portfolio.id], {compositeMemberPerformanceChanges: cashFlowAsset?.compositeMemberPerformanceChanges, characteristicDataChangeSource: characteristicsCount?.dataChangeSource})
          // mergeWith(totalValues, subTotalValues, (objValue, srcValue) => objValue + srcValue)
          lineTotalValues = subTotalValues
          baseItems.push(...subItems)
          // return {baseItems: [...baseItems, ...subItems], totalValues: totalValues}
        }

        let diffBeginningAssets = isNumber(baseValues.beginningAssets) ? (baseValues.beginningAssets || 0) - lineTotalValues.totalBeginningAssets : undefined
        if(diffBeginningAssets === 0 || (diffBeginningAssets && Math.abs(diffBeginningAssets) < 0.01)) diffBeginningAssets = undefined
        let diffCashFlowAssets = isNumber(baseValues.cashFlowAssets) ? (baseValues.cashFlowAssets || 0) - lineTotalValues.totalCashFlowAssets : undefined
        if(diffCashFlowAssets === 0 || (diffCashFlowAssets && Math.abs(diffCashFlowAssets) < 0.01)) diffCashFlowAssets = undefined
        let diffEndingAssets = isNumber(baseValues.endingAssets) ? (baseValues.endingAssets || 0) - lineTotalValues.totalEndingAssets : undefined
        if(diffEndingAssets === 0 || (diffEndingAssets && Math.abs(diffEndingAssets) < 0.01)) diffEndingAssets = undefined

        // FEE DIFFS
        let diffVehicleFee = (isNumber(baseValues.vehicleFee) && isNumber(baseValues.impliedFee)) ? (baseValues.impliedFee || 0) - (baseValues.vehicleFee || 0) : undefined
        if(diffVehicleFee === 0 || (diffVehicleFee && Math.abs(diffVehicleFee) < 0.01)) diffVehicleFee = undefined
        let diffYearVehicleFee = (isNumber(baseValues.yearVehicleFee) && isNumber(baseValues.yearImpliedFee)) ? (baseValues.yearImpliedFee || 0) - (baseValues.yearVehicleFee || 0) : undefined
        if(diffYearVehicleFee === 0 || (diffYearVehicleFee && Math.abs(diffYearVehicleFee) < 0.01)) diffYearVehicleFee = undefined
        let diffYearCalculatedFee = (isNumber(baseValues.yearCalculatedFee) && isNumber(baseValues.yearImpliedFee)) ? (baseValues.yearCalculatedFee || 0) - (baseValues.yearImpliedFee || 0) : undefined
        if(diffYearCalculatedFee === 0 || (diffYearCalculatedFee && Math.abs(diffYearCalculatedFee) < 0.01)) diffYearCalculatedFee = undefined

        let vehicle = portfolio.relatedVehicle?.vehicle
        let useNetVehicle = [VehicleCategoryCode.c, VehicleCategoryCode.e, VehicleCategoryCode.l, VehicleCategoryCode.m, VehicleCategoryCode.u, VehicleCategoryCode.v].includes(vehicle?.category?.code as VehicleCategoryCode)
        let diffVehicleReturn, diffBenchmarkReturn, diffYearVehicleReturn, diffYearBenchmarkReturn

        if(useNetVehicle){
          diffVehicleReturn = (isNumber(baseValues.netReturn) && isNumber(baseValues.vehicleReturn)) ? (baseValues.netReturn || 0) - (baseValues.vehicleReturn || 0) : undefined
          diffBenchmarkReturn = (isNumber(baseValues.netReturn) && isNumber(baseValues.benchmarkReturn)) ? (baseValues.netReturn || 0) - (baseValues.benchmarkReturn || 0) : undefined
          diffYearVehicleReturn = (isNumber(baseValues.yearNetReturn) && isNumber(baseValues.yearVehicleReturn)) ? (baseValues.yearNetReturn || 0) - (baseValues.yearVehicleReturn || 0) : undefined
          diffYearBenchmarkReturn = (isNumber(baseValues.yearNetReturn) && isNumber(baseValues.yearBenchmarkReturn)) ? (baseValues.yearNetReturn || 0) - (baseValues.yearBenchmarkReturn || 0) : undefined
        } else {
          diffVehicleReturn = (isNumber(baseValues.grossReturn) && isNumber(baseValues.vehicleReturn)) ? (baseValues.grossReturn || 0) - (baseValues.vehicleReturn || 0) : undefined
          diffBenchmarkReturn = (isNumber(baseValues.grossReturn) && isNumber(baseValues.benchmarkReturn)) ? (baseValues.grossReturn || 0) - (baseValues.benchmarkReturn || 0) : undefined
          diffYearVehicleReturn = (isNumber(baseValues.yearGrossReturn) && isNumber(baseValues.yearVehicleReturn)) ? (baseValues.yearGrossReturn || 0) - (baseValues.yearVehicleReturn || 0) : undefined
          diffYearBenchmarkReturn = (isNumber(baseValues.yearGrossReturn) && isNumber(baseValues.yearBenchmarkReturn)) ? (baseValues.yearGrossReturn || 0) - (baseValues.yearBenchmarkReturn || 0) : undefined
        }

        if(diffVehicleReturn === 0 || (diffVehicleReturn && Math.abs(diffVehicleReturn) < 0.01)) diffVehicleReturn = undefined
        if(diffBenchmarkReturn === 0 || (diffBenchmarkReturn && Math.abs(diffBenchmarkReturn) < 0.01)) diffBenchmarkReturn = undefined
        if(diffYearVehicleReturn === 0 || (diffYearVehicleReturn && Math.abs(diffYearVehicleReturn) < 0.01)) diffYearVehicleReturn = undefined
        if(diffYearBenchmarkReturn === 0 || (diffYearBenchmarkReturn && Math.abs(diffYearBenchmarkReturn) < 0.01)) diffYearBenchmarkReturn = undefined
        let diffCalculatedReturn = (isNumber(baseValues.grossReturn) && isNumber(baseValues.calculatedReturn)) ? (baseValues.grossReturn || 0) - (baseValues.calculatedReturn || 0) : undefined
        let diffYearCalculatedReturn = (isNumber(baseValues.yearGrossReturn) && isNumber(baseValues.yearCalculatedReturn)) ? (baseValues.yearGrossReturn || 0) - (baseValues.yearCalculatedReturn || 0) : undefined
        if(diffCalculatedReturn === 0 || (diffCalculatedReturn && Math.abs(diffCalculatedReturn) < 0.01)) diffCalculatedReturn = undefined
        if(diffYearCalculatedReturn === 0 || (diffYearCalculatedReturn && Math.abs(diffYearCalculatedReturn) < 0.01)) diffYearCalculatedReturn = undefined

        // CHARACTERISTIC DIFFS
        let diffCharacteristicsCount: number | undefined = Math.abs((isNumber(baseValues.characteristicsCount) ? baseValues.characteristicsCount : 0) - (isNumber(baseValues.priorCharacteristicsCount) ? baseValues.priorCharacteristicsCount : 0))
        if(diffCharacteristicsCount === 0) diffCharacteristicsCount = undefined
        let diffPercentCharacteristicsCount = isNumber(baseValues.characteristicsCount) && isNumber(baseValues.priorCharacteristicsCount) && baseValues.priorCharacteristicsCount > 0 ? ((baseValues.characteristicsCount/ baseValues.priorCharacteristicsCount) - 1)*100  : undefined


        let diffValues = {
          diffBeginningAssets,
          diffCashFlowAssets,
          diffEndingAssets,
          diffVehicleReturn,
          diffBenchmarkReturn,
          diffCalculatedReturn,
          diffYearVehicleReturn,
          diffYearBenchmarkReturn,
          diffYearCalculatedReturn,
          diffVehicleFee,
          diffYearVehicleFee,
          diffYearCalculatedFee,
          diffCharacteristicsCount,
          diffPercentCharacteristicsCount,
        }

        // PERFORMANCE STATUSES
        const emVehicle = [VehicleCategoryCode.e, VehicleCategoryCode.m].includes(vehicle?.category?.code as VehicleCategoryCode)
        const cqVehicle = [VehicleCategoryCode.c, VehicleCategoryCode.q, VehicleCategoryCode.p].includes(vehicle?.category?.code as VehicleCategoryCode)
        const activePassive = vehicle?.product?.product?.activePassive
        let statusVehicleReturn, statusBenchmarkReturn, statusCalculatedReturn, statusYearVehicleReturn, statusYearBenchmarkReturn, statusYearCalculatedReturn
        if(!emVehicle && diffValues.diffVehicleReturn && Math.abs(diffValues.diffVehicleReturn) > warningValues.performanceVehicle) statusVehicleReturn = "Vehicle"
        if(emVehicle && diffValues.diffVehicleReturn && Math.abs(diffValues.diffVehicleReturn) > warningValues.performanceMutualFund) statusVehicleReturn = "Mutual Fund/ ETF"
        if(cqVehicle && diffValues.diffVehicleReturn && Math.abs(diffValues.diffVehicleReturn) > warningValues.performanceCit) statusVehicleReturn = "CIT/Pooled"
        if(activePassive === "ACTIVE" && diffValues.diffBenchmarkReturn && Math.abs(diffValues.diffBenchmarkReturn) > warningValues.performanceActive) statusBenchmarkReturn = "Active"
        if(activePassive === "PASS" && diffValues.diffBenchmarkReturn && Math.abs(diffValues.diffBenchmarkReturn) > warningValues.performancePassive) statusBenchmarkReturn = "Passive"
        if(!emVehicle && diffValues.diffCalculatedReturn && Math.abs(diffValues.diffCalculatedReturn) > warningValues.performanceSimpleDietz) statusCalculatedReturn = "Simple Dietz"

        // YEAR PERFORMANCE STATUSES
        if(!emVehicle && diffValues.diffYearVehicleReturn && Math.abs(diffValues.diffYearVehicleReturn) > warningValues.performanceVehicle) statusYearVehicleReturn = "Vehicle"
        if(emVehicle && diffValues.diffYearVehicleReturn && Math.abs(diffValues.diffYearVehicleReturn) > warningValues.performanceMutualFund) statusYearVehicleReturn = "Mutual Fund/ ETF"
        if(cqVehicle && diffValues.diffYearVehicleReturn && Math.abs(diffValues.diffYearVehicleReturn) > warningValues.performanceCit) statusYearVehicleReturn = "CIT/Pooled"
        if(activePassive === "ACTIVE" && diffValues.diffYearBenchmarkReturn && Math.abs(diffValues.diffYearBenchmarkReturn) > warningValues.performanceActive) statusYearBenchmarkReturn = "Active"
        if(activePassive === "PASS" && diffValues.diffYearBenchmarkReturn && Math.abs(diffValues.diffYearBenchmarkReturn) > warningValues.performancePassive) statusYearBenchmarkReturn = "Passive"
        if(!emVehicle && diffValues.diffYearCalculatedReturn && Math.abs(diffValues.diffYearCalculatedReturn) > warningValues.performanceSimpleDietz) statusYearCalculatedReturn = "Simple Dietz"

        // FEE STATUSES
        let statusVehicleFee, statusYearVehicleFee, statusYearCalculatedFee
        if(emVehicle && diffValues.diffVehicleFee && Math.abs(diffValues.diffVehicleFee) > warningValues.feeMutualFund / timeDivisor) statusVehicleFee = "Mutual Fund/ ETF"
        if(emVehicle && diffValues.diffYearVehicleFee && Math.abs(diffValues.diffYearVehicleFee) > warningValues.feeMutualFund) statusYearVehicleFee = "Mutual Fund/ ETF"
        if(activePassive === "PASS" && diffValues.diffVehicleFee && Math.abs(diffValues.diffVehicleFee) > warningValues.feePassive / timeDivisor) statusVehicleFee = "Passive"
        if(activePassive === "PASS" && diffValues.diffYearVehicleFee && Math.abs(diffValues.diffYearVehicleFee) > warningValues.feePassive) statusYearVehicleFee = "Passive"
        if(!emVehicle && diffValues.diffVehicleFee && Math.abs(diffValues.diffVehicleFee) > warningValues.feeVehicle / timeDivisor) statusVehicleFee = "Vehicle"
        if(!emVehicle && diffValues.diffYearVehicleFee && Math.abs(diffValues.diffYearVehicleFee) > warningValues.feeVehicle) statusYearVehicleFee = "Vehicle"

        if(isNumber(baseValues.impliedFee) && isNumber(baseValues.yearImpliedFee) && ((baseValues.impliedFee * warningValues.feeLow * (timeDivisor/4)) > baseValues.yearImpliedFee)) statusYearCalculatedFee = "Low Fee"
        if(isNumber(baseValues.impliedFee) && isNumber(baseValues.yearImpliedFee) && ((baseValues.impliedFee * warningValues.feeHigh * (timeDivisor/4)) < baseValues.yearImpliedFee)) statusYearCalculatedFee = "High Fee"
        if(isNumber(baseValues.yearCalculatedFee) && isNumber(baseValues.yearImpliedFee) && isNumber(baseValues.impliedFee) && (Math.abs((baseValues.yearCalculatedFee - baseValues.yearImpliedFee)) > Math.abs(baseValues.impliedFee * warningValues.feeMissing * (timeDivisor/4)))) statusYearCalculatedFee = "Missing Fee"

        // CHARACTERISTIC STATUSES
        let statusCharacteristicsCount, statusPercentCharacteristicsCount
        if(isNumber(diffValues.diffCharacteristicsCount) && diffValues.diffCharacteristicsCount > warningValues.characteristicCount && ((isNumber(diffValues.diffPercentCharacteristicsCount) && Math.abs(diffValues.diffPercentCharacteristicsCount) > warningValues.characteristicPercent) || diffValues.diffPercentCharacteristicsCount === undefined)) statusCharacteristicsCount = "Cautionary"
        if(isNumber(baseValues.priorCharacteristicsCount) && baseValues.priorCharacteristicsCount > warningValues.characteristicCount && ((isNumber(diffValues.diffPercentCharacteristicsCount) && Math.abs(diffValues.diffPercentCharacteristicsCount) > warningValues.characteristicPercent) || diffValues.diffPercentCharacteristicsCount === undefined)) statusPercentCharacteristicsCount = "Cautionary"

        let statusValues = {
          statusCashFlowAssets: (diffValues.diffCashFlowAssets && Math.abs(diffValues.diffCashFlowAssets) > warningValues.diffCashFlowWarning) ? "Cautionary" : undefined,
          statusEndingAssets: (diffValues.diffEndingAssets && Math.abs(diffValues.diffEndingAssets) > warningValues.diffEndingAssetsWarning) ? "Cautionary" : undefined,
          statusReturnSync: baseValues.returnSync ? "Cautionary" : undefined,
          statusCashFlowSync: baseValues.cashFlowSync ? "Cautionary" : undefined,
          statusVehicleReturn,
          statusBenchmarkReturn,
          statusCalculatedReturn,
          statusYearVehicleReturn,
          statusYearBenchmarkReturn,
          statusYearCalculatedReturn,
          statusVehicleFee,
          statusYearVehicleFee,
          statusYearCalculatedFee,
          statusCharacteristicsCount,
          statusPercentCharacteristicsCount,
        }

        let performanceStatus = compact([statusVehicleReturn, statusBenchmarkReturn, statusCalculatedReturn])
        let yearPerformanceStatus = compact([statusYearVehicleReturn, statusYearBenchmarkReturn, statusYearCalculatedReturn])
        let feeStatus = compact(uniq([statusVehicleFee, statusYearVehicleFee, statusYearCalculatedFee]))
        let characteristicStatus = compact(uniq([statusCharacteristicsCount, statusPercentCharacteristicsCount, baseValues.syncIssues]))
        let groupStatus = {
          assetStatus: (statusValues.statusCashFlowAssets || statusValues.statusEndingAssets) ? "Cautionary" : "Within Expectations",
          syncStatus: (statusValues.statusReturnSync || statusValues.statusCashFlowSync) ? "Cautionary" : "Within Expectations",
          performanceStatus: performanceStatus.length > 0 ? performanceStatus : "Within Expectations",
          yearPerformanceStatus: yearPerformanceStatus.length > 0 ? yearPerformanceStatus : "Within Expectations",
          feeStatus: feeStatus.length > 0 ? feeStatus : "Within Expectations",
          characteristicStatus: characteristicStatus.length > 0 ? characteristicStatus : "Within Expectations",
        }

        baseItems.push({
          group: [...hierarchy, name + "|" + portfolio.id],
          uniqId: item.uniqId,
          ...portfolio,
          ...baseValues,
          ...lineTotalValues,
          ...diffValues,
          ...statusValues,
          ...groupStatus,
        })

        return baseItems
      }), totalValues}
    }

    // Handle issues from the total not being having a subgroup so can't be done in the iterateList function
    let {baseItems, totalValues} = iterateList(expandedList, [])
    let totalRow = baseItems.shift()
    if(totalRow){
      totalRow.totalCashFlowAssets = totalValues.totalCashFlowAssets - totalRow.totalCashFlowAssets
      totalRow.totalEndingAssets = totalValues.totalEndingAssets - totalRow.totalEndingAssets

      let diffCashFlowAssets = isNumber(totalRow.cashFlowAssets) ? (totalRow.cashFlowAssets || 0) - totalRow.totalCashFlowAssets : undefined
      if(diffCashFlowAssets === 0 || (diffCashFlowAssets && Math.abs(diffCashFlowAssets) < 0.01)) diffCashFlowAssets = undefined
      totalRow.diffCashFlowAssets = diffCashFlowAssets
      let diffEndingAssets = isNumber(totalRow.endingAssets) ? (totalRow.endingAssets || 0) - totalRow.totalEndingAssets : undefined
      if(diffEndingAssets === 0 || (diffEndingAssets && Math.abs(diffEndingAssets) < 0.01)) diffEndingAssets = undefined
      totalRow.diffEndingAssets = diffEndingAssets

      totalRow.statusCashFlowAssets = (totalRow.diffCashFlowAssets && Math.abs(totalRow.diffCashFlowAssets) > warningValues.diffCashFlowWarning) ? "Cautionary" : undefined
      totalRow.statusEndingAssets =(totalRow.diffEndingAssets && Math.abs(totalRow.diffEndingAssets) > warningValues.diffEndingAssetsWarning) ? "Cautionary" : undefined
      totalRow.assetStatus = (totalRow.statusCashFlowAssets || totalRow.statusEndingAssets) ? "Cautionary" : "Within Expectations"

      baseItems.unshift(totalRow)
    }
    return baseItems
  }, [data, period, date, refreshing, mappedData])
  const [currentColumnData, setCurrentColumnData] = useState<ReturnColumnDataType[]>(columnData)
  // console.log(currentColumnData)

  useEffect(() => {
    setCurrentColumnData(cloneDeep(columnData))
    let jobList = reduce(columnData, (acc, item) => {
      if(item.group[0] === "Excluded Portfolios") return acc
      if(item.savedJobs){
        return uniq(compact(acc.concat(item.savedJobs)))
      }
      return acc
    },
    [])
    setJobList(jobList)
  }, [columnData])

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    // params.api.forEachNode((node:IRowNode) => {
    //   var hoverFetch = () => {
    //     getHoverValues({variables: {
    //       id: node.data.id,
    //       endDate: date,
    //       showMonth: period === PortfolioPeriod.Monthly,
    //       showQuarter: period === PortfolioPeriod.Quarterly,
    //     }}).then((result) => {
    //       if(result && result.data?.clientPortfolio?.id === node.data.id){
    //         let nodeData = cloneDeep(node.data)
    //         set(nodeData, 'monthCashFlows', result.data?.clientPortfolio?.monthCashFlows)
    //         set(nodeData, 'quarterCashFlows', result.data?.clientPortfolio?.quarterCashFlows)
    //         node.setData(nodeData)
    //       }
    //     })
    //   }

    //   if(node.rowIndex !== null){
    //     node.addEventListener('mouseEnter', hoverFetch)
    //     params.api.addRenderedRowListener('virtualRowRemoved', node.rowIndex, () => {
    //       node.removeEventListener('mouseEnter', hoverFetch)
    //     })
    //   }
    // })
  }

  const SumSheetColumnDef = (view: SumSheetView, period: PortfolioPeriod, date: string, refreshing: boolean) => {
    const periodMapping = {
      "Monthly": "month",
      "Quarterly": "quarter",
    }

    const columnMap = {
      id: {
        headerName: "Portfolio ID",
        field: "id",
        sortable: true,
        width: 100,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        valueGetter: (params: ValueGetterParams) => {
          if(params.data.id < 0 || params.data.uniqId.startsWith("list_header")) return ""
          return params.data.id
        },
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      vehicleCategory: {
        headerName: "Vehicle Category",
        field: "relatedVehicle.vehicle.category.shortValue",
        sortable: true,
        width: 100,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      notes: {
        headerName: "Notes",
        field: "notes",
        sortable: true,
        width: 100,
        editable: false,
        cellRenderer: noteTickRenderer,
        filterParams: {
          valueGetter: (params: ValueGetterParams) => {
            return params.data.notes ? "Present" : undefined
          },
        },
      },
      assetStatus: {
        headerName: "Assets Status",
        field: "assetStatus",
        sortable: true,
        width: 100,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: statusRenderer,
      },
      syncStatus: {
        headerName: "Sync Status",
        field: "syncStatus",
        sortable: true,
        width: 100,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: statusRenderer,
      },
      performanceStatus: {
        headerName: "Performance Status",
        field: "performanceStatus",
        sortable: true,
        width: 170,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: statusRenderer,
      },
      characteristicStatus: {
        headerName: "Characteristics Status",
        field: "characteristicStatus",
        sortable: true,
        width: 170,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: statusRenderer,
      },
      yearPerformanceStatus: {
        headerName: "1Y Performance Status",
        field: "yearPerformanceStatus",
        sortable: true,
        width: 170,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: statusRenderer,
      },
      feeStatus: {
        headerName: "Fees Status",
        field: "feeStatus",
        sortable: true,
        width: 100,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: statusRenderer,
      },
      beginningAssets: {
        headerName: "Beginning Assets",
        field: "beginningAssets",
        sortable: true,
        width: 170,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: rightAlignedRenderer,
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      cashFlowActivity: {
        headerName: "Net Cash Activity",
        field: "cashFlowAssets",
        sortable: true,
        width: 170,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: rightAlignedRenderer,
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      calculatedCashFlow: {
        headerName: "Calculated Net Cash Activity",
        field: "totalCashFlowAssets",
        sortable: true,
        width: 230,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: rightAlignedRenderer,
        cellClass: (params: CellClassParams) => {
          return classNames("calculated-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "calculated-column",
      },
      diffCashFlow: {
        headerName: "Diff - Net Cash Activity",
        field: "diffCashFlowAssets",
        sortable: true,
        width: 200,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusCashFlowAssets"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      endingAssets: {
        headerName: "Ending Assets",
        field: "endingAssets",
        sortable: true,
        width: 170,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: endingMarketTooltipCellRenderer,
        cellRendererParams: {values: { date: moment(date, DATE_API_FORMAT).endOf("month").format(DATE_API_FORMAT), period: periodMapping[period]}},
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      calculatedEndingAssets: {
        headerName: "Calculated Ending Assets",
        field: "totalEndingAssets",
        sortable: true,
        width: 200,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: rightAlignedRenderer,
        cellClass: (params: CellClassParams) => {
          return classNames("calculated-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "calculated-column",
      },
      diffEndingAssets: {
        headerName: "Diff - Ending Assets",
        field: "diffEndingAssets",
        sortable: true,
        width: 170,
        valueFormatter: currencyFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusEndingAssets"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      grossPerformance: {
        headerName: "Gross Return",
        field: `grossReturn`,
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      yearGrossPerformance: {
        headerName: "1Y Gross Return",
        field: `yearGrossReturn`,
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      netPerformance: {
        headerName: "Net Return",
        field: `netReturn`,
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      yearNetPerformance: {
        headerName: "1Y Net Return",
        field: `yearNetReturn`,
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      impliedFee: {
        headerName: "Implied Fee",
        field: `impliedFee`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        headerComponentParams: {
          template: headerComponentWithIcon("ImpliedFeeTooltip")
        },
        headerTooltip: "Current Period Gross Return - Current Period Net Return",
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      yearImpliedFee: {
        headerName: "1Y Implied Fee",
        field: `yearImpliedFee`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        headerComponentParams: {
          template: headerComponentWithIcon("YearImpliedFeeTooltip")
        },
        headerTooltip: "1Y Gross Return - 1Y Net Return",
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      vehicleFee: {
        headerName: "Vehicle Fee",
        field: `vehicleFee`,
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      yearVehicleFee: {
        headerName: "1Y Vehicle Fee",
        field: `yearVehicleFee`,
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      yearCalculatedFee: {
        headerName: "1Y Calculated Fee",
        field: `yearCalculatedFee`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        headerComponentParams: {
          template: headerComponentWithIcon("CalculatedFeeTooltip")
        },
        headerTooltip: "(Current Gross Return - Current Net Return) x Periodicity. Where Periodicity = 12 if monthly sumsheet or 4 if quarterly sumsheet.",
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames("calculated-column",{"font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "calculated-column",
      },
      diffVehicleFee: {
        headerName: "Diff - Vehicle Fee",
        field: "diffVehicleFee",
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusVehicleFee"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      diffYearVehicleFee: {
        headerName: "1Y Diff - Vehicle Fee",
        field: "diffYearVehicleFee",
        sortable: true,
        width: 100,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusYearVehicleFee"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      diffYearCalculatedFee: {
        headerName: "1Y Diff - Calculated Fee",
        field: "diffYearCalculatedFee",
        sortable: true,
        width: 140,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusYearCalculatedFee"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      vehicleReturn: {
        headerName: "Vehicle Return",
        field: `vehicleReturn`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: vehicleReturnTooltipCellRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      diffVehicleReturn: {
        headerName: "Diff - Vehicle Return",
        field: "diffVehicleReturn",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusVehicleReturn"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      yearVehicleReturn: {
        headerName: "1Y Vehicle Return",
        field: `yearVehicleReturn`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: vehicleReturnTooltipCellRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      diffYearVehicleReturn: {
        headerName: "1Y Diff - Vehicle Return",
        field: "diffYearVehicleReturn",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusYearVehicleReturn"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      characteristicsCount: {
        headerName: "Characteristics Count",
        field: `characteristicsCount`,
        sortable: true,
        width: 140,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      priorCharacteristicsCount: {
        headerName: "Prior Characteristics Count",
        field: `priorCharacteristicsCount`,
        sortable: true,
        width: 140,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      diffCharacteristicsCount: {
        headerName: "Diff - Characteristics Count",
        field: "diffCharacteristicsCount",
        sortable: true,
        width: 170,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusCharacteristicsCount"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      diffPercentCharacteristicsCount: {
        headerName: "Diff % - Characteristics Count",
        field: "diffPercentCharacteristicsCount",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusPercentCharacteristicsCount"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      characteristicsUpdated: {
        headerName: "Characteristics Updated",
        field: "characteristicsUpdated",
        sortable: true,
        width: 200,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_TIME_DISPLAY_FORMAT) : "",
        comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
          if(!valueA && !valueB) return 0
          if(!valueA) return isInverted ? -1 : 1
          if(!valueB) return isInverted ? 1 : -1
          const dateA = moment(valueA)
          const dateB = moment(valueB)
          if (dateA.isBefore(dateB)) {
            return -1;
          } else if (dateA.isAfter(dateB)) {
            return 1;
          }
          return 0;
        },
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellMoment = moment(dateAsString, DATE_API_FORMAT);
            var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
            return cellMoment.diff(filterLocalDateAtMidnightMoment)
          },
        },
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      benchmarkReturn: {
        headerName: "Benchmark Return",
        field: `benchmarkReturn`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: benchmarkTooltipCellRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      diffBenchmarkReturn: {
        headerName: "Diff - Benchmark Return",
        field: "diffBenchmarkReturn",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusBenchmarkReturn"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      yearBenchmarkReturn: {
        headerName: "1Y Benchmark Return",
        field: `yearBenchmarkReturn`,
        sortable: true,
        width: 140,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: benchmarkTooltipCellRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
      },
      diffYearBenchmarkReturn: {
        headerName: "1Y Diff - Benchmark Return",
        field: "diffYearBenchmarkReturn",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusYearBenchmarkReturn"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      calculatedReturn: {
        headerName: "Simple Dietz Return",
        field: `calculatedReturn`,
        sortable: true,
        width: 120,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames("calculated-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "calculated-column",
      },
      diffCalculatedReturn: {
        headerName: "Diff - Simple Dietz Return",
        field: "diffCalculatedReturn",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusCalculatedReturn"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      yearCalculatedReturn: {
        headerName: "1Y Simple Dietz Return",
        field: `yearCalculatedReturn`,
        sortable: true,
        width: 140,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: rightAlignedRenderer,
        filter: "agNumberColumnFilter",
        cellClass: (params: CellClassParams) => {
          return classNames("calculated-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "calculated-column",
      },
      diffYearCalculatedReturn: {
        headerName: "1Y Diff - Simple Dietz Return",
        field: "diffYearCalculatedReturn",
        sortable: true,
        width: 170,
        valueFormatter: percentFormatter,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filter: "agNumberColumnFilter",
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusYearCalculatedReturn"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
        },
        headerClass: "diff-column",
      },
      returnUpdated: {
        headerName: "Return Modified",
        field: "cashFlowActivity.lastUpdated",
        cellRenderer: (params: ICellRendererParams) => {
          let origin:{[key in string]: {source: string, updated: string}} = {}
          let summaries = params.data[`${periodMapping[period]}FinancialSummary`]
          summaries?.forEach((summary:any) => {
            if(summary.type.code === "ALL" && summary.date === date && (!origin[summary.date] || moment(origin[summary.date].updated).isBefore(moment(summary.updated)))){
              origin[summary.date] = {updated: summary?.updated, source: summary?.origin?.value}
            }
          })
          if(period === PortfolioPeriod.Quarterly){
            // try other summaries
            summaries = params.data[`${periodMapping[PortfolioPeriod.Monthly]}FinancialSummary`]
            summaries?.forEach((summary:any) => {
              const isBetween = moment(summary.date).isBetween(moment(date).startOf("quarter"), moment(date), "day", "[]")
              let currentDate = origin[summary.date]
              if(summary.type.code === "ALL" && isBetween && (!currentDate|| moment(currentDate.updated).isBefore(moment(summary.updated)))){
                origin[summary.date] = {updated: summary?.updated, source: summary?.origin?.value}
              }
            })
          }
          let objectKeys = Object.keys(origin)
          let tooltip = <></>
          if(objectKeys.length === 1){
            tooltip = <>Source: {origin[objectKeys[0]].source}</>
          } else if (objectKeys.length > 1){
            tooltip = <>{objectKeys.map((key) => <div key={key}>{moment(key).format(DATE_DISPLAY_FORMAT)} - {origin[key].source}</div>)}</>
          }
          return paramsSourceTooltipCellRenderer(params, tooltip)
        },
        sortable: true,
        width: 180,
        valueGetter: (params: ValueGetterParams) => {
          if(refreshing) return "Loading"
          let latestDate: string|null = null
          let summaries = params.data[`${periodMapping[period]}FinancialSummary`]
          summaries?.forEach((summary:any) => {if(summary.type.code === "ALL" && summary.date === date && (!latestDate || moment(latestDate).isBefore(moment(summary.updated)))) latestDate = summary?.updated })
          if(period === PortfolioPeriod.Quarterly){
            // try other summaries
            summaries = params.data[`${periodMapping[PortfolioPeriod.Monthly]}FinancialSummary`]
            summaries?.forEach((summary:any) => {
              const isBetween = moment(summary.date).isBetween(moment(date).startOf("quarter"), moment(date), "day", "[]")
              if(summary.type.code === "ALL" && isBetween && (!latestDate || moment(latestDate).isBefore(moment(summary.updated)))){
                latestDate = summary?.updated
              }
            })
          }
          return latestDate
        },
        valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_TIME_DISPLAY_FORMAT) : "",
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
          if(!valueA && !valueB) return 0
          if(!valueA) return isInverted ? -1 : 1
          if(!valueB) return isInverted ? 1 : -1
          const dateA = moment(valueA)
          const dateB = moment(valueB)
          if (dateA.isBefore(dateB)) {
            return -1;
          } else if (dateA.isAfter(dateB)) {
            return 1;
          }
          return 0;
        },
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellMoment = moment(dateAsString, DATE_API_FORMAT);
            var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
            return cellMoment.diff(filterLocalDateAtMidnightMoment)
          },
        },
        cellClass: (params: CellClassParams) => {
          return classNames({"font-weight-bold": params.data.group?.length === 1})
        },
      },
      returnSync: {
        headerName: "Return Sync",
        field: "returnSync",
        sortable: true,
        width: 150,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusReturnSync"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"font-weight-bold": params.data.group?.length === 1})
        },
        filterParams: {
          filterValueGetter: (params: ValueGetterParams) => {
            return params.data.statusReturnSync || "Within Expectations"
          },
        },
        headerClass: "diff-column",
      },
      syncIssues: {
        headerName: "Sync Issues",
        field: "syncIssuesText",
        sortable: true,
        width: 150,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["syncIssues"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"font-weight-bold": params.data.group?.length === 1})
        },
        filterParams: {
          filterValueGetter: (params: ValueGetterParams) => {
            return params.data.statusReturnSync ? "Cautionary" : "Within Expectations"
          },
        },
      },
      cashFlowSync: {
        headerName: "Cash Flow Sync",
        field: "cashFlowSync",
        sortable: true,
        width: 150,
        editable: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRenderer: (params:ICellRendererParams) => diffWarningRenderer(params, ["statusCashFlowSync"]),
        cellClass: (params: CellClassParams) => {
          return classNames("diff-column",{"font-weight-bold": params.data.group?.length === 1})
        },
        filterParams: {
          filterValueGetter: (params: ValueGetterParams) => {
            return params.data.statusCashFlowSync || "Within Expectations"
          },
        },
        headerClass: "diff-column",
      },
    }

    return view.columns.map((column) => columnMap[column as keyof typeof columnMap] || {headerName: column, field: column})
  }

  const getDataPath = useCallback((data) => {
    return data.group;
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Portfolio',
      minwidth: 300,
      width: 340,
      sortable: true,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: sumSheetPortfolioLinkRenderer,
        checkbox: true,
      },
      cellClass: (params: CellClassParams) => {
        if(params.data.group && params.data.group.length > 1){
          return ''
        } else {
          return 'font-weight-bold';
        }
      },
      valueGetter: (params:ValueGetterParams) => {
        if(params.node?.groupData){
          return params.node.groupData['ag-Grid-AutoColumn'].split("|", 2)[0]
        }
      },
    };
  }, []);

  const colDef = SumSheetColumnDef(view, period, date, refreshing)
  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column px-0">
      {/* <RouteLeavingGuard
        when={editMode && valuesChanged}
        navigate={path => history.push(path)}
      /> */}
      <SortableTable
        key={`${period}-${date}`}
        loading={false}
        filterText={search}
        columnDefs={colDef}
        tableData={currentColumnData}
        rowId={"uniqId"}
        setSelectedRows={setSelectedRows}
        onReady={onReady}
        // onCellValueChanged={onCellValueChanged}
        treeData={true}
        getDataPath={getDataPath}
        noPagination
        autoGroupColumnDef={autoGroupColumnDef}
      />
    </Container>
  )
}

export interface CashFlowModalProps {
  isOpen: boolean
  toggle: () => void
  // data: SumSheetFragment
  period: PortfolioPeriod
  date: string
  selectedRow: ReturnColumnDataType
}

export type CashFlowDataType = CashFlowTransactionFragment & {
  rowId: string
  touched: boolean
  transactionType: UniqueTransactionTypeCode | "ending-assets"
  convertedAmount?: number
  monthCashFlows?: any[]
  quarterCashFlows?: any[]
}

export type TransactionTypeWithTitles = TransactionTypeFragment | {
  name: string
  code: string
  title?: boolean
  fee?: number
  cashEffect?: number
}

const netCashTypeMapping:{[key in UniqueTransactionTypeCode | "ending-assets"]: "+"|"-"|undefined} = {
  "C": "+",
  "CO": "+",
  "OR": "-",
  "TI": "+",
  "TR": "-",
  "EI": "+",
  "ER": "-",
  "D": "-",
  "CD": "+",
  "RC": "-",
  "DR": "-",
  "RD": "+",
  "DU": "-",
  "TO": "-",
  "TA": "+",
  "EO": "-",
  "EC": "+",
  "F": "-",
  "RF": "+",
  "FO": "-",
  "RO": "+",
  "FE": "-",
  "RE": "+",
  "CI": "-",
  "CL": "+",
  "I": undefined,
  "IA": undefined,
  "IL": undefined,
  "IP": undefined,
  "RA": undefined,
  "RI": undefined,
  "ending-assets": undefined,
}

export const CashFlowModal: React.FC<CashFlowModalProps> = (props) => {
  const {isOpen, toggle, date, period, selectedRow: selectedPortfolio} = props
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [selectedRows, setSelectedRows] = useState<CashFlowDataType[]>([])
  const [saving, setSaving] = useState(false)
  const [outstandingCalls, setOutstandingCalls] = useState(0)
  const [updateSumSheetCashFlow] = useUpdateSumSheetCashFlowMutation()
  const [deleteSumSheetCashFlow] = useDeleteSumSheetCashFlowMutation()
  const [deleteClientPortfolioCashFlowMarketValue] = useDeleteClientPortfolioCashFlowMarketValueMutation()

  const {data, error, loading} = useSumSheetCashFlowQuery({
    variables: {
      id: selectedPortfolio.id,
      date: date,
      showMonth: period === PortfolioPeriod.Monthly,
      showQuarter: period === PortfolioPeriod.Quarterly,
    },
    errorPolicy: "all",
  })

  const netCashTooltipId = useMemo(() => `net-cash-tooltip-${uniqueId()}`, [])
  const endingAssetsTooltipId = useMemo(() => `ending-assets-tooltip-${uniqueId()}`, [])

  // let firstDate = reduce(columnData, (acc, transaction) => {
  //   if(!acc || moment(transaction.adjustedTransactionDate).isBefore(acc)) return transaction.adjustedTransactionDate
  //   return acc
  // }, moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT))
  let firstDate = moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT)

  const {data: exchangeRateData, error: exchangeRateError, loading: exchangeRateLoading } = useExchangeRatesQuery({
    variables: {
      filters: {
        startDate: firstDate,
        endDate: date,
        currencyAbbr: data?.clientPortfolio?.baseCurrency?.code || BaseCurrencyCode.USADOL,
      }
    },
    errorPolicy: "all",
    skip: !data?.clientPortfolio?.baseCurrency?.code //|| data?.clientPortfolio?.baseCurrency?.code === "USADOL",
  })

  const endDate = moment(date)
  const startDate = moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters").endOf("month")
  const startingExchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === startDate.format(DATE_API_FORMAT))?.rate || undefined
  const endingExchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === endDate.format(DATE_API_FORMAT))?.rate || undefined

  const [columnData, usedCashFlows, investmentGainLoss, convertedInvestmentGainLoss] = useMemo(() => {
    if(loading || !data){
      return [[], undefined, 0, 0]
    }
    let allTransactions:CashFlowDataType[] = []
    let usedCashFlows = period === PortfolioPeriod.Monthly ? data.clientPortfolio?.monthCashFlows : data.clientPortfolio?.quarterCashFlows
    let investmentGainLoss = 0
    let convertedInvestmentGainLoss = 0
    usedCashFlows?.cashFlowTransactionTypes?.forEach((type) => {
      if(!type?.transactions) return
      allTransactions = allTransactions.concat(compact(type?.transactions?.map((transaction) => {
        if(!transaction || !moment(transaction.periodEndDate).isBetween(startDate, endDate, "D", "(]")) return null
        let matchedExchangeRateData = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === transaction.adjustedTransactionDate)
        return ({
          rowId: `${type.transactionType.code}:${transaction.transactionDate}:${transaction.periodEndDate}:${transaction.transactionNumber}`,
          touched: false,
          transactionType: type.transactionType.code,
          convertedAmount: !!matchedExchangeRateData?.rate ? (transaction.amount || 0) * matchedExchangeRateData.rate : undefined,
          ...transaction,
        })
      })))
    })

    let {netCashActivity, convertedNetCashActivity} = allTransactions.reduce((acc, transaction) => {
      if(transaction.transactionType && netCashTypeMapping[transaction.transactionType]){
        acc.netCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? transaction.amount : -transaction.amount
        acc.convertedNetCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? (transaction.convertedAmount || 0) : -(transaction.convertedAmount || 0)
      }
      return acc
    }, {netCashActivity: 0, convertedNetCashActivity: 0})
    investmentGainLoss = (usedCashFlows?.endingNetAssetValue?.amount || 0) - (netCashActivity + (usedCashFlows?.beginningNetAssetValue?.amount || 0))
    const convertedEndingNetAssetValue = endingExchangeRate ? (usedCashFlows?.endingNetAssetValue?.amount || 0) * endingExchangeRate : undefined
    const convertedStartingNetAssetValue = startingExchangeRate ? (usedCashFlows?.endingNetAssetValue?.amount || 0) * startingExchangeRate : undefined
    convertedInvestmentGainLoss = (convertedEndingNetAssetValue || 0) - (convertedNetCashActivity + (convertedStartingNetAssetValue || 0))

    // add ending assets
    usedCashFlows?.marketValues?.forEach((marketValue) => {
      if(!marketValue || marketValue.date === startDate.format(DATE_API_FORMAT)) return
      let matchedExchangeRateData = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === marketValue.date)
      const { assetClassAbbreviation, __typename, amount, ...rest } = marketValue
      allTransactions.unshift({
        rowId: `ending-assets:${marketValue.date}`,
        touched: false,
        transactionType: "ending-assets",
        convertedAmount: !!matchedExchangeRateData?.rate ? (marketValue.amount || 0) * matchedExchangeRateData.rate : undefined,
        adjustedTransactionDate: marketValue.date,
        assetClassAbbreviation: { code: assetClassAbbreviation as AssetClassAbbreviationCode, __typename: "AssetClassAbbreviationLookup" },
        transactionDate: marketValue.date,
        periodEndDate: marketValue.date,
        transactionNumber: -1,
        monthCashFlows: [],
        quarterCashFlows: [],
        amount: marketValue.amount + (marketValue.accruedInterests || 0),
        __typename: "CashFlowTransaction" as "CashFlowTransaction",
        ...rest,
      })
    })

    return [allTransactions, usedCashFlows, investmentGainLoss, convertedInvestmentGainLoss]
  }, [data, loading, exchangeRateData])
  const [currentColumnData, setCurrentColumnData] = useState<CashFlowDataType[]>(columnData)

  let {netCashActivity, convertedNetCashActivity} = currentColumnData.reduce((acc, transaction) => {
    if(transaction.transactionType && netCashTypeMapping[transaction.transactionType]){
      acc.netCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? transaction.amount : -transaction.amount
      acc.convertedNetCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? (transaction.convertedAmount || 0) : -(transaction.convertedAmount || 0)
    }
    return acc
  }, {netCashActivity: 0, convertedNetCashActivity: 0})

  useEffect(() => {
    setCurrentColumnData(cloneDeep(columnData))
  }, [columnData])

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    params.columnApi.applyColumnState({state: [
      {colId: 'transactionDate', sort:"desc"},
    ]})
  }


  const onCellValueChanged = (params: CellValueChangedEvent) => {
    let updatedData = cloneDeep(currentColumnData)
    const row = find(updatedData, {rowId: params.data.rowId})
    if(row){
      row.touched = true
      let exchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === row.adjustedTransactionDate)?.rate
      row.convertedAmount = exchangeRate ? row.amount * exchangeRate : undefined
      setCurrentColumnData(updatedData)
    }
  }

  const addActive = !saving
  const addRow = () => {
    if(!addActive) return
    const rowId = uniqueId()
    const latestEndingAssets = usedCashFlows?.marketValues?.reduce((a:any, b:any) => { return moment(a.date).valueOf() > moment(b.date).valueOf() ? a : b; })
    let newRow: CashFlowDataType = {
      rowId: `${rowId}`,
      adjustedTransactionDate: date,
      transactionDate: date,
      assetClassAbbreviation: {
        code: latestEndingAssets?.assetClassAbbreviation as AssetClassAbbreviationCode || data?.clientPortfolio?.cashFlowDefaultKeys?.assetClassAbbreviation || AssetClassAbbreviationCode.ALL,
        __typename: "AssetClassAbbreviationLookup",
      },
      amount: 0,
      convertedAmount: 0,
      country: {
        code: data?.clientPortfolio?.cashFlowDefaultKeys?.country || CountryCode.USA,
        __typename: "PortfolioCountryLookup",
      },
      currency: {
        code: data?.clientPortfolio?.cashFlowDefaultKeys?.currency || PortfolioCurrencyCode.USADOL,
        __typename: "PortfolioCurrencyLookup",
      },
      periodEndDate: date,
      touched: true,
      transactionNumber: -1,
      transactionType: "ending-assets",
      __typename: "CashFlowTransaction",
    }
    setCurrentColumnData([newRow, ...currentColumnData])
    if(gridApi){
      gridApi.paginationGoToPage(0);
      gridApi.ensureIndexVisible(0, "top")
    }
  }

  const deleteActive = !saving && selectedRows.length > 0
  const deleteRows = () => {
    if(!deleteActive) return
    setCurrentColumnData(currentColumnData.filter((item) => !selectedRows.some((row) => row.rowId === item.rowId)))
  }

  const resetModal = () => {
    setSelectedRows([])
    setSaving(false)
    setOutstandingCalls(0)
    setCurrentColumnData(cloneDeep(columnData))
    toggle()
  }

  const handleSubmit = () => {
    setSaving(true)
    let upsertTransactions:CashFlowTransactionTypeInput[] = []
    let deleteTransactions:DeleteCashFlowTransactionInput[] = []
    let deleteMarketTransactions:CashFlowMarketValueInput[] = []
    let marketValues:CashFlowNetAssetValueInput[] = []
    // [{
    //   date: date,
    //   amount: netCashActivity + (usedCashFlows?.beginningNetAssetValue?.amount || 0) + investmentGainLoss,
    //   assetClassAbbreviation: data?.clientPortfolio?.cashFlowDefaultKeys?.assetClassAbbreviation || AssetClassAbbreviationCode.ALL,
    //   country: data?.clientPortfolio?.cashFlowDefaultKeys?.country || CountryCode.USA,
    //   currency: data?.clientPortfolio?.cashFlowDefaultKeys?.currency || PortfolioCurrencyCode.USADOL,
    // }]
    currentColumnData.forEach((item) => {
      if(item.transactionType === "ending-assets"){
        if(item.touched){
          marketValues.push({
            date: item.adjustedTransactionDate,
            amount: item.amount,
            assetClassAbbreviation: item.assetClassAbbreviation?.code,
            country: item.country.code,
            currency: item.currency.code,
          })
        }
        return
      }
      const originalRow = find(columnData, {transactionDate: item.transactionDate, transactionNumber: item.transactionNumber})
      if(item.transactionNumber === -1 || !originalRow){
        // New Record
        const matchedIndex = findIndex(upsertTransactions, {transactionType: item.transactionType})
        let matchedType
        if(matchedIndex !== -1){
          matchedType = cloneDeep(upsertTransactions[matchedIndex])
        } else {
          matchedType = {
            transactionType: item.transactionType,
            transactions: [],
          }
        }
        matchedType.transactions?.push({
          transactionDate: item.transactionDate,
          assetClassAbbreviation: item.assetClassAbbreviation?.code,
          amount: item.amount,
          country: item.country?.code,
          currency: item.currency?.code,
          periodEndDate: item.periodEndDate,
        })
        if(matchedIndex !== -1){
          upsertTransactions[matchedIndex] = matchedType
        } else {
          upsertTransactions.push(matchedType)
        }
      } else if (originalRow && JSON.stringify(item) !== JSON.stringify(originalRow)){
        // Update Record
        const matchedIndex = findIndex(upsertTransactions, {transactionType: item.transactionType})
        let matchedType
        if(matchedIndex !== -1){
          matchedType = cloneDeep(upsertTransactions[matchedIndex])
        } else {
          matchedType = {
            transactionType: item.transactionType,
            transactions: [],
          }
        }
        matchedType.transactions?.push({
          transactionNumber: item.transactionNumber,
          transactionDate: item.transactionDate,
          assetClassAbbreviation: item.assetClassAbbreviation?.code,
          amount: item.amount,
          country: item.country?.code,
          currency: item.currency?.code,
          periodEndDate: item.periodEndDate,
        })
        if(matchedIndex !== -1){
          upsertTransactions[matchedIndex] = matchedType
        } else {
          upsertTransactions.push(matchedType)
        }
      }
    })
    columnData.forEach((item) => {
      if(item.transactionType === "ending-assets"){
        if(!find(currentColumnData, (currentItem) => { return item.transactionDate === currentItem.transactionDate && currentItem.transactionType === "ending-assets"})){
          // Deleted Record
          deleteMarketTransactions.push({
            date: item.transactionDate,
            assetClassAbbreviation: item.assetClassAbbreviation?.code,
            country: item.country.code,
            currency: item.currency.code,
          })
        }
      } else {
        if(!find(currentColumnData, (currentItem) => { return item.transactionDate === currentItem.transactionDate && item.transactionNumber === currentItem.transactionNumber && currentItem.transactionType !== "ending-assets"})){
          // Deleted Record
          deleteTransactions.push({
            periodEndDate: item.periodEndDate,
            transactionDate: item.transactionDate,
            transactionNumber: item.transactionNumber,
          })
        }
      }
    })
    const updateClientPortfolio = upsertTransactions.length > 0 || marketValues.length > 0
    setOutstandingCalls((updateClientPortfolio ? 1 : 0) + (deleteTransactions.length > 0 ? 1 : 0) + (deleteMarketTransactions.length > 0 ? 1 : 0))
    if(updateClientPortfolio){
      updateSumSheetCashFlow({
        variables: {
          input: {
            id: selectedPortfolio.id,
            patch: {
              cashFlows: {
                cashFlowTransactionTypes: upsertTransactions,
                marketValues: marketValues,
              }
            }
          },
          date: date,
          showMonth: period === PortfolioPeriod.Monthly,
          showQuarter: period === PortfolioPeriod.Quarterly,
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      })
      .catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        console.error("Error updating sum sheet cash flow", err.message)
      })
    }
    if(deleteTransactions.length > 0){
      deleteSumSheetCashFlow({
        variables: {
          input: {
            id: selectedPortfolio.id,
            transactions: deleteTransactions,
          },
        },
        update: (cache) => {
          const portfolio: any = cache.readQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
          })
          let usedKey = period === PortfolioPeriod.Monthly ? "monthCashFlows" : "quarterCashFlows"
          let updatedPortfolio = iassign(
            portfolio,
            [`clientPortfolio`,usedKey,`cashFlowTransactionTypes`],
            (transactions) => {
              let rows = cloneDeep(transactions as any[])
              return rows.map((row) => {
                let usedTransactions = row.transactions.filter((transaction:any) => !find(deleteTransactions, {transactionDate: transaction.transactionDate, transactionNumber: transaction.transactionNumber}))
                return {
                  ...row,
                  transactions: usedTransactions,
                }
              })
            }
          )
          cache.writeQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
            data: updatedPortfolio,
          })
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      })
      .catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        console.error("Error deleting sum sheet cash flow", err.message)
      })
    }
    if(deleteMarketTransactions.length > 0){
      deleteClientPortfolioCashFlowMarketValue({
        variables: {
          input: {
            id: selectedPortfolio.id,
            marketValues: deleteMarketTransactions,
          },
        },
        update: (cache) => {
          const portfolio: any = cache.readQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
          })
          let usedKey = period === PortfolioPeriod.Monthly ? "monthCashFlows" : "quarterCashFlows"
          let updatedPortfolio = iassign(
            portfolio,
            [`clientPortfolio`,usedKey,"marketValues"],
            (transactions) => {
              let rows = cloneDeep(transactions as any[])
              return rows.filter((row) => !find(deleteMarketTransactions, {date: row.date, assetClassAbbreviation: row.assetClassAbbreviation}))
            }
          )
          cache.writeQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
            data: updatedPortfolio,
          })
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      })
      .catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        console.error("Error deleting sum sheet cash flow", err.message)
      })
    }
  }

  useEffect(() => {
    if(outstandingCalls <= 0 && saving){
      setSaving(false)
      toggle()
    }
  }, [outstandingCalls, saving])

  let largeTransactionWarning = some(currentColumnData, (item) => {
    if(usedCashFlows?.beginningNetAssetValue?.amount && item.transactionType !== "ending-assets" && item.amount > usedCashFlows?.beginningNetAssetValue?.amount/10){
      return true
    }
    return false
  })

  let bodyContent = <></>
  if(loading || exchangeRateLoading){
    bodyContent = <LoadingOverlay />
  } else if(!data && error){
    bodyContent = <ErrorDisplay error={error} />
  } else if(!!usedCashFlows){
    const usedSymbol = currencySymbolMapping[data?.clientPortfolio?.baseCurrency?.code || BaseCurrencyCode.USADOL]
    const nonUsSymbol = data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL || data?.clientPortfolio?.baseCurrency?.code === null
    let transactionTitles = [
      {name: "Inflow", cashEffect: 1, fees: 0, title: true, code: "title-inflow"},
      {name: "Inflow of Fees", cashEffect: 1, fees: 1, title: true, code: "title-inflow-fees"},
      {name: "Outflow", cashEffect: -1, fees: 0, title: true, code: "title-outflow"},
      {name: "Outflow of Fees", cashEffect: -1, fees: 1, title: true, code: "title-outflow-fees"},
      {name: "Income", cashEffect: 0, fees: null, title: true, code: "title-income"}]
    const transactionTypeMapping = sortBy(data?.transactionTypeMap, "order")?.flatMap((type) => {
      let foundTitleIdx = findIndex(transactionTitles, (title) => title.cashEffect === type?.cashEffect && (title.fees === type?.fees || title.fees === null))
      if(foundTitleIdx !== -1){
        const title = transactionTitles.splice(foundTitleIdx,1)[0]
        return [
          title,
          type
        ]
      }
      return type
    })
    const frequentlyUsed = [
      {name: "Frequently Used", cashEffect: 1, fees: 0, title: true, code: "title-frequently-used"},
      {name: "Called Capital", cashEffect: 1, fees: 0, code: "frequently-C"},
      {name: "End of Day Transfer In", cashEffect: 1, fees: 0, code: "frequently-EI"},
      {name: "Distributed Capital", cashEffect: -1, fees: 0, code: "frequently-D"},
      {name: "End of Day Transfer Out", cashEffect: -1, fees: 0, code: "frequently-EO"},
      {name: "Management Fee", cashEffect: -1, fees: 1, code: "frequently-F"},
      {name: "Return of Management Fee", cashEffect: 1, fees: 1, code: "frequently-RF"},
      {name: "Income Purchase", cashEffect: 0, fees: 0, code: "frequently-IP"},
      {name: "Income-Loss", cashEffect: 0, fees: 0, code: "frequently-IL"},
    ]
    let finalTypes = compact([{name: "Assets", title: true, code: "title-assets"}, {name: "Ending Assets", code: "ending-assets"},...frequentlyUsed,...transactionTypeMapping])
    const colDef = CashFlowColumnDef(setCurrentColumnData, finalTypes, data?.clientPortfolio?.baseCurrency?.code || BaseCurrencyCode.USADOL, startDate.add(1, "day").format(DATE_API_FORMAT), endDate.format(DATE_API_FORMAT), (usedCashFlows?.beginningNetAssetValue?.amount || 0 )/10)
    let endingAssets = currentColumnData.find((item) => item.transactionType === "ending-assets" && item.transactionDate === date)?.amount
    let convertedEndingAssets = (endingAssets !== undefined && endingExchangeRate !== undefined) ? endingAssets * endingExchangeRate : undefined
    let changedNetCashActivity = typeof selectedPortfolio?.cashFlowAssets === "number" && Math.abs(netCashActivity - (selectedPortfolio?.cashFlowAssets || 0)) > 0.01
    let changedEndingAssets = typeof selectedPortfolio?.endingAssets === "number" && Math.abs((endingAssets || 0) - (selectedPortfolio.endingAssets || 0)) > 0.01

    bodyContent = <div className="p-0 w-100 view-port-60 d-flex flex-grow-1 flex-direction-column">
      <div className="w-100 p-2 border-left border-right border-top">
        <Button color={addActive ? "secondary" : "light"!} className="mr-auto" onClick={() => addRow()}>
          <span className={classNames({"text-gray-70": !addActive})}>
            Add Row
          </span>
          <FontAwesomeIcon
            icon="plus-circle"
            className={classNames("ml-2", {"text-blue-100": addActive, "text-gray-70": !addActive})}
          />
        </Button>
        <Button color={deleteActive ? "secondary" : "light" } className="mr-auto ml-2" onClick={() => deleteRows()}>
          <span className={classNames({"text-gray-70": !deleteActive})}>
            Delete Row
          </span>
          <FontAwesomeIcon
            icon="trash"
            className={classNames("ml-2", {"text-blue-100": deleteActive, "text-gray-70": !deleteActive})}
          />
        </Button>
      </div>
      <SortableTable
        key={`${period}-${date}`}
        loading={false}
        filterText={""}
        columnDefs={colDef}
        tableData={currentColumnData}
        rowId={"rowId"}
        editMode={true}
        setSelectedRows={setSelectedRows}
        onReady={onReady}
        onCellValueChanged={onCellValueChanged}
        noPagination
      />
      <div className="d-flex flex-direction-column align-items-end background-blue-30 p-2">
        <div className={classNames({"w-37": !nonUsSymbol, "w-50": nonUsSymbol})}>
          <Table>
            <tbody>
              <tr>
                <td className="border-0 text-left px-1"><div className="font-weight-bold">Net Cash Activity</div></td>
                <td className={classNames("border-0 text-right px-1",{"negative-value": (!!netCashActivity && netCashActivity < 0)})}>
                  <div>
                    {changedNetCashActivity &&
                      <>
                        <FontAwesomeIcon
                          icon="exclamation-triangle"
                          className="text-danger ml-1"
                          id={netCashTooltipId}
                        />
                        <UncontrolledTooltip placement='top' className="tooltip-ontop-modal" target={netCashTooltipId} delay={200} autohide={false}>
                          Value differs from amount in SumSheet table
                        </UncontrolledTooltip>
                      </>
                    }
                    {numbro(netCashActivity).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}
                  </div>
                </td>
                {(data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL) &&
                  <td className={classNames("border-0 text-right px-1",{"negative-value": (!!convertedNetCashActivity && convertedNetCashActivity < 0)})}>
                    <div className="ml-1">
                      {numbro(convertedNetCashActivity).format(currencyWithMantissaFormat)}
                    </div>
                  </td>
                }
              </tr>
              <tr>
                <td className="border-0 text-left px-1"><div className="font-weight-bold">Ending Assets</div></td>
                <td className={classNames("border-0 text-right px-1",{"negative-value": (!!endingAssets && endingAssets < 0)})}>
                  <div>
                    {changedEndingAssets &&
                      <>
                        <FontAwesomeIcon
                          icon="exclamation-triangle"
                          className="text-danger ml-1"
                          id={endingAssetsTooltipId}
                        />
                        <UncontrolledTooltip placement='top' className="tooltip-ontop-modal" target={endingAssetsTooltipId} delay={200} autohide={false}>
                          Value differs from amount in SumSheet table
                        </UncontrolledTooltip>
                      </>
                    }
                    {endingAssets === undefined ? "-" : numbro(endingAssets).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}
                  </div>
                </td>
                {(data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL) &&
                  <td className={classNames("border-0 text-right px-1",{"negative-value": (!!convertedEndingAssets && convertedEndingAssets < 0)})}>
                    <div className="ml-1">
                      {convertedEndingAssets === undefined ? "-" : numbro(convertedEndingAssets).format(currencyWithMantissaFormat)}
                    </div>
                  </td>
                }
              </tr>
              <tr>
                <td className="border-0 text-left px-1"><div className="font-weight-bold">Gain/Loss</div></td>
                <td className={classNames("border-0 text-right px-1 font-weight-bold",{"negative-value": (!!investmentGainLoss && investmentGainLoss < 0)})}>
                  <div>
                    {investmentGainLoss === undefined ? "-" : numbro(investmentGainLoss).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}
                  </div>
                </td>
                {(data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL) &&
                  <td className={classNames("border-0 text-right px-1 font-weight-bold",{"negative-value": (!!convertedInvestmentGainLoss && convertedInvestmentGainLoss < 0)})}>
                    <div className="ml-1">
                      {convertedInvestmentGainLoss === undefined ? "-" : numbro(convertedInvestmentGainLoss).format(currencyWithMantissaFormat)}
                    </div>
                  </td>
                }
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={resetModal} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        {largeTransactionWarning && <div className="text-white background-orange-100 modal-header-warning font-weight-normal">Transaction is more than 10% of the beginning market value. Revalue may be needed, please refer to <a className="text-decoration-underline text-white" href="https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/2057502766/Helix" target="_blank" rel="noreferrer">Helix rules.</a></div>}
        <div className='d-flex justify-content-between'>
          <div>Cash Flows</div>
          <div onClick={() => toggle()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="pt-0">
        <div>
          <div className="py-2 font-size-16">
            <div className="font-weight-bold">{selectedPortfolio.name}</div>
            <div className="d-block">{period === PortfolioPeriod.Quarterly ? "Quarter" : "Month"} Ending {moment(date).format(DATE_TEXT_FORMAT)}</div>
          </div>
        </div>
        {bodyContent}
      </ModalBody>
      <ModalFooter className={classNames({"justify-content-end": true})}>
        {/* <Button color="secondary btn-thin" className="ml-1 text-callan-blue" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button> */}
        <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => resetModal()} saving={saving} onSubmit={handleSubmit} disableOnError={true}/>
      </ModalFooter>
    </Modal>
  )
}

interface JobModalProps {
  isOpen: boolean
  toggle: () => void
  jobs?: SumSheetSavedJobFragment[]
  planId: number
  date: string
  autoRun: boolean
}

export const JobModal: React.FC<JobModalProps> = (props) => {
  const {isOpen, toggle, jobs, planId, date, autoRun} = props
  const [saving, setSaving] = useState(false)
  const [outstandingCalls, setOutstandingCalls] = useState(0)
  const [runSavedJob] = useRunSavedJobMutation()
  const { addAlert } = useContext(TemporaryAlertContext)
  const job = jobs?.[0]

  const resetModal = () => {
    setSaving(false)
    setOutstandingCalls(0)
    toggle()
  }

  useEffect(() => {
    if(outstandingCalls <= 0 && saving){
      resetModal()
      addAlert({message: <>The {job?.type?.value} jobs have been queued. View job status in the <span className="cursor-pointer text-decoration-underline" onClick={() => window.open(`/plans/${planId}/activities` ,'_blank')}>Plan's Activities.</span></>, timeout: 5000, color: "jobSuccess"})
    }
  }, [outstandingCalls])

  const runJob = () => {
    if(!job?.id) return
    setSaving(true)
    setOutstandingCalls(jobs?.length || 0)
    jobs?.forEach((job) => {
      runSavedJob({
        variables: {
          input: {
            id: job.id || -1,
            referenceDate: date
          }
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      }).catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        toggle()
        addAlert({message: `The ${job?.type?.value} failed to be queued.`, timeout: 5000, color: "error"})
        console.error("Error running Job", err.message)
      })
    })
  }

  useEffect(() => {
    if(autoRun && !saving && jobs && jobs?.length > 0){
      runJob()
    }
  }, [autoRun, jobs])

  return <Modal isOpen={!autoRun && isOpen} toggle={toggle} zIndex={1500}>
    <ModalHeader className="full-width-header">
      <div className='d-flex justify-content-between'>
        <div>Confirmation</div>
        <div onClick={() => toggle()}>
          <FontAwesomeIcon icon='times' className='ml-auto' />
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div>
        {job?.type?.value} will run for every portfolio in the current Sumsheet that uses this job.
      </div>
    </ModalBody>
    <ModalFooter>
      <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => toggle()} saving={saving} onSubmit={runJob} disableOnError={true} saveText={`Run ${job?.type?.value}`}/>
    </ModalFooter>
  </Modal>
}