import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { default as classnames, default as classNames } from 'classnames'
import { cloneDeep, first, findIndex, get, merge, orderBy, set, some } from "lodash"
import moment from 'moment'
import React, { ComponentType, FormEvent, useContext, useEffect, useState } from "react"
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap'
import { AssetClassFragment, ClientPortfolioCustomPerformanceHolderFragment, ClientPortfolioCustomPerformanceMemberFragment, CreateTargetInput, CustomMemberFragment, Maybe, Scalars, TargetConstantRebalanceFrequencyCode, TargetDefinition, TargetDefinitionQuery, useCreateTargetMutation, useTargetDefinitionLazyQuery } from '../../../__generated__/graphql'
import Auth from '../../../Auth/Auth'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField, FormInputSubClasses } from '../../../helpers/constant'
import { SearchTypeDisplays } from '../../Search/SearchEnums'
import { FormInput } from '../../ui/Forms/FormInput'
import LoadingOverlay from '../../ui/Loading'
import { GetLookupDataToOptions } from '../../ui/LookupOptions'
import { TargetConstantComponent } from './Benchmark/TargetConstant'
import { ClientPortfolioHistoryData, DEFAULT_TARGET_DETAIL, formatTargetToCreateTargetInput, TargetDefinitionExtendedType, TargetDetailExtendedType, TypeNameToMemberTypeMapping } from './helper'
import { ClientPortfolioAddStatic } from './Search'
import iassign from 'immutable-assign'
import { HistoryModal } from './HistoryModal'

type tableKeys = "new" | "current" | "previous"

type CustomPerformanceTableConfigType = {
  [K in tableKeys] : any
}

export interface CustomPerformanceTableProps {
  auth: Auth
  editMode: boolean,
  showStatus: boolean,
  key: keyof CustomPerformanceTableConfigType,
  data: CustomPerformanceExtendedType,
  planIds: {id: number, name: string, __typename: string}[],
  setEditedCustomPerformance: (props: any) => void,
  endCurrent: () => void,
  revertEndCurrent: () => void,
  setData: (data: any) => void, // to update parent state for all data
  showSuffixEndDate?: boolean
  currentTableBatchEndDate?: string
  handleTableStateChange: (state: CustomPerformanceExtendedType, tableName: keyof CustomPerformanceTableConfigType) => void
  openItems: number[]
  currentHierarchy?: number[]
  setOpenItems: (newOpenItems: number[]) => void
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | TargetDetailExtendedType[] | null | undefined>>
  hasFullHistoryButton?: boolean
}

interface CustomPerformanceMemberExtendedType extends ClientPortfolioCustomPerformanceMemberFragment {
  source?: CustomPerformanceTableProps["key"]
  editedEndDate?: Maybe<Scalars['Date']>
  name?: string
  newAdded?: boolean
  // newTarget?: any[]
}

const TypeLookupOptions = () =>
  GetLookupDataToOptions({
    data: [
      {code: "P", value: "Portfolio"},
      {code: "F", value: "Vehicle"},
      {code: "G", value: "Group"},
      {code: "X", value: "Index"},
      {code: "T", value: "Benchmark"},
    ],
    // placeholder: "Please Select"
    multiple: true,
  })

export interface CustomPerformanceExtendedType extends ClientPortfolioCustomPerformanceHolderFragment {
  editedEndDate?: Maybe<Scalars['Date']> // useless,
  newAdded?: boolean
  source?: CustomPerformanceTableProps["key"]
  orderedPid?: number
  members?: Maybe<Maybe<CustomPerformanceMemberExtendedType>[]> | undefined
  // newTarget?: any[]
}

type EditedCustomPerformanceType = {[tableKey: string]: ClientPortfolioCustomPerformanceHolderFragment| null}

type CustomPerformanceComponentProps = {
  auth: Auth
  // data: CustomPerformanceExtendedType | any
  data: EditedCustomPerformanceType
  initialState: EditedCustomPerformanceType | any
  handleChange: (value: any) => void
  editMode: boolean
  hidden?: boolean
  options?: JSX.Element
  planIds: {id: number, name: string, __typename: string}[]
  setEditedCustomPerformance: React.Dispatch<React.SetStateAction<CustomPerformanceExtendedType | undefined>>
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | TargetDetailExtendedType[] | null | undefined>>
  inceptionDate?: string
}

type DisplayDataType = {
  property: string
  checkFunction?: (input: any) => any
  value: any
}

interface columnDefItem {
  field: string
  type: string
  title: string
  className?: string
  readonly?: boolean
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
  textLinkParams?: {
    property?: string, // to get property value from state and then passed to input as text params
    url: string,
  },
  displayData?: DisplayDataType
}

interface SingleCustomPerformanceInput extends columnDefItem {
  component?: ComponentType<any> // for table
  optionSourceFunction?: (variables?: any) => JSX.Element | null // for select boxes like Asset Classes
  options?: JSX.Element | {code: string, value: string}[] // for select options that have already been loaded
}

export interface SingleCustomPerformanceProps {
  data: any
  row: string
  columnDef: SingleCustomPerformanceInput[]
  editMode: boolean
  removeRow: (input: any) => void
  updateValue: (value: any, property: string) => void
  tableKey: string
  isTargetMember?: boolean
  hideRemoveButton?: boolean
  historicalDate?: string
}

interface SingleCustomPerformanceExtendedProps extends SingleCustomPerformanceProps {
  target: TargetDetailExtendedType
  updateTargetValue: (value: any, property: string) => void
  openItems: number[]
  currentHierarchy?: number[]
  setOpenItems: (newOpenItems: number[]) => void
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | TargetDetailExtendedType[] | null | undefined>>
}

const CustomPerformanceInput: SingleCustomPerformanceInput[] = [
  {
    field: "memberType.code",
    title: "type",
    type: "select",
    subtype: "single",
    optionSource: "CustomSourceMemberTypeCode",
    readonly: true,
    subClasses: {
    },
    widthClass: "col-2",
  },
  {
    field: "member.id", // needs manipulation in value
    title: "id",
    type: "text",
    readonly: true,
    subClasses: {
      inputClasses: "shrink",
    },
    widthClass: "col-1",
  },
  {
    field: "member.name",
    title: "name",
    type: "text",
    readonly: true,
    subClasses: {
    },
    widthClass: "col-3",
  },
]

const BatchEndDateInput: FormInputField[] = [
  {
    property: "startDate",
    label: "Start",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
  {
    property: "editedEndDate",
    label: "End",
    type: "date",
    subtype: "month",
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
]

const TargetInput: SingleCustomPerformanceInput[] = [
  {
    field: "weight",
    title: "weight",
    type: "float",
    subtype: "percent",
    subClasses: { inputClasses: "text-right px-1" },
    widthClass: "col-1",
  },
  {
    field: "constantRebalance[0].constant",
    title: "constant",
    type: "table",
    component: TargetConstantComponent,
    subClasses: {
    },
    widthClass: "col-2",
  },
  {
    field: "constantRebalance[0].frequency.code",
    title: "reBalancing",
    type: "select",
    subtype: "single",
    optionSource: "TargetConstantRebalanceFrequencyCode",
    subClasses: {
    },
    widthClass: "col-1",
  },
  {
    field: "lagMonths",
    title: "lag (Months)",
    type: "number",
    subClasses: {
    },
    widthClass: "col-1",
  },
]

const CombinedInput: SingleCustomPerformanceInput[] = [
  ...CustomPerformanceInput, ...TargetInput
]


interface CustomPerformanceHistoryData extends ClientPortfolioHistoryData{
}
interface CustomPerformanceHistoryProps {
  isOpen: boolean
  title: string
  toggle: () => void
  data: CustomPerformanceHistoryData
  inceptionDate?: string
}

export const CustomPerformanceHistoryModal: React.FC<CustomPerformanceHistoryProps> = ({
  isOpen,
  title,
  toggle,
  data,
  inceptionDate,
}) => {
  const [datedOpenItems, setDatedOpenItems] = useState<{[K in string]: number[]}>({})
  const [historicalData, setHistoricalData] = useState<CustomPerformanceHistoryData["rows"]>(data.rows)

  useEffect(() => {
    setHistoricalData(data.rows)
  }, [data])
  const handleRowValueChange = (date: string, idx: any, value: any, property: any) => {
    let performanceMembersIndex = findIndex(historicalData, {date})
    let openItems = (datedOpenItems[date] || [])
    openItems.push(value.targetId)
    setDatedOpenItems({...datedOpenItems, [date]: openItems})

    let newCurrentState = iassign(
      historicalData,
      [performanceMembersIndex],
      (oldValue: any) => {
        let updatedMembers = cloneDeep(oldValue?.data)
        updatedMembers[idx]["member"] = {...updatedMembers[idx]["member"], ...value}
        return {...oldValue, data: updatedMembers}
      }
    )
    console.log({newCurrentState, date, idx, performanceMembersIndex, value})
    setHistoricalData(newCurrentState)
  }

  return (
    <HistoryModal isOpen={isOpen} title={title} toggle={toggle} xlSize={true}>
      {historicalData.map((row, idx) => {
        const fromDate = row.data[0]?.startDate === DEFAULT_NEW_START_DATE ? moment(inceptionDate).add(1, 'day').format("MM/DD/YYYY") : moment(first(row.data)?.startDate).add(1, 'day').format("MM/DD/YYYY")
        const toDate = row.data[0]?.endDate === DEFAULT_NEW_END_DATE ? "Current" : moment(first(row.data)?.endDate).format("MM/DD/YYYY")
        return (
          <div className="custom-performance-modal-history-item" key={idx}>
            <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
              <h5 className="headline mt-3 text-nowrap d-inline-block">
                {fromDate} - {toDate}
              </h5>
            </div>
            <Table className="custom-performance-modal-table">
              <thead>
              <tr className="text-uppercase">
                {CombinedInput.map((headerDef, idx) => {
                  const hasTarget = some(row.data, (el: any) => el?.memberType?.code === "T" && el?.member?.__typename === "Target")
                  let showTitle = (hasTarget) || idx < 3
                  if(!showTitle) {
                    return <th key={`custom-performance-header-${idx}`} className={""}> </th>
                  }
                  return (
                    <th key={`custom-performance-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left")}>
                      {headerDef.title}
                    </th>
                  )
                })}
              </tr>
              </thead>
              <tbody>
                {row.data.map((tr, rowIdx) => {
                  let key = "history"
                  let isTarget = tr.memberType?.code === "T" && tr.member?.__typename === "Target"
                  let target = isTarget ? (tr.member as TargetDetailExtendedType) : undefined
                  let targetMembers = target && target.definition
                  let openItems = datedOpenItems[row.date] || []
                  let isSubMemberTableOpen = !!(target && openItems?.includes(target?.targetId))
                  let calculatedTotal: number = (targetMembers || [])?.reduce((acc, el) => {
                    if(el?.weight) {
                      acc = acc + el?.weight
                    }
                    return acc
                  }, 0)
                  return (
                    <React.Fragment key={`${key}-custom-performance-history-${idx}-${rowIdx}`}>
                      <CustomPerformanceRow
                        data={tr}
                        key={`${key}-custom-performance-row-${rowIdx}`}
                        row={`${rowIdx}-history`}
                        editMode={false}
                        columnDef={CombinedInput}
                        removeRow={() => {}}
                        target={target}
                        updateValue={(value, property) => handleRowValueChange(row.date, rowIdx, value, property)}
                        openItems={openItems}
                        setOpenItems={(openItems:any[]) => {setDatedOpenItems({...datedOpenItems, [row.date]: openItems})}}
                        tableKey={key}
                        historicalDate={row.date}
                        // setLazyFetchedTargetData={setLazyFetchedTargetData}
                      />
                      {isSubMemberTableOpen && <React.Fragment key={`${key}-custom-performance-row-${idx}-target-members`}>
                        {targetMembers && !isEmptyTargetMembersTable(targetMembers) &&
                        targetMembers?.map((targetMember, targetMemberIdx) => {
                          return (
                            <CustomPerformanceRow
                              data={targetMember}
                              key={`${key}-custom-performance-target-member-${idx}-${targetMemberIdx}`}
                              row={`${key}-${idx}-${targetMemberIdx}`}
                              editMode={false}
                              columnDef={CombinedInput}
                              removeRow={() => {}}
                              updateValue={(value, property) => {}}
                              tableKey={key}
                              isTargetMember={true}
                            />
                          )
                        })}
                        {targetMembers && targetMembers?.length > 1 &&
                        <tr>
                          <td colSpan={2}></td>
                          <td align="left" colSpan={1} className={classnames("text-left font-weight-bold", {"text-danger": calculatedTotal !== 1})}>
                            Total
                          </td>
                          <td align="right" colSpan={1} className={classnames("text-right font-weight-bold", {"text-danger": calculatedTotal !== 1})}>
                            {`${Math.round(calculatedTotal*100000)/1000}%`}
                          </td>
                          <td align="left" colSpan={4} className={classnames("text-left font-weight-bold")}>
                          </td>
                        </tr>}
                      </React.Fragment>}
                    </React.Fragment>
                  )
                })}

              </tbody>
            </Table>
          </div>
        )
      })}
    </HistoryModal>
  )
}
interface CustomPerformanceCurrentTableProps extends CustomPerformanceTableProps {
  key: 'current'
  showSuffixEndDate: boolean
  setCurrentTableBatchEndDate?: React.Dispatch<React.SetStateAction<string | null>>
  currentTableBatchEndDate: string
  greyOut: boolean
}

interface CustomPerformanceNewTableProps extends CustomPerformanceTableProps {
  key: 'new'
  currentTableBatchEndDate: string
}

interface CustomPerformancePreviousTableProps extends CustomPerformanceTableProps {
  key: 'previous'
  toggleFullHistoryModal?: () => void
}

const dataShouldShow = (currentState: any, displayData: DisplayDataType) => {
  let { property: disPlayProperty, checkFunction, value: disPlayValue } = displayData

  let calculatedPropertyValInput = get(currentState, disPlayProperty)

  if (checkFunction) {
    let valueFromCheckFunction = checkFunction(calculatedPropertyValInput)
    return valueFromCheckFunction === disPlayValue
  } else {
    return calculatedPropertyValInput === disPlayValue
  }
}

export const CustomPerformanceRow = (props: SingleCustomPerformanceProps | SingleCustomPerformanceExtendedProps) => {
  let { data, row, columnDef, editMode, removeRow, updateValue, tableKey, isTargetMember, hideRemoveButton, historicalDate} = props
  let {target, updateTargetValue, openItems, setOpenItems, setLazyFetchedTargetData} = props as SingleCustomPerformanceExtendedProps
  const isOpen = !!(target && openItems?.includes(target?.targetId))
  const usedHistoricalDate = historicalDate || "9999-12-31"
  const [targetDefinition, setDefinition] = useState<Maybe<TargetDefinitionExtendedType[]>>(null)

  const [fetchTargetDefinitionQuery, {loading, error, data: fetchedDefinitionData}] = useTargetDefinitionLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (fetchedDefinitionData) => {
      if(fetchedDefinitionData?.target?.definition) {
        let currentDefs = (fetchedDefinitionData?.target?.definition?.filter(el => el?.endDate === usedHistoricalDate) || []) as TargetDefinitionExtendedType[]
        updateValue({...target, definition: currentDefs}, "member")
        addDataToLazyFetchedData(fetchedDefinitionData)
      }
    },
  })

  /**
   * 1. click caret, fetch definition from api
   * 2. definition data fetched, updateTargetValue to include this data.
   * 3. see target.definition data change in props from parent
   * 4. setOpenItems to change parent openItems state
   * 5. see openItems change in props from parent, setOpen for target itself.
  */

  useEffect(() => {
    if(target?.definition?.length) {
      setDefinition(target?.definition)
      if(!isOpen && !openItems?.includes(target?.targetId)) {
        setOpenItems([...openItems, target?.targetId])
      }
    }else {
      if(targetDefinition) {
        setDefinition(null)
      }
    }
  }, [target?.definition])

  useEffect(() => {
    if(fetchedDefinitionData && editMode) {
      // re-add data if data fetched in view and switch to edit
      let currentDefs = (fetchedDefinitionData?.target?.definition?.filter(el => el?.endDate === "9999-12-31") || []) as TargetDefinitionExtendedType[]
      updateValue({...target, definition: currentDefs}, "member")
    }
    if(!editMode && isOpen) {
      setOpenItems([])
    }
  }, [editMode])

  const toggleExpand = () => {
    if(!target) {
      return
    }
    if (isOpen) {
      let removedArray = openItems.filter((id) => target?.targetId !== id)
      setOpenItems(removedArray)
    } else {
      setOpenItems([...openItems, target.targetId])
    }
  }

  const handleExpand = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if(target) {
      if(targetDefinition) {
        toggleExpand()
      }else {
        fetchTargetDefinitionQuery({variables: {id: target.targetId}})
      }
    }
  }

  const addDataToLazyFetchedData = (fetchedData: TargetDefinitionQuery ) => {
    let target = fetchedData?.target as TargetDetailExtendedType
    if(!target || !target?.definition) {
      return
    }
    if(setLazyFetchedTargetData){
      setLazyFetchedTargetData((oldData: Maybe<TargetDetailExtendedType>[] | null | undefined) => {
        if(!oldData) {
          return [target]
        }else {
          let filteredData: Maybe<TargetDetailExtendedType>[] = oldData?.filter(el => el?.targetId === target?.targetId) || [] as Maybe<TargetDetailExtendedType>[]
          return [...filteredData, target]
        }
      })
    }
  }

  /**
   * Two types
   * 1. has target & updateTargetValue in props, expandable.
   * 3. has no target or updateTargetValue in props, not expandable
   * **/

  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"} key={row}>
      {columnDef.map((header, idx) => {
        let showTarget = target?.name && idx > 3
        let isTarget = data?.memberType?.code === "T" || target
        let options: any
        let { field, displayData, textLinkParams, type, subtype, optionSource, subClasses, optionSourceFunction} = header
        let propertyVal = get(data, field)
        let readOnly = !!header?.readonly
        let editable = !!editMode

        let updateFunction = (value: any) => {
          if (target && idx > 3) {
            return updateTargetValue(value, header.field)
          }else {
            return updateValue(value, header.field)
          }
        }
        if(optionSourceFunction) {
          options = optionSourceFunction()
        }
        if(field === "memberType.code") {
          return (
            <td key={`${idx}`} className={`flex-row target-members-caret-container ${header?.widthClass}`} onClick={handleExpand}>
              <div
                className={`d-flex`}
                style={{marginLeft: 10 + (!!isTargetMember? 10: 0)}}
              >
              <div
                className={`target-members-caret`}
              >
                {isTarget && target &&
                  <FontAwesomeIcon
                  icon={isOpen ? "caret-down" : "caret-right"}
                  size="sm"
                  />}
              </div>
              <FormInput
                key={header.field}
                property={`${row}-${idx}-${header.field}`}
                displayName={""}
                type={header.type}
                subtype={header.subtype}
                idx={`${tableKey}-benchmark-item-${row}-${idx}`}
                editMode={editable}
                propertyVal={propertyVal}
                readonly={readOnly}
                updateValue={updateFunction}
                required={header.required}
                // optionSource={header.optionSource}
                subClasses={subClasses || {}}
                options={TypeLookupOptions()}
                showZero={true}
              />
              </div>
            </td>
          )
        }
        if(field === "member.id") {
          editable = false
          if (data?.memberType?.code === "F") {
            field = "member.vehicle.id"
            propertyVal = get(data, field)
          }else if (data?.memberType?.code === "X") {
            field = "member.indexId"
            propertyVal = get(data, field)
          }
        }else if(field === "member.name") {
          // all data editable
          // editable = editable && (!!data?.member?.newAdded)
          if (displayData && !dataShouldShow(data, displayData)) {
            return <td key={idx}></td>
          }
          if(data?.memberType?.code === "F") {
            field = "member.vehicle.name"
            propertyVal = get(data, field)
          }else if(data?.memberType?.code === "T" && data?.newAdded) {
            readOnly = false
          }
        } else if(field === "weight") {
          if(!!isTargetMember) {
            propertyVal = Math.round(propertyVal*100000)/1000
            updateFunction = (value: any) =>
               updateValue(value/100, header.field)
          }else {
            return <td key={idx}></td>
          }
        } else {
          propertyVal = get(target, field)
          if (!showTarget) {
            return <td key={idx}></td>
          }else if(!data?.newAdded) {
            // enable target edit
            // editable = false
          }
          if(type === "table") {
            let Component = header.component
            if(Component) {
              return (
                <Component
                  key={idx}
                  data={propertyVal || {}}
                  editMode={editable}
                  row={idx}
                  handleChange={(newState: any) =>{
                    updateFunction(newState)
                  }}
                  >
                </Component>
              )

            }
          }
        }
        let textLinkResolved: Maybe<{ url: string }> = null
        if(type === "text" && subtype == "textLink" && !editMode && textLinkParams) {
          let {property: linkProperty, url} = textLinkParams
          let urlResolved: string = url
          if(linkProperty) {
            urlResolved +=  get(data, linkProperty)
          }
          textLinkResolved = {url: urlResolved}
        }
        if(!data.newAdded && isTargetMember && idx < 2) {
          editable = false
        }

        return (
          <td key={`${idx}`} className={`${header?.widthClass}`}>
            <FormInput
              key={header.field}
              property={`${row}-${idx}-${header.field}`}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={`${tableKey}-custom-performance-item-${row}-${idx}`}
              editMode={editable}
              propertyVal={propertyVal}
              readonly={readOnly}
              updateValue={updateFunction}
              required={header.required}
              optionSource={header.optionSource}
              subClasses={subClasses || {}}
              textLinkParams={textLinkResolved}
              options={options}
            />
          </td>
        )
      })}
      {/* hide action column in history modal*/}
      {tableKey !== "history" &&
        <td className="actions">
          {/* hide remove trash icon for existing target member(current/previous) */}
          {editMode && ((tableKey === "new") || (tableKey === "current" && !hideRemoveButton )) && (
            <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeRow(row)}>
              <FontAwesomeIcon icon="trash" className="text-blue-100" />
            </Button>
          )}
        </td>
      }
    </tr>
  )
}

const isEmptyTable = (props: CustomPerformanceExtendedType) => !(props?.members && props?.members?.length > 0)

const isEmptyTargetMembersTable = (props: TargetDefinitionExtendedType[]) => !(props && props?.length > 0)

const getMembersFromCustomPerformance = (data: CustomPerformanceExtendedType) => {
  // only show recent constantRebalance data.

  let members = data?.members as CustomPerformanceMemberExtendedType[]
  return members.map(el => {
    if(el?.memberType?.code === "T" && el?.member?.__typename === "Target"){
      let constantRebalance = el?.member?.constantRebalance
      if(!constantRebalance || constantRebalance?.length < 1) {
        return el
      }else {
        let latestRebalance = orderBy(constantRebalance, ["startDate"], ["desc"])[0]
        return ({...el, member: {...el.member, constantRebalance: [latestRebalance]}})
      }
    }
    return el
  })
}

const DEFAULT_NEW_END_DATE = "9999-12-31"
const DEFAULT_NEW_START_DATE = "1900-01-01" //This Date is different to the others for some reason

export const DEFAULT_NEW_TARGET:CreateTargetInput = {
  overview: {
    name: "",
  },
  constantRebalance: {
    startDate: moment().format(DATE_API_FORMAT),
    frequency: TargetConstantRebalanceFrequencyCode.MTH,
    constant: {
      amount: 0,
      basis: 0,
    }
  },
  definition: []
}

const getPreviousEndDate = (props: {performanceMembers: any, key: string}): any| null => {
  let {performanceMembers, key} = props
  if(key === "previous" && (!performanceMembers || performanceMembers.length < 1)) {
    return null
  }else {
    let member = performanceMembers[0]
    return member?.endDate
  }
}

const CustomPerformanceTableComponent = (props: CustomPerformanceTableProps | CustomPerformanceCurrentTableProps | CustomPerformanceNewTableProps | CustomPerformancePreviousTableProps) => {
  let {editMode, showStatus, key, data, setData, planIds, setEditedCustomPerformance, endCurrent, revertEndCurrent, showSuffixEndDate, currentTableBatchEndDate, handleTableStateChange: handleChange, hasFullHistoryButton} = props
  // handleTableStateChange: handleChange; oldName=> NewName
  const [performanceMembers, setPerformanceMembers] = useState<CustomPerformanceMemberExtendedType[]>(getMembersFromCustomPerformance(data)) // for add search

  let {toggleFullHistoryModal} = props as CustomPerformancePreviousTableProps

  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(!!showSuffixEndDate && key !== "previous")

  const [previousEndDate, setPreviousEndDate] = useState<string>(getPreviousEndDate({performanceMembers, key}))
  const [initialDate, setInitialDate] = useState((performanceMembers && performanceMembers?.length > 0) ? performanceMembers[0]?.startDate : "1900-01-01")

  const [showSearchBar, setShowSearchBar] = useState(false)
  const [currentId, setId] = useState(-1)

  // create new target module
  const [createTarget] = useCreateTargetMutation()
  const [customTargetSaving, setSaving] = useState(false)
  const [savedNewTarget, setNewTarget] = useState<Maybe<TargetDetailExtendedType | null>>(null)
  const { errors } = useContext(EditButtonContext)

  // expandable list
  let {openItems, currentHierarchy, setOpenItems, setLazyFetchedTargetData} = props as CustomPerformanceTableProps

  const [showTargetMemberSearchBar, setShowTargetMemberSearchBar] = useState(false)

  useEffect(() => {
    setInitialDate((performanceMembers && performanceMembers?.length > 0) ? performanceMembers[0]?.startDate : "1900-01-01")
    setShowTargetMemberSearchBar(false)
  }, [editMode])

  const addTargetMemberComponent = (idx: number) => {
    let target = (performanceMembers[idx].member as unknown as TargetDetailExtendedType) // TODO verify
    if(!target) {
      return <tr key={"add-target-member-not-a-target"} className={"text-center"}></tr>
    }
    let targetMembers = target?.definition
    let showCalculatedTotal = targetMembers && targetMembers?.length > 0
    let calculatedTotal: number = (targetMembers || [])?.reduce((acc, el) => {
      if(el?.weight) {
        acc = acc + el?.weight
      }
      return acc
    }, 0)
    return(
      <tr key={`add-target-member-${idx}`} className={"text-center"}>
        <td align="center" colSpan={2}>
          <Row key={`add-target-member`}>
            <div>
              <Button color="link" className="text-callan-blue btn-thin py-0 ml-4 border-bottom-line-break" onClick={() => setShowTargetMemberSearchBar(true)}>
                {"Add Benchmark Member"}
                <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
              </Button>
            </div>
          </Row>
        </td>
        {showCalculatedTotal &&
          <>
            <td align="left" colSpan={1} className={classnames("text-left font-weight-bold", {"text-danger": calculatedTotal !== 1})}>
              Total
            </td>
            <td align="right" colSpan={1} className={classnames("text-right font-weight-bold", {"text-danger": calculatedTotal !== 1})}>
              {`${Math.round(calculatedTotal*100000)/1000}%`}
            </td>
            <td align="left" colSpan={4} className={classnames("text-left font-weight-bold")}>
            </td>
          </>
        }
        {!showCalculatedTotal &&
          <>
            <td align="left" colSpan={6} className={classnames("text-left font-weight-bold")}>
            </td>
          </>
        }
      </tr>
    )
  }

  const generateId = () => {
    let nextId = currentId - 1
    setId(nextId)
    return nextId
  }

  const convertAddedItem = (addItem: any) => {
    // plan / target / vehicle
    let startDate = key === "new" ? currentTableBatchEndDate : initialDate
    let endDate = DEFAULT_NEW_END_DATE
    if(key === "previous") {
      startDate = (performanceMembers && performanceMembers?.length > 0) ? performanceMembers[0]?.startDate : null
      endDate = data.editedEndDate || previousEndDate
    }
    let newId = addItem?.id
    if(!newId) {
      newId = generateId()
    }
    let typename = addItem.__typename
    let member: CustomMemberFragment = {id: (newId).toString(), name: addItem?.name || "", __typename: "ClientPortfolio"}
    if(typename === "ClientPortfolio") {
    }else if(typename === "Target") {
      // new created typename
      member = {...DEFAULT_TARGET_DETAIL, targetId: (newId).toString(), name: addItem?.name || "", __typename: "Target"}
      let newTarget = {...DEFAULT_TARGET_DETAIL, targetId: generateId(), name: addItem?.name || "", __typename: "Target"}
      let input = formatTargetToCreateTargetInput(newTarget)
      createTarget({variables: {input}}).then(result => {
        let resultTarget = result?.data?.createTarget?.target
        let targetId = resultTarget?.targetId || 0
        if(resultTarget && targetId > 0 ) {
          member = resultTarget
          setNewTarget(resultTarget)
        }
      }).catch(err => {
        console.error(err.message)
      })
      .finally(() => {
        setSaving(false)
      })
    }else if(typename === "Vehicle") {
      let vehicle = addItem.vehicle
      member = {vehicle } as any
    }else {
      console.log("what __typename",{addItem})
    }
    setShowSearchBar(false)
    setShowTargetMemberSearchBar(false)
    let memberType = (TypeNameToMemberTypeMapping[typename] || "P") as any
    // console.log({typename, addItem, member, memberType})
    return ({
      startDate: startDate,
      endDate: endDate,
      member,
      memberType: {code: memberType || "P", __typename: "CustomMemberTypeLookup"},
      source: key,
      newAdded: true,
      __typename: "CustomPerformanceMember"
    }) as CustomPerformanceMemberExtendedType
  }

  const convertAddedMember = (addItem: any, idx: number) => {
    let startDate = key === "new" ? currentTableBatchEndDate : initialDate
    let endDate = DEFAULT_NEW_END_DATE
    if(key === "previous") {
      startDate = (performanceMembers && performanceMembers?.length > 0) ? performanceMembers[idx]?.startDate : null
      endDate = data.editedEndDate
    }
    let newId = addItem?.id
    if(!newId) {
      newId = generateId()
    }

    let typename = addItem?.__typename
    let member = {
      id: (newId).toString(),
      name: addItem?.name || "",
    __typename: typename,}

    if(typename === "ClientPortfolio") {
    }else if(typename === "Vehicle") {
      let vehicle = addItem.vehicle
      member = {vehicle } as any
    }else {
      console.log("what __typename",{addItem})
    }
    setShowSearchBar(false)
    setShowTargetMemberSearchBar(false)
    let memberType = (TypeNameToMemberTypeMapping[typename] || "P") as any
    let newMember = ({
      startDate: startDate || moment("1900-01-01").format(DATE_API_FORMAT),
      memberType: {code: memberType || "P", __typename: "TargetMemberTypeLookup"},
      member,
      assetClass: {id: "", code: ""} as any,
      source: key,
      newAdded: true,
      weight: 0,
      __typename: "TargetDefinition"
    }) as TargetDefinition
    return newMember
  }

  useEffect(() => {
    setShowSearchBar(false)
  }, [editMode])

  useEffect(() => {
    setShowDeleteButton(!!props?.showSuffixEndDate)
  }, [props?.showSuffixEndDate])

  useEffect(() => {
    if(editMode && savedNewTarget) {
      const targetIndex = performanceMembers.findIndex((member: any) => member?.member?.name === savedNewTarget?.name)
      handleRowValueChange(targetIndex || 0, savedNewTarget, "member")
    }else if (!editMode) {
      setNewTarget(null)
    }
  }, [savedNewTarget])

  useEffect(() => {
    let performanceMembers = getMembersFromCustomPerformance(data)
    setPerformanceMembers(performanceMembers)
    if(editMode){
    }else {
      setPreviousEndDate(getPreviousEndDate({performanceMembers, key}))
    }
  }, [data])

  const removeRow = (idx: number) => {
    let members = performanceMembers || []
    let newMembers = [...members.slice(0, idx), ...members.slice(idx + 1)]
    let newData ={...data, members: newMembers}
    setData(newData)
    handleChange(newData, key)
  }

  const removeTargetMemberRow = (performanceMemberIdx: number, targetMemberIdx: number) => {
    let {member, ...performanceMemberRest} = performanceMembers[performanceMemberIdx]
    let target = member as TargetDetailExtendedType

    let {definition: targetMembers, ...targetRest} = target
    targetMembers = target.definition || []
    if(!targetMembers) {
      return
    }
    let updatedTargetMembers = [...targetMembers.slice(0, targetMemberIdx), ...targetMembers.slice(targetMemberIdx + 1)]
    let updatedTarget = {definition: updatedTargetMembers, ...targetRest}
    let updatedMember = {...performanceMemberRest, member: updatedTarget} as CustomPerformanceMemberExtendedType

    let newPerformanceMembers = [...performanceMembers.slice(0, performanceMemberIdx), updatedMember, ...performanceMembers.slice(performanceMemberIdx + 1)]
    let newData ={...data, members: newPerformanceMembers}
    setData(newData)
    handleChange(newData, key)
  }

  const handleRowValueChange = (idx: any, value: any, property: any) => {
    let members = performanceMembers || []
    let newMembers: Maybe<CustomPerformanceMemberExtendedType>[] =
    members.map((el, index) => {
      if (idx === index) {
        let newValue:CustomPerformanceMemberExtendedType = cloneDeep(el)
        if (newValue) {
          set(newValue, property, value)
        }
        return newValue
      } else {
        return el
      }
    }) || []
    let newData ={...data, members: newMembers}
    handleChange(newData, key)
  }

  const handleTargetValueChange = (idx: any, value: any, property: any) => {
    let targetState = performanceMembers[idx].member as TargetDetailExtendedType
    let target = cloneDeep(targetState)
    set(target, property, value)
    handleRowValueChange(idx, target, "member")
  }

  const handleTargetMemberValueChange = (performanceMemberIdx: any, targetMemberIdx: any, value: any, property: any) => {
      let member = performanceMembers[performanceMemberIdx].member
      let target = member as TargetDetailExtendedType

      let {definition: targetMembers, ...targetRest} = target
      targetMembers = target.definition || []
      let newTargetMembers = targetMembers?.map((el, index) => {
        if (targetMemberIdx === index) {
          let newValue = cloneDeep(el) || {}
          if (newValue) {
            set(newValue, property, value)
          }
          return newValue
        } else {
          return el
        }
      }) || []
      let updatedTarget = {definition: newTargetMembers, ...targetRest}
      handleRowValueChange(performanceMemberIdx, updatedTarget, "member")
  }

  const addMemberComponent = () => {
    return(
      <tr key={"add-member"} className={"text-center border-bottom-line-break"}>
        <td align="center" colSpan={8}>
          <Row key={`add-member`}>
            {editMode &&(
              <Button color="link" className="text-callan-blue btn-thin py-0" onClick={() => setShowSearchBar(true)}>
                {"Add Member"}
                <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
              </Button>)
            }
        </Row>
        </td>
      </tr>
    )
  }

  const batchEndDateComponent = () => {
    let setCurrentTableBatchEndDate = (props as any)?.setCurrentTableBatchEndDate

    let labelClass = ""
    if(key === "current") {
      labelClass = "background-private-background text-accent-red"
    }

    return (
      <div key={"batch-end-date"} className={classnames("d-flex w-100 justify-content mb-3", labelClass)}>
        {BatchEndDateInput.map((headerDef, idx) => {
          let property = headerDef.property
          let propertyVal:string|undefined = ""
          if(key === "current" && property === "editedEndDate") {
            propertyVal = currentTableBatchEndDate
          }else if (property === "startDate") {
            propertyVal = moment(get(performanceMembers[0], property) || "1900-01-01").add(1, 'day').format(DATE_API_FORMAT)
          }

          return(
            <div key={idx} className={classnames('d-flex ml-3')}>
              <div className="client-portfolio-date-label">
                {headerDef.label}
              </div>
              <FormInput
                key={headerDef.property}
                property={property}
                displayName={""}
                type={headerDef.type}
                subtype={headerDef.subtype}
                idx={`${key}-custom-performance-item-batch-end-date`}
                editMode={editMode}
                propertyVal={propertyVal}
                readonly={!!headerDef.readonly || key ==="previous"}
                updateValue={(value: any) => {
                  // updateValue(value, headerDef.type, headerDef.field)
                  // handleBatchCurrentEndDateChange(value, data, key)
                  if(setCurrentTableBatchEndDate) setCurrentTableBatchEndDate(value)
                }}
                inputProps={{
                  min: (performanceMembers && performanceMembers?.length > 0) ? performanceMembers[0]?.startDate : "1900-01-01"
                }}
                // required={headerDef.required}
                // optionSource={headerDef.optionSource}
                subClasses={headerDef.subClasses || {}}
              />
            </div>
          )
        })}
      </div>
    )
  }

  let searchBar =  (
    <tr className="list-sidebar" key="add-member-search">
      <td colSpan={8}>
      <div className="d-flex flex-column">
          {
            <ClientPortfolioAddStatic
              list={{items: performanceMembers} as any}
              setEditedList={(value: any) => {setEditedCustomPerformance(value)}}
              editMode={editMode}
              convertAddedItem={convertAddedItem}
              convertToClientPortfolioFragment={(item: any) => get(item, `member.id`)}
              auth={{} as any}
              planIds={planIds}
              searchTypesOverride={[SearchTypeDisplays['Portfolio'], 'Benchmarks', SearchTypeDisplays['Vehicles']]}
              showMembers={false}
              jsonPath={`${key}.members`}
            />
          }
      </div>
      </td>
    </tr>
    )

  // let targetMemberSearchBar =  (
  //   <tr className="list-sidebar" key="target-member-search">
  //     <td colSpan={3}>
  //     <div className="d-flex flex-column">
  //         {
  //           <ClientPortfolioAddStatic
  //             list={{items: newTargetMembers || []} as any}
  //             setSelectedItems={() =>{}} //?
  //             setMainListInput={() =>{}} //?
  //             setEditedList={(value: any) => setEditedCustomPerformance(value)}
  //             editMode={editMode}
  //             convertAddedItem={convertAddedMember}
  //             convertToClientPortfolioFragment={(item: any) => get(item, `memberId`)}
  //             auth={{} as any}
  //             planIds={planIds}
  //             searchTypesOverride={[SearchTypeDisplays['Portfolio']]}
  //             showMembers={false}
  //             jsonPath={`${key}.members[0].member.definition`}
  //           />
  //         }
  //     </div>
  //     </td>
  //   </tr>
  //   )
  const showEndDate = key ==="current" && editMode && (props as any)?.showSuffixEndDate
  if(!showStatus) {
    return <div key={`no-${key}`}></div>
  }else {
    let greyClass = (props as any)?.greyOut ? "bg-gray-full": ""
    return (
      <div
        key={`custom-performance-${key}`}
        onClick={(event:FormEvent<any>) => {
          event.stopPropagation()
          event.preventDefault()
          }
        }
      >
        <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
          <div className="white-space-nowrap">
            <h5 className="headline mt-3 text-nowrap d-inline-block">
              {`Custom Performance `}
            </h5>
            <h5 className="headline text-uppercase text-gray-70 pl-1 mt-3 d-inline-block">{(showEndDate || key !== "current") && ` (${key})`}</h5>
            {/* {`greyState ${!!(props as any)?.greyOut}`} */}
          </div>
          <div className={classnames("mt-3")}>
            <div className="d-flex flex-column">
            </div>
            {editMode && key === "current" && (
              !showDeleteButton && <Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => endCurrent()}>
                {"End Current & Start New"}
                <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
              </Button>)}
              {editMode && (key === "new") &&
              (showDeleteButton && <Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => revertEndCurrent()}>
                {"Delete"}
                <FontAwesomeIcon icon="trash" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {key === "current" && hasFullHistoryButton &&
              (<Button color="link" className="text-callan-blue btn-thin pt-0" onClick={toggleFullHistoryModal}>
                {"View History"}
                <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {key === "current" &&
              <>
                Beginning {moment(initialDate).add(1, "day").format(DATE_DISPLAY_FORMAT)}
              </>
            }
          </div>
        </div>
        <Table hover size="sm" className={classnames(greyClass, {"mb-0": showEndDate})}>
          <thead className="table-border-bottom">
            <tr className="table-header">
              {(customTargetSaving )&& <React.Fragment key={"loading"}>
                <LoadingOverlay loadingMessage={"Loading..."} />
              </React.Fragment>}
              {CombinedInput.map((headerDef, idx) => {
                const hasTarget = some(performanceMembers, (el: any) => el?.memberType?.code === "T" && el?.member?.__typename === "Target")
                let showTitle = (hasTarget) || idx < 3
                if(!showTitle) {
                  return <th key={`custom-performance-header-${idx}`} className={""}> </th>
                }
                return (
                  <th key={`custom-performance-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left")}>
                    {headerDef.title}
                  </th>
                )
              })}
              <th key={"actions"} className={""}>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isEmptyTable(data) &&
              performanceMembers?.map((el: CustomPerformanceMemberExtendedType, idx: number) => {
                // if(!el.member) {
                //   console.log(`no member for at ${idx} in ${key}`, el, key)
                //   return <React.Fragment key={idx}></React.Fragment>
                // }
                let isTarget = el?.memberType?.code === "T" && el?.member?.__typename === "Target"
                let target = isTarget ? (el.member as TargetDetailExtendedType) : undefined
                let targetMembers = target && target.definition
                let targetMemberSearchBar =  (
                  <tr className="list-sidebar" key="target-member-search">
                    <td colSpan={8}>
                    <div className="d-flex flex-column">
                      <ClientPortfolioAddStatic
                        list={{items: targetMembers || []} as any}
                        setEditedList={(value: any) => {setEditedCustomPerformance(value)}}
                        editMode={editMode}
                        convertAddedItem={(value: any) =>convertAddedMember(value, idx)}
                        convertToClientPortfolioFragment={(item: any) => get(item, `member.id`)}
                        auth={{} as any}
                        planIds={planIds}
                        searchTypesOverride={[SearchTypeDisplays['Portfolio'], SearchTypeDisplays['Vehicles']]}
                        showMembers={false}
                        jsonPath={`${key}.members[${idx}].member.definition`}
                      />
                    </div>
                    </td>
                  </tr>
                  )
                let isSubMemberTableOpen = !!(target && openItems?.includes(target?.targetId))
                return (
                  <React.Fragment key={`${key}-custom-performance-row-${idx}`}>
                    <CustomPerformanceRow
                      data={el}
                      key={`${key}-custom-performance-row-${idx}-generic`}
                      row={`${key}-${idx}`}
                      editMode={editMode}
                      columnDef={CombinedInput}
                      removeRow={() => removeRow(idx)}
                      updateValue={(value, property) => handleRowValueChange(idx, value, property)}
                      tableKey={key}
                      // target part
                      target={target}
                      updateTargetValue={(value, property) => handleTargetValueChange(idx, value, property)}
                      openItems={openItems}
                      setOpenItems={setOpenItems}
                      setLazyFetchedTargetData={setLazyFetchedTargetData}
                      hideRemoveButton={(props as any)?.greyOut}
                    />
                    {isSubMemberTableOpen && <React.Fragment key={`${key}-custom-performance-row-${idx}-target-members`}>
                      {targetMembers && !isEmptyTargetMembersTable(targetMembers) &&
                      targetMembers?.map((targetMember, targetMemberIdx) => {
                        return (
                          <CustomPerformanceRow
                            data={targetMember}
                            key={`${key}-custom-performance-target-member-${idx}-${targetMemberIdx}`}
                            row={`${key}-${idx}-${targetMemberIdx}`}
                            editMode={editMode}
                            columnDef={CombinedInput}
                            removeRow={() => removeTargetMemberRow(idx, targetMemberIdx)}
                            updateValue={(value, property) => handleTargetMemberValueChange(idx, targetMemberIdx, value, property)}
                            tableKey={key}
                            isTargetMember={true}
                            hideRemoveButton={(props as any)?.greyOut}
                          />
                        )
                      })}
                    </React.Fragment>}
                    {editMode && target && target?.name && (props?.key !== "previous") && addTargetMemberComponent(idx)}
                    {editMode && target && target?.name && showTargetMemberSearchBar && targetMemberSearchBar}
                  </React.Fragment>
                )
              })}
            {editMode && performanceMembers?.length === 0 && addMemberComponent()}
            {editMode && isEmptyTable(data) && !showSearchBar &&(
              <tr key={"empty"} className={classNames("text-center")}>
                <td align="center" colSpan={8} className={classNames({"background-error text-danger": !!errors["customPerformanceMembers"]})}>
                  Add member to define this custom performance.
                </td>
              </tr>
            )}
            {/* {editMode && newTarget[0]?.member?.name && showTargetMemberSearchBar && targetMemberSearchBar} */}
            {editMode && showSearchBar && searchBar}
          </tbody>
        </Table>
        {showEndDate && (
          batchEndDateComponent()
        )}
      </div>
    )
  }
}


const getNewTableData = (data: EditedCustomPerformanceType) => {
  // new performance item doesn't have a performanceId
  let defaultNewData = {
    performanceId: null,
    endDate: DEFAULT_NEW_END_DATE,
    members: [],
    source: "new",
    __typename: "CustomPerformance",
  } as CustomPerformanceExtendedType
  if(!data?.new) {
    return defaultNewData
  }

  let newTable = data.new as CustomPerformanceExtendedType
  let members = newTable.members || []
  let newMembers = members?.map(el => ({
    editedEndDate: el?.editedEndDate || el?.endDate, ...el, source: "new"}))
  return {...newTable, members: newMembers} as CustomPerformanceExtendedType
}

export const getCurrentTableData = (data: EditedCustomPerformanceType) => {
  // default current table is used to create new
  let defaultNewData = {
    performanceId: null,
    endDate: DEFAULT_NEW_END_DATE,
    members: [],
    source: "current",
    __typename: "CustomPerformance",
  } as CustomPerformanceExtendedType
  if(!data?.current) {
    return defaultNewData
  }
  let currentTable = data.current as CustomPerformanceExtendedType
  let members = currentTable.members || []
  let newMembers = members.map(el => ({
    editedEndDate: el?.editedEndDate || el?.endDate, ...el, source: "current"}))
  return {...currentTable, members: newMembers} as CustomPerformanceExtendedType
}

const getPreviousTableData = (data: EditedCustomPerformanceType) => {
  let defaultNewData = {
    performanceId: null,
    endDate: "",
    members: [],
    source: "previous",
    __typename: "CustomPerformance",
  } as CustomPerformanceExtendedType
  if(!data?.previous) {
    return defaultNewData
  }

  let previousTable = data.previous as CustomPerformanceExtendedType
  let {members, ...rest} = previousTable
  let previousRest = cloneDeep(rest)
  let newMembers = (members || []).map(el => ({
    editedEndDate: el?.editedEndDate || el?.endDate, ...el, source: "previous"}))
  return {...previousRest, members: newMembers as CustomPerformanceMemberExtendedType[]} as CustomPerformanceExtendedType
}

const getFirstPreviousTableData = (data: CustomPerformanceExtendedType) => {
  let {members, ...rest} = data
  let previousRest = cloneDeep(rest)
  let membersWithOne = members && members[0] ? [members[0]]: []
  let newMembers = (membersWithOne || []).map(el => ({
    editedEndDate: el?.editedEndDate || el?.endDate, ...el, source: "previous"}))
  return {...previousRest, members: newMembers as CustomPerformanceMemberExtendedType[]} as CustomPerformanceExtendedType
}

const getHistoryTableData = (initialData: EditedCustomPerformanceType) => {
  let {previous, current} = initialData

  let data = [...(current?.members || []), ...(previous?.members || [])]
  let headers = CustomPerformanceInput.slice(0, 3).map(row => row.title)
  let result = data.reduce((acc, row) => {
    let endDate = row?.endDate || ""
    if(!endDate) {
      return acc
    }
    if(!acc[endDate]) {
      acc[endDate] = {
        date: endDate,
        data: []
      }
    }
    acc[endDate].data.push(row)
    return acc
  }, {} as any)
  let rows =  Object.values(result) as {date: string, data: any[]}[]
  return ({headers, rows}) as CustomPerformanceHistoryData
}

export const ClientPortfolioCustomPerformance: React.FC<CustomPerformanceComponentProps> = (props) => {
  const componentName = "CustomPerformance"
  const { auth, data, initialState, editMode, handleChange, planIds, setEditedCustomPerformance, setLazyFetchedTargetData, inceptionDate } = props
  const { setError } = useContext(EditButtonContext)
  // Target only, expanded when clicked to show target members
  const [openItems, setOpenItems] = useState<number[]>([])
  // initial for revert to
  // const [initialData, resetData] = useState(initialState as EditedCustomPerformanceType)
  // edited data
  const [currentData, setData] = useState(() => data)
  // const [currentId, setId] = useState(-1)
  // const [initialCurrentData, setInitialCurrentData] = useState<CustomPerformanceExtendedType>(() => getCurrentTableData(initialState))

  const [newTableData, setNewTableData] =  useState<CustomPerformanceExtendedType>(() => getNewTableData(currentData))
  const [currentTableData, setCurrentTableData] = useState(() => getCurrentTableData(currentData))
  const [previousTableData, setPreviousTableData] =  useState(() => getPreviousTableData(currentData))
  const [firstPreviousTable, setFirstPreviousTableData] = useState(() => getFirstPreviousTableData(previousTableData))

  const [greyOutCurrentTable, setGreyOutCurrentTable] = useState<boolean>(false)
  // const [showCurrentTable, setShowCurrentTable] = useState<boolean>(!!currentTableData)

  const [currentTableBatchEndDate, setCurrentTableBatchEndDate] = useState<string | null>(null)
  const [showCurrentTableEndDate, setShowCurrentTableEndDate] = useState<boolean>(false)

  // const [currentTableEditable, setCurrentTableEditable] = useState<boolean>(editMode)

  const [showNewTable, setShowNewTable] = useState<boolean>(false)
  // const [newTableEditable, setNewTableEditable] = useState<boolean>(editMode)

  const [showPreviousTable, setShowPreviousTable] = useState<boolean>(false)
  // const [previousTableEditable, setPreviousTableEditable] = useState<boolean>(editMode)

  const [historyData, setHistoryData] = useState<CustomPerformanceHistoryData>({headers: [], rows: [{date: "", data: []}]})
  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)

  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  const endCurrent = () => {
    // console.log('end current')
    // setShowDeleteButton(true)
    // setCurrentTableEditable(false)
    setShowCurrentTableEndDate(true)
    setShowNewTable(true)
    setGreyOutCurrentTable(true)
    // feedNewTable()
  }

  const revertEndCurrent = () => {
    handleChange(initialState as CustomPerformanceExtendedType)
    // setShowDeleteButton(false)
    setShowCurrentTableEndDate(false)
    // setCurrentTableEditable(true)
    setCurrentTableBatchEndDate(null)
    setShowNewTable(false)
    setGreyOutCurrentTable(false)
  }

  const showHistoryButtonComponent = () => {
    return (<div className="expand-link" key={"custom-performance-history"}>
      <Button color="link" onClick={(event) => {event.stopPropagation(); setShowPreviousTable(!showPreviousTable)}} className={"wider-link-on-hover"}>
        <FontAwesomeIcon
          icon={showPreviousTable  ? "chevron-up" : "chevron-down"}
          size="sm"
        />
        <span className="pl-2 expand-text">{showPreviousTable  ? "Hide History" : "Show History"}</span>
      </Button>
    </div>)
  }

  const handleTableStateChange = (state: any, tableName: string) => {
    let newState: any = state
    let {new: newTable, current, previous} = data
    switch (tableName) {
      case "current":
        newState = {previous, new: newTable, current: state}
        break
      case "new":
        newState = {previous, current, new: state}
        break
      case "previous":
        newState = {previous:state, current, new: newTable}
        break
    }
    handleChange(newState)
  }

  useEffect(() => {
    let newState = data
    setData(newState)
    let newPreviousTableData = getPreviousTableData(newState)
    let newCurrentData = getCurrentTableData(data)
    setPreviousTableData(newPreviousTableData)
    setNewTableData(getNewTableData(data))
    setCurrentTableData(newCurrentData)
    setFirstPreviousTableData(getFirstPreviousTableData(newPreviousTableData))
    if(!editMode) {
      // setInitialCurrentData(newCurrentData)
      setHistoryData(getHistoryTableData(newState))
    }
  }, [data])

  useEffect(() => {
    if(!editMode) {
      setShowNewTable(false)
      setShowPreviousTable(false)
      setShowCurrentTableEndDate(false)
      setCurrentTableBatchEndDate(null)
      setGreyOutCurrentTable(false)
    }
  }, [editMode])

  useEffect(() => {
    if(editMode){
      let {members, ...rest} = currentTableData
      let nextCurrentTableMembersData: Maybe<Maybe<CustomPerformanceMemberExtendedType>[]> =
        members?.map(el => {
        let newValue = cloneDeep(el)
        if (newValue) {
          set(newValue, "editedEndDate", currentTableBatchEndDate)
          if(el?.source) {
            set(newValue, "source", "current")
          }
        }
        return newValue
        }) || []
      let nextCurrentTableData = {members: nextCurrentTableMembersData, ...rest} as CustomPerformanceExtendedType
      setCurrentTableData(nextCurrentTableData)
      let {members: newTableMembers, ...newTableRest} = newTableData
      let nextNewTableMembersData: Maybe<Maybe<CustomPerformanceMemberExtendedType>[]> =
        newTableMembers?.map(el => {
        let newValue = cloneDeep(el)
        if (newValue) {
          merge(newValue, {startDate: currentTableBatchEndDate, endDate: DEFAULT_NEW_END_DATE, editedEndDate: DEFAULT_NEW_END_DATE, })
          if(el?.source) {
            set(newValue, "source", "new")
          }
        }
        return newValue
        }) || []
      let nextNewTableData = {members: nextNewTableMembersData, ...newTableRest}
      setNewTableData(nextNewTableData)
      let newState = {new: nextNewTableData, current: nextCurrentTableData, previous: previousTableData}
      handleChange(newState)
    }
  }, [currentTableBatchEndDate])

  // Handle error when current component is deleted and not replaced while there is a history
  useEffect(() => {
    if(currentTableData?.members?.length === 0 && (previousTableData?.members?.length || 0) > 0) {
      setError("customPerformanceMembers", ["Custom performance is empty but there is a history"])
    } else {
      setError("customPerformanceMembers", [])
    }

    // Reset error when unmounted to not cause issues
    return () => {
      setError("customPerformanceMembers", [])
    }
  }, [currentTableData])

  let componentProps = {auth, editMode, planIds, setEditedCustomPerformance, endCurrent, revertEndCurrent, handleTableStateChange, setLazyFetchedTargetData, openItems,setOpenItems}

  let newTableProps = {...componentProps, showStatus: showNewTable, key: "new" as tableKeys, data: newTableData, setData: setNewTableData, currentTableBatchEndDate, showSuffixEndDate: true,}
  let currentTableProps = {...componentProps, showStatus: true, key: "current" as tableKeys, data: currentTableData, setData: setCurrentTableData, showSuffixEndDate: showCurrentTableEndDate, setCurrentTableBatchEndDate, currentTableBatchEndDate, editMode: editMode, greyOut: greyOutCurrentTable, toggleFullHistoryModal, hasFullHistoryButton: (previousTableData?.members?.length || 0) > 0}
  let previousTableProps = {...componentProps, showStatus: showPreviousTable, key: "previous" as tableKeys, data: firstPreviousTable, setData: setFirstPreviousTableData, showSuffixEndDate: true, editMode: editMode, toggleFullHistoryModal, hasFullHistoryButton: true}

  return (
    <Row className={classnames("pl-0 mb-2")} key={`${componentName}`}>
      <Col className={"px-0"}>
        {/* "---new Table below ------------------------------------------" */}
        {CustomPerformanceTableComponent(newTableProps as CustomPerformanceNewTableProps)}
        {/* "---current Table below --------------------------------------" */}
        {CustomPerformanceTableComponent(currentTableProps as CustomPerformanceCurrentTableProps )}
        {/* {CustomPerformanceTableComponent(previousTableProps as CustomPerformancePreviousTableProps )}
        {(previousTableData?.members?.length || 0) > 0 && showHistoryButtonComponent()} */}
      </Col>
      <CustomPerformanceHistoryModal
        isOpen={showFullHistoryModal}
        title={'Custom Performance'}
        toggle={toggleFullHistoryModal}
        data={historyData}
        inceptionDate={inceptionDate}
      />
  </Row>
   )
}