import classNames from 'classnames'
import iassign from 'immutable-assign'
import { cloneDeep, compact, concat, differenceBy, find, get, isEqual, isNil, isUndefined, orderBy, set, sortBy, uniq } from "lodash"
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import Auth from "../../../Auth/Auth"
import {
  AssetClass,
  AssetClassFragment,
  ClientPortfolio,
  ClientPortfolioCompositesFragment,
  ClientPortfolioDataCollectionFragment,
  ClientPortfolioDatedFeeFragment,
  ClientPortfolioFeeScheduleFragment,
  // ClientPortfolioDefinitionQuery,
  // ClientPortfolioFeesFragment,
  // ClientPortfolioTargetFootnoteInput,
  ClientSpecificDataCollectionField,
  CompositeMember,
  CompositeMemberInput,
  CurrentAssetClassValueFragment,
  CustomCharacteristicsInput,
  CustomCharacteristicsInputItem,
  CustomPerformanceInput,
  CustomPerformanceInputItem,
  CustomSourceMemberTypeCode,
  GetAllAssetClassesQuery,
  Index,
  Maybe,
  MemberOfComposite,
  SearchTypes,
  TargetDetailFragment,
  Vehicle,
  useCopyFootnoteHistoryMutation,
  useCreateFootnoteMutation,
  useCreateTargetMutation,
  useDeleteFootnoteMutation,
  useUpdateClientPortfolioDefinitionMutation,
  useUpdateFootnoteMutation,
  useUpdateTargetMutation
} from "../../../__generated__/graphql"
import { DATE_API_FORMAT, FormInputField } from "../../../helpers/constant"
import exportTables from '../../../helpers/exportTable'
import { getNewStateObject } from "../../../helpers/helpers"
import { convertLookupToString, excludePropertyArray } from '../../../helpers/object'
import RouteLeavingGuard from "../../Shared/RouteLeavingGuard"
import EditButtons from "../../ui/EditButtons"
import { FormInput } from "../../ui/Forms/FormInput"
import { GetLookupDataToOptions } from "../../ui/LookupOptions"
import { ClientPortfolioBenchmarks } from "./Benchmark/Benchmark"
import { DEFAULT_EMPTY_FOOTNOTE, FootnoteComponent, FootnoteModifications, SubmitFootnoteChanges } from './Benchmark/TargetFootnotes'
import { ClientPortfolioCompositeMembers, getCurrentCompositeMembersTableData } from "./ClientPortfolioCompositeMembers"
import { ClientPortfolioCustomCharacteristics } from './ClientPortfolioCustomCharacteristics'
import { ClientPortfolioCustomPerformance, CustomPerformanceExtendedType } from './ClientPortfolioCustomPerformance'
import { ClientPortfolioContacts } from "./ClientPortfolioDataCollectionContacts"
import { ClientPortfolioFees } from './ClientPortfolioFees'
import ClientPortfolioLists, { ClientPortfolioListsProps } from './ClientPortfolioLists'
import { ClientPortfolioMemberOfComposites } from './ClientPortfolioMemberOfComposite'
import { ClientPortfolioStableValueFees } from './ClientPortfolioStableValueFees'
import { getClientPortfolioDefinitionInput } from './GetClientPortfolioDefinitionInput'
import { UpdatePortfolioListProps } from './Search'
import { ClientPortfolioBenchmarkExtendedType, ClientPortfolioDefinitionQueryUnionType, ClientPortfolioTargetExtendedType, CompositeMemberExtendedType, CustomCharacteristicsSourceExtendedType, TargetDefinitionExtendedType, TargetDetailExtendedType, customCharacteristicsBasicInfoCompareFunction, formatTargetDefinition, formatTargetMembers, formatTargetToCreateTargetInput, formatTargetToUpdateTargetInput, isTargetUpdated } from './helper'

type idProps = {
  clientportfolioId: number
  data: ClientPortfolioDefinitionQueryUnionType
  assetClasses?: GetAllAssetClassesQuery
  auth: Auth
  // match: match<{ clientportfolioId: string }>
  // refetchFunction: () => void
}

type DisplayDataType = {
  property: string
  checkFunction?: (input: any) => any
  value: any
}
interface ClientPortfolioDefinitionInput extends FormInputField {
  optionSourceEnum?: any
  displayData?: DisplayDataType
  rightText?: string
  inputProps?: {
    min?: string
  },
}

const ActiveBooleanOptions = () =>
  GetLookupDataToOptions({
    data: [
      {
        code: "true",
        value: "Active",
      },
      {
        code: "false",
        value: "Inactive",
      },
    ],
    // placeholder: "Please Select"
    multiple: true,
  })

const TypeBooleanOptions = () =>
  GetLookupDataToOptions({
    data: [
      {
        code: "false",
        value: "Single Portfolio",
      },
      {
        code: "true",
        value: "Composite Portfolio",
      },
    ],
    // placeholder: "Please Select"
    multiple: true,
  })

const PIDSettingInputList: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "Portfolio",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    property: "clientPortfolio.name",
    label: "Portfolio Name",
    type: "text",
    required: true,
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
    charactersLimit: 40,
  },
  {
    property: "clientPortfolio.id",
    label: "Portfolio ID",
    type: "text",
    readonly: true,
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.isComposite",
    label: "Type",
    type: "select",
    required: true,
    optionSourceFunction: TypeBooleanOptions,
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.dataType.code",
    label: "Portfolio Type",
    type: "select",
    optionSource: "PortfolioDataType", // Add filter rule
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.assetClass.parent.code",
    label: "Asset Class",
    type: "select",
    required: true,
    optionSourceFunction: () => null, // set in return
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.assetClass.code",
    label: "Strategy Type",
    type: "select",
    required: true,
    optionSourceFunction: () => null, // set in return
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.inceptionDate",
    label: "Asset Start Date",
    type: "date",
    required: true,
    inputProps: {
      min: "1900-01-02"
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
      inputClasses: "text-left",
    },
  },
  {
    property: "clientPortfolio.serviceStartDate",
    label: "Performance Start Date",
    type: "date",
    required: true,
    inputProps: {
      min: "1900-01-02"
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
      inputClasses: "text-left",
    },
  },
  {
    property: "clientPortfolio.serviceEndDate",
    label: "Performance End Date",
    type: "date",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
      inputClasses: "text-left",
    },
  },
  {
    property: "clientPortfolio.endAssetDate",
    label: "Asset End Date",
    type: "date",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
      inputClasses: "text-left",
    },
  },
  {
    property: "clientPortfolio.showAssets",
    label: "Show Assets",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 pl-4 left-aligned-boolean",
    },
    tooltip: {
      icon: "question-circle",
      id: "showAssetsTooltip",
    },
  },
  {
    property: "clientPortfolio.isActive",
    label: "Status",
    type: "select",
    subtype: "single",
    optionSourceFunction: ActiveBooleanOptions,
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.netOfFees",
    label: "NAV (Net Asset Value)",
    type: "radio",
    subtype: "boolean",
    required: true,
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 pl-4 left-aligned-boolean",
    },
    tooltip: {
      icon: "question-circle",
      id: "netAssetValueTooltip"
    },
  },
  {
    property: "clientPortfolio.baseCurrency.code",
    label: "Base Currency",
    type: "select",
    subtype: "single",
    optionSource: "BaseCurrencyCode",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.isTest",
    label: "Test",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 pl-4 left-aligned-boolean",
    },
  },
  {
    property: "clientPortfolio.discretionary.code",
    label: "Discretionary",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 pl-4 left-aligned-boolean",
    },
  },
  // {
  //   property: "clientPortfolio.mandateType.code",
  //   label: "Mandate",
  //   type: "select",
  //   // readonly??
  //   optionSource: "PortfolioMandateType",
  //   subClasses: {
  //     labelClasses: "col-sm-4",
  //     inputWrapperClasses: "col-sm-8",
  //   },
  // },
]

const VehicleInput: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "Vehicle",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    // test id: 24554
    property: "clientPortfolio.relatedVehicle.vehicle",
    label: "Vehicle Name",
    type: "search",
    searchTypes: [SearchTypes.Vehicle],
    placeholder: "Find vehicle by name or ID",
    subClasses: {
      wrapperClasses: "vehicle-search",
      inputClasses: "search-bar placeholder-gray-50 h-100 mr-0",
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8"
    },
    textLinkParams: {
      property: "clientPortfolio.relatedVehicle.vehicle",
      url: "/products/",
    },
  },
  {
    property: "clientPortfolio.relatedVehicle.vehicle.id",
    label: "Vehicle ID",
    type: "text",
    readonly: true,
    placeholder: "",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.relatedVehicle.vehicle.category.value",
    label: "Vehicle Category",
    type: "text",
    readonly: true,
    optionSource: "VehicleCategoryCode",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.relatedVehicle.vehicle.product.product.name",
    label: "Product Name",
    type: "text",
    subtype: "textLink",
    readonly: true,
    textLinkParams: {
      property: "clientPortfolio.relatedVehicle.vehicle.product.product.id",
      url: "/products/",
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-7",
    },
  },
  {
    property: "clientPortfolio.manager.name",
    label: "Manager Name",
    type: "text",
    subtype: "textLink",
    textLinkParams: {
      property: "clientPortfolio.manager.id",
      url: "/managers/",
    },
    readonly: true,
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
]


const GroupInputList: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "Group",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    property: "clientPortfolio.styleGroup",
    label: "Group",
    type: "search",
    searchTypes: [SearchTypes.Group],
    placeholder: "Find Group by name or ID",
    subClasses: {
      // wrapperClasses: "vehicle-search",
      inputClasses: "search-bar placeholder-gray-50 h-100 mr-0",
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8"
    },
    // textLinkParams: {
    //   property: "clientPortfolio.relatedVehicle.vehicle",
    //   url: "/products/",
    // },
  },
  {
    property: "clientPortfolio.groupPerformanceType.code",
    label: "Group Return Type",
    type: "select",
    optionSource: "GroupPerformanceTypeCode",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
]

const BankInput: ClientPortfolioDefinitionInput[] = [
  // pulled from plan , show only when available
  {
    property: "",
    label: "Bank",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    property: "clientPortfolio.plan.custodian.name",
    label: "Custodian",
    type: "text",
    subtype: "textLink",
    readonly: true,
    textLinkParams: {
      property: "clientPortfolio.plan.custodian.id",
      url: "/custodians/",
    },
    displayData: {
      property: "clientPortfolio.plan.custodian.id",
      checkFunction: (input) => !!input,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.bankAccount",
    label: "Account Number",
    type: "text",
    charactersLimit: 30,
    hideLimit: true,
    displayData: {
      property: "clientPortfolio.plan.custodian.id",
      checkFunction: (input) => !!input,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.plan.recordKeeper.name",
    label: "Record Keeper",
    type: "text",
    subtype: "textLink",
    readonly: true,
    textLinkParams: {
      property: "clientPortfolio.plan.recordKeeper.id",
      url: "/recordkeepers/",
    },
    displayData: {
      property: "clientPortfolio.plan.recordKeeper.id",
      checkFunction: (input) => !!input,
      value: true,
    },
    subClasses: {
      // wrapperClasses: "border-top",
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.empowerRelationships.empowerCusip",
    label: "Empower CUSIP Map",
    type: "text",
    charactersLimit: 9,
    hideLimit: true,
    displayData: {
      property: "clientPortfolio.plan.recordKeeper.id",
      checkFunction: (input) => input === 13736,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.empowerRelationships.empowerPlanNumber",
    label: "Empower Plan Number",
    type: "text",
    charactersLimit: 10,
    hideLimit: true,
    displayData: {
      property: "clientPortfolio.plan.recordKeeper.id",
      checkFunction: (input) => input === 13736,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.fidelityRelationship.fidelityFundCode",
    label: "Fidelity Fund Code",
    type: "text",
    charactersLimit: 4,
    hideLimit: true,
    displayData: {
      property: "clientPortfolio.plan.recordKeeper.id",
      checkFunction: (input) => input === 10522,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
    },
  },
  {
    property: "clientPortfolio.fidelityRelationship.fidelityPlanCode",
    label: "Fidelity Plan Code",
    type: "number",
    charactersLimit: 8,
    hideLimit: true,
    noFormat: true,
    displayData: {
      property: "clientPortfolio.plan.recordKeeper.id",
      checkFunction: (input) => input === 10522,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-8",
      inputClasses: "text-left",
    },
  },
  {
    property: "clientPortfolio.fidelityRelationship.fidelitySkipTrialBalance",
    label: "Fidelity Skip Trial Balance",
    type: "radio",
    subtype: "boolean",
    displayData: {
      property: "clientPortfolio.plan.recordKeeper.id",
      checkFunction: (input) => input === 10522,
      value: true,
    },
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 left-aligned-boolean",
    },
  },
]

const ListsInput: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "Associated Lists",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
    // readonly
  },
  {
    property: "clientPortfolio.lists",
    label: "",
    type: "table",
    Component: ClientPortfolioLists,
  },
]

const ContactList: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.dataCollection",
    label: "",
    type: "table",
    Component: ClientPortfolioContacts,
  },
]

const ClientPortfolioDefinitionInputLeftList: ClientPortfolioDefinitionInput[] = [
  ...PIDSettingInputList,
  ...VehicleInput,
  ...GroupInputList,
  ...BankInput,
  ...ListsInput,
]

const FeesInput: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.feeSchedule",
    label: "",
    type: "table",
    Component: ClientPortfolioFees,
  },
  {
    property: "clientPortfolio.datedFees",
    label: "",
    type: "table",
    Component: ClientPortfolioStableValueFees,
  },
]

// its container (composite) portfolio list.
const CompositesInput: ClientPortfolioDefinitionInput[] = [
  // if the portfolio is member of some composites, here's the list.
  {
    property: "",
    label: "composites",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    property: "clientPortfolio.memberOfComposites",
    label: "",
    type: "table",
    Component: ClientPortfolioMemberOfComposites,
  },
]

const CharacteristicsInput: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "characteristics",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    property: "clientPortfolio.characteristicSource.code",
    label: "Characteristics Source",
    type: "select",
    optionSource: "CharacteristicsSourceCode",
    subClasses: {
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-4",
    },
  },
]

const CharacteristicsCustomInput: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.editedCustomCharacteristics",
    label: "custom characteristics",
    type: "table",
    Component: ClientPortfolioCustomCharacteristics,
  },
]

const ClientPortfolioDefinitionInputRightList: ClientPortfolioDefinitionInput[] = [
  ...FeesInput,
  ...CompositesInput,
]

// display members it includes, when isComposite, .
const CompositeMemberInputList: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.compositeMembers",
    label: "",
    type: "table",
    Component: ClientPortfolioCompositeMembers,
  },
]

const PerformanceSourceList: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "performance",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase",
    },
  },
  {
    property: "clientPortfolio.performanceType.code",
    label: "Performance Type",
    type: "select",
    optionSource: "ClientPerformanceTypeCode",
    required: true,
    subClasses: {
      labelClasses: "col-sm-2",
      inputWrapperClasses: "col-sm-4",
    },
  },
  {
    property: "clientPortfolio.performanceSource.code",
    label: "Performance Source",
    type: "select",
    optionSource: "PerformanceSourceCode",
    required: true,
    subClasses: {
      labelClasses: "col-sm-2",
      inputWrapperClasses: "col-sm-4",
    },
  },
]

const PerformanceCustomList: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.customPerformance",
    label: "",
    type: "table",
    Component: ClientPortfolioCustomPerformance,
  },
]

const PrivateNotesInputList: ClientPortfolioDefinitionInput[] = [
  {
    property: "",
    label: "private notes",
    type: "h5",
    subClasses: {
      wrapperClasses: "border-bottom border-gray-50 mt-3",
      labelClasses: "text-uppercase text-danger",
    },
    rightText: "Internal Callan Use Only",
  },
  {
    property: "clientPortfolio.notes",
    label: "",
    type: "textarea",
    // :after internal callan use only, color: $accent-red;
    subClasses: {
      wrapperClasses: "row headline gray-underline underline text-uppercase mt-3",
      inputWrapperClasses: "col-sm-12 px-0",
    },
  },
]

const BenchmarkInputList: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.editedBenchmarks",
    label: "benchmark",
    type: "table",
    Component: ClientPortfolioBenchmarks,
    subClasses: {
      wrapperClasses: "row headline gray-underline underline text-uppercase mt-3",
    },
  },
]

const FootnoteInputList: ClientPortfolioDefinitionInput[] = [
  {
    property: "clientPortfolio.footnote",
    label: "footnote",
    type: "table",
    Component: FootnoteComponent,
    subClasses: {
      wrapperClasses: "row headline gray-underline underline text-uppercase mt-3",
    },
  },
]

const getInitialState = (data: ClientPortfolioDefinitionQueryUnionType) => {
  return data
}

const formatCustomCharacteristics = (members: CustomCharacteristicsSourceExtendedType[]) => {
  return orderBy(members, ["endDate", "startDate"], ["desc"])
}

const getInitialCustomCharacteristics = (data: ClientPortfolioDefinitionQueryUnionType) => {
  let members = compact(data?.clientPortfolio?.customCharacteristicsSource) as CustomCharacteristicsSourceExtendedType[]
  if(members) {
    let currentMembers = formatCustomCharacteristics((members  || [])!.filter(member => member?.endDate === "9999-12-31" && (!member?.source || member?.source === "current")))
    let formattedCurrentMembers = currentMembers.map(el => ({...el, source: "current" as any}))
    let previousMembers = formatCustomCharacteristics((members  || [])!.filter(member => member?.endDate !== "9999-12-31" && (!member?.source || member?.source === "previous")))
    let formattedPreviousMembers = previousMembers.map(el =>({...el, source: "previous" as any}))
    return [...formattedCurrentMembers, ...formattedPreviousMembers]
  }else {
    return []
  }
}

const getInitialMemberOfComposite = (data: ClientPortfolioDefinitionQueryUnionType) => {
  let members = compact(data?.clientPortfolio?.memberOfComposites) || [] as ClientPortfolioCompositesFragment["memberOfComposites"]
  return compact(members.map((member) => {
    if(member?.endDate !== "9999-12-31") {
      return null
    }
    return member
  })) || []
}

const getInitialCompositeMembers = (data: ClientPortfolioDefinitionQueryUnionType) => {
  let members = compact(data?.clientPortfolio?.compositeMembers) || [] as CompositeMemberExtendedType[]
  return members.map((member: CompositeMemberExtendedType) => {
    let source = "previous"
    if([DEFAULT_NEW_END_DATE, (moment(data.clientPortfolio?.endAssetDate).endOf("month").format(DATE_API_FORMAT))].includes(moment(member?.endDate).endOf("month").format(DATE_API_FORMAT))) {
      source = "current"
    }
    return {...member, source}
  })
}

const DEFAULT_NEW_END_DATE = "9999-12-31"

const formatCustomPerformanceMember = (members: CustomPerformanceExtendedType["members"]) => {
  return orderBy(members, ["endDate", "startDate"], ["desc"])
}

type NonUndefined<T> = T extends undefined ? never : T;

const getInitialCustomPerformance = (data: ClientPortfolioDefinitionQueryUnionType) => {
  let defaultNewData = {
    performanceId: null,
    endDate: DEFAULT_NEW_END_DATE,
    members: [],
    source: "current",
    __typename: "CustomPerformance",
  } as CustomPerformanceExtendedType
  let customPerformance = data?.clientPortfolio?.customPerformance
  if(customPerformance) {
    let {members, ...rest} = customPerformance as CustomPerformanceExtendedType
    members =( members || []) as NonUndefined<CustomPerformanceExtendedType["members"]>
    let currentMembers = formatCustomPerformanceMember((members  || [])!.filter(member => member?.endDate === "9999-12-31" && (!member?.source || member?.source === "current")))
    let formattedCurrentMembers = currentMembers.map(el => ({...el, source: "current" as any}))
    let previousMembers = formatCustomPerformanceMember((members  || [])!.filter(member => member?.endDate !== "9999-12-31" && (!member?.source || member?.source === "previous")))
    let formattedPreviousMembers = previousMembers.map(el =>({...el, source: "previous" as any}))
    return ({
      current: {...rest, source: "current" as any, members: formattedCurrentMembers} as CustomPerformanceExtendedType,
      previous: {...cloneDeep(rest), source: "previous" as any, members: formattedPreviousMembers as NonUndefined<CustomPerformanceExtendedType["members"]>},
      new: null
    })
  }else {
    return ({current: defaultNewData, previous: null, new: null})
  }
}

const getInitialBenchmarks = (data: ClientPortfolioDefinitionQueryUnionType) => {
  let {clientPortfolio} = data
  if(clientPortfolio) {
    let {performanceTargetMap, targetFootnotes} = clientPortfolio
    let targetMapWithNotes = performanceTargetMap?.map((el, idx) => {
      let {constantRebalance, definition} = el?.target as TargetDetailExtendedType
      let orderedConstantRebalance = orderBy(constantRebalance || [], ["startDate"], ["desc"])
      let formattedDefinition: TargetDefinitionExtendedType[] = ((definition || []) as TargetDefinitionExtendedType[]).map(definition => formatTargetDefinition(definition))
      let orderedDefinition = formatTargetMembers(formattedDefinition)
      let elExtendedType = el as ClientPortfolioTargetExtendedType
      let editedOrder = elExtendedType?.editedOrder || elExtendedType?.order
      const matchingFootnote = find(targetFootnotes, (footnote) => find(footnote?.clientPortfolioTargets, (target) => target?.clientPortfolio?.id === data.clientPortfolio?.id && target?.target?.targetId === el?.target?.targetId))
      const footnoteModification = {
        footnote: matchingFootnote || DEFAULT_EMPTY_FOOTNOTE,
        previousFootnote: matchingFootnote,
        change: "none",
        relation: {
          target: [{clientPortfolio: data.clientPortfolio?.id, target: el?.target?.targetId}]
        }
      } as FootnoteModifications
      return {...el, target: {...el?.target, constantRebalance: orderedConstantRebalance, definition: orderedDefinition}, editedOrder, footnoteModification} as ClientPortfolioTargetExtendedType
    }) || null
    return ({performanceTargetMap: targetMapWithNotes}) as ClientPortfolioBenchmarkExtendedType
  }else {
    return ({performanceTargetMap: null}) as ClientPortfolioBenchmarkExtendedType
  }
}

const getSharedPlanIds = (data: ClientPortfolioDefinitionQueryUnionType) => {
  let planIds = data?.clientPortfolio?.client?.plans
  return compact(planIds)
}

const dataShouldShow = (currentState: any, displayData: DisplayDataType) => {
  let { property: disPlayProperty, checkFunction, value: disPlayValue } = displayData

  let calculatedPropertyValInput = get(currentState, disPlayProperty)

  if (checkFunction) {
    let valueFromCheckFunction = checkFunction(calculatedPropertyValInput)
    return valueFromCheckFunction === disPlayValue
  } else {
    return calculatedPropertyValInput === disPlayValue
  }
}

const getMemberOfCompositeInput = (props:{oldState: any, newState: any}):({
  add: {startDate: any, endDate: any, memberId: number, newAdded?: boolean, siblings?: any}[];
  remove: {startDate: any, endDate: any, memberId: number, siblings?: any}[];
} | undefined) => {
  let {oldState, newState} = props
  if(!oldState || !newState) {
    return undefined
  }else {
    let action = {add: [], remove: []} as {add: {startDate: any, endDate: any, memberId: number, newAdded?: boolean, siblings: any[]}[], remove: {startDate: any, endDate: any, memberId: number}[]}

    let formattedOldState = oldState //{startDate, endDate, composite, __typename}
    let formattedNewState = newState

    // console.log({oldState, newState, formattedOldState, formattedNewState})
    formattedNewState.reduce((acc: any, item: any) => {
      // siblings & formattedSiblings is for memberOfComposite
      let siblings = getCurrentCompositeMembersTableData(item?.composite?.compositeMembers)
      let formattedSiblings = siblings?.map((el: any) => {
        let {member, ...rest} = el
        return {...rest, memberId: member?.id}
      })
      // 1. if in newState and newAdded, add for composite newState.item.composite.id
      if((item as any)?.composite?.newAdded && item?.composite?.id) {
        // new added, go to add list
        let newItem = {startDate: item.startDate, endDate: "9999-12-31", memberId: item?.composite?.id, newAdded: true}
        acc.add.push({...newItem, siblings: formattedSiblings})

      } else if(item?.composite?.id){
        // 2. not newAdded, get info from oldState and remove from target
        let oldItem = formattedOldState.find((itemInOldState: any) => itemInOldState?.composite?.id === item?.composite?.id && itemInOldState?.startDate === item?.startDate)
        if(oldItem) {
          if(oldItem.endDate !== item.endDate){
            let formattedItem = {startDate: item.startDate, endDate: item.endDate, memberId: item?.composite?.id, newAdded: true}
            // if not new added, stop all existing with self endDate, create new snapshot startDate = self.endDate
            acc.add.push({...formattedItem, siblings: formattedSiblings})
          }else {
            // console.log("same as old", {newItem: item, oldItem})
            // exact same, no update needed
            return acc
          }
        } else {
          // no matched oldItem, shouldn't enter here
          console.log('error not new but no old match.')
          let newItem = {startDate: item.startDate, endDate: item.endDate, memberId: item?.composite?.id, newAdded: true}
          acc.add.push({...newItem, siblings: formattedSiblings})
        }
      }else {
        console.log('error no item.composite.id', item)
        return acc
      }
      return acc
    }, action)

    formattedOldState.reduce((acc: any, oldItem: any) => {
      let matchedNew = formattedNewState.find((itemInNewState: any) => itemInNewState?.composite?.id === oldItem?.composite?.id && itemInNewState?.startDate === oldItem?.startDate && itemInNewState?.endDate === oldItem?.endDate)
      if(matchedNew) {
        return acc
      }else {
        let {composite} = oldItem
        let siblings = getCurrentCompositeMembersTableData(oldItem?.composite?.compositeMembers)
        let formattedSiblings = siblings?.map((el: any) => {
          let {member, ...rest} = el
          return {...rest, memberId: member?.id}
        })
        acc.remove.push({memberId: composite?.id, siblings: formattedSiblings})
        return acc
      }
    }, action)
    // if(action.remove.length > 0) {
    //   console.log('check getMemberOfCompositeInput function.')
    // }
    return action
  }
}

// work for composite & composite member component
export const getCompositeMembersInput = (props:{oldState: any, newState: any}):(CompositeMemberInput | undefined) => {
  let {oldState, newState} = props
  if(!oldState || !newState) {
    return undefined
  }else {
    // all new added
    let action = {add: [], remove: []} as CompositeMemberInput
    // const formatState = (state: any) => state.map((item: CompositeMemberExtendedType) => {
    //   let {startDate, __typename} = item
    //   let {endDate, editedEndDate, member} = item
    //   return {startDate, endDate: editedEndDate || endDate, composite: member, __typename}
    // })
    let formattedOldState = oldState
    let formattedNewState = newState

    const convertDateToNull = (date: string) => {
      if(date === "0000-01-31") {
        return null
      }else {
        return date
      }
    }

    formattedNewState.reduce((acc: any, item: any) => {
      // 1. if in newState and newAdded, add for composite newState.item.composite.id
      let endDate = convertDateToNull(item?.endDate)
      let startDate = convertDateToNull(item?.startDate)
      if((item as any)?.member?.newAdded && item?.member?.id) {
        // new added, go to add list
        let newItem = {startDate: startDate, endDate: endDate || "9999-12-31", memberId: item?.member?.id}
        acc.add.push({...newItem})
        return acc
      } else if(item?.member?.id){
        // 2. not newAdded, get info from oldState and remove from target
        let oldItem = formattedOldState.find((itemInOldState: any) => itemInOldState?.member?.id === item?.member?.id && itemInOldState?.startDate === item?.startDate)
        if(oldItem) {
          if((oldItem.endDate !== endDate)){
            let formattedItem = {startDate: startDate, endDate: endDate, memberId: item?.member?.id}
            acc.add.push({...formattedItem})
            return acc
          }else {
            // console.log("same as old", {newItem: item, oldItem})
            // exact same, no update needed
            return acc
          }
        } else {
          // no matched oldItem
          let newItem = {startDate: startDate, endDate: endDate, memberId: item?.member?.id}
          acc.add.push({...newItem})
          return acc
        }
      }else {
        console.log('error no item.member.id', item)
        return acc
      }
      // might be in both, so remove first!!
    }, action)

    formattedOldState.reduce((acc: any, oldItem: any) => {
      let {member, startDate, endDate, source, __typename, ...rest} = oldItem
      let endDateConverted = convertDateToNull(endDate)
      let startDateConverted = convertDateToNull(startDate)
      let matchedNew = formattedNewState.find((itemInNewState: any) => itemInNewState?.member?.id === oldItem?.member?.id && itemInNewState?.startDate === startDate && endDate === (itemInNewState?.endDate) )
      if(matchedNew) {
        return acc
      }else {
        acc.remove.push({...rest, memberId: member?.id, startDate: startDateConverted, endDate: endDateConverted})
        return acc
      }
    }, action)
    return action
  }
}

const formatPerformanceTargetToClientPortfolioTargetInput = (props: ClientPortfolioTargetExtendedType[]) => {
  let result = props.map(el => {
    let targetId = el?.target?.targetId
    if(targetId) {
      return {targetId, order: el.editedOrder || el.order, showPerformance: el?.showPerformance}
    }
    return null
  })
  return compact(result)
}

const mergeNewTargetToPerformanceTargetMap = (newState: any, actionArray: ClientPortfolioTargetExtendedType[]) => {
  let newTargets = cloneDeep(newState) || []
  let result = newTargets.map((el: ClientPortfolioTargetExtendedType) => {
    if(el?.target?.targetId && el?.target?.targetId > 0) {
      return el
    }else {
      let matchedTarget = actionArray.find(target => target?.editedOrder === el?.editedOrder)
      if(matchedTarget) {
        return matchedTarget
      }else {
        return el
      }
    }
  })
  return result
}

const getCustomBenchmarkInput = (props: {oldState: any, newState: any}, customBenchmarkActionArray: ClientPortfolioTargetExtendedType[]) => {
  let {oldState, newState} = props
  let oldStateTargets = oldState?.performanceTargetMap as ClientPortfolioTargetExtendedType[]
  let oldInputs = formatPerformanceTargetToClientPortfolioTargetInput(oldStateTargets)
  let newStateTargets = mergeNewTargetToPerformanceTargetMap(newState?.performanceTargetMap as ClientPortfolioTargetExtendedType[], customBenchmarkActionArray)
  let newInputs = formatPerformanceTargetToClientPortfolioTargetInput(newStateTargets)
  if(newInputs.length !== oldInputs.length || newInputs.some(el => !oldInputs.find(input => input.order === el.order && input.targetId === el.targetId && input.showPerformance === el.showPerformance))) {
    return newInputs
  }
  return null
}

const getCustomPerformanceInput = (props: {oldState:{[tableKey: string]: CustomPerformanceExtendedType| null}, newState: {[tableKey: string]: CustomPerformanceExtendedType| null}}):CustomPerformanceInput | undefined => {
  let {oldState, newState} = props
  let customPerformanceInput = {
    performanceId: newState?.current?.performanceId,
    add: [],
    remove: [],
  } as CustomPerformanceInput

  const getMemberId = (performanceMember: any) => {
    let {member, memberType} = performanceMember as any
    let memberId: any = ""
    if(member && memberType?.code) {
      switch (memberType?.code) {
        case "P":
          memberId = (member as ClientPortfolio).id
          break
        case "T":
          memberId = (member as TargetDetailFragment).targetId
          break
        case "F":
          memberId = ((member as Vehicle)?.vehicle as any).id
          break
        case "X":
          memberId = (member as Index as any)?.indexId
          break
        default:
          memberId = (member as any).id
          break
      }
    }
    return memberId?.toString() || ""
  }
  let tableKeys = ["new", "current", "previous"]
  let allMembers = tableKeys.reduce((acc: any, tableKey: string) => {
    let performance = newState[tableKey] as CustomPerformanceExtendedType
    let members = performance?.members || []
    return [...acc, ...members]
  }, [])
  let allOldMembers = tableKeys.reduce((acc: any, tableKey: string) => {
    let performance = oldState[tableKey] as CustomPerformanceExtendedType
    let members = performance?.members || []
    return [...acc, ...members]
  }, [])

  let newMemberIds = (allMembers?.map((mem) => ({memberId: getMemberId(mem), startDate: mem.startDate, endDate: mem.editedEndDate || mem.endDate, memberType: mem.memberType.code})) || []) as CustomPerformanceInputItem[]
  let oldMemberIds = (allOldMembers?.map((mem) => ({memberId: getMemberId(mem), startDate: mem.startDate, endDate: mem.editedEndDate || mem.endDate, memberType: mem.memberType.code})) || []) as CustomPerformanceInputItem[]
  let addedMembers = differenceBy(newMemberIds, oldMemberIds, (value) => `${value.memberId}:${value.startDate}:${value.endDate}:${value.memberType}`)
  let removedMembers = differenceBy(oldMemberIds, newMemberIds, (value) => `${value.memberId}:${value.startDate}:${value.endDate}:${value.memberType}`)
  if(addedMembers?.length) {
    customPerformanceInput.add = addedMembers
  }
  if(removedMembers?.length) {
    customPerformanceInput.remove = removedMembers
  }

  return customPerformanceInput
}

const getTargetFootNotesInput = (props: {oldState: ClientPortfolioBenchmarkExtendedType, newState: ClientPortfolioBenchmarkExtendedType}) => {
  let {oldState, newState} = props
  let oldTargets = oldState?.performanceTargetMap
  let newTargets = newState?.performanceTargetMap as ClientPortfolioTargetExtendedType[]
  let result: any[] = []
  // newTargets?.map(el => {
  //   let target = el?.target
  //   if(target?.footNotes && target?.footNotes?.length > 0 && target?.targetId) {
  //     let targetId = el?.target?.targetId
  //     let footNotes = target?.footNotes
  //     let newFootNote = footNotes.find(footNote => footNote?.source === "new")
  //     let currentFootNote = footNotes.find(footNote => footNote?.source === "current")
  //     if(targetId && newFootNote) {
  //       result = [...result, {targetId, isNew: true, text: newFootNote.text || ""}]
  //     }else if(targetId && currentFootNote) {
  //       let oldCurrentExists = oldTargets?.some(cpTarget => {
  //         let target = cpTarget?.target
  //         if(target?.targetId === targetId) {
  //           return target?.footNotes?.some(note => {
  //             return note?.editedValidUntilDate === "9999-12-31"
  //           })
  //         }else {
  //           return false
  //         }
  //       })
  //       if (oldCurrentExists) {
  //         result = [...result, {targetId, isNew: false, text: currentFootNote.text || ""}]
  //       }else {
  //         result = [...result, {targetId, isNew: true, text: currentFootNote.text || ""}]
  //       }
  //     }

  //   }
  // })
  // console.log(1000, {newTargets, result})
  return result
}

const mergeCustomCharacteristicsInput = (props: ({
  add?: {startDate: any, endDate: any, memberId: string, memberType: any}, remove?: {startDate: any, endDate: any, memberId: string, memberType: any}}[])) => {
    let addResult = [] as CustomCharacteristicsInputItem[]
    let removeResult = [] as CustomCharacteristicsInputItem[]
    props.forEach(el => {
      let {add , remove} = el
      if (add) {
        addResult.push(add as CustomCharacteristicsInputItem)
      }
      if(remove) {
        removeResult.push(remove as CustomCharacteristicsInputItem)
      }
    })
    return {add: addResult, remove: removeResult} as CustomCharacteristicsInput
}

const getCustomCharacteristicsInput = (props: {oldState:any, newState: any}): CustomCharacteristicsInput => {
  // TODO
  let actionsArray: any = []
  let {oldState, newState} = props
  let characteristicsMembers = newState as CustomCharacteristicsSourceExtendedType[]
  characteristicsMembers.forEach((characteristicsMember, idx) => {
    if(characteristicsMember) {
      let {isNewAdded, source } = characteristicsMember
      let {startDate, editedEndDate, member, memberType} = characteristicsMember as CustomCharacteristicsSourceExtendedType
      let editedMemberId: any = ""
      if(member && memberType?.code) {
        switch (memberType?.code) {
          case "F":
            editedMemberId = ((member as Vehicle)?.vehicle as any).id
            break
          default:
            break
        }
      }else {
        editedMemberId = editedMemberId?.toString() || ""
      }

      // initialCustomCharacteristics getDiff
      let initialSourceData = oldState as CustomCharacteristicsSourceExtendedType[]
      // find the element of the same position(source)
      let initialMember = initialSourceData.find(el => customCharacteristicsBasicInfoCompareFunction(el, characteristicsMember))
      // startDate, memberType: memberType, memberId,
      console.log(1363, {characteristicsMember, idx, initialSourceData})
      let isChanged = false
      if(initialMember) {
        let {startDate: originalStartDate, member: originalMember, memberType: originalMemberType, endDate: originalEndDate} = initialMember
        if(source === "previous"){
          // see if the initial previous data is removed
          let originalMemberId = (originalMember as any)?.vehicle?.id
          if(originalMemberId !== editedMemberId) {
            // initial previous is removed and new previous is inserted
            actionsArray.push(
              { remove: [{
                startDate: originalStartDate,
                memberType: originalMemberType?.code,
                memberId: originalMemberId.toString(),
              }],
              }
            )
            actionsArray.push(
              {
                add: [{
                  startDate,
                  endDate: editedEndDate,
                  memberType: memberType?.code,
                  memberId: editedMemberId.toString(),
                }],
              }
            )
          }else if (originalEndDate !== editedEndDate) {
            // previous el stays, endDate changed
            isChanged = true
            actionsArray.push(
              { remove: [{
                startDate: originalStartDate,
                memberType: originalMemberType?.code,
                memberId: originalMemberId.toString(),
              }],
              }
            )
          }
        }else {
          // tableKey: new or current
          if(isNewAdded) {
            // only one item for new or current table, so if new added, initial must have been removed
            // isChanged = true
            let deletedMemberId = (originalMember as any)?.vehicle?.id
            if(deletedMemberId) {
              actionsArray.push(
                {
                  remove: [{
                    startDate: originalStartDate,
                    endDate: originalEndDate,
                    memberType: originalMemberType?.code,
                    memberId: deletedMemberId.toString(),
                  }],
                }
              )
              actionsArray.push(
                {
                  add: [{
                    startDate,
                    endDate: editedEndDate,
                    memberType: memberType?.code,
                    memberId: editedMemberId,
                  }],
                })
            }
          }else {
            // current table
            let originalMemberId = ((originalMember as any)?.id || (originalMember as any)?.vehicle?.id)
            let editedMemberId = (member as any)?.id || (member as any)?.vehicle?.id
            if(originalMemberId !== editedMemberId) {
              isChanged = true
              // delete old endDate, insert new endDate
              actionsArray.push(
                {
                  remove: [{
                    startDate: originalStartDate,
                    endDate: originalEndDate,
                    memberType: originalMemberType?.code,
                    memberId: originalMemberId.toString(),
                  }],
                }
              )
            }else if(editedEndDate && editedEndDate !== originalEndDate) {
              // element stays, endDate changed so oldEndDate removed, newEndDate inserted
              let deletedMemberId = (originalMember as any)?.id || (originalMember as any)?.vehicle?.id
              if(deletedMemberId) {
                actionsArray.push(
                  {
                    remove: [{
                      startDate: originalStartDate,
                      endDate: originalEndDate,
                      memberType: originalMemberType?.code,
                      memberId: deletedMemberId.toString(),
                    }],
                  }
                )
                actionsArray.push(
                  {
                    add: [{
                      startDate,
                      endDate: editedEndDate,
                      memberType: memberType?.code,
                      memberId: editedMemberId,
                    }],
                  })
              }
            }
          }
        }
      }else {
        isChanged = true
      }
      if(isChanged) {
        actionsArray.push(
          {add: [{
            startDate,
            endDate: editedEndDate || "9999-12-31",
            memberType: memberType?.code,
            memberId: editedMemberId,
          }],
        })
      }
    }else {
      // characteristicsMember undefined, means member was deleted
      let initialSourceData = oldState as CustomCharacteristicsSourceExtendedType[]
      let initialMember = initialSourceData.find(el => customCharacteristicsBasicInfoCompareFunction(el, characteristicsMember))
      // find initial el of same position(new/current/previous)
      if(initialMember) {
        let {startDate: originalStartDate, member: originalMember, memberType: originalMemberType} = initialMember
        let deletedMemberId = (originalMember as any)?.id || (originalMember as any)?.vehicle?.id
        if(deletedMemberId) {
          actionsArray.push(
            {remove: [{
              startDate: originalStartDate,
              endDate: initialMember.endDate,
              memberType: originalMemberType?.code,
              memberId: deletedMemberId.toString(),
            }],
            }
          )
        }
      }
    }
  })

  let initialMembers = oldState as CustomCharacteristicsSourceExtendedType[]
  initialMembers.forEach(el => {
    let {startDate, endDate, memberType, member} = el
    let matchedNewMember = characteristicsMembers.find(newMember => customCharacteristicsBasicInfoCompareFunction(el, newMember))
    if(!matchedNewMember) {
      // remove from current/previous table.
      actionsArray.push(
        {remove: [{
          startDate,
          endDate,
          memberType: memberType?.code,
          memberId: (member as any)?.vehicle?.id?.toString(),
        }],
        }
      )
    }
  })
  let result = mergeCustomCharacteristicsInput(actionsArray)
  return result
}

const getFootnoteInitialState = (data: ClientPortfolioDefinitionQueryUnionType) => {
  return {
    footnote: data.clientPortfolio?.footnote || DEFAULT_EMPTY_FOOTNOTE,
    previousFootnote: data.clientPortfolio?.footnote,
    change: "none",
    relation: {
      clientPortfolio: compact([data.clientPortfolio?.id]),
    }
  } as FootnoteModifications
}

const ClientPortfolioDefinition: React.FC<idProps> = (props) => {
  let { clientportfolioId, data, auth, assetClasses } = props
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  // const { resetErrors } = useContext(EditButtonContext)
  // const formattedData = getInitialState(data)
  const [currentState, setState] = useState(getInitialState(data))
  const [initialState, setInitial] = useState(currentState)
  const [planIds,] = useState(getSharedPlanIds(data))
  const history = useHistory()
  const [savingCallCount, setSavingCallCount] = useState(0)
  const [portfolioSearch, setPortfolioSearch] = useState<string>("")

  const [footnoteModification, setFootnoteModification] = useState<FootnoteModifications>(getFootnoteInitialState(currentState))

  const [editedMemberOfComposite, setEditedMemberOfComposite] = useState<MemberOfComposite[]>(getInitialMemberOfComposite(currentState))

  const [editedCompositeMembers, setEditedCompositeMembers] = useState<CompositeMember[]>(getInitialCompositeMembers(currentState))
  const [initialCompositeMembers, setInitialCompositeMembers] = useState<CompositeMember[]>(editedCompositeMembers)

  const [editedCustomCharacteristics, setEditedCustomCharacteristics] = useState<Maybe<CustomCharacteristicsSourceExtendedType>[]>(getInitialCustomCharacteristics(currentState))
  const [initialCustomCharacteristics, setInitialCustomCharacteristics] = useState<Maybe<CustomCharacteristicsSourceExtendedType>[]>(editedCustomCharacteristics)

  const [editedCustomPerformance, setEditedCustomPerformance] = useState<{[tableKey: string]: CustomPerformanceExtendedType| null}>(getInitialCustomPerformance(currentState))
  const [initialCustomPerformance, setInitialCustomPerformance] = useState<{[tableKey: string]: CustomPerformanceExtendedType| null}>(editedCustomPerformance)

  const [editedBenchmarks, setEditedBenchmarks] = useState<ClientPortfolioBenchmarkExtendedType>(getInitialBenchmarks(currentState))
  const [initialBenchmarks, setInitialBenchmarks] = useState<ClientPortfolioBenchmarkExtendedType>(editedBenchmarks)

  // store lazy fetched data, for future compare when submit.
  const [lazyFetchedTargetData, setLazyFetchedTargetData] = useState<TargetDetailExtendedType[] | null | undefined | Maybe<TargetDetailExtendedType>[]>(null)

  const [currentNewBenchmarkId, setNewBenchmarkId] = useState(-999)
  const [footnoteSavingCallCount, setFootnoteSavingCallCount] = useState(0)

  const baseUpdateList = {
    add: null,
    remove: null,
  }
  const [updatedMemberOfCompositeInput, setUpdatedMemberOfCompositeInput] = useState<UpdatePortfolioListProps<MemberOfComposite>>(baseUpdateList)
  const [updatedCompositeMembersInput, setUpdatedCompositeMembersInput] = useState<UpdatePortfolioListProps<CompositeMember>>(baseUpdateList)

  const [updateClientPortfolio] = useUpdateClientPortfolioDefinitionMutation()
  const [createTarget] = useCreateTargetMutation()
  const [updateTarget] = useUpdateTargetMutation()

  const [createFootnote] = useCreateFootnoteMutation()
  const [updateFootnote] = useUpdateFootnoteMutation()
  const [deleteFootnote] = useDeleteFootnoteMutation()
  const [copyFootnoteHistory] = useCopyFootnoteHistoryMutation()

  let allAssetClassesData =
    assetClasses?.assetClasses ||
    ([] as ({
      __typename: "AssetClass"
      children: ({
        __typename: "AssetClass"
      } & AssetClassFragment)[]
    } & AssetClassFragment)[])

  // filter out total assets and total fund composite
  let allAssetClassesDataFiltered = allAssetClassesData.filter(
    el => !((el.code === 20 && !currentState.clientPortfolio?.isComposite) || el.code === 999)
  )
  const assetClassesOptions = useMemo(() =>
    GetLookupDataToOptions({
      data: sortBy(allAssetClassesDataFiltered, 'order'),
      sort: false,
    })
  , [allAssetClassesDataFiltered])

  const typeOptionFilterRule = useMemo(() => {
    return data.portfolioDataTypeMap?.reduce((acc, val) => {
      if (currentState.clientPortfolio?.isComposite === val?.composite) return {...acc, [val?.code || ""]: val?.order }
      return acc
    }, {})
  }, [currentState.clientPortfolio?.isComposite, data.portfolioDataTypeMap])

  const getParentCode = useCallback(() => {
    let code = (currentState.clientPortfolio?.assetClass as AssetClass & CurrentAssetClassValueFragment)?.parent?.code
    if (isUndefined(code)) {
      // TODO what to return if undefined???
      return undefined
    } else {
      return Number(code)
    }
  }, [currentState.clientPortfolio?.assetClass?.parent?.code])

  // to get parentAssetMixNum easier
  const [parentAssetMixNum, setParentAssetMixNum] = useState(getParentCode)

  const getChildrenAssetClasses = useCallback(
    (code: number | undefined) => {
      if (isUndefined(code)) {
        return <>{"No data found"}</>
      }
      let parent = allAssetClassesData.find((assetClass) => assetClass.code.toString() === code.toString())

      let children = parent?.children
      if (!children) {
        return <>Data Not Found</>
      }
      children = sortBy(children, "order")
      return GetLookupDataToOptions({
        data: children as AssetClassFragment[],
        sort: false,
      })
    },
    [allAssetClassesData]
  )

  const [strategyOptions, setStrategyTypeId] = useState(getChildrenAssetClasses(getParentCode()))

  // useEffect(()=> {
  //   if(!editMode && forceUpdateFlag && !saving) {
  //     forceUpdateNow()
  //   }else if(!editMode) {
  //     setPortfolioSearch("")
  //     setEditedMemberOfComposite(getInitialMemberOfComposite(initialState))
  //     setEditedCompositeMembers(getInitialCompositeMembers(initialState))
  //     setEditedCustomPerformance(getInitialCustomPerformance(initialState))
  //     setEditedFees(getInitialFees(initialState))
  //     setEditedBenchmarks(getInitialBenchmarks(initialState))
  //     setNewBenchmarkId(-1)
  //   }
  // }, [editMode])

  // useEffect(() => {
  //   if(!editMode) {
  //     forceUpdateNow()
  //     refetchFunction()
  //   }
  // }, [data])

  useEffect(() => {
    if(!editMode) {
      setPortfolioSearch("")
      setFootnoteModification(getFootnoteInitialState(initialState))
      setEditedMemberOfComposite(getInitialMemberOfComposite(initialState))
      setEditedCompositeMembers(getInitialCompositeMembers(initialState))
      setEditedCustomPerformance(getInitialCustomPerformance(initialState))
      setEditedCustomCharacteristics(getInitialCustomCharacteristics(initialState))
      setEditedBenchmarks(getInitialBenchmarks(initialState))
      setNewBenchmarkId(-1)
      setLazyFetchedTargetData(null)
      setState(initialState)
    }
  }, [editMode])

  useEffect(() => {
    if(savingCallCount === 0 && footnoteSavingCallCount === 0 && saving) {
      // forceUpdateNow()
      setSaving(false)
      setEditMode(false)
      // window.location.reload()
    }else if (saving) {
      console.log(1614, {savingCallCount, footnoteSavingCallCount, saving})
    }
  }, [savingCallCount, footnoteSavingCallCount])

  useEffect(() => {
    if(!editMode || saving) {
      let newState = getInitialState(data)
      setInitial(newState)
      setState(newState)
      let newCompositeMembers = getInitialCompositeMembers(newState)
      let newBenchmarks = getInitialBenchmarks(newState)
      setFootnoteModification(getFootnoteInitialState(data))
      setPortfolioSearch("")
      setEditedMemberOfComposite(getInitialMemberOfComposite(newState))
      setEditedCompositeMembers(newCompositeMembers)
      setEditedCustomPerformance(getInitialCustomPerformance(newState))
      setInitialCustomPerformance(getInitialCustomPerformance(newState))
      setInitialCompositeMembers(newCompositeMembers)
      setInitialBenchmarks(newBenchmarks)
      setEditedBenchmarks(newBenchmarks)
    }
  }, [data])

  useEffect(() => {
    const newParentCode = getParentCode()
    if (!isUndefined(newParentCode) && newParentCode !== parentAssetMixNum) {
      setParentAssetMixNum(newParentCode || 0)
      setStrategyTypeId(getChildrenAssetClasses(newParentCode))
    }
  }, [
    getParentCode,
    getChildrenAssetClasses,
    parentAssetMixNum,
  ])

    // const handleTestBenchmarkSubmit = () => {
  //   let totalCalls = 0
  //   let customBenchmarkActionArray: ClientPortfolioTargetExtendedType[] = []
  //   let benchmarks = cloneDeep(editedBenchmarks.performanceTargetMap) || []
  //   benchmarks.map((performanceTarget, idx) => {
  //     let target = performanceTarget.target
  //     if(!target?.targetId || target?.targetId < 0) {

  //     }else {
  //       let oldPerformanceTarget = initialBenchmarks?.performanceTargetMap?.find(oldPerformanceTarget => oldPerformanceTarget?.target?.targetId.toString() === target?.targetId.toString()) as ClientPortfolioTargetExtendedType
  //       let needTargetUpdate: boolean = isTargetUpdated({oldState: oldPerformanceTarget
  //         ?.target, newState: target})
  //       if(needTargetUpdate) {
  //         let props = {oldState: (oldPerformanceTarget
  //           ?.target as TargetDetailExtendedType) , newState: target}
  //         let {input, memberTargets} = formatTargetToUpdateTargetInput(props, lazyFetchedTargetData)
  //         if(memberTargets) {
  //           console.log("check2", memberTargets.length, {memberTargets, totalCalls, lazyFetchedTargetData})
  //           totalCalls += memberTargets.length
  //           console.log("update memberTargets", memberTargets)
  //           // Promise.all([...memberTargets.map((input, idx) => {
  //           //   updateTarget({variables: {input}}).then(result => {
  //           //     console.log("success on update member Target id", {result})
  //           //   }).catch(err => {
  //           //   //  setSaving(false)
  //           //     console.error(err.message)
  //           //   }).finally(() => {
  //           //     setSavingCallCount((savingCallCount) => savingCallCount - 1)})
  //           // })])
  //         }
  //         if(input) {
  //           console.log("check3", {totalCalls, input})
  //           totalCalls += 1
  //           // update target detailed info like members, name override, lag months etc.
  //         }
  //       }
  //     }
  //   })
  //   // targets fields
  //   let customBenchmarkInput = getCustomBenchmarkInput({oldState: initialBenchmarks, newState: editedBenchmarks}, customBenchmarkActionArray) || []
  //   console.log({customBenchmarkInput, editedBenchmarks, initialBenchmarks})
  // }
  // console.log(getCompositeMembersInput({oldState: currentState.clientPortfolio?.compositeMembers, newState: editedCompositeMembers}))

  const handleSubmit = () => {
    // console.log(1000, {editedCustomCharacteristics})
    setSaving(true)
    let totalCalls = 0
    // setForceUpdateFlag(true)
    let clientPortfolio = currentState?.clientPortfolio
    let data = getClientPortfolioDefinitionInput(currentState?.clientPortfolio as any)
    let filterArray = ["__typename"]
    if(!clientPortfolio?.relatedVehicle && !data.overview?.relatedVehicle) {
      filterArray = ["__typename", 'relatedVehicle']
    }
    let formattedData = excludePropertyArray(convertLookupToString(data, false), filterArray)
    let editedMemberOfComponentInput = getMemberOfCompositeInput({oldState: clientPortfolio?.memberOfComposites, newState: editedMemberOfComposite})
    let editedCompositeMembersInput = getCompositeMembersInput({oldState: clientPortfolio?.compositeMembers, newState: editedCompositeMembers})

    // new target
    // 1. new targets in customBenchmarks
    let customBenchmarkActionArray: ClientPortfolioTargetExtendedType[] = []
    let benchmarks = cloneDeep(editedBenchmarks.performanceTargetMap) || []
    benchmarks.forEach((performanceTarget, idx) => {
      let target = performanceTarget.target
      if(!target?.targetId || target?.targetId < 0) {
        let input = formatTargetToCreateTargetInput(target)
        let targetId = 0
        totalCalls += 1
        console.log("check1", {totalCalls})
        createTarget({variables: {input}}).then(result => {
          // do not update, only returned id is needed
          console.log("success on create Target id", {result})
          let newTargetData = result?.data?.createTarget?.target
          targetId = newTargetData?.targetId || 0
          if(newTargetData && targetId > 0 ) {
            // TODO editing in mapping iteration??
            set((editedBenchmarks as any), `performanceTargetMap[${idx}].target`, newTargetData)
            //
            customBenchmarkActionArray = concat(customBenchmarkActionArray, {target: newTargetData, editedOrder: performanceTarget.editedOrder || performanceTarget.order, footnoteModification: {
              footnote: DEFAULT_EMPTY_FOOTNOTE,
              previousFootnote: undefined,
              change: "none",
              relation: {
                target: [{clientPortfolio: clientPortfolio?.id, target: targetId}]
              }
            } as FootnoteModifications, __typename: 'ClientPortfolioTarget'})
          }
        }).catch(err => {
        //  setSaving(false)
          console.error(err.message)
        }).finally(() => {
          setSavingCallCount((savingCallCount) => savingCallCount - 1)})
      }else {
        let oldPerformanceTarget = initialBenchmarks?.performanceTargetMap?.find(oldPerformanceTarget => oldPerformanceTarget?.target?.targetId.toString() === target?.targetId.toString()) as ClientPortfolioTargetExtendedType
        let needTargetUpdate: boolean = isTargetUpdated({oldState: oldPerformanceTarget
          ?.target, newState: target})
        if(needTargetUpdate) {
          let props = {oldState: (oldPerformanceTarget
            ?.target as TargetDetailExtendedType) , newState: target}
          let {input, memberTargets} = formatTargetToUpdateTargetInput(props, lazyFetchedTargetData)
          if(memberTargets) {
            console.log("check2", memberTargets.length, {memberTargets, totalCalls, lazyFetchedTargetData})
            totalCalls += memberTargets.length
            Promise.all([...memberTargets.map((input, idx) => {
              return updateTarget({variables: {input}}).then(result => {
                console.log("success on update member Target id", {result})
              }).catch(err => {
              //  setSaving(false)
                console.error(err.message)
              }).finally(() => {
                setSavingCallCount((savingCallCount) => savingCallCount - 1)})
            })])
          }
          if(input) {
            console.log("check3", {totalCalls, input})
            totalCalls += 1
            // update target detailed info like members, name override, lag months etc.
            updateTarget({variables: {input}}).then(result => {
              console.log("success on update benchmark Target id", {result})
              let newTargetData = result?.data?.updateTarget?.target
              set((editedBenchmarks as any), `performanceTargetMap[${idx}].target`, newTargetData)
            }).catch(err => {
            //  setSaving(false)
              console.error(err.message)
            }).finally(() => {
              setSavingCallCount((savingCallCount) => savingCallCount - 1)})
          }
        }
      }
    })
    // targets fields
    let customBenchmarkInput = getCustomBenchmarkInput({oldState: initialBenchmarks, newState: editedBenchmarks}, customBenchmarkActionArray) || []

    // 2. new/update targets in customPerformance
    let tableKeys = ["new", "current", "previous"] //["new", "current", "previous"]
    tableKeys.forEach(tableKey => {
      // array of edited customPerformanceMembers
      let members = cloneDeep(editedCustomPerformance[tableKey]?.members)
      members?.forEach((performanceMember, idx) => {
        let member = performanceMember?.member // general info of this customPerformanceMember
        let memberType: CustomSourceMemberTypeCode = performanceMember?.memberType?.code || CustomSourceMemberTypeCode["P"]
        // console.log({tableKey, member})
        if(memberType === 'T' && !!member && parseInt((member as any).targetId) < 0) {
          // new target creation
          let input = formatTargetToCreateTargetInput(member)
          let targetId = 0
          totalCalls += 1
          // now create happens when click add,
          console.log("check4", {totalCalls, input})
          createTarget({variables: {input}}).then(result => {
            // do not update, only returned id is needed
            console.log("success on create Target id", {result})
            // let newTargetData = result?.data?.createTarget
            targetId = result?.data?.createTarget?.target?.targetId || 0
            if(targetId > 0 && editedCustomPerformance[tableKey] && (editedCustomPerformance[tableKey] as any).members) {
              // TODO editing in mapping iteration??
              // set((editedCustomPerformance[tableKey] as any), `members${idx}.member`, newTargetData)
              //
            }
          }).catch(err => {
          //  setSaving(false)
            console.error(err.message)
          }).finally(() => {
            setSavingCallCount((savingCallCount) => savingCallCount - 1)})
        }else if (memberType === 'T' && member && parseInt((member as any).targetId) > 0) {
        //   // new member, previous created target.
        //   // TODO needs global search first.
          let props = {oldState: ({} as TargetDetailExtendedType) , newState: member as TargetDetailExtendedType}
          let {input, memberTargets} = formatTargetToUpdateTargetInput(props, lazyFetchedTargetData)
          if(memberTargets) {
            console.log("check2", memberTargets.length, {memberTargets, totalCalls, lazyFetchedTargetData})
            totalCalls += memberTargets.length
            Promise.all([...memberTargets.map((input, idx) => {
              return updateTarget({variables: {input}}).then(result => {
                console.log("success on update member Target id", {result})
              }).catch(err => {
              //  setSaving(false)
                console.error(err.message)
              }).finally(() => {
                setSavingCallCount((savingCallCount) => savingCallCount - 1)})
            })])
          }
          if(input) {
            console.log("check3", {totalCalls, input})
            totalCalls += 1
            // update target detailed info like members, name override, lag months etc.
            updateTarget({variables: {input}}).then(result => {
              console.log("performance success on update Target id", {result})
              // let newTargetData = result?.data?.updateTarget?.target
              // set((editedCustomPerformance[tableKey] as any), `members[${idx}].member.target`, newTargetData)
            }).catch(err => {
            //  setSaving(false)
              console.error(err.message)
            }).finally(() => {
              setSavingCallCount((savingCallCount) => savingCallCount - 1)})
          }
        }else {
          // portfolio or Vehicle or Index
        }
      })
    })
    let customPerformanceInput = getCustomPerformanceInput({oldState: initialCustomPerformance, newState: editedCustomPerformance})

    // COMPOSITES SECTION BROKEN ONCE FILTER ADDED TO ONLY SHOW THE MOST RECENT
    // if(editedMemberOfComponentInput?.remove) {
    //   editedMemberOfComponentInput?.remove.forEach(item => {
    //     let removedItem = [{startDate: item.startDate, endDate: item.endDate, memberId: currentState!.clientPortfolio!.id}]
    //     let compositeMember = {remove: removedItem}
    //     let input = {id: item.memberId, patch: {compositeMember}}
    //     console.log(1843, {input})
    //     totalCalls += 1
    //     updateClientPortfolio({variables: {input}}).then(result => {
    //       console.log("success remove memberOfCOmposite", {result})
    //       // do not update, as it's update for container pid.
    //     }).catch(err => {
    //       console.error(err.message)
    //     }).finally(() => {
    //       setSavingCallCount((savingCallCount) => savingCallCount - 1)})
    //   })
    // }
    // // update container's composite member snapshot
    // if(editedMemberOfComponentInput?.add) {
    //   editedMemberOfComponentInput?.add.forEach(item => {
    //     // here memberId is the container id
    //     let {startDate, endDate, memberId: containerId, newAdded, siblings} = item
    //     // new added is not in siblings, so add into it.
    //     let addedItem: { startDate: any, endDate: any, memberId: number}[] = newAdded ? [{startDate, endDate, memberId: clientPortfolio!.id}] :[]
    //     let stopExistingSiblings = siblings?.map((el: any) => {
    //       let {memberId} = el
    //       if(newAdded) {
    //         // old snapshot ends on new item startDate
    //         return {startDate: el.startDate, endDate: item?.startDate, memberId}
    //       }else {
    //         // remove, old snapshot ends on removed item endDate
    //         return {startDate: el.startDate, endDate: item?.endDate, memberId}
    //       }
    //     }) || []
    //     let filteredSiblings = siblings?.filter((el: any) => el.memberId !== clientPortfolio?.id)
    //     let addNewEndDateSiblings = filteredSiblings?.map((el: any) => {
    //       let {memberId} = el
    //       // don't change siblings's original endDate
    //       if(newAdded) {
    //         // new snapshot starts on new item startDate, don't change endDate.
    //         return {startDate: item.startDate, endDate: el?.endDate, memberId}
    //       }else {
    //         // remove, // new snapshot starts on removed item endDate, don't change endDate.
    //         return {startDate: item.endDate, endDate: el?.endDate, memberId}
    //       }
    //     }) || []
    //     addedItem = addedItem.concat(stopExistingSiblings, addNewEndDateSiblings)
    //     // console.log({item, siblings, addedItem})
    //     let input = {id: containerId, patch: {compositeMember: {add: addedItem}}}
    //     totalCalls += 1
    //     console.log("check5", {totalCalls, input})
    //     updateClientPortfolio({variables: {input}}).then(result => {
    //       // do not update, as it's update for container pid.
    //       console.log("success on update container id", {result})
    //     }).catch(err => {
    //       // setSaving(false)
    //       console.error(err.message)
    //     }).finally(() => {
    //       setSavingCallCount((savingCallCount) => savingCallCount - 1)})
    //   })
    // }

    // 2. update self
    let hasCompositeMember = editedCompositeMembersInput && (editedCompositeMembersInput.add?.length || 0) + (editedCompositeMembersInput.remove?.length || 0) > 0
    // let targetFootnotes = getTargetFootNotesInput({oldState: initialBenchmarks, newState: editedBenchmarks})
    let customCharacteristicsInput = getCustomCharacteristicsInput({oldState: initialCustomCharacteristics, newState: editedCustomCharacteristics})
    let patch = hasCompositeMember ? {...formattedData, compositeMember: editedCompositeMembersInput} : formattedData
    if(customPerformanceInput) {
      patch.customPerformance = customPerformanceInput
    }
    if(customBenchmarkInput.length > 0) {
      patch.targets = customBenchmarkInput
    } else if (editedBenchmarks.performanceTargetMap?.length === 0){
      patch.targets = []
    }
    // FEES
    if(JSON.stringify(clientPortfolio?.feeSchedule) !== JSON.stringify(initialState.clientPortfolio?.feeSchedule)) {
      let newFees = clientPortfolio?.feeSchedule || []
      let oldFees = initialState.clientPortfolio?.feeSchedule || []
      // Remove all associated with a date and re add them all
      let remove = uniq(oldFees.filter((el) => !newFees.includes(el))?.map((el) => el?.date) || [])
      // let remove = uniq(oldFees.map((el) => el?.date) || [])
      let add = newFees.filter((el) => remove.includes(el?.date) || !oldFees.includes(el))
      let feeSchedule = excludePropertyArray({
        add,
        remove
      }, ["__typename"])

      patch.feeSchedule = feeSchedule
    }
    if(clientPortfolio?.clientSpecificDataCollectionFields?.questionnaireFeesRequired !== initialState.clientPortfolio?.clientSpecificDataCollectionFields?.questionnaireFeesRequired) {
      let dataRequestTypes = patch.dataRequestTypes || {}
      let addDataRequestTypes = dataRequestTypes.add || []
      let removeDataRequestTypes = dataRequestTypes.remove || []
      if(clientPortfolio?.clientSpecificDataCollectionFields?.questionnaireFeesRequired) {
        removeDataRequestTypes.push({dataRequestCode: "FEE"})
      }else {
        addDataRequestTypes.push({dataRequestCode: "FEE"})
      }
      patch.dataRequestTypes = {add: addDataRequestTypes, remove: removeDataRequestTypes}
    }
    if(JSON.stringify(clientPortfolio?.datedFees) !== JSON.stringify(initialState.clientPortfolio?.datedFees)) {
      let newFees = clientPortfolio?.datedFees || []
      let oldFees = initialState.clientPortfolio?.datedFees || []
      // Remove all associated with a date and re add them all
      let remove = uniq(oldFees.filter((el) => !newFees.some((nf) => isEqual(el, nf)))?.map((el) => el?.date) || [])
      // let remove = uniq(oldFees.map((el) => el?.date) || [])
      let add = newFees.filter((el) => remove.includes(el?.date) || !oldFees.some((of) => isEqual(el, of)))
      let datedFees = excludePropertyArray({
        add,
        remove
      }, ["__typename"])

      patch.datedFees = datedFees
    }
    // Contacts
    if(JSON.stringify(clientPortfolio?.dataCollection) !== JSON.stringify(initialState.clientPortfolio?.dataCollection)) {
      let newDataCollection = clientPortfolio?.dataCollection || []
      let oldDataCollection = initialState.clientPortfolio?.dataCollection || []
      const mapCollectionItem = (el: ClientPortfolioDataCollectionFragment | null) => {
        return {contact: el?.contact?.id, contactType: el?.contactType?.code}
      }
      let remove = (oldDataCollection.filter((el) => !newDataCollection.includes(el)) || []).map(mapCollectionItem)
      let add = (newDataCollection.filter((el) => !oldDataCollection.includes(el)) || []).map(mapCollectionItem)
      let dataCollection = {
        add,
        remove
      }
      patch.dataCollection = dataCollection
    }
    // TODO rewriting due to CAL-2440
    // if(targetFootnotes.length > 0) {
    //    patch.targetFootnotes = targetFootnotes[0]
    // }
    if(customCharacteristicsInput) {
      if(customCharacteristicsInput?.add?.length || customCharacteristicsInput?.remove?.length) {
        // patch.customCharacteristics = customCharacteristicsInput
      }
    }
    let input = {id: clientportfolioId, patch}
    totalCalls += 1
    console.log("check6", {totalCalls, input, customBenchmarkInput})
    updateClientPortfolio({variables: {input}})
    .then(result => {
      if (result.data?.updateClientPortfolio) {
        let newData = getInitialState(
          result.data?.updateClientPortfolio as any
        )
        // console.log('success update ClientPortfolio')
        setInitial(newData)
        setPortfolioSearch("")
        let initialState = newData
        setEditedMemberOfComposite(getInitialMemberOfComposite(initialState))
        setEditedCompositeMembers(getInitialCompositeMembers(initialState))
        setEditedCustomPerformance(getInitialCustomPerformance(initialState))
        setEditedCustomCharacteristics(getInitialCustomCharacteristics(initialState))
        setEditedBenchmarks(getInitialBenchmarks(initialState))
        setNewBenchmarkId(-1)
        setLazyFetchedTargetData(null)
        setInitialCompositeMembers(getInitialCompositeMembers(newData))
        setInitialBenchmarks(getInitialBenchmarks(newData))
        setState(newData)
        // setSaving(false)
      }
      SubmitFootnoteChanges({modifications: [footnoteModification, ...(editedBenchmarks.performanceTargetMap?.map((ptm) => ptm.footnoteModification) || [])], createFootnote, updateFootnote, deleteFootnote, copyFootnoteHistory, setFootnoteSavingCallCount})
    })
    .catch(err => {
      // setSaving(false)
      console.error(err.message)
    }).finally(() => {
      setSavingCallCount((savingCallCount) => savingCallCount - 1)
    })
    setSavingCallCount((savingCallCount) => savingCallCount < 0 ? savingCallCount + totalCalls : totalCalls)
  }

  const handleInputChange = (value: any, property: any) => {
    let newState = getNewStateObject({
      state: currentState,
      newValue: value,
      property,
    }) as typeof currentState
    if (property === "clientPortfolio.assetClass.parent.code") {
      // reset strategy Type to null if parentCode changed.
      newState = getNewStateObject({
        state: newState,
        newValue: null,
        property: "clientPortfolio.assetClass.code",
      }) as typeof currentState
    }
    setState(newState)
  }

  const handleChainInputChange = (props: {value: any, property: any}[]) => {
    // element in the array should be no overlapping
    let result = props.reduce((acc, {value, property}) => {
      let newState = getNewStateObject({
        state: acc,
        newValue: value,
        property,
      }) as typeof currentState
      return newState
    }, currentState)
    setState(result)
  }

  const handleChangeTableList = (newTable: any[], property: string) => {
    // TODO
    let propertyArray = property.split(".")
    let singleProperty = propertyArray.length === 1
    let lastProperty = singleProperty
      ? property
      : propertyArray[propertyArray.length - 1]
    let frontProperties = singleProperty
      ? ""
      : property.slice(0, -lastProperty.length - 1)
    let newState
    if (!frontProperties) {
      newState = { ...currentState, [property]: newTable }
    } else {
      let oldState = cloneDeep(currentState)
      if(!get(oldState, [frontProperties])) {
        set(oldState, [frontProperties], {})
      }
      newState = iassign(
        cloneDeep(currentState),
        [frontProperties],
        state => {
          if (state) {
            return {...state, [lastProperty]: newTable}
          }
          return {[lastProperty]: newTable}
        }
      )
    }
    console.log("handleChanged triggered", { property, newTable }, newState)
    setState(newState)
  }

  const handleIndividualStateChange = (stateName: string, value: any, property: any = "") => {
    switch (stateName) {
      case "editedCustomCharacteristics":
        setEditedCustomCharacteristics(value)
        return
      case "editedMemberOfComposite":
        setEditedMemberOfComposite(value)
        return
      case "editedCompositeMembers":
        setEditedCompositeMembers(value)
        return
      case "customPerformance":
        setEditedCustomPerformance(value)
        return
      case "editedBenchmarks":
        setEditedBenchmarks(value)
        return
      default:
        return
    }
  }

  const generateComponent = (inputList: ClientPortfolioDefinitionInput[], key: string, hideFlag?: boolean) => {
    return (
      <React.Fragment key={key}>
        {inputList.map(({ property, label, type, subtype, placeholder, subClasses, readonly, required, tooltip, optionSource, options, Component, displayData, rightText }, idx) => {
          if (type === "h5") {
            return (
              <div key={idx} className={classNames("d-flex justify-content-between align-items-end row", subClasses?.wrapperClasses)}>
                <div className={"d-flex justify-content-start"}>
                  <h5 className={subClasses?.labelClasses}>
                    {label}
                  </h5>
                </div>
                <div className={classNames("mb-1", subClasses?.labelClasses)}>
                  {rightText}
                </div>
              </div>
            )
          }
          let propertyVal: any = get(currentState, property)
          if (displayData && !dataShouldShow(currentState, displayData)) {
            return <React.Fragment key={idx}></React.Fragment>
          }
          let onChangeCallback = (value: any) => handleInputChange(value, property)
          if (type === "table") {
            let tableData: any
            const endAssetDate = currentState.clientPortfolio?.endAssetDate || undefined
            const startInceptionDate = currentState.clientPortfolio?.inceptionDate || undefined
            switch (property) {
              case "clientPortfolio.feeSchedule":
                tableData = currentState.clientPortfolio?.feeSchedule
                return (
                  !!Component && (
                    <ClientPortfolioFees
                      data={tableData}
                      initialState={(initialState.clientPortfolio?.feeSchedule || []) as ClientPortfolioFeeScheduleFragment[]}
                      editMode={editMode}
                      handleChange={(values: ClientPortfolioFeeScheduleFragment[]) => {
                        handleInputChange(values, property)
                      }}
                      questionnaireFeesRequired={currentState.clientPortfolio?.clientSpecificDataCollectionFields?.questionnaireFeesRequired}
                      showFeeCollectOption={currentState.clientPortfolio?.clientSpecificDataCollectionFields?.showFeeCollectOption}
                      handleFeeRequiredChange={(value: boolean) => handleInputChange(value, "clientPortfolio.clientSpecificDataCollectionFields.questionnaireFeesRequired")}
                      key={`${idx}-${property}`}
                      endAssetDate={endAssetDate ? moment(endAssetDate).endOf('month').format('YYYY-MM-DD') : undefined}
                    />
                  )
                )
              case "clientPortfolio.datedFees":
                tableData = currentState.clientPortfolio?.datedFees
                const relatedVehicleType = currentState.clientPortfolio?.relatedVehicle?.__typename
                if(currentState.clientPortfolio?.assetClass?.parent?.code !== 73 && ![
                  'OpenEndedCollectiveInvestmentFundStableValueComposite',
                  'OpenEndedSeparateAccountStableValue',
                ].includes(relatedVehicleType || '')) {
                  return (<React.Fragment key={`${idx}-${property}`}></React.Fragment>)
                }
                return (
                  <ClientPortfolioStableValueFees
                    data={tableData}
                    initialState={(initialState.clientPortfolio?.datedFees || []) as ClientPortfolioDatedFeeFragment[]}
                    editMode={editMode}
                    handleChange={(values: ClientPortfolioDatedFeeFragment[]) => {
                      handleInputChange(values, property)
                    }}
                    key={`${idx}-${property}`}
                    endAssetDate={endAssetDate ? moment(endAssetDate).endOf('month').format('YYYY-MM-DD') : undefined}
                    relatedVehicleType={relatedVehicleType}
                  />
                )
              case "clientPortfolio.dataCollection":
                let dataCollection = propertyVal as ClientPortfolioDataCollectionFragment[]
                let clientSpecificDataCollectionFields = get(currentState, "clientPortfolio.clientSpecificDataCollectionFields", {}) as Maybe<ClientSpecificDataCollectionField>
                return (
                  <ClientPortfolioContacts
                    data={dataCollection}
                    editMode={editMode}
                    handleChange={(newState: any[]) => handleChangeTableList(newState, property)}
                    key={`${idx}-${property}`}
                    managerId={currentState.clientPortfolio?.manager?.id}
                  />
                )
              case "clientPortfolio.compositeMembers":
                tableData = editedCompositeMembers as Maybe<CompositeMember[]>
                return (
                  <ClientPortfolioCompositeMembers
                    clientportfolioId={clientportfolioId}
                    data={tableData}
                    initialState={initialCompositeMembers}
                    planIds={planIds}
                    editMode={editMode}
                    handleChange={(newState: any) => {
                      console.log("update",newState)
                      handleIndividualStateChange("editedCompositeMembers", newState)
                    }}
                    inceptionDate={startInceptionDate ? moment(startInceptionDate).startOf('month').subtract(1, 'day').format('YYYY-MM-DD') : undefined}
                    endAssetDate={endAssetDate ? moment(endAssetDate).endOf('month').format('YYYY-MM-DD') : undefined}
                    setEditedCompositeMembers={setEditedCompositeMembers}
                    key={`${idx}-${property}`}
                    // revertClick={revertCompositeMemberEdit}
                  />
                )
              case "clientPortfolio.memberOfComposites":
                tableData = editedMemberOfComposite as Maybe<MemberOfComposite[]>
                return (
                  !!Component && (
                    <Component
                      data={tableData}
                      planIds={planIds}
                      editMode={editMode}
                      handleChange={(newState: any) => {
                        handleIndividualStateChange("editedMemberOfComposite", newState)
                      }}
                      setEditedMemberOfComposite={setEditedMemberOfComposite}
                      setUpdatedMemberOfCompositeInput={setUpdatedMemberOfCompositeInput}
                      key={`${idx}-${property}`}
                    />
                  )
                )
              case "clientPortfolio.editedCustomCharacteristics":
                tableData = editedCustomCharacteristics
                return (
                  !!Component && (
                    <Component
                      data={tableData}
                      initialState={initialCustomCharacteristics}
                      editMode={editMode}
                      planIds={planIds}
                      handleChange={(newState: any) => {
                        handleIndividualStateChange("editedCustomCharacteristics", newState)
                      }}
                      setEditedCustomCharacteristics={setEditedCustomCharacteristics}
                      key={`${idx}-${property}`}
                      // auth={auth}
                      // assetClasses={allAssetClassesData}
                    />
                  )
                )
              case "clientPortfolio.customPerformance":
                tableData = editedCustomPerformance // {[new|previous|current]: customPerformance}
                return (
                  <ClientPortfolioCustomPerformance
                    data={editedCustomPerformance}
                    initialState={initialCustomPerformance}
                    editMode={editMode}
                    planIds={planIds}
                    handleChange={(newState: any) => {
                      handleIndividualStateChange("customPerformance", newState)
                    }}
                    setEditedCustomPerformance={(value:any) => {setEditedCustomPerformance(value)}}
                    setLazyFetchedTargetData={setLazyFetchedTargetData}
                    key={`${idx}-${property}`}
                    auth={auth}
                    assetClasses={allAssetClassesData}
                    inceptionDate={startInceptionDate ? moment(startInceptionDate).startOf('month').subtract(1, 'day').format('YYYY-MM-DD') : undefined}
                  />
                )
              case "clientPortfolio.editedBenchmarks":
                tableData = editedBenchmarks
                return (
                  !!Component && (
                    <Component
                      data={tableData}
                      initialState={initialBenchmarks}
                      editMode={editMode}
                      planIds={planIds}
                      handleChange={setEditedBenchmarks}
                      setLazyFetchedTargetData={setLazyFetchedTargetData}
                      setEditedBenchmarks={setEditedBenchmarks}
                      key={`${idx}-${property}`}
                      // auth={auth}
                      assetClasses={assetClasses?.assetClasses}
                      clientPortfolio={currentState.clientPortfolio}
                    />
                  )
                )
              case "clientPortfolio.lists":
                tableData = (currentState.clientPortfolio?.lists) as Maybe<ClientPortfolioListsProps["data"][]>
                return (
                  !!Component && (
                    <Component
                      data={tableData}
                      editMode={editMode}
                      handleChange={(newState: any) => {
                        handleChangeTableList(newState, property)
                      }}
                      key={`${idx}-${property}`}
                    />
                  )
                )
              case "clientPortfolio.footnote":
                const onChangeCallback = (value: FootnoteModifications) => {
                  setFootnoteModification(value)
                }
                return (
                  !!Component && (
                    <Component
                      key={`${idx}-${property}`}
                      footnoteModification={footnoteModification}
                      handleChange={(value: FootnoteModifications) => onChangeCallback(value)}
                      editMode={editMode}
                      page={"portfolio"}
                      plan={currentState.clientPortfolio?.plan}
                    />
                  )
                )
              default:
                return <React.Fragment key={idx}></React.Fragment>
            }
          }
          return (
            <div key={idx}>
              <FormInput
                key={`${idx}`}
                property={property}
                displayName={label}
                subClasses={subClasses}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={idx}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                options={options}
                readonly={readonly}
                required={required}
                tooltip={tooltip}
                defaultOptions={propertyVal}
              />
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  const performanceCustomShouldShow = () => {
    let performanceCode: string = get(currentState, "clientPortfolio.performanceSource.code", "")
    return performanceCode && performanceCode.toString() === "CUSTOM"
  }

  const characteristicsCustomShouldShow = () => {
    let characteristicsCode = get(currentState, "clientPortfolio.characteristicSource.code", "")
    return characteristicsCode && characteristicsCode.toString() === "CUSTOM"
  }

  const compositeMemberShouldShow = () => {
    let characteristicsCode: any = get(currentState, "clientPortfolio.isComposite", "")
    return characteristicsCode === true || characteristicsCode === "true"
  }

  // const addToList = () => {
  //   console.log("TODO: Add to List")
  // }

  // new/existing list dropdown for add to list button
  // const [isAddToListDropdownOpen, setAddToListDropdown] = useState(false)
  // toggle select new/existing list from add to list dropdown
  // const toggleAddToListDropdown = () => setAddToListDropdown(!isAddToListDropdownOpen)

  return (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <Container fluid className={"px-0"}>
        <Row className="px-3">
          <Col>
            <div className="pane pane-toolbar sticky-top client-portfolio">
              {/* <ButtonDropdown isOpen={isAddToListDropdownOpen} toggle={toggleAddToListDropdown} className={"headline-dropdown add-to-composite-picker-dropdown"}>
                <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link" onClick={addToList}>
                  {"Add to List"}
                </DropdownToggle>
              </ButtonDropdown> */}
              <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
                Export CSV
              <img src='/assets/CSV.svg' className="ml-2"/>
              </Button>
              {auth.checkPermissions(["edit:manager"]) && (
                <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} disableOnError/>
              )}
            </div>
            <div className="pane mb-4 client-portfolio exportable-form" data-export-form="Portfolio Details">
              <Row className={"form-section-title headline underline small-font-size py-2 mb-2"} key="row-0">
                Portfolio Details
              </Row>
              <Row className="justify-content-between" key="row-1">
                <Col sm="4" className="pr-3" key={`left`}>
                  {ClientPortfolioDefinitionInputLeftList.map(({ property, label, type, subtype, placeholder, subClasses, readonly, required, tooltip, optionSource, optionSourceFunction, displayData, Component, textLinkParams, searchTypes, noFormat, charactersLimit, rightText, hideLimit, inputProps }, idx) => {
                    if (type === "h5") {
                      return (
                        <div key={idx} className={classNames("d-flex justify-content-between align-items-end row", subClasses?.wrapperClasses)}>
                          <div className={"d-flex justify-content-start"}>
                            <h5 className={subClasses?.labelClasses}>
                              {label}
                            </h5>
                          </div>
                          <div className={classNames("mb-1", subClasses?.labelClasses)}>
                            {rightText}
                          </div>
                        </div>
                      )
                    }
                    let propertyVal: any = get(currentState, property)
                    let updatedSubClasses = subClasses || {}
                    let updatedRequired = required || false
                    if (displayData && !dataShouldShow(currentState, displayData)) {
                      return <React.Fragment key={idx}></React.Fragment>;
                    }
                    let onChangeCallback = (value: any) => handleInputChange(value, property)

                    if (property === "clientPortfolio.isActive" || property === "clientPortfolio.isComposite") {
                      propertyVal = propertyVal && propertyVal !== "false" ? "true" : "false"
                      onChangeCallback = (value: any) => handleInputChange(value === "true" ? true : false, property)
                      // editable only for Callan User
                      readonly = !auth.checkPermissions(["view:all_clients"])
                    }else if(property === "clientPortfolio.discretionary.code") {
                      // to make single select look like boolean, change value & handleChange
                      propertyVal = propertyVal && propertyVal === "DISC" ? "true" : "false"
                      onChangeCallback = (value: any) => handleInputChange(value? "DISC": "NDISC", property)
                      // options = optionSourceFunction()
                    } else if(property === "clientPortfolio.groupPerformanceType.code") {
                      if(!!currentState.clientPortfolio?.styleGroup && !!currentState.clientPortfolio?.styleGroup?.id && currentState.clientPortfolio?.styleGroup?.id !== "") {
                        updatedRequired = true
                      }
                    }

                    if(["clientPortfolio.fidelityRelationship.fidelityPlanCode", "clientPortfolio.fidelityRelationship.fidelitySkipTrialBalance"].includes(property)) {
                      if(currentState.clientPortfolio?.fidelityRelationship?.fidelityFundCode || currentState.clientPortfolio?.fidelityRelationship?.fidelityPlanCode || !isNil(currentState.clientPortfolio?.fidelityRelationship?.fidelitySkipTrialBalance))
                        updatedRequired = true

                    } else if (property.startsWith("clientPortfolio.empowerRelationships.")) {
                      if(currentState.clientPortfolio?.empowerRelationships?.empowerCusip || currentState.clientPortfolio?.empowerRelationships?.empowerPlanNumber)
                        updatedRequired = true
                    }

                    let options: any
                    if (type === "select") {
                      if (optionSourceFunction) {
                        switch (property) {
                          case "clientPortfolio.assetClass.parent.code":
                            options = assetClassesOptions
                            break
                          case "clientPortfolio.assetClass.code":
                            options = strategyOptions
                            break
                          case "clientPortfolio.isActive":
                            options = optionSourceFunction()
                            propertyVal = propertyVal && propertyVal === "true" ? "true" : "false"
                            onChangeCallback = (value: any) => handleInputChange(value === "true"? true: false, property)
                            break
                          case "clientPortfolio.isComposite":
                            // required field, no default option
                            options = optionSourceFunction()
                            break
                          default:
                            break
                        }
                      }
                    } else if(type === "search") {
                      let resetSearchOnViewMode = true
                      let textLinkResolved = {url: ""}
                      if(textLinkParams) {
                        let {url} = textLinkParams
                        let urlResolved: string | undefined = url
                        if(property === "clientPortfolio.relatedVehicle.vehicle") {
                          let vehicleId = propertyVal?.id
                          let productId = propertyVal?.product?.product?.id
                          urlResolved = url.concat(`${productId}/vehicles/${vehicleId}`)
                          textLinkResolved = {url: urlResolved}
                        }
                      }
                      return (
                        <div key={idx}>
                          <FormInput
                            property={property}
                            displayName={label}
                            type={"search"}
                            subtype={"single"}
                            placeholder={placeholder}
                            staticText=""
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal || null}
                            updateValue={(value: any) => {
                              if (
                                propertyVal?.id !== value?.vehicle?.id ||
                                propertyVal?.id !== value?.id
                              ) {
                                // keep product & manager info if vehicle.id not changed.
                                console.log("search", { value })
                                let props = [{ value: value, property }]
                                if (
                                  property ===
                                  "clientPortfolio.relatedVehicle.vehicle"
                                ) {
                                  props = [
                                    { value: value?.vehicle, property },
                                    {
                                      value: value?.vehicle?.managerName,
                                      property: "clientPortfolio.manager.name",
                                    },
                                  ]
                                }
                                handleChainInputChange(props)
                              }
                            }}
                            searchTypes={searchTypes || []}
                            subClasses={subClasses}
                            resetSearchOnViewMode={resetSearchOnViewMode}
                            clearValueCallback={() =>{
                              if(property === "clientPortfolio.relatedVehicle.vehicle"){
                                handleChainInputChange([
                                  { value: { id: "", name: "" }, property },
                                  {
                                    value: "",
                                    property: "clientPortfolio.manager.name",
                                  },
                                ])
                              } else if (property === "clientPortfolio.styleGroup") {
                                handleChainInputChange([
                                  { value: { id: "", groupName: "" }, property },
                                ])
                              }
                            }}
                            required={updatedRequired}
                            textLinkParams={textLinkResolved}
                          />
                        </div>
                      )
                    } else if (type === "table") {
                      let data: any
                      switch (property) {
                        case "clientPortfolio.lists":
                          data = (currentState.clientPortfolio?.lists) as Maybe<ClientPortfolioListsProps["data"][]>
                          return (
                            !!Component && (
                              <Component
                                data={data}
                                editMode={editMode}
                                handleChange={(newState: any) => {
                                  handleChangeTableList(newState, property)
                                }}
                                key={`${idx}-${property}`}
                              />
                            )
                          )
                        default:
                          return <React.Fragment key={idx}></React.Fragment>
                      }
                    }

                      let textLinkResolved: Maybe<{ url: string | undefined }> = null
                      if(type === "text" && subtype === "textLink" && readonly && textLinkParams) {
                        let {property: linkProperty, url} = textLinkParams
                        let urlResolved: string | undefined = url
                        if(linkProperty) {
                          let appendix = get(currentState, linkProperty)
                          if(appendix) {
                            urlResolved +=  appendix
                          }else {
                            // if can't get value , do not show as url link
                            urlResolved = undefined
                          }
                        }
                        textLinkResolved = {url: urlResolved}
                      }
                      let optionFilterRule
                      if (optionSource === "PortfolioDataType") {
                        optionFilterRule = typeOptionFilterRule
                      }
                      if (property === "clientPortfolio.plan.recordKeeper.name") {
                        if (!!get(currentState, "clientPortfolio.plan.custodian.id")) {
                          updatedSubClasses["wrapperClasses"] = "border-top"
                        }
                      }
                      return (
                        <div key={idx}>
                          <FormInput
                            key={`idx`}
                            property={property}
                            displayName={label}
                            subClasses={subClasses}
                            type={type}
                            subtype={subtype}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            optionSource={optionSource}
                            options={options}
                            optionFilterRule={optionFilterRule}
                            readonly={readonly}
                            required={updatedRequired}
                            tooltip={tooltip}
                            defaultOptions={propertyVal}
                            textLinkParams={textLinkResolved}
                            noFormat={noFormat}
                            charactersLimit={charactersLimit}
                            hideLimit={hideLimit}
                            inputProps={inputProps}
                          />
                        </div>
                      )
                    })}
                  </Col>
                  <Col sm="7" className="pl-1" key={`right`}>
                    {generateComponent(ClientPortfolioDefinitionInputRightList, "right")}
                    {compositeMemberShouldShow() && generateComponent(CompositeMemberInputList, "composite-members")}
                    <div className={classNames({"mb-4": !performanceCustomShouldShow()})}>
                      {generateComponent(PerformanceSourceList, "performance")}
                    </div>
                    {performanceCustomShouldShow() && generateComponent(PerformanceCustomList, "custom-performance")}
                    {/* {generateComponent(CharacteristicsInput, "characteristics")} Hide for now */}
                    {/* {characteristicsCustomShouldShow() && generateComponent(CharacteristicsCustomInput, "custom-characteristics")} */}
                    {generateComponent(FootnoteInputList, "footnote")}
                    {generateComponent(ContactList, "contacts")}
                    {auth.checkPermissions(["edit:portfolio_note"]) && generateComponent(PrivateNotesInputList, "private-notes")}
                  </Col>
                </Row>
                <Row className={"form-section-title headline underline small-font-size py-2 mb-2"}key="row-2">Benchmarks</Row>
                <Row className="justify-content-between" key="row-3">
                  <Col>
                    {generateComponent(BenchmarkInputList, "benchmarks")}
                  </Col>
                </Row>
              </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default ClientPortfolioDefinition
