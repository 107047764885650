import { gql } from '@apollo/client'
import { DASHBOARD_STOPLIGHT_FRAGMENT } from './DashboardFragments'
import {
  FOOTNOTE_SHOW_FRAGMENT,
  FOOTNOTE_SHOW_TARGET_FRAGMENT,
  REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT,
} from './FootnoteFragments'
import {
  REPORT_LIST_HEADER_FRAGMENT,
  REPORT_SETTINGS_LIST_FRAGMENT,
} from './ListFragments'
import {
  REPORT_COLUMN_DATA_FRAGMENT,
  REPORT_MANAGER_PERFORMANCE_FETCH_FRAGMENT,
} from './ManagerPerformanceFragments'
import { REPORT_PERFORMANCE_COMPARISON_FETCH_FRAGMENT } from './PerformanceComparisonFragments'

export const REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportsListClientPortfolioFragment on ClientPortfolio {
    id
    plan {
      id
      fiscalMonth
    }
    name
    hasComponents
    dataType {
      code
      value
    }
  }
`

export const REPORT_SIMPLE_FRAGMENT = gql`
  fragment ReportSimpleFragment on Report {
    id
    name
    active
    owner {
      id
      firstName
      lastName
    }
    subCategory {
      code
      value
    }
    category {
      code
      value
    }
    client {
      id
      name
    }
    plans {
      id
      name
      client {
        id
      }
    }
    draftList {
      id
      lastSavedDate
    }
    liveList {
      id
      lastSavedDate
    }
    lastPublished
  }
`

export const REPORT_SIMPLE_LIST_MEMBER_FRAGMENT = gql`
  fragment ReportSimpleListMemberFragment on ListMember {
    order
    group
    type
    item {
      ... on ListGroup {
        id
      }
      ... on ListHeader {
        ...ReportListHeaderFragment
      }
      ... on ClientPortfolio {
        ...ReportsListClientPortfolioFragment
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT}
`

export const REPORT_SIMPLE_LIST_FRAGMENT = gql`
  fragment ReportSimpleListFragment on List {
    id
    name
    items {
      ...ReportSimpleListMemberFragment
    }
  }
  ${REPORT_SIMPLE_LIST_MEMBER_FRAGMENT}
`

export const LISTS_FOR_ORG_FRAGMENT = gql`
  fragment ListsForOrgFragment on List {
    id
    name
    type
    subCategory {
      code
      value
    }
  }
`

export const REPORT_ATTRIBUTION_FETCH_FRAGMENT = gql`
  fragment ReportAttributionFetchFragment on AttributionSettings {
    list {
      ...ReportSettingsListFragment
    }
    date
    clientPortfolio {
      id
      name
      performanceTargetMap {
        order
        target {
          targetId: id
          name
        }
      }
    }
    target {
      targetId: id
      name
    }
    performancePeriodicity
    attributionPerformanceType: performanceType
    lineChart
    attributionReportingPerformancePeriod: reportingPerformancePeriod
    attributionPeriod: period
    startDate
    showTargetFootnote
  }
`

export const REPORT_ASSET_ALLOCATION_FETCH_FRAGMENT = gql`
  fragment ReportAssetAllocationFetchFragment on AssetAllocation {
    list {
      ...ReportSettingsListFragment
    }
    date
    showTarget
    inPercent
    totalFundPid
    showFootnote
    showTargetFootnote
    target {
      targetId: id
      name
    }
    monthlyOptions {
      show
      dates
    }
  }
  ${REPORT_SETTINGS_LIST_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportAssetAllocationClientPortfolioFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    inceptionDate
    endAssetDate
    showAssets
    assets(filter: { startDate: $date, endDate: $date }) {
      date
      totalMarketValue
    }
    performanceTargetMap {
      order
      target {
        targetId: id
        name
        endDate
        definition(date: $date) {
          assetClass {
            id
            fullName
            parent {
              id
              fullName
            }
          }
          weight
        }
      }
    }
    assetClass {
      id
      fullName
      parent {
        id
        fullName
      }
    }
    footnote {
      ...FootnoteShowFragment
    }
    targetFootnotes {
      ...FootnoteShowTargetFragment
    }
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${FOOTNOTE_SHOW_FRAGMENT}
  ${FOOTNOTE_SHOW_TARGET_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_TABLE_FRAGMENT = gql`
  fragment ReportAssetAllocationTableFragment on AssetAllocationTable {
    columns
    drilldownId
    rows {
      rowId: id
      productId
      color
      footnote
      targetFootnote
      name
      total
      columnData {
        ...ReportColumnDataFragment
      }
      error
    }
  }
  ${REPORT_COLUMN_DATA_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_DATA_FRAGMENT = gql`
  fragment ReportAssetAllocationDataFragment on AssetAllocationData {
    chart
    tables {
      ...ReportAssetAllocationTableFragment
    }
  }
  ${REPORT_ASSET_ALLOCATION_TABLE_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_FETCH_FRAGMENT = gql`
  fragment ReportAssetDistributionFetchFragment on AssetDistribution {
    list {
      ...ReportSettingsListFragment
    }
    date
    showNNI
    showWeight
    assetDistributionPeriod: period
    showFootnote
    totalFundPid
    showParticipants
    monthlyOptions {
      show
      dates
    }
  }
  ${REPORT_SETTINGS_LIST_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_ROW_FRAGMENT = gql`
  fragment AssetDistributionRowFragment on AssetDistributionRow {
    rowId: id
    name
    productId
    portfolio
    target
    total
    header
    group
    footnote
    columnData {
      ...ReportColumnDataFragment
    }
    error
  }
  ${REPORT_COLUMN_DATA_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_DATA_FRAGMENT = gql`
  fragment ReportAssetDistributionDataFragment on AssetDistributionData {
    columns {
      text
      tooltip
    }
    rows {
      ...AssetDistributionRowFragment
      children {
        ...AssetDistributionRowFragment
        children {
          ...AssetDistributionRowFragment
          children {
            ...AssetDistributionRowFragment
            children {
              ...AssetDistributionRowFragment
              children {
                ...AssetDistributionRowFragment
              }
            }
          }
        }
      }
    }
  }
  ${REPORT_ASSET_DISTRIBUTION_ROW_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportAssetDistributionClientPortfolioFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    dataType {
      code
      value
    }
    showAssets
    inceptionDate
    endAssetDate
    endingValue: assets(filter: { startDate: $date, endDate: $date }) {
      date
      totalMarketValue
    }
    beginningValue: assets(
      filter: { startDate: $beginningDate, endDate: $beginningDate }
    ) {
      date
      totalMarketValue
    }
    cashFlowActivity(filter: { startDate: $beginningDate, endDate: $date })
      @include(if: $showNNI) {
      period
      date
      netFlow
    }
    footnote {
      ...FootnoteShowFragment
    }
    numberOfParticipants(filter: { startDate: $date, endDate: $date }) {
      date
      count
    }
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${FOOTNOTE_SHOW_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_DETAIL_FRAGMENT = gql`
  fragment ReportAssetDistributionDetailFragment on List {
    id
    name
    items {
      order
      group
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          ...ReportListHeaderFragment
        }
        ... on ClientPortfolio {
          ...ReportAssetDistributionClientPortfolioFragment
        }
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${REPORT_ASSET_DISTRIBUTION_CLIENT_PORTFOLIO_FRAGMENT}
`

export const REPORT_TEXT_FETCH_FRAGMENT = gql`
  fragment ReportTextFetchFragment on Text {
    hideTitle
    text
  }
`

export const CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT = gql`
  fragment ClientPortfolioDetailComponentSettingsFragment on ComponentSettings {
    ... on AssetAllocation {
      ...ReportAssetAllocationFetchFragment
    }
    ... on AssetDistribution {
      ...ReportAssetDistributionFetchFragment
    }
    ... on ManagerPerformance {
      ...ReportManagerPerformanceFetchFragment
    }
    ... on AttributionSettings {
      ...ReportAttributionFetchFragment
    }
    ... on Stoplight {
      ...DashboardStoplightFragment
    }
    ... on PerformanceComparison {
      ...ReportPerformanceComparisonFetchFragment
    }
    ... on Text {
      ...ReportTextFetchFragment
    }
  }
  ${REPORT_ASSET_ALLOCATION_FETCH_FRAGMENT}
  ${REPORT_ASSET_DISTRIBUTION_FETCH_FRAGMENT}
  ${REPORT_MANAGER_PERFORMANCE_FETCH_FRAGMENT}
  ${REPORT_ATTRIBUTION_FETCH_FRAGMENT}
  ${DASHBOARD_STOPLIGHT_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_FETCH_FRAGMENT}
  ${REPORT_TEXT_FETCH_FRAGMENT}
`

export const SUM_SHEET_SAVED_JOB_FRAGMENT = gql`
  fragment SumSheetSavedJobFragment on SavedJob {
    id
    name
    type {
      code
      value
    }
  }
`

export const SUM_SHEET_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment SumSheetClientPortfolioFragment on ClientPortfolio {
    id
    name
    monthPerformanceGross: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        period: {
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 1
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $showMonth) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    monthPerformanceNet: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        period: {
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 1
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $showMonth) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    quarterPerformanceGross: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $showQuarter) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    quarterPerformanceNet: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $showQuarter) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    yearMonthPerformanceGross: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        period: {
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 12
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $show1YearMonth) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    yearMonthPerformanceNet: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        period: {
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 12
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $show1YearMonth) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    yearQuarterPerformanceGross: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $show1YearQuarter) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    yearQuarterPerformanceNet: performance(
      filters: {
        actual: true
        orderId: $orderId
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $endDate
        }
      }
    ) @include(if: $show1YearQuarter) {
      endDate
      assetClassGroup {
        code
      }
      value {
        active
      }
      status
    }
    performanceTargetMap {
      order
      target {
        id
        name
        useType {
          code
        }
        definition(date: $endDate) {
          member {
            ... on Index {
              indexId: id
              name
            }
          }
        }
        monthPerformance: defaultPerformance(
          filters: {
            orderId: $orderId
            period: {
              type: TrailingNumberOfMonths
              trailingNumberOfMonths: 1
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $showMonth) {
          value {
            active
          }
          status
        }
        quarterPerformance: defaultPerformance(
          filters: {
            orderId: $orderId
            period: {
              type: TrailingNumberOfQuarters
              trailingNumberOfQuarters: 1
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $showQuarter) {
          value {
            active
          }
          status
        }
        yearMonthPerformance: defaultPerformance(
          filters: {
            orderId: $orderId
            period: {
              type: TrailingNumberOfMonths
              trailingNumberOfMonths: 12
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $show1YearMonth) {
          value {
            active
          }
          status
        }
        yearQuarterPerformance: defaultPerformance(
          filters: {
            orderId: $orderId
            period: {
              type: TrailingNumberOfQuarters
              trailingNumberOfQuarters: 4
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $show1YearQuarter) {
          value {
            active
          }
          status
        }
      }
    }
  }
`

export const SUM_SHEET_NON_POLLING_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment SumSheetNonPollingClientPortfolioFragment on ClientPortfolio {
    id
    name
    notes
    plan {
      id
      name
    }
    savedJobs {
      ...SumSheetSavedJobFragment
    }
    assets(filter: { startDate: $startDate, endDate: $endDate }) {
      date
      totalMarketValue
    }
    cashFlowActivity(filter: { startDate: $startDate, endDate: $endDate }) {
      date
      period
      netFlow
      lastUpdated
      underlyingDataChange
      cashflowDataChanges
      compositeMemberPerformanceChanges {
        member {
          id
          name
        }
        date
      }
    }
    dataType {
      code
    }
    monthCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $endDate
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 1
        }
        useCurrency: true
      }
    ) @include(if: $showMonth) {
      marketValues {
        date
        accruedInterests
        amount
      }
    }
    quarterCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $endDate
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
        }
        useCurrency: true
      }
    ) @include(if: $showQuarter) {
      marketValues {
        date
        accruedInterests
        amount
      }
    }
    characteristicsCount(filters: { startDate: $startDate, endDate: $endDate })
      @include(if: $showCharacteristics) {
      count
      updated
      date
      underlyingDataChange
      dataChangeSource {
        date
        id
      }
    }
    relatedVehicle {
      vehicle {
        fundid
        name
        category {
          code
          value
          shortValue
        }
        product {
          product {
            id
            name
            activePassive
          }
        }
        monthPerformance: defaultPerformance(
          filters: {
            type: t
            period: {
              type: TrailingNumberOfMonths
              trailingNumberOfMonths: 1
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $showMonth) {
          value {
            active
          }
        }
        quarterPerformance: defaultPerformance(
          filters: {
            type: t
            period: {
              type: TrailingNumberOfQuarters
              trailingNumberOfQuarters: 1
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $showQuarter) {
          value {
            active
          }
        }
        yearMonthPerformance: performance(
          filters: {
            type: t
            period: {
              type: TrailingNumberOfMonths
              trailingNumberOfMonths: 12
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $show1YearMonth) {
          endDate
          value {
            active
          }
        }
        yearQuarterPerformance: performance(
          filters: {
            type: t
            period: {
              type: TrailingNumberOfQuarters
              trailingNumberOfQuarters: 4
              dateToDateEnd: $endDate
            }
          }
        ) @include(if: $show1YearQuarter) {
          endDate
          value {
            active
          }
        }
      }
      ... on OpenEndedMutualFund @include(if: $showFees) {
        mutualFund {
          datedFees(filter: { endDate: $endDate, startDate: $startDate }) {
            totalExpense
            date
          }
        }
      }
      ... on OpenEndedExchangeTradedFund @include(if: $showFees) {
        exchangeTradedFund {
          datedFees(filter: { endDate: $endDate, startDate: $startDate }) {
            totalExpense
            date
          }
        }
      }
      ... on OpenEndedPooledVehicle @include(if: $showFees) {
        pooledVehicle {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedPooledVehiclePrivateCredit @include(if: $showFees) {
        pooledVehicle {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedPooledVehiclePrivateEquity @include(if: $showFees) {
        pooledVehicle {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedPooledVehicleRealAssets @include(if: $showFees) {
        pooledVehicle {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedVariableAnnuity @include(if: $showFees) {
        variableAnnuity {
          datedFees(filter: { endDate: $endDate, startDate: $startDate }) {
            totalExpense
            date
          }
        }
      }
      ... on OpenEndedCollectiveInvestmentFundComposite
        @include(if: $showFees) {
        collectiveInvestmentFundComposite {
          datedFees(filter: { endDate: $endDate, startDate: $startDate }) {
            equalWeightedFee
            date
          }
        }
      }
      ... on OpenEndedCollectiveInvestmentFundCompositeRealAssets
        @include(if: $showFees) {
        collectiveInvestmentFundComposite {
          datedFees(filter: { endDate: $endDate, startDate: $startDate }) {
            equalWeightedFee
            date
          }
        }
      }
      ... on OpenEndedSeparateAccount @include(if: $showFees) {
        separateAccount {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }

      ... on OpenEndedSeparateAccountRealAssets @include(if: $showFees) {
        separateAccount {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedPrivateNonRegisteredHedgeFund @include(if: $showFees) {
        hedgeFundVehicle {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedCollectiveInvestmentFundStableValueComposite
        @include(if: $showFees) {
        stableValueCollectiveInvestmentFund {
          fees(filter: { endDate: $endDate, startDate: $startDate }) {
            allInFee
            date
          }
        }
      }
      ... on OpenEndedCollectiveInvestmentFundStableValue
        @include(if: $showFees) {
        stableValueCollectiveInvestmentFund {
          fees(filter: { endDate: $endDate, startDate: $startDate }) {
            allInFee
            date
          }
        }
      }
      ... on OpenEndedSeparateAccountStableValue @include(if: $showFees) {
        stableValueSeparateAccount {
          feeAtAum(filter: { date: $endDate, aum: 50000000 }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedCollectiveInvestmentFund @include(if: $showFees) {
        collectiveInvestmentFund {
          datedFees(filter: { endDate: $endDate, startDate: $startDate }) {
            totalExpense
            date
          }
        }
      }
    }
    monthFinancialSummary: financialSummary(
      filters: {
        period: Monthly
        dateRange: { startDate: $startDate, endDate: $endDate }
      }
    ) {
      date
      origin {
        code
        value
      }
      type {
        code
        value
      }
      updated
    }
    quarterFinancialSummary: financialSummary(
      filters: {
        period: Quarterly
        dateRange: { startDate: $startDate, endDate: $endDate }
      }
    ) {
      date
      origin {
        code
        value
      }
      type {
        code
        value
      }
      updated
    }
  }
  ${SUM_SHEET_SAVED_JOB_FRAGMENT}
`

export const SUM_SHEET_HOVER_FRAGMENT = gql`
  fragment SumSheetHoverFragment on ClientPortfolio {
    id
    monthCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $endDate
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 1
        }
        useCurrency: true
      }
    ) @include(if: $showMonth) {
      marketValues {
        date
        accruedInterests
        amount
      }
    }
    quarterCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $endDate
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
        }
        useCurrency: true
      }
    ) @include(if: $showQuarter) {
      marketValues {
        date
        accruedInterests
        amount
      }
    }
  }
`

export const SUM_SHEET_FRAGMENT = gql`
  fragment SumSheetFragment on List {
    id
    name
    items {
      order
      group
      type
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          ...ReportListHeaderFragment
        }
        ... on ClientPortfolio {
          ...SumSheetClientPortfolioFragment
        }
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${SUM_SHEET_CLIENT_PORTFOLIO_FRAGMENT}
`

export const SUM_SHEET_NON_POLLING_FRAGMENT = gql`
  fragment SumSheetNonPollingFragment on List {
    id
    name
    items {
      order
      group
      type
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          ...ReportListHeaderFragment
        }
        ... on ClientPortfolio {
          ...SumSheetNonPollingClientPortfolioFragment
        }
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${SUM_SHEET_NON_POLLING_CLIENT_PORTFOLIO_FRAGMENT}
`

export const CASH_FLOW_TRANSACTION_FRAGMENT = gql`
  fragment CashFlowTransactionFragment on CashFlowTransaction {
    transactionDate
    adjustedTransactionDate
    amount
    assetClassAbbreviation {
      code
    }
    country {
      code
    }
    currency {
      code
    }
    periodEndDate
    transactionNumber
    updateDate
    originName
  }
`

export const PORTFOLIO_CASH_FLOW_FRAGMENT = gql`
  fragment PortfolioCashFlowFragment on ClientPortfolio {
    id
    name
    baseCurrency {
      code
    }
    monthCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $date
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 1
        }
        useCurrency: true
      }
    ) @include(if: $showMonth) {
      endingNetAssetValue {
        amount
        date
      }
      beginningNetAssetValue {
        amount
        date
      }
      cashFlowTransactionTypes {
        netValue
        transactionType {
          code
          value
        }
        transactions {
          ...CashFlowTransactionFragment
        }
      }
      marketValues {
        amount
        accruedInterests
        date
        assetClassAbbreviation
        country {
          code
          value
        }
        currency {
          code
          value
        }
      }
    }
    quarterCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $date
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
        }
        useCurrency: true
      }
    ) @include(if: $showQuarter) {
      endingNetAssetValue {
        amount
        date
      }
      beginningNetAssetValue {
        amount
        date
      }
      cashFlowTransactionTypes {
        netValue
        transactionType {
          code
          value
        }
        transactions {
          ...CashFlowTransactionFragment
        }
      }
      marketValues {
        amount
        accruedInterests
        date
        assetClassAbbreviation
        country {
          code
          value
        }
        currency {
          code
          value
        }
      }
    }
    cashFlowDefaultKeys {
      assetClassAbbreviation
      country
      currency
    }
  }
  ${CASH_FLOW_TRANSACTION_FRAGMENT}
`

export const TRANSACTION_TYPE_FRAGMENT = gql`
  fragment TransactionTypeFragment on TransactionTypeMap {
    code
    name
    cashEffect
    fees
    order
    shortName
    dateAdjustment
  }
`
export const REPORT_PERFORMANCE_MESSAGE = gql`
  fragment ReportPerformanceMessage on ManagerPerformanceMessages {
    period
    messages
  }
`

export const REPORT_PERFORMANCE_COMPARISON_ROW_FRAGMENT = gql`
  fragment ReportPerformanceComparisonRowFragment on PerformanceComparisonTableRow {
    rowId: id
    name
    productId
    feeType
    color
    symbol
    showFeeBadge
    total
    group
    target
    portfolio
    header
    vehicle
    index
    color
    symbol
    footnote {
      name
      note
    }
    errors {
      ...ReportPerformanceMessage
    }
    warnings {
      ...ReportPerformanceMessage
    }
    infos {
      ...ReportPerformanceMessage
    }
    columnData {
      rawValue
      formattedValue
      status
      additionalAttributes {
        type
        intValue
        floatValue
        stringValue
      }
    }
  }
  ${REPORT_PERFORMANCE_MESSAGE}
  ${REPORT_COLUMN_DATA_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TABLE_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTableFragment on PerformanceComparisonTable {
    columns
    rows {
      ...ReportPerformanceComparisonRowFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_ROW_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_DATA_FRAGMENT = gql`
  fragment ReportPerformanceComparisonDataFragment on PerformanceComparisonData {
    title
    chart
    table {
      ...ReportPerformanceComparisonTableFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TABLE_FRAGMENT}
`
export const REPORT_ATTRIBUTION_TABLE_ROW_FRAGMENT = gql`
  fragment ReportAttributionTableRowFragment on AttributionTableRow {
    rowId: id
    name
    total
    columnData {
      formattedValue
      rawValue
      status
      additionalAttributes {
        type
        intValue
        floatValue
        stringValue
      }
    }
  }
`

export const REPORT_ATTRIBUTION_TABLE_FRAGMENT = gql`
  fragment ReportAttributionTableFragment on AttributionTable
  {
    title
    columns
    rows {
      ...ReportAttributionTableRowFragment
    }
  },
  ${REPORT_ATTRIBUTION_TABLE_ROW_FRAGMENT}
`


export const REPORT_ATTRIBUTION_DATA_FRAGMENT = gql`
  fragment ReportAttributionDataFragment on AttributionData {
    error
    status
    info
    warning
    chart {
      title
      config
      additionalSeries {
        assetMixNum
        series {
          name
          data {
            date
            value
          }
        }
      }
    }
    table {
      ...ReportAttributionTableFragment
    }
  },
  ${REPORT_ATTRIBUTION_TABLE_FRAGMENT}
`

export const REPORT_TEXT_DATA_FRAGMENT = gql`
  fragment ReportTextDataFragment on TextData {
    text
  }
`

export const DEFAULT_COMPONENT_DEFINITION_FRAGMENT = gql`
  fragment DefaultComponentDefinitionFragment on ComponentDefinition {
    type
    defaultSettings {
      ...ClientPortfolioDetailComponentSettingsFragment
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT}
`
