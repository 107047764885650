import { FirstDataRenderedEvent, GridApi } from '@ag-grid-community/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import iassign from "immutable-assign";
import { cloneDeep, find, first, get, groupBy, set, sortBy, uniqueId } from "lodash";
import React, { useMemo, useState } from "react";
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ClientPortfolioDetailComponentFragment, ClientPortfolioDetailComponentSettingsFragment, ClientPortfolioDetailLayoutFragment, ComponentApprovalCode, ComponentType, LayoutSectionType, List, ListSubCategoryCode, ReportCategoryCode, ReportFilters, ReportsFragment, ReportSimpleFragment, useDefaultComponentSettingsQuery, useMeQuery, usePlanSimplePortfoliosListQuery, useReportDetailQuery, useReportListQuery, useReportPerformanceComparisonDefaultPortfolioQuery } from "../../../__generated__/graphql";
import { appDate } from "../../../Context/CalendarContext";
import { ImportReportListColumnDef } from "../../../helpers/columnDefs";
import { DATE_API_FORMAT } from "../../../helpers/constant";
import { expandReportList, listReportExpanded } from "../../../helpers/report";
import { getRecentReports } from "../../../helpers/session";
import SortableTable from "../../Shared/SortableTable";
import Loading from "../../ui/Loading";
import { IconMapping, ReportDisplayType, selectedComponentProp } from "./ReportComponent";
import { ReportInstanceType } from "./ReportMain";
import ReportSidebar from "./ReportSidebar";

type ReportAddableProps = {
  show: boolean
  sectionNumber: number
  componentNumber: number
  report?: ReportsFragment
  setSelectedComponentId?: (value:selectedComponentProp) => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  portfolioId?: number
  instanceType?: ReportInstanceType
  children?: (props: {setModalOpen: (value:boolean) => void}) => JSX.Element
}

enum ModalType {
  Add = 1,
  ReportList = 2,
  ChooseComponent = 3,
}

type ReportAddableComponents = {
  name: string
  componentType: ComponentType
  defaultSettings: ClientPortfolioDetailComponentSettingsFragment
}

export const ReportAddable: React.FC<ReportAddableProps> = ({ show, setEditedDraftLayout, editedDraftLayout, sectionNumber, componentNumber, report, setSelectedComponentId, portfolioId, children, instanceType}) => {
  // // Hide Addable for now
  // return (<></>)

  const [hovering, setHovering] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState(ModalType.Add)
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [search, setSearch] = useState("")
  const [selectedReport, setSelectedReport] = useState<ReportSimpleFragment | undefined>(undefined)

  let reportFilter: ReportFilters = {limit: 1000}

  const { loading, data, error } = useReportListQuery({
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    variables: { filters: reportFilter },
  })

  const { loading:defaultLoading, data:defaultData, error:defaultError } = useDefaultComponentSettingsQuery({})
  const { loading:portfolioLoading, data:portfolioData, error:portfolioError } = useReportPerformanceComparisonDefaultPortfolioQuery({
    variables: { id: portfolioId },
    skip: !portfolioId || instanceType !== "ClientPortfolio"
  })
  const { loading:planPortfolioLoading, data:planPortfolioData, error:planPortfolioError } = usePlanSimplePortfoliosListQuery({
    variables: { id: report?.plans?.[0]?.id || -1 },
    skip: !report?.plans?.[0]?.id
  })

  const { data:userData } = useMeQuery({ fetchPolicy: "cache-first" })

  const defaultDate = get(first(first(editedDraftLayout?.sections)?.components)?.draftSettings, 'date') || appDate.format(DATE_API_FORMAT)

  const dataComponents:ReportAddableComponents[] = useMemo(() => {
    let defaultList = report?.draftList as List
    if(![ListSubCategoryCode.MASTER, ListSubCategoryCode.ALTS, ListSubCategoryCode.SUBPLN].includes(report?.draftList?.subCategory?.code as ListSubCategoryCode)){
      const masterList = find(planPortfolioData?.plan?.lists, (list) => [ListSubCategoryCode.MASTER, ListSubCategoryCode.ALTS, ListSubCategoryCode.SUBPLN].includes(list?.subCategory?.code as ListSubCategoryCode))
      if(masterList) defaultList = masterList as List
    }

    let totalFundPid
    defaultList?.items?.some((item) => {
      if(item.item?.__typename === "ClientPortfolio" && item.item.dataType?.code === "TOT" && !!item.group){
        totalFundPid = item.item.id
        return true
      }
      return false
    })

    const groupedDefaults = groupBy(defaultData?.componentDefinitions, "type")

    return [
      {
        name: "Asset Allocation",
        componentType: ComponentType.AssetAllocation,
        defaultSettings: {
          ...groupedDefaults["AssetAllocation"]?.[0]?.defaultSettings,
          list: defaultList,
          date: defaultDate,
          totalFundPid: totalFundPid,
          __typename: "AssetAllocation",
        }
      },
      {
        name: "Asset Distribution",
        componentType: ComponentType.AssetDistribution,
        defaultSettings: {
          ...groupedDefaults["AssetDistribution"]?.[0]?.defaultSettings,
          list: defaultList,
          date: defaultDate,
          totalFundPid: totalFundPid,
          __typename: "AssetDistribution",
        }
      },
      {
        name: "Attribution",
        componentType: ComponentType.Attribution,
        defaultSettings: {
          ...groupedDefaults["Attribution"]?.[0]?.defaultSettings,
          list: defaultList,
          date: defaultDate,
          clientPortfolio: portfolioData?.clientPortfolio,
          __typename: "AttributionSettings",
        }
      },
      {
        name: "Manager Performance",
        componentType: ComponentType.ManagerPerformance,
        defaultSettings: {
          ...groupedDefaults["ManagerPerformance"]?.[0]?.defaultSettings,
          list: defaultList,
          date: defaultDate,
          totalFundPid: totalFundPid,
          __typename: "ManagerPerformance",
        }
      },
      {
        name: "Performance Comparison",
        componentType: ComponentType.PerformanceComparison,
        defaultSettings: {
          ...groupedDefaults["PerformanceComparison"]?.[0]?.defaultSettings,
          date: defaultDate,
          clientPortfolio: portfolioData?.clientPortfolio,
          __typename: "PerformanceComparison",
        }
      }
    ]

  }, [defaultData, portfolioData, defaultDate, planPortfolioData, report])

  const otherStandardComponents = [
    {
      name: "Notes",
      componentType: ComponentType.Text,
      defaultSettings: {
        text: "",
        hideTitle: false,
        __typename: "Text",
      }
    },
  ]

  const toggleModal = () => {
    setModalOpen(!modalOpen)
    setModalType(ModalType.Add)
  }

  const selectReport = () => {
    const selectedReport = gridApi?.getSelectedRows()[0]
    if(selectedReport) {
      setSelectedReport(selectedReport)
      setModalType(ModalType.ChooseComponent)
    }
  }

  let modal = (<Modal
    size="sm"
    className="mt-5"
    isOpen={modalOpen}
    toggle={toggleModal}
    zIndex={1500}
    key="modal"
  >
    <ModalHeader className='full-width-header'>
      <div className="d-flex justify-content-between w-100">
        <div>
          Add Component
        </div>
        <div onClick={toggleModal}>
          <FontAwesomeIcon
            icon="times"
            className="ml-auto"
          />
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div>
        <div>Data</div>
        {dataComponents.map(({ name, componentType, defaultSettings }) => {
          const handleClick = () => {
            toggleModal()
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => currentState?.sections,
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  const newId = parseInt(uniqueId()) * -1
                  const owner = userData?.me?.person
                  // If not in a column then add to a new single column
                  if (componentNumber || componentNumber === 0) {
                    // -1 signifies a new column
                    if (componentNumber === -1) {
                      sections?.splice(sectionNumber,0,{
                        type: LayoutSectionType.SingleColumnSection,
                        components: [{
                          id: newId,
                          name,
                          type: componentType,
                          approval: {
                            code: ComponentApprovalCode._1,
                            value: 'Needs Review',
                            __typename: 'ComponentApprovalLookup'
                          },
                          draftSettings: defaultSettings as ClientPortfolioDetailComponentSettingsFragment,
                          liveSettings: undefined,
                          template: false,
                          owner,
                          __typename: 'Component'
                        }],
                        __typename: 'LayoutSection',
                      })
                    } else {
                      if(sections){
                        var selectedSection = sections[sectionNumber]
                        if(selectedSection) set(selectedSection, `components[${componentNumber}]`, {
                          id: newId,
                          name,
                          type: componentType,
                          approval: {
                            code: ComponentApprovalCode._1,
                            value: 'Needs Review',
                            __typename: 'ComponentApprovalLookup'
                          },
                          draftSettings: defaultSettings as ClientPortfolioDetailComponentSettingsFragment,
                          liveSettings: undefined,
                          template: false,
                          owner,
                          __typename: 'Component'
                        });
                      }
                    }
                    if(setSelectedComponentId) setSelectedComponentId({id: newId, type: "component"})
                  }
                  return sections
                }
              )
              return newState
            })
          }
          return (
            <div key={componentType} className="text-gray-100 py-1 cursor-pointer background-hover-gray-10" onClick={() => handleClick()}>
              <FontAwesomeIcon
                icon={IconMapping[componentType] || "table"}
                className="text-gray-70 ml-4 mr-3"
              />
              {name}
            </div>
          )
        })}
        <div className="mt-3">Standard</div>
        {/* Columns must be the root component */}
        {componentNumber === -1 &&
          <div className="text-gray-100 py-1 cursor-pointer background-hover-gray-10" onClick={() => {
            toggleModal()
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => currentState?.sections,
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  sections?.splice(sectionNumber,0,{
                    type: LayoutSectionType.TwoColumnSection,
                    components: [null,null,null],
                    __typename: 'LayoutSection',
                  })
                  return sections
                }
              )
              if(setSelectedComponentId) setSelectedComponentId({id: sectionNumber, type: "section"})
              return newState
            })
          }}>
            <FontAwesomeIcon
              icon={"columns"}
              className="text-gray-70 ml-4 mr-3"
            />
            Columns
          </div>
        }
        {otherStandardComponents.map(({ name, componentType, defaultSettings }) => {
          const handleClick = () => {
            toggleModal()
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => currentState?.sections,
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  const newId = parseInt(uniqueId()) * -1
                  // If not in a column then add to a new single column
                  if (componentNumber || componentNumber === 0) {
                    // -1 signifies a new column
                    if (componentNumber === -1) {
                      sections?.splice(sectionNumber,0,{
                        type: LayoutSectionType.SingleColumnSection,
                        components: [{
                          id: newId,
                          name,
                          type: componentType,
                          approval: {
                            code: ComponentApprovalCode._1,
                            value: 'Needs Review',
                            __typename: 'ComponentApprovalLookup'
                          },
                          draftSettings: defaultSettings as ClientPortfolioDetailComponentSettingsFragment,
                          liveSettings: undefined,
                          template: false,
                          __typename: 'Component'
                        }],
                        __typename: 'LayoutSection',
                      })
                    } else {
                      if(sections){
                        var selectedSection = sections[sectionNumber]
                        if(selectedSection) set(selectedSection, `components[${componentNumber}]`, {
                          id: newId,
                          name,
                          type: componentType,
                          approval: {
                            code: ComponentApprovalCode._1,
                            value: 'Needs Review',
                            __typename: 'ComponentApprovalLookup'
                          },
                          draftSettings: defaultSettings as ClientPortfolioDetailComponentSettingsFragment,
                          liveSettings: undefined,
                          template: false,
                          __typename: 'Component'
                        });
                      }
                    }
                    if(setSelectedComponentId) setSelectedComponentId({id: newId, type: "component"})
                  }
                  return sections
                }
              )
              return newState
            })
          }
          return (
            <div key={componentType} className="text-gray-100 py-1 cursor-pointer background-hover-gray-10" onClick={() => handleClick()}>
              <FontAwesomeIcon
                icon={IconMapping[componentType] || "table"}
                className="text-gray-70 ml-4 mr-3"
              />
              {name}
            </div>
          )
        })}
        { report?.category?.code !== ReportCategoryCode.FPROF &&
          <div className="d-flex justify-content-center mt-3">
            <Button color="secondary" onClick={() => setModalType(ModalType.ReportList)}>
              Import Component...
            </Button>
          </div>
        }
      </div>
    </ModalBody>
  </Modal>)

  if(modalType === ModalType.ReportList){
    let colDef = ImportReportListColumnDef()
    const onReady = (event:GridReadyEvent) => {
      setGridApi(event.api)
    }

    const onFirstDataRendered = (params:FirstDataRenderedEvent) => {
      params.api.setFilterModel({
        'owner': {
          filterType: 'set',
          // values: [`Kayla Leung`]
          values: [`${userData?.me?.person?.firstName} ${userData?.me?.person?.lastName}`]
        }
      })
    }

    let recentReports = getRecentReports()

    const sortedReports = sortBy(data?.reports, (report) => {
      const recentIndex = recentReports.findIndex((recentReport) => recentReport.id === report.id)
      return [recentIndex > -1 ? recentIndex : 9999]
    })

    modal = (<Modal
      size="lg"
      className="mt-5"
      isOpen={modalOpen}
      toggle={toggleModal}
      zIndex={1500}
      key="modal"
    >
      <ModalHeader className='full-width-header'>
        <div className="d-flex justify-content-between w-100">
          <div>
            Import Component
          </div>
          <div onClick={toggleModal}>
            <FontAwesomeIcon
              icon="times"
              className="ml-auto"
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <h4>Select Report</h4>
          <div>
            <FormGroup row className="relative no-gutters">
              <Input
                type="text"
                placeholder="Find Reports by name"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="wide-search m-0"
              />
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            </FormGroup>
          </div>
          <SortableTable
            loading={loading}
            filterText={search}
            columnDefs={colDef}
            tableData={sortedReports}
            rowId={"id"}
            singleRowSelection={true}
            onReady={onReady}
            onFirstDataRendered={onFirstDataRendered}
            subClasses={{tableContainerClasses: "short-table"}}
            // onFilterChanged={() => setFilterUpdates(filterUpdates + 1)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={selectReport}>
          Select
        </Button>
      </ModalFooter>
    </Modal>)
  }

  if(modalType === ModalType.ChooseComponent && selectedReport){
    modal = (
      <ImportComponentFromReport
        report={selectedReport}
        intoReport={report}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        back={() => setModalType(ModalType.ReportList)}
        sectionNumber={sectionNumber}
        componentNumber={componentNumber}
        setEditedDraftLayout={setEditedDraftLayout}
      />
    )
  }

  if(loading || defaultLoading || portfolioLoading || planPortfolioLoading){
    modal = (
      <Modal
        size="sm"
        className="mt-5"
        isOpen={modalOpen}
        toggle={toggleModal}
        zIndex={1500}
        key="modal"
      >
        <ModalHeader className='full-width-header'>
          <div className="d-flex justify-content-between w-100">
            <div>
              Add Component
            </div>
            <div onClick={toggleModal}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <>
      {modal}
      {!children &&
        <div className={classNames('d-flex position-absolute justify-content-center report-addable-container')} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
          {hovering &&
            <>
              <div className='position-absolute mt-3 report-addable-bar'></div>
              <Button size="tiny" color="primary" className='z-index-1 mt-2' onClick={() => setModalOpen(!modalOpen)}>
                <FontAwesomeIcon icon="plus" />
              </Button>
            </>
          }
        </div>
      }
      {children && children({setModalOpen})}
    </>
  )
}

interface ImportComponentFromReportProps {
  report: ReportSimpleFragment
  intoReport?: ReportsFragment
  modalOpen: boolean
  toggleModal: () => void
  back: () => void
  sectionNumber: number
  componentNumber: number
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  setSelectedComponentId?: (value:selectedComponentProp) => void
}

export const ImportComponentFromReport:React.FC<ImportComponentFromReportProps> = (props) => {
  const { report, intoReport, modalOpen, toggleModal, back, sectionNumber, componentNumber, setEditedDraftLayout, setSelectedComponentId } = props
  const [selectedPlan, setSelectedPlan] = useState<listReportExpanded | undefined>(undefined)
  const [selectedComponent, setSelectedComponent] = useState<ClientPortfolioDetailComponentFragment | undefined>(undefined)

  const { loading, data, error } = useReportDetailQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: report.id, liveView: true, draftView: true },
  })

  const handleClick = (component: ClientPortfolioDetailComponentFragment, linked: boolean) => {
    toggleModal()
    setEditedDraftLayout((prevState) => {
      let newState = iassign(
        prevState,
        currentState => currentState?.sections,
        sectionsTable => {
          let sections = cloneDeep(sectionsTable)
          const newId = linked ? component.id : (parseInt(uniqueId()) * -1)
          const currentReport = {
            list: {
              id: intoReport?.liveList?.id || -1,
              __typename: 'List' as 'List'
            },
            report: {
              id:   intoReport?.id || -1,
              name: intoReport?.name || "",
              __typename: 'Report' as 'Report'
            },
            __typename: 'ComponentReportUsage' as 'ComponentReportUsage'
          }
          const reportsUsedIn = linked ? [...(component.reportsUsedIn || []), currentReport] : [currentReport]
          // If not in a column then add to a new single column
          if (componentNumber || componentNumber === 0) {
            // -1 signifies a new column
            if (componentNumber === -1) {
              sections?.splice(sectionNumber,0,{
                type: LayoutSectionType.SingleColumnSection,
                components: [{
                  id: newId,
                  name: component.name,
                  type: component.type,
                  owner: component.owner,
                  approval: {
                    code: ComponentApprovalCode._1,
                    value: 'Needs Review',
                    __typename: 'ComponentApprovalLookup'
                  },
                  reportsUsedIn,
                  draftSettings: component.draftSettings,
                  liveSettings: component.liveSettings,
                  template: component.template,
                  __typename: 'Component'
                }],
                __typename: 'LayoutSection',
              })
            } else {
              if(sections){
                var selectedSection = sections[sectionNumber]
                if(selectedSection) set(selectedSection, `components[${componentNumber}]`, {
                  id: newId,
                  name: component.name,
                  type: component.type,
                  owner: component.owner,
                  approval: {
                    code: ComponentApprovalCode._1,
                    value: 'Needs Review',
                    __typename: 'ComponentApprovalLookup'
                  },
                  reportsUsedIn,
                  draftSettings: component.draftSettings,
                  liveSettings: component.liveSettings,
                  template: component.template,
                  __typename: 'Component'
                });
              }
            }
            if(setSelectedComponentId) setSelectedComponentId({id: newId, type: "component"})
          }
          return sections
        }
      )
      return newState
    })
  }

  const baseModal = (children: JSX.Element) => {
    return (
      <Modal
        size="lg"
        className="mt-5"
        isOpen={modalOpen}
        toggle={toggleModal}
        zIndex={1500}
        key="modal"
      >
        <ModalHeader className='full-width-header'>
          <div className="d-flex justify-content-between w-100">
            <div>
              Import Component
            </div>
            <div onClick={toggleModal}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="background-gray-10 p-2">
            <h4>{report.name}</h4>
            {children}
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={back}>
            Back
          </Button>
          <Button color="primary" disabled={!selectedComponent} onClick={() => selectedComponent && handleClick(selectedComponent, true)}>
            Import Component
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  if(loading) return baseModal(<div>Loading...</div>)
  if(error) return baseModal(<div>Error</div>)
  if(data && data.report?.liveList && data.report.client) {
    const showList = expandReportList(data.report.liveList)
    const sections = selectedPlan?.liveLayout?.sections || []
    return baseModal(
      <Row>
        <Col xs={6} className="pr-0">
          <div className="border">
            <ReportSidebar
              clientId={data.report.client.id}
              planId={data.report.plans?.[0]?.id}
              reportId={report.id}
              portfolioId={selectedPlan?.id}
              tree={showList}
              view={ReportDisplayType.External}
              externalLink={(item) => setSelectedPlan(item)}
              startOpen={true}
              holderClass={"h-100 modal-sidebar"}
              listType="report"
            />
          </div>
        </Col>
        <Col>
          <div className="border h-100 background-white">
            {sections.map((section, sectionNum) =>{
              return section?.components?.map(
                (component, componentNum) => {
                  return (<div key={"C-" + componentNum} className={classNames("py-2 d-flex cursor-pointer", {"background-blue-40": component?.id === selectedComponent?.id})} onClick={() => setSelectedComponent(component || undefined)}>
                    {component?.type &&
                      <FontAwesomeIcon
                        icon={IconMapping[component.type] || "table"}
                        className="text-gray-70 ml-4 mr-3"
                      />
                    }
                    {component?.name}
                  </div>)
                }
              )
            })}
          </div>
        </Col>
      </Row>
    )
  } else if (data && !data.report?.liveList) {
    return baseModal(<div>No published report available</div>)
  }
  return baseModal(<div>Error</div>)
}