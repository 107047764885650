import { disableFragmentWarnings, gql } from "@apollo/client"
import {
  FILE_FULL_DETAILS_FRAGMENT,
  REPORT_DOCUMENT_DETAILS_FRAGMENT
} from "./Fragments"
import { REPORT_LIST_HEADER_FRAGMENT } from "./ListFragments"
import {
  PLAN_DOCUMENTS_FRAGMENT,
  PLAN_MANAGER_DOCUMENTS_FRAGMENT,
} from "./PlanFragments"
import {
  CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT,
  REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT,
} from "./ReportFragments"

disableFragmentWarnings()

export const REPORT_OVERVIEW_FRAGMENT = gql`
  fragment ReportOverviewFragment on Report {
    id
    name
    active
    category {
      code
    }
    liveList {
      id
      name
    }
    draftList {
      id
      name
    }
  }
`

export const PLAN_OVERVIEW_FRAGMENT = gql`
  fragment PlanOverviewFragment on Plan {
    id
    name
    shortName
    report(filters: {limit: 1000}) {
      ...ReportOverviewFragment
    }
  }
  ${REPORT_OVERVIEW_FRAGMENT}
`

export const CLIENT_OVERVIEW_FRAGMENT = gql`
  fragment ClientOverviewFragment on Client {
    id
    name
    plans {
      ...PlanOverviewFragment
    }
  }
  ${PLAN_OVERVIEW_FRAGMENT}
`

export const CLIENT_REPORT_DOCUMENTS_FRAGMENT = gql`
  fragment ClientReportDocumentsFragment on Client {
    id
    plans {
      ...PlanDocumentsFragment
    }
    report(filters: {limit: 1000}) {
      ...ReportDocumentDetailsFragment
    }
    documents {
      ...FileFullDetailsFragment
    }
  }
  ${REPORT_DOCUMENT_DETAILS_FRAGMENT}
  ${FILE_FULL_DETAILS_FRAGMENT}
  ${PLAN_DOCUMENTS_FRAGMENT}
`

export const REPORT_MANAGER_DOCUMENTS_FRAGMENT = gql`
  fragment ReportManagerDocumentsFragment on Report {
    id
    plans {
      ...PlanManagerDocumentsFragment
    }
  }
  ${PLAN_MANAGER_DOCUMENTS_FRAGMENT}
`

export const CLIENT_PORTFOLIOS_TABLE_FRAGMENT = gql`
  fragment ClientPortfoliosTableFragment on ClientPortfolio {
    id
    name
    dataType {
      code
      value
    }
    relatedVehicle {
      vehicle {
        id: fundid
        name
        category {
          code
          value
        }
        product {
          product {
            id
            name
            activePassive
            manager {
              id
              name
            }
            assetClass {
              id
              shortName
              parent {
                id
                shortName
              }
            }
          }
        }
      }
    }
  }
`

export const PORTFOLIO_REPORTS_LIST_FRAGMENT = gql`
  fragment PortfolioReportsListFragment on List {
    id
    name
    type
    active
    items {
      order
      group
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          ...ReportListHeaderFragment
        }
        ... on ClientPortfolio {
          ...ClientPortfoliosTableFragment
        }
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${CLIENT_PORTFOLIOS_TABLE_FRAGMENT}
`

export const REPORT_PORTFOLIOS_FRAGMENT = gql`
  fragment ReportPortfoliosFragment on Report {
    id
    name
    active
    draftList {
      ...PortfolioReportsListFragment
    }
    liveList {
      ...PortfolioReportsListFragment
    }
  }
  ${PORTFOLIO_REPORTS_LIST_FRAGMENT}
`

export const CLIENT_REPORT_PORTFOLIOS_FRAGMENT = gql`
  fragment ClientReportPortfoliosFragment on Client {
    id
    report(filters: {limit: 1000}) {
      ...ReportPortfoliosFragment
    }
  }
  ${REPORT_PORTFOLIOS_FRAGMENT}
`

export const CLIENT_PORTFOLIO_DETAIL_COMPONENT_FRAGMENT = gql`
  fragment ClientPortfolioDetailComponentFragment on Component {
    id
    name
    type
    template
    exportTypes
    approval {
      code
      value
    }
    reportsUsedIn {
      list {
        id
      }
      live
      report {
        id
        name
        category {
          code
          value
        }
        client {
          id
          name
        }
      }
    }
    draftSettings @include(if: $draftView) {
      ...ClientPortfolioDetailComponentSettingsFragment
    }
    liveSettings @include(if: $liveView) {
      ...ClientPortfolioDetailComponentSettingsFragment
    }
    owner {
      id
      firstName
      lastName
    }
    cachedDates {
      date
      quarter
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT}
`

export const CLIENT_PORTFOLIO_DETAIL_SECTION_FRAGMENT = gql`
  fragment ClientPortfolioDetailSectionFragment on LayoutSection {
    type
    components {
      ...ClientPortfolioDetailComponentFragment
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_FRAGMENT}
`

export const CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT = gql`
  fragment ClientPortfolioDetailLayoutFragment on Layout {
    sections {
      ...ClientPortfolioDetailSectionFragment
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_SECTION_FRAGMENT}
`

export const CLIENT_REPORT_INTERACTIONS_FRAGMENT = gql`
  fragment ClientReportInteractionsFragment on Client {
    id
    plans {
      id
      name
      report(filters: {limit: 1000}) {
        id
        name
        active
      }
      interactions {
        ...InteractionSidebarFragment
      }
    }
  }
`

export const PLAN_PORTFOLIO_FRAGMENT = gql`
  fragment PlanPortfolioFragment on Report {
    id
  }
`

export const REPORTS_LIST_MEMBER_FRAGMENT = gql`
  fragment ReportsListMemberFragment on ListMember {
    order
    group
    type
    item {
      ... on ListGroup {
        id
      }
      ... on ListHeader {
        ...ReportListHeaderFragment
      }
      ... on ClientPortfolio {
        ...ReportsListClientPortfolioFragment
      }
    }
    draftLayout @include(if: $draftView) {
      ...ClientPortfolioDetailLayoutFragment
    }
    liveLayout @include(if: $liveView) {
      ...ClientPortfolioDetailLayoutFragment
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT}
  ${CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT}
`

export const REPORTS_LIST_FRAGMENT = gql`
  fragment ReportsListFragment on List {
    id
    name
    type
    active
    subCategory {
      code
    }
    items {
      ...ReportsListMemberFragment
    }
  }
  ${REPORTS_LIST_MEMBER_FRAGMENT}
`

export const REPORTS_CONTACTS_FRAGMENT = gql`
  fragment ReportsContactsFragment on ReportContact {
    contact {
      id
      firstName
      lastName
    }
    deliveryMethod {
      code
      value
    }
  }
`

export const REPORTS_FRAGMENT = gql`
  fragment ReportsFragment on Report {
    id
    name
    active
    order
    lastPublished
    draftList @include(if: $draftView) {
      ...ReportsListFragment
    }
    liveList @include(if: $liveView) {
      ...ReportsListFragment
    }
    owner {
      id
      lastName
      firstName
    }
    client {
      id
      name
    }
    category {
      code
      value
    }
    subCategory {
      code
      value
    }
    plans {
      id
      name
      shortName
      fiscalMonth
      report(filters: {limit: 1000}) {
        id
        name
      }
    }
    dueDates {
      dueDate
      quarterDate
    }
    frequency {
      code
      value
    }
    dataEntryAnalyst {
      id
      lastName
      firstName
    }
    exportSettings {
      filename
      footerName
      titleSlide {
        title
        subtitle
      }
    }
    contacts {
      ...ReportsContactsFragment
    }
    publishVersions {
      version
      published
    }
  }
  ${REPORTS_LIST_FRAGMENT}
  ${REPORTS_CONTACTS_FRAGMENT}
`

export const CLIENT_REPORTS_FRAGMENT = gql`
  fragment ClientReportsFragment on Client {
    id
    report(filters: {limit: 1000}) {
      ...ReportsFragment
    }
    plans {
      id
      report(filters: {limit: 1000}) {
        ...ReportsFragment
      }
    }
  }
  ${REPORTS_FRAGMENT}
`

export const CLIENT_REPORT_ORDER_FRAGMENT = gql`
  fragment ClientReportOrderFragment on Report {
    id
    name
    active
    order
    category {
      code
      value
    }
  }
`

export const CLIENT_PLAN_ORDER_FRAGMENT = gql`
  fragment ClientPlanOrderFragment on Plan {
    id
    shortName
    name
    order
    report(filters: {limit: 1000}) {
      ...ClientReportOrderFragment
    }
  }
  ${CLIENT_REPORT_ORDER_FRAGMENT}
`