import { access } from 'fs'
import JSZip from 'jszip'
import moment from 'moment'
import { DATE_API_FORMAT } from './constant'
import { text } from 'stream/consumers'
// Based on https://stackoverflow.com/questions/15547198/export-html-table-to-csv-using-vanilla-javascript

type ExportFile = {
  baseFilename: string
  filename: string
  csvString: string
}

interface ExportTablesProps {
  groupName?: string
  arbitraryCols?: ArbitraryCol[]
  extraRows?: ExtraRow[]
  parentElement?: Element
}

interface ExportToCsvProps {
  object: Element
  rowsSelector:string
  colSelector:string
  name:string
  separator:string
  arbitraryCols?: ArbitraryCol[]
  extraRows?: ExtraRow[]
  previousCsv?: string
}

type ArbitraryCol = {
  heading: string
  value: string
  column: number
}

export type ExtraRow = {
  values: (string | undefined | null)[]
}

export const exportTables = (props:ExportTablesProps = {}) => {
  let {groupName, arbitraryCols, extraRows, parentElement} = props
  if(!groupName) groupName= "reports"
  if(!arbitraryCols) arbitraryCols = []
  if(!extraRows) extraRows = []
  const parent = parentElement || document
  const tables = parent.querySelectorAll('table.exportable')
  const grids = parent.querySelectorAll('div.exportable')
  const forms = parent.querySelectorAll('div.exportable-form')
  let files:ExportFile[] = []

  tables.forEach((table) => {
    const tableName = table.getAttribute('data-export-name') || "table"
    const extraSettings = JSON.parse(table.getAttribute('data-export-settings') || "{}")
    const previousIndex = files.findIndex((file) => file.baseFilename === (extraSettings.name || tableName))
    if(tableName != "table" && previousIndex !== -1) {
      // Don't duplicate headers so no th as part of export
      let exported = exportToCsv({object: table, rowsSelector: 'tr:not(.no-export-table)', colSelector:'td', name: tableName, separator: ',', arbitraryCols, extraRows, previousCsv: files[previousIndex].csvString, ...extraSettings})
      files[previousIndex] = exported[0]
      files.push(...exported.slice(1))
    } else {
      files.push(...exportToCsv({object: table, rowsSelector: 'tr:not(.no-export-table)', colSelector:'td, th', name: tableName, separator: ',', arbitraryCols, extraRows, ...extraSettings}))
    }
  })
  grids.forEach((grid) => {
    const gridName = grid.getAttribute('data-export-name') || "table"
    const extraSettings = JSON.parse(grid.getAttribute('data-export-settings') || "{}")
    files.push(...exportToCsv({object: grid, rowsSelector: 'div.row', colSelector: '[class*="col"]', name: gridName, separator: ',', arbitraryCols, extraRows, ...extraSettings}))
  })
  forms.forEach((form) => {
    const formName = form.getAttribute('data-export-name') || "table"
    const extraSettings = JSON.parse(form.getAttribute('data-export-settings') || "{}")
    const previousIndex = files.findIndex((file) => file.baseFilename === (extraSettings.name || formName))
    if(formName != "table" && previousIndex !== -1) {
      let exported = exportToCsv({object: form, rowsSelector: 'div.form-group:not(.no-export-form)', colSelector:'div:not(.no-export-form-label) > label,div.exportable-form-input', name: formName, separator: ',', arbitraryCols, extraRows, previousCsv: files[previousIndex].csvString, ...extraSettings})
      files[previousIndex] = exported[0]
      files.push(...exported.slice(1))
    } else {
      files.push(...exportToCsv({object: form, rowsSelector: 'div.form-group:not(.no-export-form)', colSelector: 'div:not(.no-export-form-label) > label,div.exportable-form-input', name: formName, separator: ',', arbitraryCols, extraRows, ...extraSettings}))
    }
  })
  if(files.length > 1) {
    let zip = new JSZip()
    files.forEach((file) => {
      zip.file(file.filename, file.csvString)
    })
    zip.generateAsync({
      type: "base64",
      compression: "DEFLATE"
    }).then((odsFile) => {
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:application/zip;base64,' + odsFile);
      link.setAttribute('download', `${groupName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  } else if (files.length === 1) {
    const file = files[0]
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(file.csvString));
    link.setAttribute('download', file.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error("No tables to export")
  }
}

const exportToCsv = (props: ExportToCsvProps) => {
  const {object, rowsSelector, colSelector, name, separator, arbitraryCols, extraRows, previousCsv} = props
  // Select rows from table_id
  const rows = object.querySelectorAll(rowsSelector);
  const filename = name + '-' + moment().format(DATE_API_FORMAT) + '.csv';
  let files = [{ baseFilename: name, filename, csvString: "" }]
  let currentFile = 0
  // Construct csv
  let csv:any[] = [];
  rows.forEach((tableRow, rowNum) => {
    let row:any[] = [], cols = tableRow.querySelectorAll(colSelector);
    if(cols.length === 0) return
    if(rowsSelector === "dt") console.log({tableRow, cols})
    if(tableRow.getAttribute('data-export-restart')){
      files[currentFile].csvString = (previousCsv || "") + csv.join('\n');
      csv = []
      currentFile++
      const newExportName = tableRow.getAttribute('data-export-restart') || "report-" + currentFile
      const newFilename = newExportName + '-' + moment().format(DATE_API_FORMAT) + '.csv'
      files.push({ baseFilename: newExportName, filename: newFilename, csvString: "" })
    }
    cols.forEach((col:any, idx) => {
      const arbitraryColsCurrent = arbitraryCols?.filter((ac:ArbitraryCol) => ac?.column === idx)
      arbitraryColsCurrent?.forEach(( ac:ArbitraryCol) => {
        if(rowNum === 0){
          row.push('"' + ac.heading + '"');
        } else {
          row.push('"' + ac.value + '"');
        }
      })
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = col.innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')

      const input = col.querySelector('input')
      const select = col.querySelector('select')
      const textarea = col.querySelector('textarea')
      if(input?.type === "checkbox"){
        if(input?.checked == false){
          input.value = ""
        }
      }
      if(input) {
        if(input.getAttribute('data-export-type') === "number"){
          data = input.value.replace(/[%]/gm, '')
        } else {
          data = input.value
        }
      } else if (select && typeof select.selectedIndex === 'number' && select.options.length > (select?.selectedIndex || -1)) {
        data = select.options[select.selectedIndex].text
      } else if (textarea) {
        data = textarea.value
      }

      if(col.getAttribute('data-export-value')){
        data = col.getAttribute('data-export-value')
      }

      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
      for(let i = 0; i < ((col.getAttribute('colspan') || 1) - 1); i++){
        row.push("")
      }
    })
    csv.push(row.join(separator));
  })
  extraRows?.forEach((extraRow) => {
    let row:any[] = []
    extraRow.values.forEach((value, idx) => {
      let data
      if(!value){
        data = ""
      }
      else{
        data = value?.replace(/(\s\s)/gm, ' ').replace(/"/g, '""')
      }
      row.push('"' + data + '"');
    })
    csv.push(row.join(separator))
  })
  let csvString = (previousCsv || "") + csv.join('\n');
  files[currentFile].csvString = csvString + "\n"
  // Download it
  return files
}

export default exportTables