import iassign from 'immutable-assign'
import _, { orderBy, sortBy } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import React, { FormEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Form, Row } from 'reactstrap'
import moment from 'moment'

import { AssetClass, AssetClassFragment, ClosedEnded, CurrentAssetClassFragment, DeleteAssociatedSymbolsInput, DeleteGeographicRegionCodesInput, DeleteMaterialChangesToProductInput, DeleteProductSectorsInput, DeleteUsRegionCodesInput, GetAllAssetClassesQuery, HedgeFund, MaterialChangesToProduct, Maybe, OpenEnded, PrivateCredit, PrivateEquity, Product, ProductFields, ProductSummaryOptionsByAssetClassMapsQuery, ProductSummaryQuery, RealAssets, useDeleteAssociatedSymbolsMutation, useDeleteGeographicRegionCodesMutation, useDeleteProductMaterialChangesMutation, useDeleteProductSectorsMutation, useDeleteUsRegionCodesMutation, useGetAllAssetClassesQuery, useProductBenchmarksOptionsQuery, useProductSummaryOptionsByAssetClassMapsQuery, useProductSummaryQuery, useUpdateProductOverviewSummaryMutation } from "../../__generated__/graphql"
import Auth from "../../Auth/Auth"
import { FormInputField } from "../../helpers/constant"
import { getNewStateObject } from "../../helpers/helpers"
import { excludePropertyArray, getNumValue, reShapeObject } from "../../helpers/object"
import { getProductType, ProductType } from "../../helpers/productFunction"
import { ProductOverviewSummaryConfig } from "../../LayoutConfig/Product/ProductOverviewSummary/ProductOverviewSummaryConfig"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from '../ui/EditButtons'
import { FormInput } from "../ui/Forms/FormInput"
import { GetLookupDataToOptions, GetLookupDataToOptionsProps } from "../ui/LookupOptions"
import PlaceHolder from "../ui/PlaceHolder"
import AssociatedSymbolsTableDisplay from "./ProductOverviewSummaryAssociatedSymbols"
import { PrimaryBenchmarkComponent } from "./ProductOverviewSummaryBenchmarks"
import BlendedBenchmarkTableDisplay from "./ProductOverviewSummaryBlendedBenchmark"
import DebtMaturityScheduleTableDisplay from "./ProductOverviewSummaryDebtMaturityScheduleTable"
import FundRaisingTableDisplay from "./ProductOverviewSummaryFundRaisingTable"
import MaterialChangesTableDisplay from "./ProductOverviewSummaryMaterialChangesTable"
import ProjectedCloseDatesTableDisplay from "./ProductOverviewSummaryProjectedCloseDateTable"
import TermsTableDisplay, { TermsTableRowDef } from "./ProductOverviewSummaryTermsTable"
import exportTables from '../../helpers/exportTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const BasicDetailsFields: FormInputField[] = [
  {
    property: "",
    label: "Product Details",
    type: "",
    subClasses: {
      wrapperClasses:
        "row form-section-title headline underline small-font-size py-2 mb-2"
    }
  },
  {
    property: "product.name",
    label: "Product Name",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "productNameTooltip"
    }
  },
  {
    property: "product.id",
    label: "Product ID",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "productIdTooltip"
    }
  },
  {
    property: "product.inactive",
    label: "Status",
    type: "select",
    subtype: "single",
    readonly: true,
    required: true,
    optionSourceFunction: () => null
  },
  {
    property: "product.assetClass.parent.code",
    label: "Asset Class",
    type: "select",
    subtype: "single",
    optionSourceFunction: GetLookupDataToOptions
  },
  {
    property: "product.assetClass.code",
    label: "Strategy Type",
    type: "select",
    subtype: "single",
    required: true,
    optionSourceFunction: GetLookupDataToOptions
  }
]

const BenchmarkFields: FormInputField[] = [
  {
    property: "product.primaryBenchmark",
    label: "Benchmark",
    type: "table",
    subtype: "",
    Component: PrimaryBenchmarkComponent,
    optionSourceFunction: GetLookupDataToOptions
  },
  {
    property: "product.blendedBenchmark",
    label: "",
    type: "table",
    Component: BlendedBenchmarkTableDisplay
  }
]

const OtherDetailsFields: FormInputField[] = [
  {
    property: "product.activePassive",
    label: "Active/Passive",
    type: "select",
    subtype: "single",
    optionSource: "ActivePassive"
  },
  {
    property: "product.structure.code",
    label: "Product Structure",
    type: "select",
    subtype: "single",
    optionSource: "ProductStructureCode",
    required: true
  },
  {
    property: "closedEnded.fundFocus.code",
    label: "Fund Focus",
    type: "select",
    subtype: "single",
    optionSource: "FundFocusCode"
  },
  {
    property: "product.capitalStructure",
    label: "Capital Structure",
    type: "select",
    subtype: "single",
    optionSource: "CapitalStructure",
    readonly: true
  },
  {
    property: "product.totalCapacity",
    label: "Total Product Capacity ($M)",
    type: "float",
    subtype: "currency",
    placeholder: "Enter total strategy capacity",
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-4",
      inputClasses: "placeholder-left"
    }
  },
  {
    property: "closedEnded.vintageYearFirstCashflow",
    label: "Vintage - First Cash Flow",
    type: "date",
    readonly: true,
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-6",
      inputClasses: "text-left"
    },
    tooltip: {
      icon: "question-circle",
      id: "vintageYearFirstCashflowToolTip"
    }
  },
  {
    property: "closedEnded.vintageYearLegalInception",
    label: "Vintage - Legal Inception",
    type: "date",
    readonly: true,
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-6",
      inputClasses: "text-left"
    },
    tooltip: {
      icon: "question-circle",
      id: "vintageYearLegalInceptionTooltip"
    }
  },
  {
    property: "hedgeFund.directionalBias.code",
    label: "Directional Bias",
    type: "select",
    subtype: "single",
    optionSource: "DirectionalBiasCode"
  },
  {
    property: "closedEnded.controlRights.code",
    label: "Control Rights",
    type: "select",
    subtype: "single",
    optionSource: "ControlRightsCode"
  }
]

const ReturnTableInput: FormInputField[] = [
  {
    property: "",
    label: "Terms",
    type: "table"
  },
  {
    property: "privateCredit.targetGrossYield",
    label: "Target Gross Yield",
    type: "float",
    subtype: "percent",
    exportTableName: "TermsValues"
  },
  {
    property: "privateCredit.targetOriginationFees",
    label: "OID/Origination Fee",
    type: "float",
    subtype: "percent",
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.targetRaise",
    label: "Target Raise",
    type: "float",
    subtype: "currency",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues",
  },
  {
    property: "closedEnded.finalCommitSize",
    label: "Fund Commit Size",
    type: "float",
    subtype: "currency",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "fundCommitSizeTooltip"
    },
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "product.hardCap",
    label: "Hard Cap",
    type: "float",
    subtype: "currency",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.minimumCommitment",
    label: "Minimum Commitment",
    type: "float",
    subtype: "currency",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.sponsorCommitment",
    label: "Sponsor Commitment",
    type: "float",
    subtype: "percent",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.investmentPeriod",
    label: "Investment Periods (Yrs)",
    type: "float",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.term",
    label: "Term (Yrs)",
    type: "number",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.extensions.numberAllowed",
    label: "# of Extensions Allowed",
    type: "number",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  },
  {
    property: "closedEnded.extensions.lengthAllowed",
    label: "Length of Extensions (Yrs)",
    type: "number",
    subClasses: {
      wrapperClasses: "pl-1",
      labelClasses: "col-sm-4 pl-3",
      inputWrapperClasses: "col-sm-7",
    },
    exportTableName: "TermsValues"
  }
]

const FundRaisingTable: FormInputField[] = [
  {
    property: "closedEnded.fundRaising",
    label: "Fund Raising",
    type: "table",
    Component: FundRaisingTableDisplay
  }
]

const ProjectedCloseDateTable: FormInputField[] = [
  {
    property: "closedEnded.projectedCloseDates",
    label: "Projected Close Date",
    type: "table",
    Component: ProjectedCloseDatesTableDisplay
  }
]

const DebtMaturityScheduleTable: FormInputField[] = [
  {
    property: "realAssets.debtMaturitySchedule",
    label: "Debt Maturity Schedule",
    type: "table",
    Component: DebtMaturityScheduleTableDisplay
  }
]
const MaterialChangesTable = {
  property: "product.materialChanges",
  label: "Material Changes",
  type: "table",
  Component: MaterialChangesTableDisplay
}

const AssociatedSymbolsTable = {
  property: "openEnded.associatedSymbols",
  label: "Associated Symbols",
  type: "table",
  Component: AssociatedSymbolsTableDisplay
}

const MultiSelectFields: FormInputField[] = [
  {
    property: "privateEquity.targetInvestmentStyle",
    label: "Primary Investment Style(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "InvestmentStyleCode"
  },
  {
    property: "privateCredit.targetInvestmentStyle",
    label: "Primary Investment Style(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "InvestmentStyleCode"
  },
  {
    property: "hedgeFund.primaryHedgeFundInvestmentStyle",
    label: "Primary Investment Style(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "InvestmentStyleCode"
  },
  {
    property: "hedgeFund.primaryHedgeFundAssetType",
    label: "Primary Asset Class Usage",
    type: "select",
    subtype: "multiple",
    optionSource: "HedgeFundAssetTypeCode"
  },
  {
    /*no avaiLable data in db, doesn't work right now, see CAL-340*/
    property: "hedgeFund.primaryHedgeFundSectorsTarget",
    label: "Primary Sector(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "SectorByChildCode" //AssetClassMaps [45]
  },
  {
    property: "privateEquity.targetPrimaryGICSSectors",
    label: "Primary Sector(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "GicsSectorCodes"
  },
  {
    property: "realAssets.primaryRealAssetSectorsTarget",
    label: "Primary Sector(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "SectorByChildCode" // AssetClassMaps [9，125]
  },
  {
    /*no avaiLable data in db, doesn't work for hedgeFund product right now, see CAL-340*/
    property: "product.primaryGeographicRegion",
    label: "Primary Geographic Region(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "GeographicRegionCode" // AssetClassMaps hedged/ ClosedEnded
  },
  {
    property: "realAssets.primaryUSRegions",
    label: "Primary US Region(s)",
    type: "select",
    subtype: "multiple",
    optionSource: "USRegionsCode"
  }
]

const ActiveBooleanOptions = GetLookupDataToOptions({
  data: [
    {
      code: "false",
      value: "Active",
    },
    {
      code: "true",
      value: "Inactive",
    },
  ],
  multiple: true,
})

const ProductFieldsInput: FormInputField[] = [
  ...BasicDetailsFields,
  ...BenchmarkFields,
  ...OtherDetailsFields,
  ...MultiSelectFields,
  ...DebtMaturityScheduleTable,
  ...FundRaisingTable,
  ...ProjectedCloseDateTable
]

const RightPaneTables: FormInputField[] = [
  ...ReturnTableInput,
  AssociatedSymbolsTable
]

const omitArray = [
  //readonly
  "product.id",
  "product.name",
  "product.assetClass.parent",
  "product.capitalStructure"
]

const omitClosedEnded = [
  "vintageYearFirstCashflow",
  "vintageYearLegalInception"
]

const NumberFields = [...ProductFieldsInput, ...ReturnTableInput].filter(
  el => el.type === "number"
)

const formatNumberInputs = (state: any, config: any) => {
  /**
   * Number, not floats. should use the same way as handleBlur function
   *  in NumberFieldInput
   */
  let newState = _.cloneDeep(state)
  NumberFields.filter(
    el => _.get(state, el.property) && _.get(config, el.property)
  ).forEach(el => {
    let value = _.get(state, el.property)
    let parseValue = parseInt(value)
    _.set(newState, el.property, parseValue)
  })
  return newState
}

const omitState = (state: any, omitArray: string[]) => {
  const newState = { ...state }
  omitArray.forEach(property => {
    _.unset(newState, property)
  })
  if (!state.closedEnded) {
    return newState
  }
  const newClosedEnded = { ...newState.closedEnded }
  omitClosedEnded.forEach(property => {
    _.unset(newClosedEnded, property)
  })
  let { closedEnded, id, ...rest } = newState
  let u = { ...rest, closedEnded: newClosedEnded }
  return u
}
const reShapeProductActions = {
  "product.assetClass": {
    destinationPath: "product.assetClass",
    // string to num
    action: (a: any) => {
      if (_.isEmpty(a?.code) || _.isNull(a?.code)) {
        return null
      } else {
        return parseInt(a?.code)
      }
    }
  },
  "product.primaryBenchmark.code": {
    destinationPath: "product.primaryBenchmark",
    action: (a: any) => a || null
  },
  "product.blendedBenchmark": {
    destinationPath: "product.blendedBenchmark",
    action: (a: any[]) =>
      a?.map(el => ({ ...el, benchmark: el.benchmark.code })) || []
  },
  "product.primaryGeographicRegion": {
    destinationPath: "product.primaryGeographicRegion",
    action: (a: any[]) => a?.map(el => el.code) || []
  },
  "product.structure": {
    destinationPath: "product.structure",
    action: (a: any) => a?.code || null
  }
}

const reShapeClosedEndedAction = {
  "closedEnded.controlRights": {
    destinationPath: "closedEnded.controlRights",
    action: (a: any) => a?.code || null
  },
  "closedEnded.fundFocus": {
    destinationPath: "closedEnded.fundFocus",
    action: (a: any) => a?.code || null
  },
  // parse string to float
  "closedEnded.fundRaising": {
    destinationPath: "closedEnded.fundRaising",
    action: (a: any[]) =>
      a?.map(
        el =>
          ({ ...el, committedCapital: getNumValue(el.committedCapital) } || [])
      )
  }
}

const reShapePrivateCreditAction = {
  "privateCredit.targetInvestmentStyle": {
    destinationPath: "privateCredit.targetInvestmentStyle",
    action: (a: any[]) => a?.map(el => el.code) || []
  }
}

const reShapePrivateEquityAction = {
  "privateEquity.targetPrimaryGICSSectors": {
    destinationPath: "privateEquity.targetPrimaryGICSSectors",
    action: (a: any[]) => a?.map(el => el.code) || []
  },
  "privateEquity.targetInvestmentStyle": {
    destinationPath: "privateEquity.targetInvestmentStyle",
    action: (a: any[]) => a?.map(el => el.code) || []
  }
}
const reShapeRealAssetsActions = {
  "realAssets.primaryUSRegions": {
    destinationPath: "realAssets.primaryUSRegions",
    action: (a: any[]) => a?.map(el => el.code) || []
  },
  "realAssets.primaryRealAssetSectorsTarget": {
    destinationPath: "realAssets.primaryRealAssetSectorsTarget",
    action: (a: any[]) => a?.map(el => el.code) || []
  }
}

const reShapeHedgeFundActions = {
  "hedgeFund.primaryHedgeFundInvestmentStyle": {
    destinationPath: "hedgeFund.primaryHedgeFundInvestmentStyle",
    action: (a: any[]) => a?.map(el => el.code) || []
  },
  "hedgeFund.primaryHedgeFundSectorsTarget": {
    destinationPath: "hedgeFund.primaryHedgeFundSectorsTarget",
    action: (a: any[]) => a?.map(el => el.code) || []
  },
  "hedgeFund.primaryHedgeFundAssetType": {
    destinationPath: "hedgeFund.primaryHedgeFundAssetType",
    action: (a: any[]) => a?.map(el => el.code) || []
  },
  "hedgeFund.directionalBias": {
    destinationPath: "hedgeFund.directionalBias",
    action: (a: any) => a?.code
  }
}

/** add unique identifier for item in each field. e.g. multiple selections and table rows*/
const IdsNeedProperties = [
  { property: "product.materialChanges", sourceIds: ["date"] },
  { property: "openEnded.associatedSymbols", sourceIds: ["ticker", "cusip"] },
  { property: "realAssets.debtMaturitySchedule", sourceIds: ["year"] }
]
const addIds = (state: any) => {
  let newState = _.cloneDeep(state)
  IdsNeedProperties.forEach(element => {
    let ItemList = _.get(state, element.property)
    if (!_.isUndefined(ItemList)) {
      let newList = ItemList.map((el: any) => ({
        ...el,
        uniqueId: element.sourceIds.map(sourceId => el[sourceId]?.toString()).join("-")
      }))
      _.set(newState, element.property, newList)
    }
  })

  return newState
}

const getInitialState = (data: any) => {
  let { product } = data.product as Product

  let sortedMaterialChanges = orderBy(
    product?.materialChanges,
    ["date"],
    ["desc"]
  ) as Maybe<MaterialChangesToProduct>[] | null | undefined

  let newProduct = { ...product, materialChanges: sortedMaterialChanges } as
    | ProductFields
    | null
    | undefined

  let state = {
    __typename: data.product?.__typename,
    product: newProduct
  } as any

  const { openEnded } = data.product as OpenEnded
  const { closedEnded } = data.product as ClosedEnded
  const { hedgeFund } = data.product as HedgeFund
  const { privateEquity } = data.product as PrivateEquity
  const { privateCredit } = data.product as PrivateCredit
  const { realAssets } = data.product as RealAssets

  if (openEnded) {
    state.openEnded = openEnded
  } else if (closedEnded) {
    let sortedClosedEndedFundRaising = orderBy(
      closedEnded.fundRaising,
      ["date"],
      ["desc"]
    )

    let sortedClosedEndedProjectedCloseDates = orderBy(closedEnded.projectedCloseDates, (date: any) => {
      return moment(date).format('YYYYMMDD')
    }, ["asc"]);

    state.closedEnded = { ...closedEnded, fundRaising : sortedClosedEndedFundRaising,  projectedCloseDates: sortedClosedEndedProjectedCloseDates }
  }
  if (hedgeFund) {
    state.hedgeFund = hedgeFund
  } else if (privateEquity) {
    state.privateEquity = privateEquity
  } else if (privateCredit) {
    state.privateCredit = privateCredit
  } else if (realAssets) {
    state.realAssets = realAssets
  }

  const newState = addIds(state)
  return newState
}

/*
  to get all inputs for delete mutation methods.
  1. deleteMaterialChangesToProduct
  2. deleteAssociatedSymbols
  !important items below are not handled/ implemented yet.
  3. deleteDebtMaturitySchedule // seems no need to call this function
  4. deleteBlendedBenchmarkCode
  5. deleteProjectedCloseDates
  6. deleteInvestmentStyle
  7. deleteProductSectors
  8. deleteGeographicRegionCodes
  9. deleteUSRegionCodes
  10. deleteHedgeFundAssetType
*/
const DeletedProperties = [
  { property: "product.materialChanges", field: "date" },
  { property: "openEnded.associatedSymbols", field: "cusip", uniqueId: "uniqueId" },
  { property: "product.primaryGeographicRegion", field: "code" },
  { property: "realAssets.primaryUSRegions", field: "code" },
  { property: "hedgeFund.primaryHedgeFundSectorsTarget", field: "code" },
  { property: "privateEquity.targetPrimaryGICSSectors", field: "code" },
  { property: "realAssets.primaryRealAssetSectorsTarget", field: "code" },
]

const getDeletedInputs = (currentState: any, initialState: any) => {
  let result = {} as any

  DeletedProperties.forEach(element => {
    let initialArray = _.get(initialState, element.property) || []
    let currentArray = _.get(currentState, element.property) || []
    let difference = _.differenceBy(
      initialArray,
      currentArray,
      element.uniqueId || element.field
    ).map((el: any) => el[element.field])
    if (!_.isEmpty(difference)) {
      _.set(result, element.property, difference)
    }
  })
  return result
}

const getFilterMap = (props: {
  data: Maybe<{ code: any; assetMixNum: number }>[] | null | undefined
  assetMixNum: number
}) => {
  let { data, assetMixNum } = props
  if (!data || _.isEmpty(data)) {
    return {}
  }
  let filtered = data.filter(el => el && el.assetMixNum === assetMixNum) as {
    code: any
    assetMixNum: number
  }[]
  return filtered.reduce((acc, el) => ({ ...acc, [el.code]: true }), {})
}

const getInputs = (state: any) => {
  let newState = {
    ...(reShapeObject(state, reShapeProductActions) as any).product
  }
  newState = _.cloneDeep(newState)
  if (state.closedEnded) {
    newState = {
      ...newState,
      ...(reShapeObject(state, reShapeClosedEndedAction) as any).closedEnded
    }
  } else if (state.openEnded) {
    newState = {
      ...newState,
      ...state.openEnded
    }
  }
  if (state.privateEquity) {
    newState = {
      ...newState,
      ...(reShapeObject(state, reShapePrivateEquityAction) as any).privateEquity
    }
  } else if (state.privateCredit) {
    newState = {
      ...newState,
      ...(reShapeObject(state, reShapePrivateCreditAction) as any).privateCredit
    }
  } else if (state.hedgeFund) {
    newState = {
      ...newState,
      ...(reShapeObject(state, reShapeHedgeFundActions) as any).hedgeFund
    }
  } else if (state.realAssets) {
    newState = {
      ...newState,
      ...(reShapeObject(state, reShapeRealAssetsActions) as any).realAssets
    }
  }
  return newState
}

const ProductOverviewSummary: React.FC<{ productId: number, auth: Auth }> = props => {
  const { productId, auth } = props

  const { loading, error, data } = useProductSummaryQuery({
    variables: { id: productId },
    fetchPolicy: "no-cache",
  })
  const {
    loading: assetClassesLoading,
    error: assetClassesError,
    data: assetClasses
  } = useGetAllAssetClassesQuery()

  const {
    loading: optionsByAssetClassMapsLoading,
    error: optionsByAssetClassMapsError,
    data: optionsByAssetClassMaps
  } = useProductSummaryOptionsByAssetClassMapsQuery()

  if (loading || assetClassesLoading || optionsByAssetClassMapsLoading) {
    return (
      <div>
        <PlaceHolder />
      </div>
    )
  }
  if (error || assetClassesError || optionsByAssetClassMapsError) {
    return (
      <div>
        <p>
          {error?.message ||
            assetClassesError?.message ||
            optionsByAssetClassMapsError?.message}
        </p>
      </div>
    )
  }
  if (!data || !data.product) {
    return <p>Product Summary Data not found</p>
  }
  if (!assetClasses) {
    console.error("AssetClasses Data Not Found.")
  }
  if (!optionsByAssetClassMaps) {
    console.error("AssetClassMaps Data Not Found.")
  }
  return (
    <Display
      data={data}
      assetClasses={assetClasses}
      optionsByAssetClassMaps={optionsByAssetClassMaps}
      auth={auth}
    />
  )
}

const Display: React.FC<{
  data: ProductSummaryQuery
  assetClasses?: GetAllAssetClassesQuery
  auth: Auth
  optionsByAssetClassMaps?: ProductSummaryOptionsByAssetClassMapsQuery
}> = ({ data, assetClasses, optionsByAssetClassMaps, auth }) => {
  const [productType] = useState<{ [type: string]: boolean }>(
    getProductType(data as { product: Maybe<ProductType> })
  )

  const history = useHistory()
  const [currentState, setState] = useState(getInitialState(data))
  const getParentCode = useCallback(() => {
    let code = (currentState.product?.assetClass as AssetClass &
      CurrentAssetClassFragment).parent?.code
    if (_.isUndefined(code)) {
      // TODO what to return if undefined???
      return undefined
    } else {
      return Number(code)
    }
  }, [currentState.product])
  const [initialState, setInitial] = useState(currentState)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [updateProductSummary] = useUpdateProductOverviewSummaryMutation()
  // const [updateFileMutation] = useUpdateFileMetadataMutation()
  const [deleteMaterialChanges] = useDeleteProductMaterialChangesMutation()
  const [deleteAssociatedSymbols] = useDeleteAssociatedSymbolsMutation()
  const [deleteGeographicRegionCodes] = useDeleteGeographicRegionCodesMutation()
  const [deleteUsRegionCodes] = useDeleteUsRegionCodesMutation()
  const [deleteProductSectors] = useDeleteProductSectorsMutation()

  // to get parentAssetMixNum easier
  const [parentAssetMixNum, setParentAssetMixNum] = useState(getParentCode)
  const [childAssetMixNum] = useState(
    (currentState.product?.assetClass as AssetClass & CurrentAssetClassFragment)
      .code
  )

  const [targetInvestmentStyleFilterRules] = useState(() =>{
    const childAssetData = getFilterMap({
      data:
        optionsByAssetClassMaps?.assetClassMaps?.targetInvestmentStyleCodeAssetClassMap,
      assetMixNum: childAssetMixNum
    })
    if (_.isEmpty(childAssetData)){  // fallback to parent
      return getFilterMap({
        data:
          optionsByAssetClassMaps?.assetClassMaps?.targetInvestmentStyleCodeAssetClassMap,
        assetMixNum: parentAssetMixNum || 0
      })
    }
    return childAssetData
  })

  const [sectorByChildFilterRules] = useState(() => {
    const childAssetData = getFilterMap({
      data:
        optionsByAssetClassMaps?.assetClassMaps?.sectorByChildCodeAssetClassMap,
      assetMixNum: childAssetMixNum
    })
    if (_.isEmpty(childAssetData)){  // fallback to parent
      return getFilterMap({
        data:
          optionsByAssetClassMaps?.assetClassMaps?.sectorByChildCodeAssetClassMap,
        assetMixNum: parentAssetMixNum || 0
      })
    }
    return childAssetData
  })

  const [geographicRegionCodeFilterRules] = useState(() => {
    const childAssetData = getFilterMap({
      data:
        optionsByAssetClassMaps?.assetClassMaps?.geographicRegionCodeAssetClassMap,
      assetMixNum: childAssetMixNum
    })
    if (_.isEmpty(childAssetData)){  // fallback to parent
      return getFilterMap({
        data:
          optionsByAssetClassMaps?.assetClassMaps?.geographicRegionCodeAssetClassMap,
        assetMixNum: parentAssetMixNum || 0
      })
    }
    return childAssetData
  })

  let params1 = {
    variables: { asset_mix_num: parentAssetMixNum || 0 }
  }

  // const allowedSubtypes = [
  //   DocumentSubType._5, // DDQ Related
  //   DocumentSubType._6, // Product Updates
  //   DocumentSubType._7, // Presentations
  //   DocumentSubType._8, // Product Performance
  // ]

  // const filteredDocuments = compact(filter(currentState.product.documents, (doc) => { return (!doc?.type?.code || doc?.type?.code === DocumentType._1) && (!doc?.subType?.code || allowedSubtypes.includes(doc?.subType?.code))})) as FileBasicInfoFragment[]

  const {
    loading: benchmarkLoading,
    error: benchmarkError,
    data: benchmarkData,
    refetch: refetchBenchmarkOptions
  } = useProductBenchmarksOptionsQuery(params1)

  let config = {
    __typename: true,
    ...ProductOverviewSummaryConfig[data.product?.__typename || "product"]
  }

  let allAssetClassesData = useMemo(() =>
  assetClasses?.assetClasses ||
    ([] as ({
      __typename: "AssetClass"
      children: ({
        __typename: "AssetClass"
      } & AssetClassFragment)[]
    } & AssetClassFragment)[])
, [assetClasses?.assetClasses])

  // filter out total assets and total fund composite
  let allAssetClassesDataFiltered = allAssetClassesData.filter(
    el => !(el.code === 20 || el.code === 999)
  )
  const [assetClassesOptions] = useState(() =>
    GetLookupDataToOptions({
      data: sortBy(allAssetClassesDataFiltered, "order"),
      sort: false,
    })
  )

  // const getFloatFieldList = (input: FormInputField[], config: any) => {
  //   return input
  //     .filter(def => def.type === "float" && _.get(config, def.property))
  //     .map(def => def.property)
  // }

  const getChildrenAssetClasses = useCallback(
    (code: number | undefined) => {
      if (_.isUndefined(code)) {
        return <>{"No data found"}</>
      }
      let parent = allAssetClassesData.filter(
        assetClass => assetClass.code.toString() === code.toString()
      )[0]

      let children = parent?.children
      if (!children) {
        return <>Data Not Found</>
      }
      children = sortBy(children, "order")
      return GetLookupDataToOptions({
        data: children as AssetClassFragment[],
        sort: false
      })
    },
    [allAssetClassesData]
  )
  const getBenchmarkOptions = (
    data: GetLookupDataToOptionsProps["data"] | undefined
  ) => {
    if (_.isUndefined(data)) {
      return <>{"No data found"}</>
    }
    return GetLookupDataToOptions({
      data: data as GetLookupDataToOptionsProps["data"],
      placeholder: "Select Benchmark"
    })
  }
  const getPrimaryBenchmarkData = useCallback(() => {
    let result = {
      primaryBenchmark: currentState.product.primaryBenchmark
    } as any
    if (
      productType["hedgeFund"] ||
      (productType["openEnded"] &&
        productType["MAC"] &&
        !productType["passive"])
    ) {
      result.targetBenchmarkAdd = currentState.product.targetBenchmarkAdd
    }
    return result
  }, [
    currentState.product.primaryBenchmark,
    currentState.product.targetBenchmarkAdd,
    productType
  ])
  const [strategyOptions, setStrategyTypeId] = useState(
    getChildrenAssetClasses(getParentCode())
  )

  const [primaryBenchmarkData, setPrimaryBenchmarkData] = useState(
    getPrimaryBenchmarkData
  )

  useEffect(() => {
    setPrimaryBenchmarkData(getPrimaryBenchmarkData)
  }, [getPrimaryBenchmarkData])

  useEffect(() => {
    const newParentCode = getParentCode()
    refetchBenchmarkOptions()
    if (!_.isUndefined(newParentCode) && newParentCode !== parentAssetMixNum) {
      setParentAssetMixNum(newParentCode || 0)
      setStrategyTypeId(getChildrenAssetClasses(newParentCode))
      setPrimaryBenchmarkData(getPrimaryBenchmarkData)
    }
  }, [
    getPrimaryBenchmarkData,
    getParentCode,
    getChildrenAssetClasses,
    parentAssetMixNum,
    refetchBenchmarkOptions
  ])

  // const errorCheck = () => {
  //   let errorTracker = false
  //   currentState.product.documents.map((document:FileBasicInfoFragment) =>{
  //     if(!document.type?.code || !document.subType?.code){
  //       errorTracker = true
  //     }
  //   })
  //   if(errorTracker != hasError){
  //     setHasError(errorTracker)
  //   }
  // }

  // useEffect(() => {
  //   errorCheck()
  // }, [
  //   filteredDocuments
  // ])

  // useEffect(() => {
  //   setPrimaryBenchmarkData(getPrimaryBenchmarkData)
  // }, [
  //   currentState.product.primaryBenchmark,
  //   currentState.product.targetBenchmarkAdd,
  //   productType,
  //   getPrimaryBenchmarkData
  // ])

  if (benchmarkLoading) {
    // TODO line below triggers rerender when editing, editing content stays though.
    return (
      <>
        <PlaceHolder />
      </>
    )
  } else if (benchmarkError) {
    return <>{benchmarkError.message}</>
  }

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const getTermsTableData = (
    currentState: any,
    productType: { [type: string]: boolean }
  ) => {
    let result = {} as any
    let defaultValue = {
      low: null,
      high: null,
      target: null,
      explanation: null
    }
    TermsTableRowDef.forEach(row => {
      let { field } = row
      let productTypeForField = field.split(".")[0] || "product"
      if (productType[productTypeForField] && _.get(config, field)) {
        _.setWith(result, field, _.get(currentState, field) || defaultValue)
      }
    })
    return result
  }

  const handleSubmit = (event?: any) => {
    event?.preventDefault()
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    if(hasError){
      return
    }
    setSaving(true)
    const { id } = currentState.product

    const beforeState = _.cloneDeep(currentState)
    // only callan user can edit inactive
    let isCallanUser = auth.checkPermissions(["view:all_clients"])
    const beforeState2 = isCallanUser ?
    excludePropertyArray(beforeState, ["uniqueId", "documents"]): excludePropertyArray(beforeState, ["uniqueId", "documents", "inactive"])
    let state = omitState({ ...beforeState2 }, omitArray)
    if (state.__typename === "OpenEndedMAC") {
      state = reShapeObject(state, {
        "product.blendedBenchmark": {
          destinationPath: "product.blendedBenchmark",
          action: (a: any[]) =>
            a?.map(el => ({ ...el, percent: getNumValue(el.percent) } || []))
        }
      })
    } else if (state.__typename === "ClosedEndedRealAssets") {
      state = reShapeObject(state, {
        "realAssets.debtMaturitySchedule": {
          destinationPath: "realAssets.debtMaturitySchedule",
          action: (a: any[]) =>
            a?.map(el => ({ ...el, property: getNumValue(el.property) } || []))
        }
      })
    }
    let formattedState = formatNumberInputs(state, config)
    if(isCallanUser) {
      formattedState = reShapeObject(state, {
        "product.inactive": {
          destinationPath: "product.inactive",
          action: (a: string| boolean) =>!!(a === "true" || a === true)
        }
      })
    }
    let flattenedState = getInputs(formattedState) as any
    const newState = omitDeep(flattenedState, "__typename")
    const deletedInputs = getDeletedInputs(currentState, initialState)

    // Strip finalCommitSize, readonly
    _.unset(newState, "finalCommitSize")

    if (!_.isUndefined(_.get(deletedInputs, "product.materialChanges"))) {
      let input = {
        id,
        date: _.get(deletedInputs, "product.materialChanges")
      } as DeleteMaterialChangesToProductInput
      deleteMaterialChanges({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteMaterialChangesToProduct
        })
        .catch(err => {
          console.error(err.message)
        })
    }
    if (!_.isUndefined(_.get(deletedInputs, "openEnded.associatedSymbols"))) {
      let input = {
        id,
        cusip: _.get(deletedInputs, "openEnded.associatedSymbols")
      } as DeleteAssociatedSymbolsInput
      deleteAssociatedSymbols({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteAssociatedSymbols
        })
        .catch(err => {
          console.error(err.message)
        })
    }
    if (!_.isUndefined(_.get(deletedInputs, "product.primaryGeographicRegion"))) {
      let input = {
        id,
        regionCode: _.get(deletedInputs, "product.primaryGeographicRegion")
      } as DeleteGeographicRegionCodesInput

      const finalRegionCode = input.regionCode.filter((i) => i !== null)
      input = { id: input.id, regionCode: finalRegionCode }

        deleteGeographicRegionCodes({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteGeographicRegionCodes
        })
        .catch(err => {
          console.error(err.message)
        })
    }
    if (!_.isUndefined(_.get(deletedInputs, "realAssets.primaryUSRegions"))) {
      let input = {
        id,
        regionCode: _.get(deletedInputs, "realAssets.primaryUSRegions")
      } as DeleteUsRegionCodesInput
      deleteUsRegionCodes({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteUSRegionCodes
        })
        .catch(err => {
          console.error(err.message)
        })
    }
    if (!_.isUndefined(_.get(deletedInputs, "hedgeFund.primaryHedgeFundSectorsTarget"))) {
      let input = {
        id,
        sector: _.get(deletedInputs, "hedgeFund.primaryHedgeFundSectorsTarget")
      } as DeleteProductSectorsInput
      deleteProductSectors({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteProductSectors
        })
        .catch(err => {
          console.error(err.message)
        })
    }
    if (!_.isUndefined(_.get(deletedInputs, "privateEquity.targetPrimaryGICSSectors"))) {
      let input = {
        id,
        sector: _.get(deletedInputs, "privateEquity.targetPrimaryGICSSectors")
      } as DeleteProductSectorsInput
      deleteProductSectors({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteProductSectors
        })
        .catch(err => {
          console.error(err.message)
        })
    }
    if (!_.isUndefined(_.get(deletedInputs, "realAssets.primaryRealAssetSectorsTarget"))) {
      let input = {
        id,
        sector: _.get(deletedInputs, "realAssets.primaryRealAssetSectorsTarget")
      } as DeleteProductSectorsInput
      deleteProductSectors({ variables: { input } })
        .then(result => {
          const data = result.data?.deleteProductSectors
        })
        .catch(err => {
          console.error(err.message)
        })
    }

    // const diffDocuments = currentState.product.documents.reduce((diff:FileBasicInfoFragment[], document:FileBasicInfoFragment, idx:number) => {
    //   if(JSON.stringify(document) != JSON.stringify(initialState.product.documents[idx])){
    //     return diff.concat([document])
    //   }
    //   return diff as FileBasicInfoFragment[]
    // }, [] as FileBasicInfoFragment[])

    // diffDocuments.map((document:FileBasicInfoFragment) => {
    //   let formattedPatch = convertLookupToString(
    //     document,
    //     false,
    //   )
    //   let updateData = excludePropertyArray(formattedPatch, ["__typename", "name", "id", "versions", "body", "mimeType", "versionId", "url","managers","plans","products","vehicles","glidePaths", "access"])
    //   const input = { id: document.id, patch: updateData } as UpdateFileMetadataInput
    //   updateFileMutation({ variables: { input } })
    // })

    let patch = { ...newState }
    const input = {
      id,
      patch
    }
    updateProductSummary({ variables: { input } })
      .then(result => {
        setSaving(false)
        if (result.data?.updateProductOverviewSummary) {
          let newData = getInitialState(
            result.data?.updateProductOverviewSummary
          )
          setInitial(newData)
          setState(newData)
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.error(err.message)
      })
  }

  const handleInputChange = (
    value: any,
    property: string,
    type: string = "string"
  ) => {
    let parseValue: any
    switch (type) {
      case "string":
        parseValue = value
        break
      default:
        parseValue = value
        break
    }
    let newState = getNewStateObject({
      state: currentState,
      newValue: parseValue,
      property,
      type
    }) as typeof currentState

    if (property === "product.assetClass.parent.code") {
      // reset strategy Type to null if parentCode changed.
      newState = getNewStateObject({
        state: newState,
        newValue: null,
        property: "product.assetClass.code",
        type
      }) as typeof currentState
    }
    setState(newState)
  }

  const handlePrimaryBenchmarkComponentChange = (value: any) => {
    const newState = getNewStateObject({
      state: currentState,
      newValue: value.primaryBenchmark,
      property: "product.primaryBenchmark",
      type: "select"
    }) as typeof currentState
    const result = getNewStateObject({
      state: newState,
      newValue: value.targetBenchmarkAdd,
      property: "product.targetBenchmarkAdd",
      type: "float"
    }) as typeof currentState
    setState(result)
  }

  /* special handler for term table*/
  const handleChangeTermsTable = (newValue: any, property: string) => {
    let newState = getNewStateObject({
      state: currentState,
      newValue,
      property
    })
    setState(newState)
  }

  const handleChangeTableList = (newTable: any[], property: string) => {
    let propertyArray = property.split(".")
    let singleProperty = propertyArray.length === 1
    let lastProperty = singleProperty
      ? property
      : propertyArray[propertyArray.length - 1]
    let frontProperties = singleProperty
      ? ""
      : property.slice(0, -lastProperty.length - 1)
    let newState
    if (!frontProperties) {
      newState = { ...currentState, [property]: newTable }
    } else {
      let path = frontProperties.split(".")
      let oldState = _.cloneDeep(currentState)
      if(!_.get(oldState, path)){
        _.set(oldState, path, {})
      }
      newState = iassign(
        oldState,
        path,
        state => ({
          ...(state || {}),
          [lastProperty]: newTable
        })
      )
    }
    setState(newState)
  }

  // const handleDocumentChange = (document:FileBasicInfoFragment, property:string, value:any) => {
  //   let newState = iassign(
  //     currentState,
  //     currentState => _.get(currentState, 'product.documents'),
  //     (rows) => {
  //       let rowsClone = _.cloneDeep(rows)
  //       const idx = _.findIndex(rowsClone, {'id': document.id})
  //       rowsClone = iassign(
  //         rowsClone,
  //         rowsClone => _.get(rowsClone, "["+idx+"]."+property),
  //         () => value
  //       )
  //       if(property === "type.code"){
  //         rowsClone = iassign(
  //           rowsClone,
  //           (newState) => _.get(newState, "["+idx+"].subType.code"),
  //           () => null
  //         )
  //       }
  //       return rowsClone
  //     }
  //   )
  //   setState(newState)
  // }

  // const addDocument = (document:FileBasicInfoFragment) => {
  //   let newState = iassign(
  //     currentState,
  //     currentState => _.get(currentState, 'product.documents'),
  //     (rows) => {
  //       let rowsClone = _.cloneDeep(rows)
  //       rowsClone.push(document)
  //       return rowsClone
  //     }
  //   )
  //   setState(newState)
  // }

  // const removeDocument = (document:FileBasicInfoFragment) => {
  //   let newState = iassign(
  //     currentState,
  //     currentState => _.get(currentState, 'product.documents'),
  //     (rows) => {
  //       let rowsClone = _.cloneDeep(rows)
  //       const idx = _.findIndex(rowsClone, {'id': document.id})
  //       rowsClone?.splice(idx, 1)
  //       return rowsClone
  //     }
  //   )
  //   setState(newState)
  // }

  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables()}>
                  Export CSV
                  <img src='/assets/CSV.svg' className="ml-2"/>
                </Button>
              {auth.checkPermissions(["edit:manager"]) &&
                <EditButtons
                  editMode={editMode}
                  setEditMode={setEditMode}
                  saving={saving}
                  onSubmit={handleSubmit}
                  cancelEdit={() => setState(initialState)}
                />
              }
              </div>
              <div className="pane mb-4">
                <Row className="justify-content-between">
                  <Col sm="5" className="px-3 exportable-form" data-export-name= "ProductDetails">
                    {ProductFieldsInput.map(
                      (
                        {
                          property,
                          Component,
                          label,
                          type,
                          subtype,
                          placeholder,
                          optionSource,
                          optionSourceFunction,
                          subClasses,
                          readonly,
                          required,
                          noFormat,
                          tooltip,
                        },
                        idx
                      ) => {
                        if (!subClasses) {
                          if (type === "number") {
                            subClasses = {
                              labelClasses: "col-sm-10",
                              inputWrapperClasses: "col-sm-2"
                            }
                          } else if (
                            type === "select" &&
                            subtype === "multiple"
                          ) {
                            subClasses = {
                              labelClasses: "col-sm-5 legend-multiple",
                              inputWrapperClasses: "col-sm-6 my-2"
                            }
                          } else {
                            subClasses = {
                              labelClasses: "col-sm-5",
                              inputWrapperClasses: "col-sm-6"
                            }
                          }
                        }
                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        let hidden: boolean = false
                        let optionFilterRule = {}
                        if (property !== "") {
                          if (property.endsWith("code")) {
                            hidden = !_.get(config, property.slice(0, -5))
                          } else {
                            hidden = !_.get(config, property)
                          }
                        }

                        if(property === "product.inactive") {
                          propertyVal = propertyVal && propertyVal !== "false" ? "true": "false"
                          // editable only for Callan User
                          readonly = !auth.checkPermissions(["view:all_clients"])
                        }

                        if (hidden) {
                          // TODO comment out below and return the other after test.
                          // return (
                          //   <p className="text-info font-italic" key={idx}>
                          //     Hide {property}
                          //   </p>
                          // )
                          return <Fragment key={idx}></Fragment>
                        }
                        if (type === "table") {
                          switch (property) {
                            case "product.blendedBenchmark":
                            case "realAssets.debtMaturitySchedule":
                            case "closedEnded.fundRaising":
                            case "closedEnded.projectedCloseDates":
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    {label && (
                                      <div
                                        className={
                                          "row form-section-title headline underline small-font-size py-2 mb-2"
                                        }
                                        key={`${idx}-0`}
                                      >
                                        {label}
                                      </div>
                                    )}
                                    {Component && (
                                      <Component
                                        data={propertyVal || []}
                                        editMode={editMode}
                                        handleChange={(newState: any[]) =>
                                          handleChangeTableList(
                                            newState,
                                            property
                                          )
                                        }
                                        key={`${idx}-1`}
                                      />
                                    )}
                                  </Fragment>
                                )
                              )
                            case "product.primaryBenchmark":
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    {Component && (
                                      <Component
                                        data={primaryBenchmarkData}
                                        options={getBenchmarkOptions(
                                          benchmarkData?.productBenchmarks as GetLookupDataToOptionsProps["data"]
                                        )}
                                        hasTargetAdded={
                                          productType["hedgeFund"] ||
                                          (productType["openEnded"] &&
                                            productType["MAC"] &&
                                            !productType["passive"])
                                        }
                                        editMode={editMode}
                                        handleChange={(value: any) => {
                                          handlePrimaryBenchmarkComponentChange(
                                            value
                                          )
                                        }}
                                        key={`${idx}-1`}
                                      />
                                    )}
                                  </Fragment>
                                )
                              )
                            default:
                              return <Fragment key={idx}></Fragment>
                          }
                        }
                        let options: any
                        if (type === "select") {
                          if (optionSourceFunction) {
                            switch (property) {
                              case "product.inactive":
                                // required field, no default option
                                options = ActiveBooleanOptions
                                break
                              case "product.assetClass.parent.code":
                                options = assetClassesOptions
                                break
                              case "product.assetClass.code":
                                options = strategyOptions
                                break
                              default:
                                break
                            }
                          } else {
                            switch (optionSource) {
                              // hide these fields if there are no options for this asset mix
                              case "SectorByChildCode":
                                optionFilterRule = sectorByChildFilterRules
                                hidden = hidden || _.isEmpty(optionFilterRule)
                                break
                              case "GeographicRegionCode":
                                optionFilterRule = geographicRegionCodeFilterRules
                                hidden = hidden || _.isEmpty(optionFilterRule)
                                break
                              case "InvestmentStyleCode":
                                optionFilterRule = targetInvestmentStyleFilterRules
                                hidden = hidden || _.isEmpty(optionFilterRule)
                                break
                              default:
                                break
                            }
                          }
                        }

                        return (
                          !hidden && (
                            <div key={idx}>
                              <FormInput
                                key={idx}
                                property={property}
                                displayName={label}
                                subClasses={subClasses}
                                type={type}
                                subtype={subtype}
                                placeholder={placeholder}
                                idx={idx}
                                editMode={editMode}
                                propertyVal={propertyVal}
                                updateValue={onChangeCallback}
                                optionSource={optionSource}
                                optionFilterRule={
                                  !editMode && !propertyVal ? (
                                    <option key={-1} value="">
                                      empty
                                    </option>
                                  ) : (
                                    optionFilterRule
                                  )
                                }
                                options={options}
                                readonly={readonly}
                                required={required}
                                tooltip={tooltip}
                                defaultOptions={propertyVal}
                              />
                            </div>
                          )
                        )
                      }
                    )}
                  </Col>
                  <Col sm="6">
                    {RightPaneTables.map(
                      (
                        {
                          property,
                          label,
                          type,
                          Component,
                          subClasses,
                          subtype,
                          placeholder,
                          readonly,
                          noFormat,
                          tooltip,
                          exportTableName
                        },
                        idx
                      ) => {
                        if (!subClasses) {
                          if (type === "number") {
                            subClasses = {
                              labelClasses: "col-sm-8",
                              inputWrapperClasses: "col-sm-3"
                            }
                          } else if (type === "float") {
                            subClasses = {
                              labelClasses: "col-sm-8",
                              inputWrapperClasses: "col-sm-3"
                            }
                          } else {
                            subClasses = {
                              labelClasses: "col-sm-5",
                              inputWrapperClasses: "col-sm-6"
                            }
                          }
                        }
                        let propertyVal: any = _.get(currentState, property)
                        let hidden: boolean = false
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        if (property !== "") {
                          if (property.endsWith("code")) {
                            hidden = !_.get(config, property.slice(0, -5))
                          } else {
                            hidden = !_.get(config, property)
                          }
                        }

                        if (hidden) {
                          return <Fragment key={idx}></Fragment>
                        }

                        if (type === "table") {
                          switch (property) {
                            case "product.materialChanges":
                            case "openEnded.associatedSymbols":
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    {label && (
                                      <div
                                        className={
                                          "row form-section-title headline underline small-font-size py-2 mb-2"
                                        }
                                        key={`${idx}-0`}
                                      >
                                        {label}
                                      </div>
                                    )}
                                    {Component && (
                                      <Component
                                        data={propertyVal || []}
                                        editMode={editMode}
                                        handleChange={(newState: any[]) =>
                                          handleChangeTableList(
                                            newState,
                                            property
                                          )
                                        }
                                        key={`${idx}-1`}
                                      />
                                    )}
                                  </Fragment>
                                )
                              )
                            case "":
                              if (
                                !(
                                  productType["closedEnded"] ||
                                  productType["privateEquity"] ||
                                  productType["realAssets"]
                                ) || productType["hedgeFund"]
                              ) {
                                return <Fragment key={`${idx}`}></Fragment>
                              }
                              let tableData = getTermsTableData(
                                currentState,
                                productType
                              )
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    <div
                                      className={
                                        "row form-section-title headline underline small-font-size py-2 mb-2"
                                      }
                                      key={`${idx}-0`}
                                    >
                                      {label}
                                    </div>
                                    <TermsTableDisplay
                                      data={tableData || []}
                                      productType={productType}
                                      editMode={editMode}
                                      handleChange={(
                                        newState: any,
                                        property: string
                                      ) =>
                                        handleChangeTermsTable(
                                          newState,
                                          property
                                        )
                                      }
                                      key={`${idx}-1`}
                                    />
                                  </Fragment>
                                )
                              )
                            default:
                              return <text>Not found Table</text>
                          }
                        }
                        return (
                          !hidden && (
                            <div className="exportable-form" data-export-name={exportTableName} key={idx}>
                              <FormInput
                                key={idx}
                                property={property}
                                displayName={label}
                                subClasses={subClasses}
                                type={type}
                                subtype={subtype}
                                placeholder={placeholder}
                                idx={idx}
                                editMode={editMode}
                                propertyVal={propertyVal}
                                updateValue={onChangeCallback}
                                readonly={readonly}
                                tooltip={tooltip}
                              />
                            </div>
                          )
                        )
                      }
                    )}
                    {/* (auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["edit:documents"])) &&
                      <DocumentCompactList
                        associationId={currentState.product.id}
                        associationType="Product"
                        documents={filteredDocuments}
                        auth={auth}
                        removeDocument={removeDocument}
                        editMode={editMode}
                        updateValue={handleDocumentChange}
                        forceValues={{type: DocumentType._1, access: DocumentAccess._1}}
                        addDocument={addDocument}
                        allowedSubtypes={allowedSubtypes}
                        allowedTypes={[DocumentType._1]}
                      />
                    */}
                  </Col>
                  <Col sm="12">
                    {[MaterialChangesTable].map(
                      ({ property, label, type, Component }, idx) => {
                        let propertyVal: any = _.get(currentState, property)
                        let hidden: boolean = false
                        if (property !== "") {
                          if (property.endsWith("code")) {
                            hidden = !_.get(config, property.slice(0, -5))
                          } else {
                            hidden = !_.get(config, property)
                          }
                        }

                        if (hidden) {
                          return <Fragment key={idx}></Fragment>
                        }

                        if (type === "table") {
                          switch (property) {
                            case "product.materialChanges":
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    {label && (
                                      <div
                                        className={
                                          "row form-section-title headline underline small-font-size py-2 mb-2"
                                        }
                                        key={`${idx}-0`}
                                      >
                                        {label}
                                      </div>
                                    )}
                                    {Component && (
                                      <Component
                                        data={propertyVal || []}
                                        editMode={editMode}
                                        handleChange={(newState: any[]) =>
                                          handleChangeTableList(
                                            newState,
                                            property
                                          )
                                        }
                                        key={`${idx}-1`}
                                      />
                                    )}
                                  </Fragment>
                                )
                              )
                            case "":
                              if (
                                !(
                                  productType["closedEnded"] ||
                                  productType["privateEquity"] ||
                                  productType["realAssets"]
                                ) || productType["hedgeFund"]
                              ) {
                                return <Fragment key={`${idx}`}></Fragment>
                              }
                              let tableData = getTermsTableData(
                                currentState,
                                productType
                              )
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    <div
                                      className={
                                        "row form-section-title headline underline small-font-size py-2 mb-2"
                                      }
                                      key={`${idx}-0`}
                                    >
                                      {label}
                                    </div>
                                    <TermsTableDisplay
                                      data={tableData || []}
                                      productType={productType}
                                      editMode={editMode}
                                      handleChange={(
                                        newState: any,
                                        property: string
                                      ) =>
                                        handleChangeTermsTable(
                                          newState,
                                          property
                                        )
                                      }
                                      key={`${idx}-1`}
                                    />
                                  </Fragment>
                                )
                              )
                            default:
                              return <text>Not found Table</text>
                          }
                        }
                      }
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export default ProductOverviewSummary
