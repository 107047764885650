import { gql } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { default as classNames } from 'classnames'
import { compact, get, isEqual, pick, sortBy } from 'lodash'
import React, { useEffect } from 'react'
import { Alert, Button, ButtonDropdown, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledTooltip } from 'reactstrap'

import { BulkUpdateComponentApprovalsInput, ClientPortfolioDetailComponentFragment, ComponentApprovalCode, ReportListHeaderFragment, ReportsListClientPortfolioFragment, UpdateComponentInput, useBulkUpdateComponentApprovalsMutation, useComponentMonthCacheQuery, useUpdateComponentApprovalMutation } from '../../../__generated__/graphql'
import { ReportStateType } from './ReportMain'


interface ReportEditApprovalsProps {
  allComponents: ClientPortfolioDetailComponentFragment[]
  portfolio: ReportsListClientPortfolioFragment | ReportListHeaderFragment
  fullReportState: ReportStateType
}

export const ReportApprovals: React.FC<ReportEditApprovalsProps> = ({ allComponents, portfolio, fullReportState }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [bulkUpdateComponentApprovals] = useBulkUpdateComponentApprovalsMutation()
  const [saving, setSaving] = React.useState(false)
  const [unapprovableComponents, setUnapprovableComponents] = React.useState<ClientPortfolioDetailComponentFragment[]>([])

  // console.log({ fullReportState })
  const componentIds = allComponents.map(component => component?.id || 0)
  const componentReportStates = pick(fullReportState, componentIds)
  const allLoaded = Object.values(componentReportStates).every(state => !state.loading)
  console.log({ componentIds, componentReportStates, allLoaded })
  const erroredComponents = Object.values(componentReportStates).filter(state => state.errored)
  const anyErrors = erroredComponents.length > 0

  const { data, loading, error, startPolling, stopPolling } = useComponentMonthCacheQuery({
    variables: { ids: allComponents.map(component => component?.id || 0) },
    pollInterval: 2000,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  })

  useEffect(() => {
    const calculatedUnapprovableComponents = allComponents.filter(component => {
      if(!component){
        return true
      }

      const reportState = componentReportStates[component.id]
      if (reportState?.loading || reportState?.errored) {
        return true
      }

      const fetchedCachedDates = data?.components.find(cachedComponent => cachedComponent?.id === component.id)?.cachedDates || []

      if (component.type === "AssetAllocation" || component.type === "AssetDistribution" || component.type === "ManagerPerformance") {
        if (!get(component,'draftSettings.monthlyOptions.show', false)) return false

        const monthlyDates = fetchedCachedDates.filter(cache => cache?.quarter === false).map(cache => cache?.date)
        const primaryDates = fetchedCachedDates.filter(cache => cache?.quarter).map(cache => cache?.date)

        console.log(component.name, { fetchedCachedDates, monthlyDates, primaryDates, draftSettings: component.draftSettings })

        if (!isEqual(sortBy(get(component, 'draftSettings.monthlyOptions.dates', [])), sortBy(monthlyDates || []))) {
          return true
        }

        return !primaryDates.includes(get(component, 'draftSettings.date', null))
      }

      return false
    })

    if (!isEqual(calculatedUnapprovableComponents, unapprovableComponents)) {
      setUnapprovableComponents(calculatedUnapprovableComponents)
    }
  }, [allComponents, data, fullReportState])

  useEffect(() => {
    startPolling(2000)
  }, [allComponents])

  // Ensure all unapprovable components are marked for review.
  useEffect(() => {
    if (unapprovableComponents.length > 0 && !loading) {
      const changedComponents = unapprovableComponents.filter(component => component?.approval?.code === ComponentApprovalCode._3)

      if (changedComponents.length > 0) {
        setSaving(true)
        const updateData = {
          components: compact(changedComponents.map((component, idx) => {
            return component?.id || undefined
          })),
          approval: ComponentApprovalCode._1,
        } as BulkUpdateComponentApprovalsInput

        bulkUpdateComponentApprovals({ variables: { input: updateData, liveView: false, draftView: true} })
          .then(result => {
            setSaving(false)
          })
          .catch(err => {
            setSaving(false)
            console.log("Error Component Save", err.message)
          })
      }
    }
  }, [unapprovableComponents])



  let name = ""
  if(portfolio.__typename === "ClientPortfolio"){
    name = portfolio.name || ""
  } else {
    name = portfolio.text || ""
  }

  const allComponentsApprovable = unapprovableComponents.length === 0
  console.log({ allComponentsApprovable, unapprovableComponents })
  if (allComponentsApprovable) {
    stopPolling()
  }

  const handleBulkUpdate = (approvalCode: ComponentApprovalCode) => {
    if(saving){
      return
    }
    setSaving(true)


    const updateData = {
      components: compact(allComponents.map((component, idx) => {
        return component?.id || undefined
      })),
      approval: approvalCode,
    } as BulkUpdateComponentApprovalsInput

    bulkUpdateComponentApprovals({ variables: { input: updateData, liveView: false, draftView: true} })
      .then(result => {
        setSaving(false)
      })
      .catch(err => {
        setSaving(false)
        console.log("Error Component Save", err.message)
      })
  }

  const checkComponentApproval = (component: ClientPortfolioDetailComponentFragment) => {
    const reportState = componentReportStates[component.id]
    if (reportState?.loading || reportState?.errored) {
      return false
    }

    if (component.type !== "AssetAllocation" && component.type !== "AssetDistribution" && component.type !== "ManagerPerformance") { return true }

    const fetchedCachedDates = data?.components.find(cachedComponent => cachedComponent?.id === component.id)?.cachedDates || []

    const monthlyDates = fetchedCachedDates.filter(cache => cache?.quarter === false).map(cache => cache?.date)
    const primaryDates = fetchedCachedDates.filter(cache => cache?.quarter).map(cache => cache?.date)

    console.log({ monthlyDates, primaryDates, draftSettings: component.draftSettings})
    if (!primaryDates.includes(get(component, 'draftSettings.date', null))) {
      return false
    }

    if (get(component,'draftSettings.monthlyOptions.show', false)) {
      return isEqual(sortBy(get(component, 'draftSettings.monthlyOptions.dates', [])), sortBy(monthlyDates || []))
    }

    return true
  }

  const approvalErrorText = () => {
    if (!allLoaded) {
      return "All components need to be loaded before approval."
    }

    if (erroredComponents.length === unapprovableComponents.length) {
      return "The following components contain errors which need to be fixed before approval:"
    }

    if (erroredComponents.length !== unapprovableComponents.length && anyErrors) {
      return "All tabs need to be inspected and all errors fixed on the following components before approval:"
    }

    return "The following components need all tabs to be inspected before approval:"
  }

  return(
    <>
      <div className='background-gray-10 pl-2 py-1'>
        <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} color="light btn-thin" className="mr-1 text-callan-blue">
          <DropdownToggle caret>
            Set Status
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleBulkUpdate(ComponentApprovalCode._1)}>
              <div className="simple-icon-circle blank d-inline-block mr-2">
                <FontAwesomeIcon icon={"times"} size={"xs"} className="w-inherit"/>
              </div>
              {ComponentApprovalCodeMapping[ComponentApprovalCode._1]}
            </DropdownItem>
            <DropdownItem onClick={() => handleBulkUpdate(ComponentApprovalCode._2)}>
              <div className="simple-icon-circle dotted d-inline-block mr-2">
                <FontAwesomeIcon icon={"check"} size={"xs"} className="w-inherit"/>
              </div>
              {ComponentApprovalCodeMapping[ComponentApprovalCode._2]}
            </DropdownItem>
             <DropdownItem onClick={() => handleBulkUpdate(ComponentApprovalCode._3)} disabled={!allComponentsApprovable || loading || !allLoaded || anyErrors }>
              <div className="simple-icon-circle solid d-inline-block mr-2">
                <FontAwesomeIcon
                  icon={"check"}
                  size={"xs"}
                  className="text-inherit w-inherit"
                />
              </div>
              {ComponentApprovalCodeMapping[ComponentApprovalCode._3]}
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        { !allComponentsApprovable && (
          <Alert color="warning" className="mt-1 p-2 flat mr-2 pb-1">
            { loading && (<FontAwesomeIcon icon="spinner" spin className='text-white float-right'/>)}

            {approvalErrorText()}
            <ul className='ml-0 pl-4 mb-1'>
              {unapprovableComponents.map((component, idx) => (
                <li key={`unapprovable-component-${idx}`}>{component?.name}</li>
              ))}
            </ul>
          </Alert>
        )}
      </div>
      <div className='background-gray-10'>
        {name}
      </div>
        {allComponents?.map((component, idx) => {
          if(!component){
            return (
              <React.Fragment key={idx} />
            )
          }

          const approvable = checkComponentApproval(component)

          return (
            <ApprovalsReportEdit
              key={component.id}
              value={component.approval?.code || undefined}
              name={component.name || undefined}
              componentId={component.id}
              approvable={approvable}
            />
          )
        })}
    </>
  )
}

interface ApprovalsReportEditProps {
  handleInputChange?: (value: any) => void
  value?: ComponentApprovalCode
  name?: string
  componentId: number
  approvable?: boolean
}

const ComponentApprovalCodeMapping:{[key: string]: string} = {
  '_1': 'Needs Review',
  '_2': 'Reviewed',
  '_3': 'Approved'
}

export const ApprovalsReportEdit: React.FC<ApprovalsReportEditProps> = ({value, handleInputChange, name, children, componentId, approvable = true}) => {
  const [updateComponentApprovalMutation] = useUpdateComponentApprovalMutation()
  const [saving, setSaving] = React.useState(false)
  const approveRef = React.useRef(null)

  const handleChange = handleInputChange ? handleInputChange : (value: any) => {
    if(saving){
      return
    }
    // setSaving(true)
    const updateData = {
      id: componentId,
      patch: {
        approval: value,
      }
    } as UpdateComponentInput

    updateComponentApprovalMutation({
      variables: { input: updateData },
      optimisticResponse: {
        updateComponent: {
          component: {
            id: componentId,
            approval: {
              code: value,
              value: ComponentApprovalCodeMapping[value],
              __typename: "ComponentApprovalLookup",
            },
            __typename: "Component",
          },
          __typename: "ComponentPayload",
        },
        __typename: 'Mutation',
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `Component:${componentId}`,
          fragment: gql`
            fragment ComponentApproval on Component {
              id
              approval {
                code
                value
              }
            }
          `,
          data: {
            id: componentId,
            approval: {
              code: value,
              value: ComponentApprovalCodeMapping[value],
            },
            __typename: "Component",
          },
        });
      }, })
      .then(result => {
        // setSaving(false)
      })
      .catch(err => {
        // setSaving(false)
        console.log("Error Component Save", err.message)
      })
  }

  return (
    <div className={classNames("client-report-edit-template")}>
      <Row className="justify-content-between">
        <Col sm={6}>
          <div
            className={classNames("client-report-approvals-title p-2 my-1", {
              "needs-review": value === ComponentApprovalCode._1,
              reviewed: value === ComponentApprovalCode._2,
              approved: value === ComponentApprovalCode._3,
            })}
          >
            {name || "Approval"}
          </div>
        </Col>
        <Col sm={6} className="align-self-center">
          <ButtonGroup className="">
            <Button
              color={value === ComponentApprovalCode._1 ? "primary" : "light"}
              onClick={() => handleChange(ComponentApprovalCode._1)}
              className="p-2 btn-approval"
              disabled={saving}
            >
              <div className="simple-icon-circle blank">
                <FontAwesomeIcon icon={"times"} size={"xs"} />
              </div>
            </Button>
            <Button
              color={value === ComponentApprovalCode._2 ? "primary" : "light"}
              onClick={() => handleChange(ComponentApprovalCode._2)}
              className="p-2 btn-approval"
              disabled={saving}
            >
              <div className="simple-icon-circle dotted">
                <FontAwesomeIcon icon={"check"} size={"xs"} />
              </div>
            </Button>
            <Button
              color={value === ComponentApprovalCode._3 ? "approval1" : "light"}
              onClick={() => handleChange(ComponentApprovalCode._3)}
              className="p-2 btn-approval"
              disabled={saving || !approvable }
            >
              <div className="simple-icon-circle solid" ref={approveRef}>
                <FontAwesomeIcon
                  icon={"check"}
                  size={"xs"}
                  className="text-inherit"
                />
              </div>
              { !approvable && (<UncontrolledTooltip placement='top' target={approveRef} delay={200} autohide={false}>
                All tabs need to be inspected before approval.
              </UncontrolledTooltip>)}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  )
}

interface ApprovalsReportDisplayProps {
  value?: ComponentApprovalCode
  name?: string
}

export const ApprovalsReportDisplay: React.FC<ApprovalsReportDisplayProps> = ({value, name}) => {
  return (
    <div className={classNames("client-report-edit-template")}>
      <Row className="justify-content-between">
        <Col sm={4}>
          <div
            className={classNames("client-report-approvals-title p-2 my-1", {
              "needs-review": value === ComponentApprovalCode._1,
              reviewed: value === ComponentApprovalCode._2,
              approved: value === ComponentApprovalCode._3,
            })}
          >
            {name || "Approval"}
          </div>
        </Col>
        <Col sm={8} className="align-self-center">
            {value === ComponentApprovalCode._1 &&
              <div>
                <div className="simple-icon-circle blank d-inline-block mr-2">
                  <FontAwesomeIcon icon={"times"} size={"xs"} className="w-inherit"/>
                </div>
                Needs Review
              </div>
            }
            {value === ComponentApprovalCode._2 &&
              <div>
                <div className="simple-icon-circle dotted d-inline-block mr-2">
                  <FontAwesomeIcon icon={"check"} size={"xs"} className="w-inherit"/>
                </div>
                Reviewed
              </div>
            }
            {value === ComponentApprovalCode._3 &&
              <div>
                <div className="simple-icon-circle solid d-inline-block mr-2">
                  <FontAwesomeIcon
                    icon={"check"}
                    size={"xs"}
                    className="text-inherit w-inherit"
                  />
                </div>
                Approved
              </div>
            }
        </Col>
      </Row>
    </div>
  )
}