import { useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { History } from 'history'
import iassign from 'immutable-assign'
import { cloneDeep, compact, filter, find, findIndex, first, flatMap, forEach, get, indexOf, isArray, isEqual, lowerFirst, mapValues, mergeWith, remove, set, uniq } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Auth from '../../../Auth/Auth'
import { appDate } from '../../../Context/CalendarContext'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { TemporaryAlertContext } from '../../../Context/TemporaryAlertContext'
import { BulkUpdateComponentApprovalsInput, ClientPlanOrderFragment, ClientPortfolioDetailComponentFragment, ClientPortfolioDetailLayoutFragment, ClientPortfolioDetailSectionFragment, ClientReportOrderFragment, Component, ComponentApprovalCode, ComponentOverrideSettings, ComponentReportUsage, ComponentSettingsInput, ComponentType, CreateComponentInput, ListMemberIdType, MeFragment, PublishLayoutArgs, ReportCategoryCode, ReportsFragment, ReportsListFragment, ReportsListFragmentDoc, ReportsListMemberFragment, UpdateComponentInput, UpdateListInput, UpdateReportInput, UpsertLayoutArgs, useBulkUpdateComponentApprovalsMutation, useClearReportComponentCacheMutation, useClientAllReportsLazyQuery, useCreateComponentMutation, useDeleteComponentMutation, useExportReportLazyQuery, usePublishReportMutation, usePushAllComponentDatesMutation, useSetAllComponentDatesMutation, useUpdateComponentMutation, useUpdateListMutation, useUpdatePlanOrderMutation, useUpdateReportMutation, useUpdateReportOrderMutation, useUpsertLayoutMutation } from '../../../__generated__/graphql'
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { downloadWithFilename } from '../../../helpers/download'
import { typeIdMapping } from '../../../helpers/list'
import { convertLookupToString, excludePropertyArray } from '../../../helpers/object'
import { convertComponentForMutation, expandReportList, listReportExpanded, recursivelyOrderReport } from '../../../helpers/report'
import { registerRecentReport } from '../../../helpers/session'
import RouteLeavingGuard, { GuardModal, ModalScript } from '../../Shared/RouteLeavingGuard'
import { EditButtons } from '../../ui/EditButtons'
import { FormInput } from '../../ui/Forms/FormInput'
import { AssetAllocationReportState } from '../Components/AssetAllocation'
import { AssetDistributionReportState } from '../Components/AssetDistribution'
import { AttributionComponentState } from '../Components/Attribution'
import { ManagerPerformanceComponentState } from '../Components/ManagerPerformance'
import { PerformanceComparisonComponentState } from '../Components/PerformanceComparison'
import { ReportAddable } from './ReportAddable'
import { ExportSettingsMapping, ReportDisplayType, ReportTab, selectedComponentProp } from './ReportComponent'
import ReportEdit from './ReportEdit'
import ReportSection, { SectionMapping } from './ReportSection'
import ReportSidebar, { SidebarOrderModal } from './ReportSidebar'

type idProps = {
  clientId: number
  reportId: number
  history: History
  auth: Auth
  portfolioId?: number
  user?: MeFragment
  list: listReportExpanded[]
  view: ReportDisplayType
  report: ReportsFragment
  hideFullExport?: boolean
  hideSingleExport?: boolean
  addHeading?: React.ReactNode
}

export type ComponentReportState = AssetAllocationReportState | AssetDistributionReportState | AttributionComponentState | ManagerPerformanceComponentState | PerformanceComparisonComponentState

export type ReportStateType = {
  [key: string]: ComponentReportState
}

enum PublishStatus{
  Start = 1,
  Publishing = 2,
  Error = 3,
  Success = 4,
}

enum savingStatus{
  Initial = 1,
  StartUpdatingComponents = 2,
  CreatingUpdatingComponents = 3,
  StartOrder = 4,
  UpdatingOrder = 5,
  Error = 6,
}

enum FireAction {
  RollOver = 1,
  ChooseDate = 2,
}
export type ReportInstanceType = "ClientPortfolio" | "ListHeader"

export const defaultEmptyLayout:ClientPortfolioDetailLayoutFragment = {
  __typename: "Layout",
  sections: [],
}

const CACHE_CLEARABLE_COMPONENTS = [ComponentType.ManagerPerformance, ComponentType.PerformanceComparison, ComponentType.Attribution] // Add Attribution here

const formattedReportMessage = (report: Pick<ReportsFragment, "id"|"name"|"__typename"> | undefined| null) => (report?.id? `Report Name: ${report?.name}, ReportId = ${report?.id}`: undefined)

const formattedComponentMessage = (component: Pick<Component, "id"|"name"|"__typename"> | undefined| null) => (component?.id? `Component Name: ${component?.name}, ComponentId = ${component?.id}`: undefined)

const getLinkedComponentsMessage = (usedList: ReportsListFragment, reportId: number, reportType: ReportCategoryCode) => {
  let usedInstances = usedList.items || []
  let sections = flatMap(usedInstances, (layout) => layout?.draftLayout?.sections) || []
  let components: ClientPortfolioDetailComponentFragment[] = compact(flatMap(sections, (section) => section?.components))
  const linkedComponents = compact(components?.filter((component) => component?.reportsUsedIn && component?.reportsUsedIn?.length > 1))
  const isFundProfileReport = reportType === ReportCategoryCode.FPROF

  let allCanUpdated = true
  const formattedMessages = linkedComponents.map((component) => {
    const currentComponent= formattedComponentMessage(component)
    const linkedReports = compact(component?.reportsUsedIn?.filter((position) => position?.report && position?.live === false && position?.report?.id !== reportId)) || [] as ComponentReportUsage[]
    const linkedReportNames = compact(linkedReports?.map((report) => formattedReportMessage(report?.report))) || []
    if(linkedReportNames.length < 1) return undefined
    if(currentComponent && isFundProfileReport){
      return `${currentComponent} is linked to ${linkedReportNames.join(", ")}.`
    }else if(currentComponent){
      const canNotUpdatedReports = linkedReports?.filter((report) => report?.report?.category?.code === ReportCategoryCode.FPROF)
      const canNotUpdatedReportsName  = compact(canNotUpdatedReports?.map((report) => formattedReportMessage(report?.report))) || []
      if(canNotUpdatedReportsName.length > 0){
        if(allCanUpdated){
          allCanUpdated = false
        }
        // linked to some fund profile reports, so no updates allowed. display messages.
        return `Component ${currentComponent} will not be updated as it is linked to fund profile reports ${canNotUpdatedReportsName.join(", ")}.`
      }else {
        // not linked to any fund profile reports, update allowed. display messages.
        let canUpdatedReports = linkedReports?.filter((report) => report?.report?.category?.code !== ReportCategoryCode.FPROF)
        let canUpdatedReportsName  = compact(canUpdatedReports?.map((report) => formattedReportMessage(report?.report))) || []

        if(canUpdatedReportsName.length < 1){
          // no linked reports affected. No message needed.
          return undefined
        }else {
          return `${currentComponent} is linked to ${linkedReportNames.join(", ")}.`
        }
      }
    }else {
      console.log("exclusion", {currentComponent, isFundProfileReport})
    }
  })
  return {messages: compact(formattedMessages), allCanUpdated}
}

const ReportMain: React.FC<idProps> = (props) => {
  const { auth, portfolioId, reportId, user, list, clientId, view, report, addHeading } = props
  const planId = report.plans?.[0]?.id
  const { hideFullExport, hideSingleExport} = props
  let usedList:ReportsListFragment | undefined , usedInstance:ReportsListMemberFragment | undefined
  let usedLayout:ClientPortfolioDetailLayoutFragment | undefined
  let instanceType:ReportInstanceType | undefined
  if(view === ReportDisplayType.Live){
    usedList = report.liveList || undefined
  } else if (view === ReportDisplayType.Draft){
    usedList = report.draftList || undefined
  }
  usedInstance = find(usedList?.items, (item) => {
    if(typeof item !== 'object') return false
    if(item.item?.__typename === 'ClientPortfolio'){
      instanceType = item.item?.__typename
      return item.item.id === portfolioId
    } else if (item.item?.__typename === 'ListHeader'){
      instanceType = item.item?.__typename
      return item.item.id === portfolioId
    }
  }) as ReportsListMemberFragment
  if(view === ReportDisplayType.Live){
    usedLayout = usedInstance?.liveLayout || undefined
  } else if (view === ReportDisplayType.Draft){
    usedLayout = usedInstance?.draftLayout || undefined
  }
  if(!usedLayout) usedLayout = defaultEmptyLayout

  const [editedDraftLayout, setEditedDraftLayout] = useState<ClientPortfolioDetailLayoutFragment | undefined>(usedLayout)
  const [editedReport, setEditedReport] = useState<ReportsFragment>(report)
  const [editedList, setEditedList] = useState<ReportsListFragment | undefined>(usedList)
  const [reportState, setReportState] = useState<ReportStateType>({}) // Used to store state that must be shared with export
  const [reportTabOrder, setReportTabOrder] = useState<ClientReportOrderFragment[]>([])
  const [planTabOrder, setPlanTabOrder] = useState<ClientPlanOrderFragment[]>([])

  const [publishReportMutation] = usePublishReportMutation()
  const [updateComponentMutation] = useUpdateComponentMutation()
  const [createComponentMutation] = useCreateComponentMutation()
  const [deleteComponentMutation] = useDeleteComponentMutation()
  const [upsertLayoutMutation] = useUpsertLayoutMutation()
  const [updateReportMutation] = useUpdateReportMutation()
  const [updateListMutation] = useUpdateListMutation()
  const [pushAllComponentDatesMutation] = usePushAllComponentDatesMutation()
  const [updateReportOrderMutation] = useUpdateReportOrderMutation()
  const [updatePlanOrderMutation] = useUpdatePlanOrderMutation()
  const { addAlert } = useContext(TemporaryAlertContext)
  const [fetchClientReports, { data: clientReportData }] = useClientAllReportsLazyQuery()

  const [publishModalOpen, setPublishModalOpen] = useState(false)

  // dropdown for rollover/chooseDate
  // 1. Rollover Modal open, user selects rollover.
  // 2. ChooseDate Process.
  const [rolloverDropdownOpen, setRolloverDropdownOpen] = useState(false)

  const [rolloverModalOpen, setRolloverModalOpen] = useState(false)
  // chooseDate Process, 2-4 shared with rollover.
  // 1. chooseDateModal open, user set a choose date.
  // 2. confirmChooseDateActionModal open, user can see what will happen if update.
  // 3. user clicks a) confirm => chooseDateForAllConfirmed = true. or b) cancel.
  // 4. set all components to the choose date
  const [chooseDateModalOpen, setChooseDateModalOpen] = useState(false)
  const [chooseDate, setChooseDate] = useState<string>(appDate.format(DATE_API_FORMAT))

  // tell user what will happen if update through chooseDate.
  const [confirmChooseDateMessages, setConfirmChooseDateMessages] = useState<{messages: string[], allCanUpdated: boolean}>({messages: [], allCanUpdated: true})
  const [confirmChooseDateActionModalOpen, setConfirmChooseDateActionModalOpen] = useState(false)
  const [confirmChooseDateFlag, setConfirmChooseDate] = useState(false)

  const [fireAction, setFireAction] = useState<FireAction>(FireAction.RollOver)

  const [setAllComponentDatesMutation] = useSetAllComponentDatesMutation()
  const [clearComponentCacheMutation] = useClearReportComponentCacheMutation()
  const [bulkUpdateComponentApprovals] = useBulkUpdateComponentApprovalsMutation()

  const [listEditModalOpen, setListEditModalOpen] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [publishCount, setPublishCount] = useState(0)
  const [publishStatus, setPublishStatus] = useState(PublishStatus.Start)
  const [publishError, setPublishError] = useState<string[] | null>(null)
  const { resetErrors } = useContext(EditButtonContext)
  const graphqlClient = useApolloClient()
  const history = useHistory()

  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(savingStatus.Initial)
  const [savingComponents, setSavingComponents] = useState(0)
  const [orderingComponents, setOrderingComponents] = useState(0)
  const [selectedComponent, setSelectedComponent] = useState<selectedComponentProp | undefined>(undefined)
  const [toSelectedComponent, setToSelectedComponent] = useState<selectedComponentProp | undefined>(undefined)
  const [editNavbar, setEditNavbar] = useState(editMode ? "Component" : "Report")
  const [toEditNavbar, setToEditNavbar] = useState<string | undefined>(undefined)
  const [recacheLoading, setRecacheLoading] = useState(false)

  const allEditedComponents = flatMap(editedDraftLayout?.sections, (section) => section?.components)
  const allBaseComponents = flatMap(usedLayout?.sections, (section) => section?.components)
  let latestAsOfDate: Moment | undefined = undefined
  let componentEdited = compact(allEditedComponents).filter(x => {
    const matchingComponent = find(allBaseComponents, {id: x?.id})
    const componentDate = get(x, view === ReportDisplayType.Live ? "liveSettings.date" : "draftSettings.date")
    if(componentDate && (!latestAsOfDate || moment(componentDate).isAfter(latestAsOfDate))){
      latestAsOfDate = moment(componentDate)
    }
    if(matchingComponent){
      return JSON.stringify(matchingComponent) !== JSON.stringify(x)
    }
    return true
  }).length > 0

  useEffect(() => {
    const reportType = report?.category?.code || ReportCategoryCode.FPROF

    if((chooseDateModalOpen || rolloverModalOpen) && usedList && view === ReportDisplayType.Draft) {
      let results = getLinkedComponentsMessage(usedList, reportId, reportType)
      setConfirmChooseDateMessages(results)
    }else if(chooseDateModalOpen || rolloverModalOpen) {
      console.log("exclusion", {usedLayout, view, reportType})
    }
  }, [chooseDateModalOpen, rolloverModalOpen])

  useEffect(() => {
    if(confirmChooseDateFlag){
      if(confirmChooseDateActionModalOpen){
        setConfirmChooseDateActionModalOpen(false)
      }
      if(fireAction === FireAction.RollOver){
        rolloverReport()
      }else if(fireAction === FireAction.ChooseDate){
        chooseDateForAllComponents()
      }
    }
  }, [confirmChooseDateFlag])

  useEffect(() => {
    if(savingComponents < 1 && editMode && saving === savingStatus.CreatingUpdatingComponents){
      setSaving(savingStatus.StartOrder)
    }
  }, [editMode, saving, savingComponents])

  useEffect(() => {
    if(orderingComponents < 1 && editMode && saving === savingStatus.UpdatingOrder){
      setSaving(savingStatus.Initial)
      setEditMode(false)
    }
  }, [editMode, saving, orderingComponents])

  useEffect(() => {
    if(!editMode){
      setEditedDraftLayout(usedLayout)
    }
  }, [usedLayout, editMode])

  useEffect(() => {
    setFireAction(FireAction.RollOver)
    setEditedReport(report)
  }, [report, editMode])

  useEffect(() => {
    if(!editMode){
      setEditedList(usedList)
    }
  }, [usedList, editMode])

  useEffect(() => {
    registerRecentReport({
      id: reportId,
      name: report.name,
      category: report.category?.value || "",
      subCategory: report.subCategory?.value || "",
    })
  }, [])

  const tree = useMemo(() => {
    return editedList ? expandReportList(editedList) : []
  }, [editedList])

  const handleEdit = (value: boolean) => {
    setEditMode(value)
    setEditedReport(report)
    if(usedLayout){
      setEditedDraftLayout(usedLayout)
    }
  }

  const afterSave = () => {
    setSavingComponents(prevCount => prevCount - 1)
  }

  const afterOrder = () => {
    setOrderingComponents(prevCount => prevCount - 1)
  }

  const handleSubmit = () => {
    setSaving(savingStatus.StartUpdatingComponents)
  }

  const fireRolloverReport = () => {
    setFireAction(FireAction.RollOver)
    setRolloverModalOpen(false)
    setConfirmChooseDateActionModalOpen(true)
  }

  const rolloverReport = () => {
    pushAllComponentDatesMutation({ variables: { input: { reportId }, liveView: false, draftView: true }})
      .then(result => {
        let successMessage = "Success | Report updated."
        if(!confirmChooseDateMessages.allCanUpdated){
          successMessage += " The linked components will not be updated."
        }
        addAlert({title: successMessage, message: "", color: "userSuccess", timeout: 3000})
      })
      .catch(error => {
        addAlert({title: "Error | Report not updated.", message: error.message, color: "danger", timeout: 5000})
      })
  }

  const fireChooseDate = () => {
    setFireAction(FireAction.ChooseDate)
    setChooseDateModalOpen(false)
    setConfirmChooseDateActionModalOpen(true)
  }

  const chooseDateForAllComponents = () => {
    setAllComponentDatesMutation({ variables: { input: { reportId, date: chooseDate}, liveView: false, draftView: true }}).then(result => {
      let successMessage = "Success | Report updated."
        if(!confirmChooseDateMessages.allCanUpdated){
          successMessage += " The linked components will not be updated."
        }
      addAlert({title: successMessage, message: "", color: "userSuccess", timeout: 3000})
    })
    .catch(error => {
      addAlert({title: "Error | Report not updated.", message: error.message, color: "danger", timeout: 5000})
    }).finally(() => {
      setChooseDate(appDate.format(DATE_API_FORMAT))
      setConfirmChooseDate(false)
    })
  }

  const [getExportUrl, {loading: exportLoading, data: exportData, error: exportError}] = useExportReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.exportReport.url) downloadWithFilename(data.exportReport.url, (report?.exportSettings?.filename || report?.name) + ".pptx")
    }
  })

  const exportReport = () => {
    if(!exportLoading) {
      let reviewType = ""
      let reviewDate = latestAsOfDate?.format(DATE_API_FORMAT)
      let componentOverrideSettings:ComponentOverrideSettings = {}
      usedLayout?.sections?.forEach((section, sectionNum) => {
        section?.components?.forEach((component, componentNum) => {
          if(!component?.type) return
          const exportSettings = ExportSettingsMapping[component.type]
          if(!exportSettings) return
          const currentReportState = get(reportState, component.id.toString(), {})
          const currentTab = get(currentReportState, 'tab', {} as ReportTab)
          if(currentTab && currentTab.primary === true && (reviewType === "" || component.type === ComponentType.ManagerPerformance)){
            reviewType = "Quarterly Review"
            reviewDate = currentTab.value
          } else if (currentTab && (reviewType === "" || component.type === ComponentType.ManagerPerformance)) {
            reviewType = "Monthly Review"
            reviewDate = currentTab.value
          }
          const exportedComponent = exportSettings({
            component: component,
            report: report,
            auth: auth,
            view: view,
            sectionNumber: sectionNum,
            componentNumber: componentNum,
            editMode: false,
            setEditedDraftLayout,
            graphqlClient: graphqlClient,
            editedDraftLayout: usedLayout as ClientPortfolioDetailLayoutFragment,
            reportState: currentReportState,
            clientId: clientId,
            portfolioId: portfolioId,
            instanceType: instanceType,
          })
          componentOverrideSettings = mergeWith(componentOverrideSettings, exportedComponent, (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue);
            }
          })
        })
      })

      getExportUrl({variables: {input: {
        name: report?.exportSettings?.filename || report?.name,
        slides: compact(usedLayout?.sections?.map((section, idx) => {
          if(section?.type){
            const firstComponent = first(section.components)
            const usedSettings = view === ReportDisplayType.Live ? firstComponent?.liveSettings : firstComponent?.draftSettings
            const hideTitle = usedSettings && "hideTitle" in usedSettings && usedSettings?.hideTitle

            return {
              title: hideTitle ? "" : first(section.components)?.name || report.name,
              sections:[{
                components: compact(section.components?.map((component) => {
                  return component?.id
                })),
                type: section.type
              }]
            }
          }
          return undefined
        })),
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report.client?.name,
          componentOverrideSettings,
        },
        titleSlide: {
          date: reviewDate,
          title: report?.exportSettings?.titleSlide?.title || report?.name,
          subtitle: (report?.exportSettings?.titleSlide?.subtitle || "Investment Measurement Service") + `\n${reviewType}`,
        }
      }}})
    }
  }

  // This starts when handleSubmit is called
  if(editedDraftLayout){
    if(saving === savingStatus.StartUpdatingComponents){
      // work out what has been changed
      setSaving(savingStatus.CreatingUpdatingComponents)
      const editedComponents = compact(editedDraftLayout?.sections?.flatMap((section) => section?.components))
      const draftComponents = compact(usedLayout?.sections?.flatMap((section) => section?.components))
      let updatedComponents = editedComponents?.filter(x => {
        const matchingComponent = find(draftComponents, {id: x.id})
        if(matchingComponent){
          return JSON.stringify(matchingComponent) !== JSON.stringify(x)
        }
        return false
      })
      let newComponents = editedComponents?.filter(x => {
        const matchingComponent = find(draftComponents, {id: x.id})
        // Don't create a new component if it is linked to an existing component
        if(matchingComponent || (x.reportsUsedIn || []).length > 1){
          return false
        }
        return true
      })
      let deletedComponents = draftComponents?.filter(x => {
        const matchingComponent = find(editedComponents, {id: x.id})
        // Don't delete a component if it is linked to an existing component
        if(matchingComponent || (x.reportsUsedIn || []).length > 1){
          return false
        }
        return true
      })

      let reportUpdateCount = 0

      // update report order
      if(reportTabOrder.length > 0 && planId){
        updateReportOrderMutation({ variables: { input: {
          planId: planId,
          order: reportTabOrder.map((r, idx) => ({ reportId: r.id, order: idx }))
        }}}).then(() => {
          fetchClientReports({
            fetchPolicy: "network-only",
            variables: { id: clientId }
          })
        })
      }

      updatePlanOrderMutation({ variables: { input: {
        orgId: clientId,
        order: planTabOrder.map((r, idx) => ({ planId: r.id, order: idx }))
      }}}).then(() => {
        // fetchClientReports({
        //   fetchPolicy: "network-only",
        //   variables: { id: clientId }
        // })
      })

      // update the report
      const isReportEdited = JSON.stringify(report) !== JSON.stringify(editedReport)
      if(isReportEdited){
        let patchList = convertLookupToString(editedReport, false, [], ["Person", "Client", "List"])
        let excludedPatch = excludePropertyArray(patchList, ["__typename", "id", "lastPublished", "liveList", "plans", "dueDates", "contacts", "order", "publishVersions"])

        // handle due date
        const currentDueDate = find(editedReport.dueDates, {quarterDate: appDate.format(DATE_API_FORMAT)})
        const previousDueDate = find(report.dueDates, {quarterDate: appDate.format(DATE_API_FORMAT)})
        if(currentDueDate && currentDueDate.dueDate && (!previousDueDate || !previousDueDate.dueDate || currentDueDate.dueDate !== previousDueDate.dueDate)){
          excludedPatch.dueDate = {add: [currentDueDate]}
          if(previousDueDate){
            excludedPatch.dueDate.remove = [appDate.format(DATE_API_FORMAT)]
          }
        }
        //handle owner change
        const currentOwner = editedReport.owner?.id
        const previousOwner = report.owner?.id
        if(currentOwner !== previousOwner){
            //editedReport.draftList
          const ownerLists: number[] = compact(uniq([editedReport.draftList?.id, ...editedReport?.draftList?.items?.flatMap((item:any) => {
            return item.draftLayout?.sections?.flatMap((section:any) =>{
              return section?.components?.flatMap((component:any) =>{
                if("list" in component?.draftSettings){
                  return component?.draftSettings?.list.id
                }
                return []
              }) || []
            }) || []
          })|| []]))
          ownerLists.forEach((list)=>{
            reportUpdateCount += 1
            const listSettings: UpdateListInput = {
              id: list,
              patch: {owner: currentOwner}
            }
            updateListMutation({
              variables: {
                input: listSettings,
              }}).then(result => {
                afterSave()
              })
              .catch(err => {
                afterSave()
                setSaving(savingStatus.Error)
                console.log("Error Component Save", err.message)
              })
          })
        }
        // handle contacts
        let usedContacts = cloneDeep(report.contacts) || []
        editedReport.contacts?.forEach((x) => {
          const matchingContact = remove(usedContacts, (c) => c.contact?.id === x.contact?.id)
          const contactPatch = {contactId: x.contact?.id, deliveryMethod: x.deliveryMethod?.code}
          if(!contactPatch.contactId || !contactPatch.deliveryMethod) return
          if(matchingContact && matchingContact.length > 0){
            if(JSON.stringify(matchingContact[0]) !== JSON.stringify(x)){
              excludedPatch.contacts = {add: compact([...(excludedPatch.contacts?.add || []), contactPatch]), remove: compact([...(excludedPatch.contacts?.remove || []) , matchingContact[0].contact?.id])}
            }
          } else {
            excludedPatch.contacts = {add: compact([...(excludedPatch.contacts?.add || []), contactPatch])}
          }
        })
        usedContacts?.forEach((x) => {
          excludedPatch.contacts = {...excludedPatch.contacts, remove: compact([...(excludedPatch.contacts?.remove || []), x.contact?.id])}
        })

        let reportSettings:UpdateReportInput = {
          id: editedReport.id,
          patch: excludedPatch,
        }
        reportUpdateCount += 1

        updateReportMutation({
          variables: {
            input: reportSettings,
            liveView: false,
            draftView: true,
          }}).then(result => {
            afterSave()
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Save", err.message)
          })
      }

      setSavingComponents(updatedComponents.length + newComponents.length + deletedComponents.length + reportUpdateCount)

      // update each component that was updated
      forEach(updatedComponents, (component) => {
        let patchList = convertComponentForMutation(component.draftSettings || undefined, component.type)

        let componentSettings:ComponentSettingsInput = {}
        set(componentSettings, lowerFirst(component.type || ""), excludePropertyArray(patchList, ["__typename", "timeSpan"]))

        const updateData = {
          id: component.id,
          patch: {
            name: component.name,
            settings: componentSettings
          }
        } as UpdateComponentInput

        updateComponentMutation({ variables: { input: updateData, liveView: true, draftView: true} })
          .then(result => {
            // flush caches for those components that need it
            if(indexOf(CACHE_CLEARABLE_COMPONENTS, component.type) > -1){ // add Attribution here
              clearComponentCacheMutation({ variables: { ids: [component.id] } }).then(() => afterSave())
            } else {
              afterSave()
            }
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Save", err.message)
          })
      })

      // create each component that was new
      forEach(newComponents, (component) => {
        let patchList = convertComponentForMutation(component.draftSettings || undefined, component.type)
        let componentSettings:ComponentSettingsInput = {}
        set(componentSettings, lowerFirst(component.type || ""), excludePropertyArray(patchList, ["__typename", "timeSpan", "id"]))
        console.log({ patchList, componentSettings })

        const newData = {
          name: component.name,
          type: component.type,
          settings: componentSettings,
        } as CreateComponentInput

        createComponentMutation({ variables: { input: newData, liveView: true, draftView: true} })
          .then(result => {
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => currentState?.sections,
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  var selectedSection = findIndex(sections, (o) => {return !!find(o?.components, {id: component.id})})
                  const reportsUsedIn = [{
                    list: {
                      id: usedList?.id || 0,
                      __typename: "List" as "List"
                    },
                    report: {
                      id: report.id,
                      name: report.name,
                      client: {
                        name: report.client?.name || "",
                        __typename: "Client" as "Client"
                      },
                      __typename: "Report" as "Report"
                    },
                    __typename: "ComponentReportUsage" as "ComponentReportUsage"
                  }]
                  if(selectedSection >= 0){
                    sections = iassign(
                      sections,
                      currentSection => currentSection?.[selectedSection]?.components,
                      componentsTable => {
                        let components = cloneDeep(componentsTable)
                        const componentIndex = findIndex(components, {id: component.id})
                        if(componentIndex !== -1 && result.data?.createComponent?.component) components?.splice(componentIndex, 1, {...result.data?.createComponent?.component, reportsUsedIn})
                        return components
                      }
                    )
                  }
                  return sections
                }
              )
              return newState
            })
            afterSave()
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Save", err.message)
          })
      })

      forEach(deletedComponents, (component) => {
        deleteComponentMutation({ variables: { input: {id: component.id}} })
          .then(result => {
            afterSave()
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Delete", err.message)
          })
      })

    } else if (saving === savingStatus.StartOrder) {
      setSaving(savingStatus.UpdatingOrder)

      const runLayout = !!usedInstance
      setOrderingComponents(compact([runLayout]).length)
      if(runLayout){
        const layout = filter(editedDraftLayout.sections?.map(section => {
          return {
            type: section?.type,
            components: compact(section?.components?.map(component => component?.id && component.id > 0 && component.id)) || []
          }
        }), (section) => section.components.length > 0)
        const baseType = usedInstance?.type as ListMemberIdType
        const orderData = {
          listId: usedList?.id,
          memberId: get(usedInstance, `item.${typeIdMapping[baseType]}`, "")?.toString(),
          memberIdType: usedInstance?.type,
          layout
        } as UpsertLayoutArgs

        upsertLayoutMutation({ variables: { input: orderData, liveView: true, draftView: true},
          optimisticResponse: {
            __typename: "Mutation",
            upsertLayout: {
              __typename: "LayoutPayload",
              layout: {
                __typename: "ListItemLayout",
                layout: editedDraftLayout
              }
            }
          },
          update: (cache, { data }) => {
            const listFragment = cache.readFragment({
              id: `List:${usedList?.id}`, // The value of the to-do item's cache ID
              variables: { liveView: true, draftView: true },
              fragment: ReportsListFragmentDoc,
              fragmentName: "ReportsListFragment",
            }) as ReportsListFragment | null
            if(listFragment){
              let clonedList = cloneDeep(listFragment)
              let updatedFragment = iassign(
                clonedList,
                currentState => currentState?.items,
                (items) => {
                  let itemsClone = cloneDeep(items) || []
                  const itemIndex = findIndex(itemsClone, (item) => {
                    if(item.item?.__typename === usedInstance?.item?.__typename){
                      if(usedInstance?.item && "id" in usedInstance?.item && item.item && "id" in item.item){
                        return item.item?.id === usedInstance?.item?.id
                      }
                    }
                    return false
                  })
                  if(itemIndex !== -1){
                    let updatedItem = cloneDeep(itemsClone[itemIndex])
                    updatedItem.draftLayout = data?.upsertLayout?.layout?.layout
                    itemsClone.splice(itemIndex, 1, updatedItem)
                  }
                  return itemsClone
                }
              )
              cache.writeFragment({
                id: `List:${usedList?.id}`,
                variables: { liveView: true, draftView: true },
                fragment: ReportsListFragmentDoc,
                data: updatedFragment,
                fragmentName: "ReportsListFragment",
              });
            }
          }
        })
        .then(result => {
          afterOrder()
          // if(result.data?.upsertLayout?.layout?.layout) setEditedDraftLayout(result.data.upsertLayout.layout.layout)
        })
        .catch(err => {
          afterOrder()
          setSaving(savingStatus.Error)
          console.log("Error Component Save", err.message)
        })
      }
    }
  }

  const handleComponentSelect = ( inSelectedComponent: selectedComponentProp | undefined ) => {
    if(!isEqual(selectedComponent,inSelectedComponent)){
    // if(editMode && componentEdited){
    //   setModalVisible(true)
    //   setToSelectedComponentId(id)
    // } else {
      setSelectedComponent(inSelectedComponent)
      resetErrors()
    }
  }

  const handleReportRecalculate = () => {
    setRecacheLoading(true)
    const orderedItems = recursivelyOrderReport(tree)
    const allClearableComponents:Component[] = compact(orderedItems.map((item) => {
      return item.draftLayout?.sections?.filter((section:ClientPortfolioDetailSectionFragment) =>
        section?.components?.filter((component) => {
          return indexOf(CACHE_CLEARABLE_COMPONENTS, component?.type) > -1
        })
      ).map((section:ClientPortfolioDetailSectionFragment) => section.components).flat()
    })).flat()

    clearComponentCacheMutation({ variables: { ids: allClearableComponents.map(component => component.id) } })
      .then(result => {
        console.log('Cache Cleared', result)
        const approvalUpdateData = {
          components: compact(allClearableComponents.map((component, idx) => {
            return component?.id || undefined
          })),
          approval: ComponentApprovalCode._1,
        } as BulkUpdateComponentApprovalsInput

        bulkUpdateComponentApprovals({ variables: { input: approvalUpdateData, liveView: false, draftView: true} })
          .then(result => {
            const newReportState = cloneDeep(reportState)
            mapValues(newReportState, (v) => set(v, 'cacheCleared', true))
            compact(allClearableComponents.map((component, idx) => {
              set(newReportState, component.id.toString(), {cacheCleared: true})
            }))
            setReportState(newReportState)
            setRecacheLoading(false)
            // will need to update report state to force component resets
          })
          .catch(err => {
            console.log("Error Component Save", err.message)
          })
      })
      .catch(err => {
        console.error("Error Clearing Component Cache", err.message)
      })
  }


  const guardModalScript: ModalScript = {
    header: "Unsaved Component Changes",
    body: "If you leave this unsaved component, changes will be lost.",
    leaveButtonContent: "Cancel",
    stayButtonContent: "Save Draft"
  }

  const handleEditNavbar = (value:string) => {
    if(editMode && componentEdited){
      setModalVisible(true)
      setToEditNavbar(value)
    } else {
      setEditNavbar(value)
    }
  }

  const handlePublishModal = (value:boolean) => {
    if(!value){
      setPublishStatus(PublishStatus.Start)
    }
    setPublishModalOpen(value)
  }

  let content = (
    <Row>
      <Col>
        <h3>No Data Returned</h3>
      </Col>
    </Row>
  )

  const modals = <>
    <RolloverModal
      key={"RolloverModal"}
      show={rolloverModalOpen}
      toggle={() => setRolloverModalOpen(!rolloverModalOpen)}
      fireRollover={fireRolloverReport}
    />
    <ChooseDateModal
      show={chooseDateModalOpen}
      toggle={() => setChooseDateModalOpen(!chooseDateModalOpen)}
      chooseDate={chooseDate}
      setChooseDate={setChooseDate}
      fireChooseDate={fireChooseDate}
    />
    <ConfirmChooseDateActionModal
      show={confirmChooseDateActionModalOpen}
      toggle={() => setConfirmChooseDateActionModalOpen(!confirmChooseDateActionModalOpen)}
      setConfirmChooseDate={setConfirmChooseDate}
      messages={confirmChooseDateMessages.messages}
    />
    <SidebarOrderModal
      key={"SidebarOrderModal"}
      modalOpen={listEditModalOpen}
      setModalOpen={setListEditModalOpen}
      list={editedList}
      tree={tree}
      startOpen={true}
      setEditedList={setEditedList}
      portfolioId={portfolioId}
      clientId={clientId}
      planId={planId}
      reportId={reportId}
      history={history}
      auth={auth}
      user={user}
      view={view}
      editMode={true}
      listType={'report'}
    />
    <GuardModal
      key={"GuardModal"}
      open={modalVisible}
      script={guardModalScript}
      stay={() => {
        setModalVisible(false)
        setEditedDraftLayout(usedLayout)
        setSelectedComponent(toSelectedComponent)
        if(toEditNavbar) setEditNavbar(toEditNavbar)
        setToEditNavbar(undefined)
        resetErrors()
      }}
      leave={()=>{
        setModalVisible(false)
      }}
    />
  </>
  let layout:ClientPortfolioDetailLayoutFragment | undefined
  let publishReport

  if(!portfolioId){
    content = (
      <Row>
        <Col>
          <h3>No Portfolio Selected</h3>
        </Col>
      </Row>
    );
  } else if (!usedInstance){
    content = (
      <Row>
        <Col>
          <h3>Selected Item is not a Portfolio</h3>
        </Col>
      </Row>
    );
  } else if (usedList && (usedInstance.item?.__typename === 'ClientPortfolio' || usedInstance.item?.__typename === 'ListHeader')) {
    if(view === ReportDisplayType.Draft ){
      layout = editedDraftLayout
    } else {
      layout = editedDraftLayout
    }
    publishReport = () => {
      setPublishStatus(PublishStatus.Publishing)
      setPublishError(null)
      let layouts:PublishLayoutArgs[] = []
      let componentIdsToPublish:number[] = []
      if(usedList){
        usedList.items?.forEach(item => {
          if(item.draftLayout && usedList){
            const baseType = item.type as ListMemberIdType
            layouts.push({listId: usedList.id, memberId: get(item, `item.${item.type && typeIdMapping[baseType]}`, "").toString(), memberIdType: item?.type || ListMemberIdType.portfolio_num})
            item.draftLayout.sections?.forEach(section => {
              componentIdsToPublish = componentIdsToPublish.concat(compact(section?.components?.map(component => component?.id)))
            })
          }
        });
      }

      publishReportMutation({ variables: { id: reportId } })
        .then(result => {
          setPublishCount((count) => count + 1)
          setPublishStatus(PublishStatus.Success)
        })
        .catch(err => {
          console.error("Error Publishing", err.message)
          setPublishError(err.message.split("\n"))
          setPublishStatus(PublishStatus.Error)
        })
    }
    content = (
      <Row className="flex-grow">
        <Col md={view === ReportDisplayType.Draft ? 9 : 12} className="flex-grow report-main-scroll">
          {layout?.sections?.map((section, idx) => {
            if(section?.type && section?.components && layout){
              return(
                <ReportSection
                  key={idx}
                  type={section?.type}
                  components={
                    section?.components as ClientPortfolioDetailComponentFragment[]
                  }
                  view={view}
                  auth={auth}
                  selectedComponentId={selectedComponent}
                  setSelectedComponentId={handleComponentSelect}
                  editMode={editMode}
                  report={editedReport}
                  sectionNumber={idx}
                  setEditedDraftLayout={setEditedDraftLayout}
                  editedDraftLayout={layout}
                  setReportState={setReportState}
                  reportState={reportState}
                  hideSingleExport={hideSingleExport}
                  clientId={clientId}
                  portfolioId={portfolioId}
                  instanceType={instanceType}
                />
              )
            }
            return <React.Fragment key={idx} />
          })}
          {!layout && !editMode && <ReportError error="No components available at this time." />}
          {layout?.sections?.length === 0 && !editMode && (
            <ReportError error="No components available at this time." />
          )}
          {editedDraftLayout && editMode && layout?.sections?.length === 0&&
            <>
              <ReportAddable
                show={editMode}
                sectionNumber={(layout?.sections?.length || 0) + 1}
                componentNumber={-1}
                report={report}
                setEditedDraftLayout={setEditedDraftLayout}
                setSelectedComponentId={handleComponentSelect}
                editedDraftLayout={editedDraftLayout}
                portfolioId={portfolioId}
                instanceType={instanceType}
              >
                {
                  ({setModalOpen}:any) => {
                    return (
                      <div className='pane pane-table pane-profile pane-missing-component mt-4 pb-3 d-flex justify-content-center'>
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                          <Button onClick={() => setModalOpen(true)} color="primary" className="">
                            Add Component <FontAwesomeIcon icon="plus" className="ml-2" />
                          </Button>
                        </div>
                      </div>
                    )
                  }
                }
              </ReportAddable>
              <div className='my-1 pb-2'>
                <ReportAddable
                  show={editMode}
                  sectionNumber={(layout?.sections?.length || 0) + 1}
                  componentNumber={-1}
                  report={report}
                  setEditedDraftLayout={setEditedDraftLayout}
                  setSelectedComponentId={handleComponentSelect}
                  editedDraftLayout={editedDraftLayout}
                  portfolioId={portfolioId}
                  instanceType={instanceType}
                />
              </div>
            </>
          }
        </Col>
        {view === ReportDisplayType.Draft && (
          <Col md={3} className="client-report-edit-sidebar-column pl-0">
            <div className="background-white client-report-edit-sidebar">
              <ReportEdit
                reportTabOrder={reportTabOrder}
                setReportTabOrder={setReportTabOrder}
                editedDraftLayout={editedDraftLayout}
                selectedComponentId={selectedComponent}
                setEditedDraftLayout={setEditedDraftLayout}
                setSelectedComponentId={handleComponentSelect}
                editNavbar={editNavbar}
                setEditNavbar={handleEditNavbar}
                portfolio={usedInstance.item}
                clientId={clientId}
                reportId={reportId}
                editMode={editMode}
                report={editedReport}
                setReport={setEditedReport}
                user={user}
                list={tree}
                auth={auth}
                planTabOrder={planTabOrder}
                setPlanTabOrder={setPlanTabOrder}
                fullReportState={reportState}
              />
            </div>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <Col>
      {/* THIS IS NO LONGER USED AND CAN BE DELETED IN ITS OWN COMMIT */}
      {/* <ReportAfterPublish
        layout={usedLayout}
        auth={auth}
        report={report}
        view={view}
        publishCount={publishCount}
        setEditedDraftLayout={setEditedDraftLayout}
        setReportState={setReportState}
        reportState={reportState}
        clientId={clientId}
        portfolioId={portfolioId}
        instanceType={instanceType}
      /> */}
      <ReportMainHolder
        auth={auth}
        view={view}
        publishReport={publishReport}
        publishModalOpen={publishModalOpen}
        setPublishModalOpen={(value: boolean) => handlePublishModal(value)}
        setRolloverModalOpen={(value: boolean) => setRolloverModalOpen(value)}
        rolloverDropdownOpen={rolloverDropdownOpen}
        setRolloverDropdownOpen={setRolloverDropdownOpen}
        setChooseDateModalOpen={setChooseDateModalOpen}
        publishStatus={publishStatus}
        publishError={publishError}
        editMode={editMode}
        setEditMode={handleEdit}
        saving={[savingStatus.StartUpdatingComponents, savingStatus.CreatingUpdatingComponents, savingStatus.StartOrder, savingStatus.UpdatingOrder].includes(saving)}
        handleSubmit={handleSubmit}
        setListEditModalOpen={setListEditModalOpen}
        user={user}
        tree={tree}
        list={editedList}
        setEditedList={setEditedList}
        portfolioId={portfolioId}
        instanceType={instanceType}
        clientId={clientId}
        planId={planId}
        reportId={reportId}
        componentEdited={componentEdited}
        layout={layout}
        exportReport={exportReport}
        exportLoading={exportLoading}
        hideFullExport={hideFullExport}
        addHeading={addHeading}
        report={report}
        handleReportRecalculate={handleReportRecalculate}
        recacheLoading={recacheLoading}
      >
        {content}
      </ReportMainHolder>
      {modals}
    </Col>
  );
}

type ReportMainHolderProps = {
  auth: Auth
  editMode: boolean
  setEditMode: (value:boolean) => void
  saving: boolean
  handleSubmit: () => void
  publishReport?: () => void
  publishStatus?: PublishStatus
  publishModalOpen: boolean
  publishError?: string[] | null
  setPublishModalOpen: (value:boolean) => void
  rolloverDropdownOpen: boolean
  setRolloverDropdownOpen: (value:boolean) => void
  setRolloverModalOpen: (value:boolean) => void
  setChooseDateModalOpen: (value:boolean) => void
  setListEditModalOpen: (value:boolean) => void
  user?: MeFragment
  tree: listReportExpanded[]
  list: ReportsListFragment | undefined
  setEditedList: React.Dispatch<React.SetStateAction<ReportsListFragment | undefined>>
  portfolioId?: number
  instanceType?: ReportInstanceType
  clientId: number
  planId?: number
  reportId: number
  view: ReportDisplayType
  componentEdited: boolean
  layout?: ClientPortfolioDetailLayoutFragment | undefined
  exportReport: () => void
  exportLoading: boolean
  hideFullExport?: boolean
  addHeading?: React.ReactNode
  report: ReportsFragment
  handleReportRecalculate: () => void
  recacheLoading: boolean
}

const ReportMainHolder: React.FC<ReportMainHolderProps> = (props) => {

  const {auth, children, view, publishReport, publishModalOpen, rolloverDropdownOpen, setPublishModalOpen, setRolloverModalOpen, setListEditModalOpen, setRolloverDropdownOpen,setChooseDateModalOpen, publishStatus, editMode, setEditMode, saving, handleSubmit, user, tree, list, setEditedList, portfolioId, clientId, planId, reportId, componentEdited, layout, exportReport, exportLoading, addHeading, report, handleReportRecalculate, recacheLoading, publishError } = props
  const {hideFullExport: hideExport} = props
  const history = useHistory()

  const orderedItems = recursivelyOrderReport(tree)
  const allApproved = orderedItems.every((item) => {
    const sections = item.draftLayout?.sections?.every((section) =>
      section?.components?.every((component) => {
        return component?.approval?.code === ComponentApprovalCode._3
      })
    )
    return sections !== false
  })

  const usedSection = layout?.sections || []
  const missingComponents = !usedSection.every((section) => {
    if(section?.type && SectionMapping[section.type]?.expectedCount) {
      const expectedCount = SectionMapping[section.type]?.expectedCount || 0
      const realComponents = compact(section.components).length
      if(expectedCount === 1) return true
      return realComponents >= expectedCount
    }
    return true
  })
  const isFundReport = report.category?.code === ReportCategoryCode.FPROF

  let heading = <></>
  //<FontAwesomeIcon
    //icon={exportLoading ? "spinner-third" : ["fas", "file-download"]}
    //spin={exportLoading}
    //size="lg"
    //className="ml-2 text-callan-blue"
  ///>

  if(view === ReportDisplayType.Draft && auth.checkPermissions(["view:client_portfolios"])){
    heading = (
      <div className="pane pane-toolbar sticky-top above-picker">
        { addHeading }
        {view === ReportDisplayType.Draft &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={()=> window.open(`/clients/${clientId}/${planId}${isFundReport ? "" : "/reports"}/${reportId}/${portfolioId}`, '_blank')}>
            View Live Report
            <FontAwesomeIcon icon={"external-link"} className="ml-2" />
          </Button>
        }
        {auth.checkPermissions(["publish:report"]) && view === ReportDisplayType.Draft && publishReport &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" disabled={!allApproved} onClick={()=> allApproved ? setPublishModalOpen(true) : () => null}>
            Publish Report
          </Button>
        }
        { !hideExport && !editMode && view === ReportDisplayType.Draft && auth.checkPermissions(['export:report']) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={()=> exportReport()}>
            Download PPTX
            {!exportLoading && <img src={"/assets/PPTX.svg"} className="ml-2 text-callan-blue"/>}
            {exportLoading && <FontAwesomeIcon icon="spinner-third" spin />}
          </Button>
        }
        { !editMode &&
          <div className='ml-2 pl-2 border-left' key={"first"}>
            <ButtonDropdown
              isOpen={rolloverDropdownOpen}
              toggle={() => setRolloverDropdownOpen(!rolloverDropdownOpen)}
              disabled={editMode}
            >
              <DropdownToggle caret className={"border-override my-0 py-1 text-callan-blue"}>
                <FontAwesomeIcon icon={["fal","calendar-alt"]} className="mr-2" />
                  As-of Dates
              </DropdownToggle>
              <DropdownMenu className="w-100">
                <DropdownItem onClick={() => setRolloverModalOpen(true)}>
                  Rollover Report...
                </DropdownItem>
                <DropdownItem onClick={() => setChooseDateModalOpen(true)}>
                  Choose Date...
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        }
        { !editMode && view === ReportDisplayType.Draft &&(
          <Button color="light btn-thin" className="ml-1 text-callan-blue" onClick={()=> handleReportRecalculate()}>
            Recalculate
            { recacheLoading && <FontAwesomeIcon icon="spinner-third" className='ml-2' spin /> }
            { !recacheLoading &&  <FontAwesomeIcon
              icon={["fas", "sync"]}
              className={classNames("text-callan-blue", "ml-2")}
            />}
          </Button>
        )}
        {(!editMode && report.category?.code !== ReportCategoryCode.FPROF) &&
          <Button color="secondary btn-thin" className="text-callan-blue ml-2" onClick={()=> setListEditModalOpen(true)}>
            Update Pages...
          </Button>
        }
        {view === ReportDisplayType.Draft &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} hideOnContext={true} saveText={"Save Draft"} disableOnError={true} disabled={missingComponents}/>
        }
        <Modal size="md" className="mt-5" isOpen={publishModalOpen} toggle={() => setPublishModalOpen(!publishModalOpen)} zIndex={1500}>
          <ModalHeader className="fee-modal-header">
            Publish Report
          </ModalHeader>
          <ModalBody>
            {(publishStatus === PublishStatus.Start || publishStatus === PublishStatus.Publishing) &&
              <>
                Publishing the report will update both the list and report for the client.
                <br/>
                This update cannot be undone.
              </>
            }
            {publishStatus === PublishStatus.Success && "Publish Successful"}
            { publishStatus === PublishStatus.Error && (
              <>
              <h5>Error Publishing</h5>
              {
                publishError?.map((e) => {
                  return <div key={e}>{e}</div>
                })
              }
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {publishStatus === PublishStatus.Start &&
              <>
                <Button onClick={() => setPublishModalOpen(false)} color="secondary" className="mr-1 ml-auto">Cancel</Button>
                <Button onClick={() => publishReport && publishReport()} color="primary" className="mr-1">Publish</Button>
              </>
            }
            {publishStatus === PublishStatus.Publishing &&
              <Button color="danger" className="mr-1">
                Publishing
                <FontAwesomeIcon icon="spinner-third" className="ml-2" spin/>
              </Button>
            }
            {publishStatus === PublishStatus.Success &&
              <Button onClick={() => setPublishModalOpen(false)} color="secondary" className="mr-1 ml-auto">Okay</Button>
            }
            {publishStatus === PublishStatus.Error &&
              <Button onClick={() => setPublishModalOpen(false)} color="secondary" className="mr-1 ml-auto">Cancel</Button>
            }
          </ModalFooter>
        </Modal>
      </div>
    )
  } else if (view === ReportDisplayType.Live && auth.checkPermissions(['export:report'])) {
    heading = (
      <div className="pane pane-toolbar sticky-top above-picker">
        { addHeading }
        {!hideExport && !editMode && auth.checkPermissions(['export:report']) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={()=> exportReport()}>
            Download PPTX
            {!exportLoading && <img src={"/assets/PPTX.svg"} className="ml-2 text-callan-blue"/>}
            {exportLoading && <FontAwesomeIcon icon="spinner-third" spin />}
          </Button>
        }
      </div>
    )
  }
  return (
    <div className="client-report d-flex flex-grow-1 flex-direction-column">
      <RouteLeavingGuard
        when={editMode}
        navigate={path => {setEditMode(false); history.push(path)}}
      />
      {heading}
      <div className="pane pane-report flex-grow mb-4 d-flex">
        <div className="pr-1 d-flex">
          <ReportSidebar
            list={list}
            tree={tree}
            startOpen={true}
            setEditedList={setEditedList}
            portfolioId={portfolioId}
            clientId={clientId}
            planId={planId}
            reportId={reportId}
            history={history}
            auth={auth}
            user={user}
            view={view}
            editMode={(editMode && report.category?.code !== ReportCategoryCode.FPROF)}
            listType={'report'}
            reorderModal={true}
          />
        </div>
        <DndProvider backend={HTML5Backend}>
          {children}
        </DndProvider>
      </div>
    </div>
  )
}

// THIS IS NO LONGER USED AND CAN BE DELETED IN ITS OWN COMMIT
//
// interface ReportAfterPublishProps {
//   layout?: ClientPortfolioDetailLayoutFragment
//   auth: Auth
//   report: ReportsFragment
//   view: ReportDisplayType
//   publishCount: number // increment this to force get after publish to run
//   setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
//   setReportState: (value:React.SetStateAction<ReportStateType>) => void
//   reportState: object
//   clientId: number
//   portfolioId?: number
//   instanceType?: ReportInstanceType
// }

// export const ReportAfterPublish: React.FC<ReportAfterPublishProps> = (props) => {
//   const {layout, report, auth, view, publishCount, setEditedDraftLayout, setReportState, reportState, clientId, portfolioId, instanceType} = props
//   return(<>
//     {layout?.sections?.map((section, sectionNum) =>{
//       return section?.components?.map(
//         (component, componentNum) => {
//           if(component?.type && AfterPublishMapping[component?.type]){
//             const element = AfterPublishMapping[component?.type]
//             if(element){
//               return React.createElement(element, {
//                 component: component,
//                 report: report,
//                 auth: auth,
//                 view: view,
//                 publishCount: publishCount,
//                 key: `component-${sectionNum}-${component?.id}`,
//                 sectionNumber: sectionNum,
//                 componentNumber: componentNum,
//                 editMode: false,
//                 setEditedDraftLayout,
//                 editedDraftLayout: layout,
//                 setReportState: setReportState,
//                 reportState: reportState,
//                 clientId: clientId,
//                 portfolioId,
//                 instanceType,
//               })
//             }
//           }
//           return (<React.Fragment key={"C-" + componentNum} />)
//         }
//       )
//     })}
//   </>
//   )

// }

type ReportErrorProps = {
  error: string
}

// When errors happen show this component
export const ReportError: React.FC<ReportErrorProps> = ({error}) => {
  return (
    <div>
      <div className={classNames("pane pane-table pane-profile pane-missing-component mt-4 pb-3")}>
        <div className="w-100 py-4 d-flex align-items-center justify-content-center">
          <div>
            {error}
          </div>
        </div>
      </div>
      <div className={classNames("pane pane-table pane-profile pane-missing-component mt-4 pb-3")}>
        <div className="w-100 py-4 d-flex align-items-center justify-content-center">
          <div>
          </div>
        </div>
      </div>
      <div className={classNames("pane pane-table pane-profile pane-missing-component mt-4 pb-3")}>
        <div className="w-100 py-4 d-flex align-items-center justify-content-center">
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface RolloverModalProps {
  show: boolean
  toggle: () => void
  fireRollover: () => void
}

const RolloverModal: React.FC<RolloverModalProps> = ({ show, toggle, fireRollover }) => (
  <Modal size="md" className="mt-5" isOpen={show} toggle={toggle} zIndex={1500}>
    <ModalHeader toggle={toggle}>Rollover Report</ModalHeader>
    <ModalBody>
      The as-of date for every component will be advanced to the next reporting period. The new as-of date will be relative to each component's current quarter and report's frequency
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
      <Button color="primary" onClick={fireRollover}>Rollover As-of Dates</Button>
    </ModalFooter>
  </Modal>
)

interface ConfirmChooseDateActionModalProps {
  show: boolean
  toggle: () => void
  setConfirmChooseDate: (value: boolean) => void
  messages: string[]
}

const BlankComponentMessages = "There's no linked component present."

const ConfirmChooseDateActionModal: React.FC<ConfirmChooseDateActionModalProps> = (props) => {
  const { show, toggle, setConfirmChooseDate, messages } = props
  return (
    <Modal size="md" className="mt-5" isOpen={show} toggle={toggle} zIndex={1500}>
      <ModalHeader>Linked Components Present</ModalHeader>
      <ModalBody className="modal-medium-height">
        {messages.length === 0 && BlankComponentMessages}
        {messages.length > 0 && messages.map((message, idx) => (<p key={idx}>{message}</p>))}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
        <Button color="primary" onClick={() => setConfirmChooseDate(true)}>Confirm</Button>
      </ModalFooter>
    </Modal>
  )
}

interface ChooseDateModalProps {
  show: boolean
  toggle: () => void
  chooseDate: string
  setChooseDate: (value: string) => void
  fireChooseDate: () => void
}

const ChooseDateModal: React.FC<ChooseDateModalProps> = (props) => {
  const { show, toggle, fireChooseDate, chooseDate, setChooseDate } = props
  return (
    <Modal size="md" className="mt-5" isOpen={show} toggle={toggle} zIndex={1500}>
      <ModalHeader>Set As-of Date</ModalHeader>
      <ModalBody className="modal-medium-height">
        As-of date for report's components:
        <FormInput
          idx={"1-report-as-of-date"}
          property={"as-of-date"}
          displayName={""}
          propertyVal={chooseDate}
          editMode={true}
          updateValue={setChooseDate}
          type={"date"}
          subtype={"quarter"}
          subClasses={{inputClasses: "force-zindex", inputWrapperClasses: "col-sm-7", wrapperClasses: "no-gutters"}}
          inputProps={{max: appDate.format(DATE_API_FORMAT)}}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
        <Button color="primary" onClick={fireChooseDate}>Update Components</Button>
      </ModalFooter>
    </Modal>
  )
}


interface ResetAsOfModalProps {
  show: boolean
  toggle: () => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment | undefined
}

const ResetAsOfModal: React.FC<ResetAsOfModalProps> = ({ show, toggle, setEditedDraftLayout, editedDraftLayout }) => {
  const [asOfDate, setAsOfDate] = useState<string>(get(first(first(editedDraftLayout?.sections)?.components)?.draftSettings, 'date') || '')
  const  updateAsOf = () => {
    setEditedDraftLayout((prevState) => {
      if(asOfDate){
        let oldState = cloneDeep(prevState || ({sections: [], __typename: "Layout"} as ClientPortfolioDetailLayoutFragment))
        if(!oldState?.sections) {
          set(oldState, "sections", [])
        }
        let newState = iassign(
          oldState,
          currentState => currentState?.sections,
          sectionsTable => {
            let sections = cloneDeep(sectionsTable || [])
            return sections?.map(section => {
              if(section){
                if(!section?.components) {
                  set(section, "components", [])
                }
                let newSection = iassign(
                  section,
                  currentSection => currentSection?.components,
                  components => {
                    let newComponents = cloneDeep(components)
                    return newComponents?.map(component => {
                      if(component){
                        if(!component?.draftSettings) {
                          set(component, "draftSettings", [])
                        }
                        let newComponent = iassign(
                          component,
                          currentComponent => currentComponent?.draftSettings,
                          settings => {
                            if(settings && "date" in settings && "monthlyOptions" in settings && settings.monthlyOptions){
                              return {...settings, date: asOfDate, monthlyOptions: {...settings.monthlyOptions, dates: [], __typename: settings.monthlyOptions.__typename}}
                            } else if (settings && "date" in settings){
                              return {...settings, date: asOfDate}
                            }
                            return settings
                          }
                        )
                        return {...newComponent, approval: {__typename: 'ComponentApprovalLookup' as 'ComponentApprovalLookup', code: ComponentApprovalCode._1, value: "Needs Review"}}
                      }
                      return component
                    })
                  })
                return newSection
              }
              return section
            })
          }
        )
        toggle()
        return newState
      }
    })
  }

  return(
    <Modal size="md" className="mt-5" isOpen={show} toggle={toggle} zIndex={1500}>
      <ModalHeader toggle={toggle}>Reset As-of Date for all Components</ModalHeader>
      <ModalBody className='modal-medium-height'>
        <FormInput
          idx={1}
          editMode={true}
          property="reset-date"
          label="As of date"
          type="date"
          subtype="quarter"
          required={true}
          propertyVal={asOfDate}
          updateValue={(value:string) => setAsOfDate(value)}
          subClasses={{inputClasses: "force-zindex", wrapperClasses: "no-gutters"}}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
        <Button color="primary" onClick={updateAsOf}>Update</Button>
      </ModalFooter>
    </Modal>
  )
}


export default ReportMain